
import { NotificationManager } from 'react-notifications';

export const Toast = ({ type = 'success', message, time = 3000, toastId }) => {
  if (Array.isArray(message)) {
    let item;

    for (item of message) {
      NotificationManager[type](item.message, '', time, toastId || undefined);
    }
  } else {
    NotificationManager[type](message, '', time, toastId || undefined);
  }
};
