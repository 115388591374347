import React, { useEffect, useRef, useState } from "react";
import "../CustomerOrder/styles.scss";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { handleNameField } from "service/helperFunctions";
import {
  NormalButton,
  CountryCodeDropDown,
  CommonPageLoader,
  NormalInput,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import ErrorComponent from "../../common/ErrorComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createCustomerAddress,
  getCustomerActiveAddress,
  updateCustomerAddress,
  deleteCustomerActiveAddress,
} from "action/cartAct";
import edit_underline from "assets/images/edit_underline.png";
import bin from "assets/images/bin.png";
import AddressDetails from "../AddressDetail/index";
import otpsuccess from "assets/svg/circle-check-fill.svg";
import {
  sendOtpEmailAddress,
  sendOtpVerifyEmailAddress,
  sendOtpphoneAddress,
  sendOtpVerifyphoneAddress,
} from "action/AuthAct";
const initalState = {
  firstName: "",
  lastName: "",
  userTypeRole: "",
  phoneNumber: "",
  countryCode: "+91",
  default: false,
  AddressDetails: {
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    area: "",
    pincode: "",
    location: { lat: "", long: "" },
  },
};
function AddressDetail({
  getCustomerActiveAddress,
  createCustomerAddress,
  updateCustomerAddress,
  orderId,
  deleteCustomerActiveAddress,
  sendOtpAddressEmailCall,
  sendOtpVerifyAddressCall,
  sendOtpphoneAddress,
  sendOtpVerifyphoneAddress,
}) {
  const [error, setErrors] = useState({});
  const [formData, setFormData] = useState(initalState);
  const [isLoading, setIsLoading] = useState(true);
  const [customerAddress, setCustomerAddress] = useState({});
  const userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  const [showAdressPopup, setshowAdressPopup] = useState(false);
  const [showDeleteAddressPopup, setDeleteshowAddressPopup] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [sendOtpPopup, setSendOtpPopup] = useState(false);
  const [sendmobileOtpPopup, setSendmobileOtpPopup] = useState(false);
  const [sendSucess, SetSucesspopup] = useState(false);
  const [sendphoneSucess, SetsendphoneSucess] = useState(false);
  const [sendSucessEmail, SetSucessEmailPopup] = useState(false);
  const [otpmobileSend, setOtpmobileSend] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [isButtonVerify, setisButtonVerify] = useState(true);
  const [AddressOtp, setAddressOtp] = useState({
    otp: "",
  });
  const handleClosePopup = () => setshowAdressPopup(false);
  const handleShowPopup = () => setshowAdressPopup(true);
  const handleCloseEmailPopup = () => {
    setSendOtpPopup(false);
    setAddressOtp({ otp: "" });
  };
  const handleCloseMobilePopup = () => {
    setSendmobileOtpPopup(false);
    setAddressOtp({ otp: "" });
  };
  const handleClosePopupDeleteAddress = () => setDeleteshowAddressPopup(false);
  const handleShowPopupDeleteAddress = (id) => {
    setAddressID(id);
    setDeleteshowAddressPopup(true);
  };

  const [isEdit, setIsEdit] = useState(true);

  const [FirstName, setFirstName] = useState(null);
  const [AddressID, setAddressID] = useState(null);
  const [LastName, setLastName] = useState(null);
  const [Email, setEmail] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState(null);
  const [countryCode, setCountryCode] = useState("+91");

  //life cycle
  useEffect(() => {
    if (userDetails?._id) {
      fetchCustomerActiveAddress();
    }
  }, []);

  const handleChange = ({ target: { name, value, currency } }) => {
    if (name === "countryCode") {
      setCountryCode(value);
    }
  };

  const fetchCustomerActiveAddress = async () => {
    setIsLoading(true);
    await getCustomerActiveAddress(localStorage.getItem("userId"))
      .then((data) => {
        if (!!data) {
          setCustomerAddress(data.data);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const newAddress = () => {
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPhoneNumber(null);
    setCountryCode("+91");
    setAddressID(null);
    setFormData({
      AddressDetails: {
        country: "",
        state: "",
        address1: "",
        address2: "",
        city: "",
        area: "",
        pincode: "",
        location: {
          lat: "",
          long: "",
        },
      },
    });
    handleShowPopup();
  };
  const editAddress = (data) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setPhoneNumber(data.mobileNumber);
    setCountryCode(data.countryCode);
    setAddressID(data._id);
    setOtpSend(true);
    setOtpmobileSend(true);
    setFormData({
      AddressDetails: {
        country: data.AddressDetails.country,
        state: data.AddressDetails.state,
        address1: data.AddressDetails.address1,
        address2: data.AddressDetails.address2,
        city: data.AddressDetails.city,
        area: data.AddressDetails.area,
        pincode: data.AddressDetails.pincode,
        location: {
          lat: data.AddressDetails.location.lat,
          long: data.AddressDetails.location.long,
        },
      },
    });

    handleShowPopup();
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  //sent email otp
  const sendOtpContact = (e) => {
    e.preventDefault();
    let body = {
      email: Email,
    };
    sendOtpAddressEmailCall(body)
      .then(() => setSendOtpPopup(true))
      .catch(() => setisButtonClicked(false));
  };
  // verify otp
  const handleOtpSubmit = () => {
    let body = {
      email: Email,
      otp: AddressOtp.otp,
    };
    sendOtpVerifyAddressCall(body)
      .then(() => {
        SetSucesspopup(true);
        setSendOtpPopup(false);
        SetSucessEmailPopup(true);
        setOtpSend(true);
        setAddressOtp({ otp: "" });
      })
      .catch(() => setisButtonClicked(false));
  };
  //sent mobile otp
  const sendOtphone = (e) => {
    e.preventDefault();
    // if (Email) {
    //   return Toast({
    //     type: "error",
    //     message: "Please Enter Email Id",
    //   });
    // }
    let body = {
      countryCode: countryCode,
      mobileNumber: PhoneNumber,
    };
    sendOtpphoneAddress(body)
      .then(() => setSendmobileOtpPopup(true))
      .catch(() => setisButtonClicked(false));
  };
  // verify mobile otp
  const handlemobileOtpSubmit = () => {
    let body = {
      countryCode: countryCode,
      mobileNumber: PhoneNumber,
      otp: AddressOtp.otp,
    };
    sendOtpVerifyphoneAddress(body)
      .then(() => {
        SetsendphoneSucess(true);
        setSendmobileOtpPopup(false);
        setOtpmobileSend(true);
        setAddressOtp({ otp: "" });
      })
      .catch(() => setisButtonClicked(false));
  };
  const handleInputOtpChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setAddressOtp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({ ...tempErrors });
    setisButtonVerify(false);
  };

  const handleAddressDelete = async () => {
    setIsLoading(true);
    let id = localStorage.getItem("userId") + "/" + AddressID;

    await deleteCustomerActiveAddress(id)
      .then(() => {
        fetchCustomerActiveAddress();
        setDeleteshowAddressPopup(false);
        setIsLoading(false);

        Toast({
          type: "success",
          message: "Your address has been successfully deleted.",
        });
      })
      .catch(() => setIsLoading(false));
  };

  const handleAddressSubmit = async () => {
    if (AddressID) {
      setOtpSend(true);
      setOtpmobileSend(true);
    }
    if (validator.current.allValid() && otpSend && otpmobileSend) {
      // submit action (API call)

      //cleanup
      formData["userId"] = localStorage.getItem("userId");
      formData["firstName"] = handleNameField(FirstName);
      formData["lastName"] = handleNameField(LastName);
      formData["email"] = handleNameField(Email);
      formData["mobileNumber"] = handleNameField(PhoneNumber);
      formData["id"] = handleNameField(AddressID);
      formData["countryCode"] = handleNameField(countryCode);
      formData["userTypeRole"] = "customer";

      //ormData["location"]["lat"] = "customer";
      //ormData["location"]["long"] = "customer";

      delete formData["__v"];
      let formAction = AddressID
        ? updateCustomerAddress
        : createCustomerAddress;

      await formAction(formData)
        //await createCustomerAddress(formData)
        .then(() => {
          // setFirstName(null);
          // setLastName(null);
          // setEmail(null);
          // setPhoneNumber(null);
          // setAddressID(null);
          // setFormData({
          //   AddressDetails: {
          //     country: "",
          //     state: "",
          //     address1: "",
          //     address2: "",
          //     city: "",
          //     area: "",
          //     pincode: "",
          //     location: {
          //       lat: "",
          //       long: "",
          //     }
          //   }
          // })

          // close add address popup
          setshowAdressPopup(false);
          setIsLoading(false);

          fetchCustomerActiveAddress();
          SetSucesspopup(false);
          SetsendphoneSucess(false);
          SetSucessEmailPopup(false);
          setOtpSend(false);
          setOtpmobileSend(false);
          Toast({
            type: "success",
            message: AddressID
              ? "Your address has been updated successfully."
              : "Your address has been added successfully.",
          });

          //window.location.reload(false); //todo reload component only
        })
        .catch(() => setIsLoading(false));
    } else {
      validator.current.showMessages();
      if (!otpSend) {
        Toast({
          type: "error",
          message:
            "To verify your email address, please click on the 'Send OTP' button.",
        });
      }
      if (!otpmobileSend) {
        Toast({
          type: "error",
          message:
            "To verify your mobile number, please click on the 'Send OTP' button.",
        });
      }
      setUpdateValidator(!updateValidator);
      setIsLoading(true);
    }
  };

  return (
    <>
      <div id="custom-profile-customer">
        <div className="customer_detail_inner_container">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="header">My Addresses</div>
              <div className="filter-outline">
                <NormalButton
                  primary
                  label="Add New Address"
                  className="order-track ff"
                  onClick={() => newAddress()}
                />
              </div>
            </div>
          </div>
        </div>

        <div id="order-custom" className="chef_detail_inner_container mt-3">
          <div className="availble-advance-order">
            {!isLoading ? (
              <div className="row">
                {customerAddress?.length > 0 ? (
                  <>
                    {customerAddress.map((key, index) => (
                      <div
                        className="col-xs-12 col-sm-12 col-lg-12 mb-2 bg-grey-f0 p-3 "
                        key={index}
                      >
                        <div className={"address-boxes"}>
                          <div className="address-head d-flex justify-content-between mb-1">
                            <div className="address-head-left">
                              <div>
                                <span
                                  className="text-secondary-color fs-18 fw-700"
                                  o
                                >
                                  {key.firstName} {key.lastName}{" "}
                                </span>
                              </div>
                            </div>
                            {!key.default ? (
                              <div className="address-head-right px-4">
                                <img
                                  className="mx-3 cursor-pointer"
                                  src={edit_underline}
                                  alt="icon"
                                  onClick={() => editAddress(key)}
                                />
                                <img
                                  src={bin}
                                  alt="icon"
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleShowPopupDeleteAddress(key._id)
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="address-body fs-14 mt-2">
                            <div className="d-flex">
                              <p className="me-5">
                                Ph. No: {key.countryCode} {key.mobileNumber}
                              </p>
                              <p>Email: {key.email}</p>
                            </div>
                            <p>{key.AddressDetails.address1} </p>
                            <p>{key.AddressDetails.address2} </p>
                            <p> {key.AddressDetails.area} </p>
                            <p>
                              {key.AddressDetails.city} -{" "}
                              {key.AddressDetails.pincode}{" "}
                            </p>
                            <p>
                              {key.AddressDetails.state} -{" "}
                              {key.AddressDetails.country}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="col-12">
                    <p className="text-center fs-16 fw-600 text-black-1c">
                      No data found !!!
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <CommonPageLoader />
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showDeleteAddressPopup}
        onHide={handleClosePopupDeleteAddress}
        backdrop="static"
        keyboard={false}
        className="address-popup"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" text-center fw-700 fs-20">
          Are you Sure you want to Delete?
        </Modal.Body>
        <Modal.Footer>
          <NormalButton
            whiteBtn
            label={"Yes, Delete"}
            className="create-order-btn"
            onClick={handleAddressDelete}
          />
          <NormalButton
            primary
            label="No, Keep Address Info"
            onClick={handleClosePopupDeleteAddress}
            className="create-order-btn border-btn"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAdressPopup}
        onHide={handleClosePopup}
        backdrop="static"
        keyboard={false}
        className="address-popup my-address-content"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {AddressID ? "Edit Address" : "Add New Address"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={FirstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {validator.current.message("First Name", FirstName, "required")}
            </Form.Group>

            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={LastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {validator.current.message("Last Name", LastName, "required")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email ID"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={AddressID ? true : false || sendSucessEmail}
              />
              {validator.current.message("Email", Email, "required")}
              {!sendSucess ? (
                AddressID ? (
                  ""
                ) : (
                  <div
                    className="text-end text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtpContact}
                  >
                    <span>Send OTP</span>
                  </div>
                )
              ) : AddressID ? (
                ""
              ) : (
                <>
                  <div className="text-end text-primary-color fw-500 fs-16 cursor-pointer">
                    <img src={otpsuccess} alt="otpsuccess" />
                  </div>
                </>
              )}
              <Modal
                show={sendOtpPopup}
                onHide={handleCloseEmailPopup}
                backdrop="static"
                keyboard={false}
                className="sendotp-popup"
                size="sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black-25 fs-14 text-center">
                  OTP has been sent to email id {Email}
                </Modal.Body>
                <Modal.Footer>
                  <div className="input-group fs-16">
                    <NormalInput
                      label="Enter OTP"
                      value={AddressOtp.otp}
                      onChange={handleInputOtpChange}
                      name="otp"
                    />
                    {error.otp && <ErrorComponent message={error.otp[0]} />}
                  </div>
                  {error.emailotp && (
                    <ErrorComponent message={error.emailotp[0]} />
                  )}
                  <div
                    className="set-otp mt-1 text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtpContact}
                  >
                    <span>Resend OTP</span>
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center w-100 text-center">
                    <div className="">
                      <NormalButton
                        primary
                        label="Verify"
                        className="w-100 fw-700"
                        onClick={handleOtpSubmit}
                        disabled={isButtonVerify}
                      />
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form.Group>
            <Form.Group>
              <Form.Label>Mobile Number</Form.Label>
              <div className="input-group position-relative phone-number-input flex-inherit">
                <div className="country-code-dropdown">
                  <CountryCodeDropDown
                    value={countryCode}
                    onClick={(e, cur) => {
                      handleChange({
                        target: {
                          name: "countryCode",
                          value: e,
                          currency: cur,
                        },
                      });
                    }}
                    disabled={AddressID ? true : false || sendphoneSucess}
                  />
                </div>
                <input
                  type="number"
                  placeholder="Enter mobile number"
                  value={PhoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  disabled={AddressID ? true : false || sendphoneSucess}
                  className="cursor-notallowed"
                />
              </div>
              {validator.current.message(
                "countryCode",
                countryCode,
                "required"
              )}
              {validator.current.message(
                "PhoneNumber",
                PhoneNumber,
                "required|min:10|max:20"
              )}
              {!sendphoneSucess ? (
                AddressID ? (
                  ""
                ) : (
                  <div
                    className="text-end text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtphone}
                  >
                    <span>Send OTP</span>
                  </div>
                )
              ) : AddressID ? (
                ""
              ) : (
                <div className="text-end text-primary-color fw-500 fs-16 cursor-pointer">
                  <img src={otpsuccess} alt="otpsuccess" />
                </div>
              )}

              <Modal
                show={sendmobileOtpPopup}
                onHide={handleCloseMobilePopup}
                backdrop="static"
                keyboard={false}
                className="sendotp-popup"
                size="sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black-25 fs-14 text-center">
                  OTP has been sent to Phone Number {PhoneNumber}
                </Modal.Body>
                <Modal.Footer>
                  <div className="input-group fs-16">
                    <NormalInput
                      label="Enter OTP"
                      value={AddressOtp.otp}
                      onChange={handleInputOtpChange}
                      name="otp"
                    />
                    {error.otp && <ErrorComponent message={error.otp[0]} />}
                  </div>
                  {error.emailotp && (
                    <ErrorComponent message={error.emailotp[0]} />
                  )}
                  <div
                    className="set-otp mt-1 text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtphone}
                  >
                    <span>Resend OTP</span>
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center w-100 text-center">
                    <div className="">
                      <NormalButton
                        primary
                        label="Verify"
                        className="w-100 fw-700"
                        onClick={handlemobileOtpSubmit}
                        disabled={isButtonVerify}
                      />
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form.Group>
            <div className="mt-4">
              <AddressDetails
                isEdit={isEdit}
                validator={validator}
                formData={formData}
                setFormData={setFormData}
                updateValidator={updateValidator}
                isDefault={true}
              />
            </div>
          </Form>
          {!otpSend && !otpmobileSend && (
            <p className="text-center fs-16 mt-3 mb-0 text-secondary-color">
              We will send you OTP to your phone number and email ID
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <NormalButton
            whiteBtn
            label="Cancel"
            onClick={handleClosePopup}
            className="create-order-btn border-btn me-2"
          />
          <NormalButton
            primary
            label={AddressID ? "Update" : "Save"}
            className="create-order-btn"
            onClick={handleAddressSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerActiveAddress,
      createCustomerAddress,
      updateCustomerAddress,
      deleteCustomerActiveAddress,
      sendOtpAddressEmailCall: sendOtpEmailAddress,
      sendOtpVerifyAddressCall: sendOtpVerifyEmailAddress,
      sendOtpphoneAddress,
      sendOtpVerifyphoneAddress,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AddressDetail);
