import { addQuery, defaultValidator } from "service/helperFunctions";
import { chefdetailsApi } from "../service/apiVariables";

import { userLocationType } from "service/actionType";
//get Chef Details By Id Api
export const getMenuDetails =
  (body, query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, chefdetailsApi.getMenuApi);
        api({ ...chefdetailsApi.getMenuApi, body })
          .then((data) => {
            let page =
              data && data.data && data.data.pageMeta
                ? data.data.pageMeta.page
                : 1;
            dispatch({
              type: userLocationType.currentPageMenu,
              payload: page,
            });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//get Chef Details By Id Api
export const chefCompleted =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.chefCompleted, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const verifySubScriptionDetail =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.verifySubScriptionDetail, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getMenuWithChef =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getMenuWithChef, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// create dish
export const createDish =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.createDish, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
// Update dish
export const upDateDish =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.updateDish, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
export const menuAvailability =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.menuAvailability, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
export const deleteDish =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.deleteDish, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };




export const businessTypeList =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.businessTypeList, id })
          .then((response) => {
            resolve(response)
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
//create Chef Details By Id Api
export const chefUpdate =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.chefUpdate, body })
          .then((response) => {
            if (
              defaultValidator(
                response.message ===
                "An account with this number already exists. You can complete your registration with the same number.",
                response.message ===
                "Account with the mobile number already exists, complete registration with the same number"
              )
            ) {
              Toast({ type: "success", message: response.message });
            }
            resolve(response);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//Quite Chef Details By Id Api
export const chefQuite =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.chefUpdate, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const chefEditUpdate =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.chefEditUpdate, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };


  //   export const subscriptionInitiate =
  // (datum, options) =>
  //   (dispatch, getState, { api, Toast }) => {
  //     return new Promise((resolve, reject) => {
  //       api({
  //         ...chefdetailsApi.subscriptionInitiate,
  //         body: datum,
  //         headers: {
  //           "Content-Type": "Application/json",
  //           Authorization: `Bearer ${options.clientSecret}`,
  //         },
  //       })
  //         .then((response) => resolve(response))
  //         .catch(({ message }) => reject(Toast({ type: "error", message })));
  //     });
  //   };


    export const subscriptionInitiate =
    (body) =>
      (dispatch, getState, { api, Toast }) => {
        return new Promise((resolve, reject) => {
          api({ ...chefdetailsApi.subscriptionInitiate, body })
            .then((response) => resolve(response))
            .catch(({ message }) => reject(Toast({ type: "error", message })));
        });
      };

export const deactivateChef =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.deactivateChef, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
export const deleteChef =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.deleteChef, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const deleteUser =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.deleteUser, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getChefWithBrand =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getByBrandName, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get single chef
export const getSingle =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getSingle, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getSinglePending =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getSinglePending, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get single chef pdf
export const getChefPdf =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getPdf, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
//get Chef Details  Api
export const getChefDetails =
  (query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, chefdetailsApi.getChefDetailsApi);
        api({ ...chefdetailsApi.getChefDetailsApi })
          .then((data) => {
            let page =
              data && data.data && data.data.pageMeta
                ? data.data.pageMeta.page
                : 1;
            dispatch({
              type: userLocationType.currentPageChef,
              payload: page,
            });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//get Chef Details By Id Api
export const getCusineList =
  () =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getCusineList })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
//get Chef Details By Id Api
export const foodlabelList =
  () =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.foodlabelList })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// contactUs Api
export const contactUs =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.contactUsApi, body })
          .then(({ message, data }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// Filter Api
export const filterCuisine =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.filterCuisineApi, body })
          .then(({ data }) => resolve(data))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get all order user
export const getAllOrderChef =
  (query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, chefdetailsApi.getAllOrderChef);
        api({ ...chefdetailsApi.getAllOrderChef })
          .then((data) => {
            let page =
              data && data.data && data.data.pageMeta
                ? data.data.pageMeta.page
                : 1;
            dispatch({
              type: userLocationType.currentPageMenu,
              payload: page,
            });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get active order
export const getAllActiveOrderChef =
  (query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, chefdetailsApi.getAllActiveOrderChef);
        api({ ...chefdetailsApi.getAllActiveOrderChef })
          .then((data) => {
            let page =
              data && data.data && data.data.pageMeta
                ? data.data.pageMeta.page
                : 1;
            dispatch({
              type: userLocationType.currentPageMenu,
              payload: page,
            });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// Order Uodate status Api
export const getOrderUpdateStatus =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.getOrderUpdateStatus, body })
          .then(({ message, data }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
// Order Uodate status Api
export const orderUpdateStatus =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.orderUpdateStatus, body })
          .then(({ message, data }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//payment Subscription
export const paymentSubscription =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.paymentSubscription, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//quit Chef
export const quitChef =
  (chefId) =>
    (dispatch, getState, { api, Toast }) => {
      chefdetailsApi.quitChef.chefId = chefId;
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.quitChef })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
export const checkDeliveryPartnerAvailablity =
  (body = { lat: "", long: "" }) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.checkDeliveryPartnerAvailablity, body })
          .then((response) => {
            // If the API call is successful, resolve the promise with the response.
            resolve(response);

            // Display a success toast message.
            Toast({
              type: "success",
              message: "Pickup location is serviceable",
            });
          })
          .catch(({ message }) => {
            // If there's an error, reject the promise and display an error message using 'Toast'.
            reject(
              // Toast({
              //   type: "error",
              //   message: message,
              // }),
              Toast({
                type: "error",
                message: "Pickup location is not serviceable. Please proceed with own delivery partner",
              })
            );
          });
      });
    };

// export const checkDeliveryPartnerAvailablity =
//   (body = { lat: "", long: "" }) =>
//   (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//       api({ ...chefdetailsApi.checkDeliveryPartnerAvailablity, body })
//         .then((response) => resolve(response))

//         .catch(({ message }) =>
//           reject(
//             Toast({
//               type: "error",
//               message: message,
//               // +

//               //  " Please Modify delivery Parner Option"
//             })
//           )
//         );
//     });
//   };

export const assigntoOwnDeliveryPartner =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.assignOrderToOwnPartner, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const trackOwnDeliveryOrderStatus =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      addQuery(body, chefdetailsApi.trackOwnPartnerOrderStatus);

      return new Promise((resolve, reject) => {
        api({ ...chefdetailsApi.trackOwnPartnerOrderStatus })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
