import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { Pagination } from "component/common/Pagination";
import { CommonPageLoader } from "component/common/CommonPageLoader";
import { history } from "service/helpers";
import { getUserDetails } from "service/helperFunctions";
import { getNotificationList, updateNotificationList } from "action/AuthAct";
import { getSingle } from "action/ChefDetailsAct";
import { logout } from "service/utilities";
import { Toast } from "service/toast";

const moment = require("moment");
export class NotificationListClass extends Component {
  state = {
    notifications: [
      { message: "Test", isViewed: true },
      { message: "Test 1", isViewed: true },
      { message: "Test 2", isViewed: true },
      { message: "Test 3", isViewed: true },
    ],
    pageMeta: null,
    isLoading: false,
    userId: null,
  };

  componentDidMount() {
    let details = getUserDetails();
    if (details?._id) {
      this.getProfile(details?._id)
      this._getNotificationList(details._id);
    }
  }

  getProfile = async (id) => {
    this.props.getSingle(id)
      .then((response) => {
        let { data } = response;
        if (data.deactivateId) {
          Toast({ type: "error", message: "Account is deactivated", toastId: 'success1' });
          logout();
        } else {
          this.setState({
            userId: data._id,
          });
        }
      })
  };

  //Get notificaton data
  _getNotificationList = (id, page = 1) => {
    this.setState({ isLoading: true });
    this.props
      .getNotificationList(id, { page })
      .then(({ data }) => {
        this.setState({
          notifications: data.list,
          pageMeta: data.pageMeta,
          isLoading: false,
        });
        if (data.list.length !== 0) {
          let payload = {
            chefId: id,
            notifications: [],
          };
          data.list.map((key) => {
            let obj = {
              _id: key._id,
              msgOpened: true,
            };
            payload.notifications.push(obj);
          });
          if (data.list.length === payload.notifications.length) {
            this.props.updateNotificationList(payload);
          }
        }
      })
      .catch(() => this.setState({ isLoading: true }));
  };

  //handle page change
  handlePagination = ({ page }) => {
    let { userId } = this.state;
    this._getNotificationList(userId, page);
  };

  render() {
    const { notifications, pageMeta, isLoading } = this.state;

    return (
      <div className="notification-page">
        <div className="container">
          <div className="row px-md-0 px-3">
            <div className="col-md-10 col-12 bg-white mx-auto py-4 px-md-5">
              <div className="pb-4 w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h3 className="text-primary-color fs-24 line-height-28 mb-0">
                    Notifications
                  </h3>
                  <div
                    className="d-flex align-items-center"
                    onClick={() => history.goBack()}
                  >
                    <i className="icon-normal-24 text-primary-color fs-24" />
                    <p className="ms-2 text-primary-color fs-18 line-height-25 fw-700 mb-0 cursor-pointer">
                      Back
                    </p>
                  </div>
                </div>
                <hr />
                <div className="pt-4">
                  {isLoading ? (
                    <CommonPageLoader />
                  ) : notifications.length > 0 ? (
                    <>
                      {notifications.map((data, index) => (
                        <div
                          className={`cursor-pointer ${data.msgOpened
                            ? "alert-notify-seen"
                            : "alert-notify"
                            }`}
                          key={index}
                        >
                          <h4 className="text-primary-color fs-16 line-height-18 mb-1">
                            {data.title}
                          </h4>
                          <div className="message">{data.message}</div>
                          <div className="time">
                            {`${moment(data.createdAt).utc().toNow(true)} ago`}
                          </div>
                        </div>
                      ))}
                      {pageMeta ? (
                        <Pagination
                          handleChange={this.handlePagination}
                          pageMeta={pageMeta}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className="empty-data">No results found !!!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotificationList,
      updateNotificationList,
      getSingle
    },
    dispatch
  );
};

const component = NotificationListClass;

export const Notifications = connect(null, mapDispatchToProps)(component);