import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LandingComp } from "component/Landing/Landing";
export class LandingClass extends Component {
  render() {
    return (
      <>
        <Helmet>
          <link rel="canonical" href="https://www.thecheflane.com/" />
          <title>
            Order your favorite home cooked authentic food online | The Chef
            Lane
          </title>
          <meta
            property="og:title"
            content="Order your favorite home cooked authentic food online | The Chef Lane"
          />
          <meta
            name="description"
            content="The Chef Lane: Our home cooked food is authentic in taste and best in class. Choose your home chef, Satisfy your cravings and get homemade food delivered."
          />
          <meta
            name="keywords"
            content="homemade cake, homemade chocolate, homemade pizza, home made chocolates, home made cake, homemade food, homemade cakes near me, homemade snacks, homemade chocolate cake, home made food, homemade birthday cake, homemade pickles, homemade sweets, home cooked, home chef, chef cooked, homebakers near me, good baker, cooked dinner, cooked dishes, home cooked food delivered, home made food online, cooked food list, home cooked meals, home made food near me, home cooked meals delivered, authentic food, homebakers, home bread bakery, hand crafted chocolate, handcrafted cake, handmade cake, handmade homemade chocolate, handmade biscuits, handmade cookies"
          />
        </Helmet>
        <LandingComp />
      </>
    );
  }
}
export const Landing = connect(null, null)(LandingClass);
