import { cartManageApi, customerApi } from "../service/apiVariables";
import { CartDataType, localCartType } from "service/actionType";

// create cart
export const handleAddToCart =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.addToCart, body })
          .then((response) => {
            resolve(response);
            let message = response?.message;
            Toast({ type: "success", message });
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message }));
          });
      });
    };
    

// create order
export const createOrder =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.createOrder, body })
          .then((response) => resolve(response))
          .catch(({ message }) => {
            reject(Toast({ type: "error", message }));
          });
      });
    };
// create all  cart
export const handleAddToCartAll =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.addAllCart, body })
          .then((response) => {
            resolve(response);
            let message = response?.message;
            Toast({ type: "success", message });
            let payload = [];
            dispatch({
              type: localCartType.totalCartLocal,
              payload,
            });
          })
          .catch(({ message }) => {
            // reject(Toast({ type: "error", message }));
            localStorage.setItem('cartMsg', message);
          });
      });
    };
// get all cart details
export const getAllCartById =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.getAllCart, body })
          .then((response) => {

            resolve(response);
            let payload = [];
            payload = [...response?.data];
            dispatch({
              type: CartDataType.totalCart,
              payload,
            });
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message }));
          });
      });
    };
// delete cart
export const deleteCart =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.deleteCart, body })
          .then((response) => {
            resolve(response);
            let payload = response?.data;
            let message = response?.message;
            Toast({ type: "success", message });
            dispatch({
              type: CartDataType.totalCart,
              payload,
            });
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message }));
          });
      });
    };
// all delete cart

export const clearCart =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.clearCart, id })
          .then((response) => {
            resolve(response);
            let message = response?.message === 'Your cart has been cleared successfully!' ? 'Order is sucessfully placed' : '';
            message && Toast({ type: "success", message });
            dispatch({
              type: CartDataType.totalCart,
              payload: [],
            });
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message }));
          });
      });
    };
// delete cart
export const priceSummery =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.priceSummery, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//get Payment Detail
export const getPaymentDetail =
  (body) => {
    return (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.createPayment, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
  }


//get Payment Detail
export const codPayment =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.codPayment, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//payment Detail Capture
export const paymentDetailCapture =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...cartManageApi.paymentDetailCapture, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getCustomerActiveAddress =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getAllActiveAddress, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getAllCountries =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getAllCountries })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getStatebyCountryCode =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getStatebyCountryCode, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const deleteCustomerActiveAddress =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.deleteActiveAddress, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// create customer
export const createCustomerAddress =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.createAddress, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// create customer
export const updateCustomerAddress =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.updateAddress, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const getPaymentDetailApi =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {

        api({ ...customerApi.createPayment, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
export const verifyPaymentDetailApi =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {

        api({ ...customerApi.verifyPayment, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };


