import React, { useState, useEffect } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NormalButton } from "component/common";
import { TableWrapper } from "component/common/TableWrapper";
import { CommonTableLoader } from "component/common/CommonTableLoader";
import { NormalSearch } from "component/common/NormalSearch";
import { getSNo } from "service/helperFunctions";
import { listReview, publishTestimonials } from "action/OrderReviewAct";
import Staricon from "assets/images/Staricon.png";
function RateReview({ listReview, publishTestimonials }) {
  
  

  const [pageMeta, setPageMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialList, setTestimonialList] = useState([]);
  const [originalTestimonialList, setOriginalTestimonialList] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const headerDetails = [
    { label: "S.No" },
    { label: "Name" },
    { label: "Review" },
    { label: "Rating" },
    { label: "Status" },
  ];
  //handle Input Search
  const handleInputSearch = ({ target: { value } }) => {
    setSearchValue(value);

    if (value) {
      const filteredList = originalTestimonialList.filter((item) =>
        item.userName.toLowerCase().includes(value.toLowerCase())
      );
      setTestimonialList(filteredList);
    } else {
      // Reset to full list when searchValue is cleared
      setTestimonialList(originalTestimonialList);
    }
  };

  //life cycle
  useEffect(() => {
    testimonialsListApiCallFunc();
  }, [page]);

  //new Request Api Call Func
  const testimonialsListApiCallFunc = async () => {
    setIsLoading(true);
    let params = {
      search: searchValue,
    };

    let body = {
      page,
      chefIdTestimonial: localStorage.getItem("userId"),
    };

    listReview(params, body)
      .then(({ data }) => {
        setIsLoading(false);
        
        if (data) {
          setOriginalTestimonialList(data);
          setTestimonialList(data);
          setPageMeta(data.pageMeta);
        }

      })
      .catch(() => setIsLoading(false));
  };

  // handle  Submit
  const handleSubmit = (id, status) => {
    let payload = { testimonialId: id, chefIsPublish: !status };
    setIsButtonClicked(true);
    publishTestimonials(payload)
      .then(() => {
        testimonialsListApiCallFunc();
        setIsButtonClicked(false);
      })
      .catch(() => setIsButtonClicked(false));
  };

  return (
    <>
      <div className="rate_detail_inner_container">
        <div>
          <div className="d-flex align-items-center justify-content-between title-rateing">
            <div className="header">Ratings & Reviews</div>
            <div className="tab-search my-3">
              <NormalSearch
                placeholder="Search"
                value={searchValue}
                name="searchValue"
                onChange={handleInputSearch}
              />
            </div>
          </div>
          <hr></hr>
          <TableWrapper
            headerDetails={headerDetails}
            pageMeta={pageMeta}
            handlePageChange={setPage}
            sortActiveKey="name"
          >
            {isLoading ? (
              <CommonTableLoader tdLength={6} />
            ) : testimonialList && testimonialList.length !== 0 ? (
              testimonialList.map((item, index) => {
                const {
                  userName = "",
                  review = "",
                  rating = "",
                  chefIsPublish = false,
                  _id,
                } = item;
                return (
                  <tr key={index}>
                    <td>{getSNo(pageMeta ? pageMeta.page : 1, 10, index)}</td>
                    <td>{userName ? userName : "-"}</td>
                    <td>{review ? review : "-"}</td>
                    <td>
                      {rating ? (
                        <div className="text-gold fw-600 d-flex align-items-center rating-span ">
                          <img
                            src={Staricon}
                            alt="rating icon"
                            className="mx-1"
                          />
                          <span>{rating}</span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <div className="d-flex align-items-center w-100 button-block-rate">
                        {chefIsPublish ? (
                          <NormalButton
                            btnGrey
                            label="Published"
                            className=" fw-500 mr-2 published-Btn"
                          />
                        ) : (
                          <NormalButton
                            primary
                            label="Publish"
                            className="fw-700 mr-2"
                            onClick={() => handleSubmit(_id, chefIsPublish)}
                            disabled={isButtonClicked}
                          />
                        )}
                        <span className="p-2" />
                        {chefIsPublish === false ? (
                          <NormalButton
                            btnGrey
                            label="Unpublished"
                            className="fw-500 mr-2 white-btn"
                          />
                        ) : (
                          <NormalButton
                            viewButtonHeader
                            label="Unpublish"
                            className=" fw-500 white-btn"
                            onClick={() => handleSubmit(_id, chefIsPublish)}
                            disabled={isButtonClicked}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <p className="py-5 fs-20 text-center text-gold-d8 line-height-23 fw-700">
                    No records found !!!
                  </p>
                </td>
              </tr>
            )}
          </TableWrapper>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ listReview, publishTestimonials }, dispatch);
};

export default connect(null, mapDispatchToProps)(RateReview);
