import React, { useRef, useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import { NormalButton } from "component/common";
import { history } from "service/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import closeBtn from "assets/images/cloceBtn.png";
import { AddReview } from "action/OrderReviewAct";
import { ternaryCondition } from "service/helperFunctions";

function UnderReviewTrial({ AddReview, oneOrder, isTrial, setisTrial }) {
  const [reviewDetails, setreviewDetails] = useState("");
  const [active, setactive] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrors] = useState({});
  const [updateValidator, setUpdateValidator] = useState(true);
  const handleInputChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setreviewDetails(value);
    setErrors({ ...tempErrors });
  };
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const handlaAddReview = async (id) => {
    if (validator.current.allValid()) {
      let body = {
        userId: localStorage.getItem("userId"),
        chefId: oneOrder.chefId,
        review: reviewDetails,
        rating: active,
        orderId: oneOrder.id,
      };
      setIsLoading(true);
      await AddReview(body)
        .then((response) => {
          if (!!response.data) {
            history.push("/index/customerProfile");
          }
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };

  let isExpired = false;
  let orderDetails = oneOrder.items;
  return (
    <div>
      <Modal
        show={isTrial}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal-order"
        centered
      >
        <Modal.Header className="bg-white border-0">
          <Modal.Title></Modal.Title>
          {!isExpired ? (
            <span class="cursor-pointer" onClick={() => setisTrial(false)}>
              <img src={closeBtn} alt="icon" />
            </span>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body className="review-popup">
          <div className="review-ratingorder">
            <div className="bitesheading col-lg-12  col-md-12 col-10 mb-5 line-height-28 fs-24 fw-700 text-primary-color">
              Rate & review your order
            </div>
            <p className="fs-16 fw-400 text-grey-4f mb-2">
              <b> Order Number</b> - {oneOrder?.orderId}
            </p>
            <p className="fs-16 fw-400 text-grey-4f order-items-num">
              <b>Order Items</b> -
              {orderDetails?.length > 0 ? (
                orderDetails?.map((item, index) => (
                  <span key={index}>
                    {" "}
                    {item.foodId?.name} X {item.quantity}
                  </span>
                ))
              ) : (
                <div className="text-gold text-center mt-2">
                  No Order Found !!!
                </div>
              )}
            </p>
            <div className="review-deActive mt-3 py-3 mb-4 px-4">
              <div className="text-black-25 fs-16 mb-3">Rating</div>
              <div className="rating-count  fs-18  text-black">
                <span
                  className={`cursor-pointer ${ternaryCondition(
                    active === 1,
                    "text-primary-color faq-active",
                    "text-grey-9d"
                  )}`}
                  onClick={() => setactive(1)}
                >
                  1
                </span>
                <span
                  className={`cursor-pointer ${ternaryCondition(
                    active === 2,
                    "text-primary-color faq-active",
                    "text-grey-9d"
                  )}`}
                  onClick={() => setactive(2)}
                >
                  2
                </span>
                <span
                  className={`cursor-pointer ${ternaryCondition(
                    active === 3,
                    "text-primary-color faq-active",
                    "text-grey-9d"
                  )}`}
                  onClick={() => setactive(3)}
                >
                  3
                </span>
                <span
                  className={`cursor-pointer ${ternaryCondition(
                    active === 4,
                    "text-primary-color faq-active",
                    "text-grey-9d"
                  )}`}
                  onClick={() => setactive(4)}
                >
                  4
                </span>
                <span
                  className={`cursor-pointer ${ternaryCondition(
                    active === 5,
                    "text-primary-color faq-active",
                    "text-grey-9d"
                  )}`}
                  onClick={() => setactive(5)}
                >
                  5
                </span>
              </div>
            </div>
            <div className="review-message">
              <textarea
                className="mt-1 d-flex flex-column w-100 form-control"
                name="message"
                placeholder="Review"
                value={reviewDetails}
                onChange={handleInputChange}
                rows="4"
              />
              {validator.current.message(
                "review details",
                reviewDetails,
                "required|max:250"
              )}
            </div>
          </div>
          <Modal.Footer className="w-100 mt-4 justify-content-center">
            <>
              <div className="d-flex pb-3">
                <NormalButton
                  primary
                  label="Submit"
                  className="px-5 mt-3 fw-700"
                  disabled={isLoading}
                  onClick={() => handlaAddReview()}
                />
              </div>
            </>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ AddReview }, dispatch);
};
export default connect(null, mapDispatchToProps)(UnderReviewTrial);
