import React from "react";
import cheflist from "assets/images/customerConnection.png";
import vegIcon from "assets/images/veg.png";
import nonvegIcon from "assets/images/nonveg.png";
import { NormalButton } from "component/common/";
import { history } from "service/helpers";

const OrderAvailable = ({ food, trackId }) => {
  return (
    <>
      <div className=" mb-3 chef_detail_inner_container p-3">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-12">
            <div className="row">
              <div className="col-sm-8 col-12">
                <div className="d-flex">
                  <div className="">
                    <div
                      className="foodimage"
                    >
                      <img
                        src={
                          !!food?.foodId?.dishImage
                            ? food?.foodId?.dishImage
                            : cheflist
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="menucard_description ml-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex mt-2">
                        <div className="foodname fs-16 line-height-20 fw-700 text-black-25 d-flex">
                          {food?.foodId?.name}
                          <div className="nonVageIcons ms-3">
                            <span className="mx-0">
                              <img
                                src={
                                  food?.foodId?.dishCategory === "Non Veg"
                                    ? nonvegIcon
                                    : vegIcon
                                }
                                alt="icons"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-rate my-3"></div>
                    Quantity - {food.quantity}
                    {" * "}₹{food.price}
                    <div className="d-block d-md-none d-flex mt-4">
                      <div className="d-flex flex-wrap w-100 me-4">
                        <div className="d-flex justify-content-between w-100 pb-1  mb-2">
                          <h4 className="fs-16 w-100 text-black-25 fw-700 mb-0">
                            {" "}
                            ₹{food.price * food.quantity}{" "} 
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4 col-12 d-flex justify-content-start align-items-end flex-column">
              <div className="text-end">
                <div
                  className={`mb-3 d-inline-flex fs-14 text-${
                    food.orderStatus === "REQUEST"
                      ? "secondary-color"
                      : food.orderStatus === "DECLINE"
                      ? "-red-DB"
                      : "gold-tt"
                  }   title-order-block  p-2   align-items-center  bg-${
                    food.orderStatus === "REQUEST"
                      ? "white-de"
                      : food.orderStatus === "DECLINE"
                      ? "white-green"
                      : "yellow-C3"
                  } `}
                >
                  {food.orderStatus === "REQUEST"
                    ? "Will be prepared soon"
                    : food.orderStatus === "DECLINE"
                    ? "Declined"
                    : "Getting prepared "}
                </div>
                <div className="cursor-pointer text-end">
                  {food.orderStatus === "DECLINE" ? (
                    <NormalButton
                      disabled={true}
                      label="Declined"
                      className="clear text-black-25 line-height-18 bg-white fs-16 fw-400"
                    />
                  ) : (
                    <NormalButton
                      primary
                      label="Track Order"
                      className="px-5 order-track ff"
                      onClick={() => {
                        history.push(
                          `/index/trackorder/${trackId}/${food?.foodId?._id}/track`
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div> */}
              <div className="d-md-block  d-lg-block   d-none  col-xs-12 col-sm-12 col-lg-4 justify-content-center align-item-center">
                <div className="p-4 mt-2 text-center text-lg-end">
                  ₹{food.price * food.quantity} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAvailable;
