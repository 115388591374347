import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import cheflist from "assets/images/customerConnection.png";
import vegIcon from "assets/images/veg.png";
import nonvegIcon from "assets/images/nonveg.png";

const OrderServedAvailable = ({ food, orderDetails, trackId }) => {
  return (
    <>
      <div className="mb-3 chef_detail_inner_container p-3 mx-2">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-8 order-image-column history">
            <div className="d-flex outline flex-column">
              <div className="">
                <div className="d-flex ">
                  <div className="">
                    <div className="foodimage">
                      <img
                        src={
                          !!food?.foodId?.dishImage
                            ? food?.foodId?.dishImage
                            : cheflist
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="menucard_description ml-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex mt-2">
                        <div className="foodname fs-18 line-height-20 fw-700 text-black-25 d-flex">
                          {food?.foodId?.name}{" "}
                          <div className="nonVageIcons ms-3">
                            <span className="mx-0">
                              <img src={
                                food?.foodId?.dishCategory === "Non Veg"
                                  ? nonvegIcon
                                  : vegIcon
                              } alt="icons" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-rate my-3"></div>
                    Quantity - {food.quantity}
                    {" * "}₹{food.price}
                    <div className="d-block d-md-none  d-flex mt-4">
                      <div className="d-flex flex-wrap w-100 me-4">
                        <h4 className="fs-16 w-100 pb-2 text-black-25 fw-700">
                          {" "}
                          ₹{food.price * food.quantity}{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-lg-4 ps-4">
          <div className="foodname fs-14 line-height-20 fw-700  text-grey-7c d-flex text-end">
            Ordered on:{" "}
            {moment(orderDetails.estimatedDeliveryDate).format(
              "Do MMMM YYYY, h:mm:ss a"
            )}
          </div>
            </div> */}
          <div className="d-md-block  d-lg-block   d-none  col-xs-12 col-sm-12 col-lg-4 justify-content-center align-item-center">
            <div className="p-4 mt-3 text-center text-lg-end">
              ₹{food.price * food.quantity}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
export default connect(null, mapDispatchToProps)(OrderServedAvailable);
