import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { SignupComp } from "component/auth/login/index";
export class SignupClass extends Component {
  render() {
    return <SignupComp />;
  }
}
export const Signup = connect(null, null)(SignupClass);
