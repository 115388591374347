import cheflist from "assets/images/customerConnection.png";
import React, { useEffect, useState } from "react";
import "./style.scss";
import "../Cart/style.scss";
import getSymbolFromCurrency from "currency-symbol-map";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";

import AddToButton from "./addToCart";
import { NormalButton } from "component/common";

import { connect } from "react-redux";
import { handleAddToCart, deleteCart, getAllCartById } from "action/cartAct";
import { bindActionCreators } from "redux";

const MenuCard = ({ list, isAddingCart, ChefDetail, isListingToPlus, deleteCart, userCart }) => {
  const [learnMore, setLearnMore] = useState(false);
  const [counter, setCounter] = useState(0);

  const [isCartcountChanged, setIsCartcountChanged] = useState(false)

  const authToken = localStorage.getItem("authToken");
  let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));

  const handleLearnmore = () => {
    setLearnMore(!learnMore);
  };

  let imagePreference = list.dishCategory === "Veg" ? vegIcon : nonvegIcon;

  const handleIncrement = () => {
    setCounter(counter + 1);
    setIsCartcountChanged(true);
  };

  const handleDeleteCart = () => {
    let itemInCart = userCart.find(item => item.foodId._id === list._id)
    let body = {
      cartId: itemInCart._id,
      userId: userDetails?._id,
    };
    let cartPayload = { userId: userDetails?._id };
    if (!!authToken) {
      deleteCart(body)
        .then((data) => {
          if (!!data) {
            getAllCartById(cartPayload);
          }
        })
    }
  };


  const handleDecrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
      setIsCartcountChanged(true);
    }
    else {
      let itemInCart = userCart.find(item => item.foodId._id === list._id)
      setCounter(counter - 1);
      if (itemInCart) {
        handleDeleteCart()
      }
    }
  };


  useEffect(() => {
    let itemInCart = userCart.find(item => item.foodId._id === list._id)
    setCounter(itemInCart ? itemInCart.quantity : 0)
  }, [userCart]);

  return (
    <>
      {!list.dishIsAvailable && (
        <>
          <div className="position-absolute blur-certfication p-3">
            <p className="blur mb-0 text-white">
              This Dish is Currently Unavailable
            </p>
          </div>
          <div className="custom-boxShadow-menu  bg-white-FF p-3 d-flex justify-content-start content-media ">
            <div className="d-flex ">
              <div className="foodimage">
                <img src={list.dishImage ? list.dishImage : cheflist} alt="" />
                <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700"></div>
              </div>

              <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                {list.dishIsAvailable}
              </div>
            </div>
            <div className=" menucard_description ml-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <div className=" foodname fs-16 fw-700 text-primary-color ">
                    {list.name.length < 15 ? (
                      list.name
                    ) : (
                      <React.Fragment>
                        {list.name.substring(0, 14)}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.name}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                </div>

                <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                  <div className="nonVageIcon mx-2">
                    {imagePreference !== "" && (
                      <img src={imagePreference} alt="" srcset="" />
                    )}
                  </div>
                  <span>
                    {ChefDetail.currency
                      ? getSymbolFromCurrency(ChefDetail.currency)
                      : "₹"}

                    {list.price}
                  </span>
                </div>
              </div>
              {list.about && (
                <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                  {list.about.length < 35 ? (
                    list.about
                  ) : learnMore ? (
                    <React.Fragment>
                      {list.about}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        ...Less
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {list.about && list.about.substring(0, 34)}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        Read more...
                      </span>
                    </React.Fragment>
                  )}
                </p>
              )}

              {list.preference ? (
                <div className="d-flex my-2 type-menu-container flex-wrap">
                  {list.preference.length <= 2 ? (
                    list.preference.map((type, index) => (
                      <span
                        key={index}
                        className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                      >
                        {type}
                      </span>
                    ))
                  ) : (
                    <React.Fragment>
                      {list.preference.map(
                        (type, index) =>
                          index < 2 && (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          )
                      )}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 300 }}
                        overlay={
                          <Tooltip>
                            {list.preference.slice(2).join(", ")}
                          </Tooltip>
                        }
                        containerPadding={20}
                      >
                        <span
                          className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                          style={{
                            color: "#b58204",
                          }}
                        >
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
      {list.deactivateId && (
        <>
          <div className="position-absolute blur-certfication">
            <p className="blur mb-0 text-white">
              This Chef’s Certification has been expired.
            </p>
          </div>
          <div className="custom-boxShadow-menu  bg-white-FF p-3 d-flex justify-content-start content-media">
            <div className="d-flex ">
              <div className="foodimage">
                <img src={list.dishImage ? list.dishImage : cheflist} alt="" />
                <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700"></div>
              </div>

              <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                {list.dishIsAvailable}
              </div>
            </div>
            <div className=" menucard_description ml-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <div className="foodname fs-16  fw-700 text-primary-color ">
                    {list.name.length < 15 ? (
                      list.name
                    ) : (
                      <React.Fragment>
                        {list.name.substring(0, 14)}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.name}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                </div>

                <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                  <div className="nonVageIcon mx-2">
                    {imagePreference !== "" && (
                      <img src={imagePreference} alt="" srcset="" />
                    )}
                  </div>
                  <span>
                    {ChefDetail.currency
                      ? getSymbolFromCurrency(ChefDetail.currency)
                      : "₹"}

                    {list.price}
                  </span>
                </div>
              </div>
              {list.about && (
                <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                  {list.about.length < 35 ? (
                    list.about
                  ) : learnMore ? (
                    <React.Fragment>
                      {list.about}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        ...Less
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {list.about && list.about.substring(0, 34)}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        Read more...
                      </span>
                    </React.Fragment>
                  )}
                </p>
              )}

              {list.preference ? (
                <div className="d-flex my-2 type-menu-container flex-wrap">
                  {list.preference.length <= 2 ? (
                    list.preference.map((type, index) => (
                      <span
                        key={index}
                        className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                      >
                        {type}
                      </span>
                    ))
                  ) : (
                    <React.Fragment>
                      {list.preference.map(
                        (type, index) =>
                          index < 2 && (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          )
                      )}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 300 }}
                        overlay={
                          <Tooltip>
                            {list.preference.slice(2).join(", ")}
                          </Tooltip>
                        }
                        containerPadding={20}
                      >
                        <span
                          className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                          style={{
                            color: "#b58204",
                          }}
                        >
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
      {list.dishIsAvailable && !list.deactivateId && (
        <>
          <div
            className={`bg-white-FF p-3 d-flex flex-column custom-boxShadow-menu h-100 ${list.typeOfMenu === "Advance Order"
              ? "min-height-165"
              : "min-height-137"
              }`}
          >
            <div className="bg-white-FF d-flex justify-content-start content-media h-100">
              {list.dishImage ? (
                <>
                  <div className="d-flex  ">
                    <div className="foodimage">
                      <img src={list.dishImage} alt="" />
                      <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700"></div>
                    </div>

                    <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                      {list.dishIsAvailable}
                    </div>
                  </div>
                  <div className=" menucard_description ml-5">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex ">
                        <div className=" foodname fs-16 fw-700 text-primary-color ">
                          {list.name.length < 15 ? (
                            list.name
                          ) : (
                            <React.Fragment>
                              {list.name.substring(0, 14)}
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 200, hide: 300 }}
                                overlay={<Tooltip>{list.name}</Tooltip>}
                                containerPadding={20}
                              >
                                <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                                  ...Read more
                                </span>
                              </OverlayTrigger>
                            </React.Fragment>
                          )}
                        </div>
                      </div>

                      <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                        <div className="nonVageIcon mx-2">
                          {imagePreference !== "" && (
                            <img src={imagePreference} alt="" srcset="" />
                          )}
                        </div>
                        <span>
                          {ChefDetail.currency
                            ? getSymbolFromCurrency(ChefDetail.currency)
                            : "₹"}

                          {list.price}
                        </span>
                      </div>
                    </div>
                    {list.about && (
                      <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                        {list.about.length < 35 ? (
                          list.about
                        ) : learnMore ? (
                          <React.Fragment>
                            {list.about}{" "}
                            <span
                              className="text-yellow-C1 cursor-pointer"
                              onClick={handleLearnmore}
                            >
                              ...Less
                            </span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {list.about && list.about.substring(0, 34)}{" "}
                            <span
                              className="text-yellow-C1 cursor-pointer"
                              onClick={handleLearnmore}
                            >
                              Read more...
                            </span>
                          </React.Fragment>
                        )}
                      </p>
                    )}

                    <div className="d-flex justify-content-center align-items-center add-button-cart">
                      {list.preference ? (
                        <div className="d-flex  type-menu-container flex-wrap">
                          {list.preference.length <= 2 ? (
                            list.preference.map((type, index) => (
                              <span
                                key={index}
                                className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                              >
                                {type}
                              </span>
                            ))
                          ) : (
                            <React.Fragment>
                              {list.preference.map(
                                (type, index) =>
                                  index < 2 && (
                                    <span
                                      key={index}
                                      className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                                    >
                                      {type}
                                    </span>
                                  )
                              )}
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 200, hide: 300 }}
                                overlay={
                                  <Tooltip>
                                    {list.preference.slice(2).join(", ")}
                                  </Tooltip>
                                }
                                containerPadding={20}
                              >
                                <span
                                  className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                                  style={{
                                    color: "#b58204",
                                  }}
                                >
                                  ...Read more
                                </span>
                              </OverlayTrigger>
                            </React.Fragment>
                          )}
                        </div>
                      ) : null}
                      {/* {!list.isButtonHide &&
                        list.typeOfMenu !== "Advance Order" &&
                        ChefDetail.service !== "LISTING" && (
                          <AddToButton
                            id={list._id}
                            ChefDetail={ChefDetail}
                            list={list}
                          />
                        )} */}
                    </div>
                  </div>
                </>
              ) : (
                <div className=" menucard_description ms-0 w-100">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex ">
                      <div className=" foodname fs-16 fw-700 text-primary-color ">
                        {list.name.length < 15 ? (
                          <div className="d-flex flex-row">
                            <div>
                              <img
                                src={cheflist}
                                className="dummyFood"
                                alt="food"
                              />
                            </div>
                            <div className="ps-3">{list.name}</div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {list.name.substring(0, 14)}
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 200, hide: 300 }}
                              overlay={<Tooltip>{list.name}</Tooltip>}
                              containerPadding={20}
                            >
                              <span className="cursor-pointer">...</span>
                            </OverlayTrigger>
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                      <div className="nonVageIcon mx-2">
                        {imagePreference !== "" && (
                          <img src={imagePreference} alt="" srcset="" />
                        )}
                      </div>
                      <span>
                        {ChefDetail.currency
                          ? getSymbolFromCurrency(ChefDetail.currency)
                          : "₹"}
                        {list.price}
                      </span>
                    </div>
                  </div>
                  {list.about && (
                    <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                      {list.about.length < 35 ? (
                        list.about
                      ) : learnMore ? (
                        <React.Fragment>
                          {list.about}{" "}
                          <span
                            className="text-yellow-C1 cursor-pointer"
                            onClick={handleLearnmore}
                          >
                            ...Less
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {list.about && list.about.substring(0, 34)}{" "}
                          <span
                            className="text-yellow-C1 cursor-pointer"
                            onClick={handleLearnmore}
                          >
                            Read more...
                          </span>
                        </React.Fragment>
                      )}
                    </p>
                  )}

                  <div className="d-flex justify-content-between align-items-center mb-auto add-button-cart mt-2">
                    {list.preference ? (
                      <div className="d-flex  type-menu-container flex-wrap">
                        {list.preference.length <= 2 ? (
                          list.preference.map((type, index) => (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          ))
                        ) : (
                          <React.Fragment>
                            {list.preference.map(
                              (type, index) =>
                                index < 2 && (
                                  <span
                                    key={index}
                                    className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                                  >
                                    {type}
                                  </span>
                                )
                            )}
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 200, hide: 300 }}
                              overlay={
                                <Tooltip>
                                  {list.preference.slice(2).join(", ")}
                                </Tooltip>
                              }
                              containerPadding={20}
                            >
                              <span
                                className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                                style={{
                                  color: "#b58204",
                                }}
                              >
                                ...Read more
                              </span>
                            </OverlayTrigger>
                          </React.Fragment>
                        )}
                      </div>
                    ) : null}


                    {/* {!list.isButtonHide &&
                      list.typeOfMenu !== "Advance Order" &&
                      ChefDetail.service !== "LISTING" && (
                        <AddToButton
                          id={list._id}
                          ChefDetail={ChefDetail}
                          list={list}
                        />
                      )} */}


                  </div>
                </div>
              )}
            </div>
            {!list.isButtonHide &&
              ChefDetail.service !== "LISTING" &&
              !ChefDetail.isSubscriptionExpired &&
              <div className="d-flex justify-content-end align-items-center  mb-3">
                <div className="add-count-contain-minus">
                  <NormalButton
                    label="-"
                    className="count text-black-25 fs-20 bg-white-FF add-count-btn p-0"
                    onClick={() => handleDecrement()}
                    disabled={counter === 0}
                  // disabled={isAddingCart}
                  />
                </div>
                <div className="px-2 text-center">
                  <NormalButton
                    label={counter}
                    disabled={counter === 0}
                    primary
                    className="count text-white-FF fs-14 bg-primary-color add-count-num-btn p-0 "
                  // disabled={isAddingCart}
                  />
                </div>
                <div className="add-count-contain-plus ">
                  <NormalButton
                    label="+"
                    className="count text-black-25 fs-20 bg-white-FF add-count-btn p-0"
                    onClick={() => handleIncrement()}
                  // disabled={isAddingCart}
                  />
                </div>
              </div>
            }
            {!list.isButtonHide &&
              ChefDetail.service !== "LISTING" &&
              !ChefDetail.isSubscriptionExpired && (
                <div className="d-flex justify-content-between align-items-end mt-auto ">
                  <p className="mt-1 fw-700 fs-16 text-primary-color mb-0 text-start line-height-16 ">
                    {list.typeOfMenu === "Advance Order" &&
                      list.AdvanceOrderType}
                  </p>

                  <div className="ml-auto">
                    <AddToButton
                      id={list._id}
                      ChefDetail={ChefDetail}
                      quantity={counter}
                      list={list}
                      isCartcountChanged={isCartcountChanged}
                      setIsCartcountChanged={setIsCartcountChanged}
                      disabled={counter === 0}
                    />
                  </div>
                </div>
              )}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userCart: state.commonStore.totalCart,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleAddToCart,
      deleteCart,
      getAllCartById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuCard);
