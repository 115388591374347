import React, { useState } from "react";
import "./style.scss";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "reactstrap";

export const Accordion = () => {
  const faqList = [
    {
      title: "What is The Chef Lane?",
      description:
        "The Chef Lane is a platform/meeting place that connects home chefs with customers looking for healthy and hygienic, home-made food.",
    },
    {
      title: "Who is The Chef Lane for?",
      description: "Home chefs and cooks who want to sell their food.",
    },
    {
      title: "Do I need professional training to be on The Chef Lane?",
      description:
        "No. You just need a passion for food and a bagful of recipes that will bring customers back for more..",
    },
    {
      title: "How do I sell on The Chef Lane?",
      description: (
        <p>
          You submit your business and contact details.
          <br />
          We create a personalised profile with a detailed menu. <br />
          Choose to sell directly to the customer or via the e-commerce option
          on The Chef Lane. <br /> Work from home, only when you want to.
          <br /> Once your profile is up on The Chef Lane, you're in business!
        </p>
      ),
    },
    {
      title: "How do I get paid?",
      description:
        "If you have chosen the ListingPLUS service, payment will reach you directly from the customer through the payment gateway. The Chef Lane does not collect any payment from your customers.",
    },
    {
      title: "Do you charge commission on orders?",
      description: "No. We do not charge any commission or fees on orders.",
    },
    {
      title: "Will you deliver my orders?",
      description:
        "We are in the process of finalising delivery partners who can deliver for you. Until then, you are required to arrange delivery yourself.",
    },
    {
      title: "What can I sell on The Chef Lane?",
      description:
        "Any food item made by you (or your family) which you think will be liked by others.",
    },
    {
      title: "What do I do, if my question is not listed above?",
      description: (
        <>
          Send your query to{" "}
          <a href="mailto:support@thecheflane.com">support@thecheflane.com</a>.
        </>
      ),
    },
  ];

  const [activeIndex, setActiveIndex] = useState("1");

  return (
    <div className="Earn">
      <div className="">
        <div className="container">
          <div className="row py-md-4">
            <div className="pt-md-3 col-lg-10 col-12 mx-auto">
              {faqList.map((list, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="px-md-3 py-md-3 d-flex align-items-center w-100 jusitfy-content-between cursor-pointer"
                      onClick={() =>
                        activeIndex === index
                          ? setActiveIndex("")
                          : setActiveIndex(index)
                      }
                    >
                      <div className="w-75">
                        <div className="d-flex align-items-center">
                          <p className="fs-18 line-height-28 fw-700 text-primary-color mb-0 mb-md-3 mt-3 text-start">
                            {list.title}
                          </p>
                        </div>
                      </div>
                      <div className="w-25 d-flex   justify-content-end align-items-center">
                        <FontAwesomeIcon
                          icon={
                            activeIndex === index ? faChevronUp : faChevronDown
                          }
                        />
                      </div>
                    </div>
                    <Collapse isOpen={activeIndex === index}>
                      <div className="px-md-3  py-1 fs-14 mb-md-4 text-black-25 fw-400">
                        {list.description}
                      </div>
                    </Collapse>
                    <div className="divider mt-0" />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
