import React, { useState } from "react";
import moment from "moment";

import cheflist from "assets/images/customerConnection.png";
import vegIcon from "assets/images/veg.png";
import nonvegIcon from "assets/images/nonveg.png";

import { NormalButton } from "component/common/";

const OrderServedAvailable = ({ food, orderDetails }) => {
  const [ViewMore, setViewMore] = useState(false);
  const handleViewmore = () => {
    setViewMore(!ViewMore);
  };

  return (
    <>
      <div className=" mb-3 chef_detail_inner_container p-3">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-8 order-image-column">
            <div className="d-flex outline flex-column">
              <div className="">
                <div className="d-flex ">
                  <div className="">
                    <div className="foodimage">
                      <img
                        src={
                          !!food?.foodId?.dishImage
                            ? food?.foodId?.dishImage
                            : cheflist
                        }
                        alt=""
                      />
                    </div>
                    {!ViewMore ? (
                      <div
                        className="d-md-none d-lg-none d-block"
                        onClick={handleViewmore}
                      >
                        <span className="fs-12 fw-bold text-primary-color cursor-pointer">
                          {" "}
                          View More
                        </span>
                      </div>
                    ) : (
                      <div
                        className="d-md-none d-lg-none d-block"
                        onClick={handleViewmore}
                      >
                        <span className="fs-12 fw-bold text-primary-color cursor-pointer">
                          {" "}
                          View Less
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="menucard_description border-left-responsive ml-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex mb-3">
                        <div className="foodname fs-18 line-height-20 fw-700 text-grey-7c d-flex">
                          {food?.foodId?.name}
                          <div className="nonVageIcons ms-3">
                            <span className="mx-0">
                              <img src={food.foodId.dishCategory === "Non Veg" ? nonvegIcon : vegIcon} alt="icons" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    Quantity - {food.quantity}
                    {" * "}₹{food.price}
                    <div className="d-block d-md-none p-2 mt-2 text-left">
                      ₹{food.price * food.quantity}
                    </div>
                    <div className="d-md-none d-lg-none d-block">
                      <div className="col-12">
                        <p className="fs-12 text-gray-black mb-0">
                          {" "}
                          {food.orderStatus === "ACCEPT"
                            ? "Accepted on"
                            : "Declined "}
                          {moment(orderDetails.orderEndDate).format("LT")}
                        </p>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-lg-4 pt-3">
                        <div className="foodname fs-12 line-height-20 fw-700  text-grey-7c d-flex">
                          Status
                        </div>
                        <div className="">
                          <NormalButton
                            label={
                              food.orderStatus === "DECLINE"
                                ? "Declined"
                                : "Served"
                            }
                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-block  d-lg-block   d-none  col-xs-12 col-sm-12 col-lg-4 justify-content-center align-item-center">
            <div className="p-4 mt-2 text-end">
              ₹{food.price * food.quantity}
            </div>
          </div>
          {ViewMore ? (
            <div className="row mt-4 d-md-none d-lg-none d-block">
              <div className="col-12 me-4">
                <h4 className="fs-14 fw-700"> Placed By</h4>
                <div className="fs-14 fw-4=500 text-grey-7c">
                  {orderDetails.userId?.firstName}
                </div>
                <div className="d-flex">
                  <p className="fs-14 fw-700 text-grey-7c me-4">
                    {orderDetails.addressId?.address1}
                  </p>
                  <p className="fs-14 fw-700 text-grey-7c">
                    {orderDetails.addressId?.city},
                    {orderDetails.addressId?.state},
                    {orderDetails.addressId?.pincode}
                  </p>
                </div>
              </div>
              <div className="col-12 ">
                <h4 className="fs-14 fw-700 text-nowrap"> Placed at</h4>
                <div className="d-flex">
                  <p className="fs-14 fw-700 text-grey-7c"></p>
                  {moment(orderDetails.orderEndDate).format("hA")}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default OrderServedAvailable;
