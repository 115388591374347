import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSingle } from "action/ChefDetailsAct";
import { logout } from "service/utilities";
import { useCallback } from "react";
import { Toast } from "service/toast";

function Deactivate({ getSingle }) {
  let authToken = localStorage.getItem("AuthToken");
  const checkActiveStatus = useCallback(() => {
    getSingle(localStorage.getItem("userId")).then((response) => {
      let { data } = response;
      if (data?.deactivateId) {
        Toast({
          type: "error",
          message: "Account is deactivated",
          toastId: "success1",
        });
        logout();
      }
    });
  }, [authToken]);

  useEffect(() => {
    if (authToken) {
      checkActiveStatus();
    }
  }, [checkActiveStatus]);

  return <></>;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSingle,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Deactivate);
