import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { SuccessSubscriptionComp } from 'component/SuccessSubscription'

export class SuccessSubscriptionClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props
    return <SuccessSubscriptionComp orderId={params.orderId} />
  }
}
export const SuccessSubscription = connect(null, null)(SuccessSubscriptionClass)
