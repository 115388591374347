import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { PageIcons, NormalButton } from "component/common";
import { CommonPageLoader } from "component/common/CommonPageLoader";
import { getOneOrderFoodApi } from "action/CustomerDetailsAct";
import { getDeliveryStatus, getDeliveryStatusChange, changeStatusDelivered } from "action/AuthAct";
import TrackDeliverPartner from "./TrackDeliverPartner";
import ReviewOrderRate from "./ReviewOrderRate";
import { useLocation } from "react-router-dom";
import { DELIVERY_PARTNER_TYPE } from "utils/constants"
import { trackOwnDeliveryOrderStatus } from "action/ChefDetailsAct";
import { history } from "service/helpers";

const ChefTrackOrderClass = ({
  getOneOrderFoodApi,
  trackId,
  getDeliveryStatus,
  getDeliveryStatusChangeApi,
  trackOwnDeliveryOrderStatus,
  changeStatusDelivered
}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [OrderOneOrder, setOneOrder] = useState([]);
  const [CostDelivery, setCostDelivery] = useState(0);
  const [trackStatusDetails, settrackStatusDetails] = useState({});
  const { state: { isOwnDelivery } } = useLocation();
  //life cycle
  useEffect(() => {
    getOneOrderFoodApiFunction();
  }, []);

  const getOneOrderFoodApiFunction = () => {
    let id = trackId;
    setIsLoading(true);
    getOneOrderFoodApi(id)
      .then(({ data }) => {
        setOneOrder(data);
        setCostDelivery(data.deliveryCharges);

        if (isOwnDelivery !== DELIVERY_PARTNER_TYPE.OWN) {
          handleStatus(data._id);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleStatus = (id) => {
    setIsLoading(true);
    getDeliveryStatus(id)
      .then(({ data }) => {
        settrackStatusDetails(data);
        let totalPrice = data.estimatedPrice;
        let totalFixedToal = totalPrice.toFixed(2);
        setCostDelivery(totalFixedToal);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const handleStatusChange = (id) => {
    getDeliveryStatusChangeApi(id).then(() => handleStatus(id));
  };

  const handleStatusDelivered = (id) => {
    let body = {
      orderId: id,
      isTesting: true
    }
    changeStatusDelivered(body).then(() => getOneOrderFoodApiFunction());
  };

  return (
    <div className="trackorder-container position-relative pt-3 pb-2">
      <div className="container pb-3">
        <div className="pt-4">
          {isLoading ? (
            <CommonPageLoader />
          ) : (
            <>
              <div className="menucard_description ml-5">
                <div className="d-flex">
                  <div className="bitesheading col-lg-12  col-md-12 col-10   line-height-28 fs-24 fw-700 text-primary-color">
                    Track Delivery Patner Status
                  </div>

                  {/* <div className="button_container d-flex justify-content-end ">
                    <NormalButton
                      label="Help"
                      className="applyNow bg-yellow-C1 text-white-FF line-height-18 fs-16 "
                      primary
                      onClick={() => history.push("/index/contactus")}
                    />
                  </div> */}
                </div>
                <div className="Order-track mt-3">
                  {trackStatusDetails &&
                    trackStatusDetails.state !== "delivered" ? (
                    <p className="fs-16 fw-400 text-grey-4f mb-2">
                      Please Click that Below button to initial with Delivery
                      Patner
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="track-button d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
                    <p>
                      Delivery Charge from Delivery partner &nbsp; ₹
                      {CostDelivery}{" "}
                    </p>
                  </div>
                </div>
                <div className="order-items">
                  {/* <p className="fs-16 fw-400 text-grey-4f order-items-num">
                    Order Items -
                    {OrderOneOrder.items?.length > 0 ? (
                      OrderOneOrder.items?.map((item, index) => {
                        return (
                          <>
                            <span>
                              {" "}
                              {item.foodId.name} X {item.quantity}
                            </span>
                          </>
                        );
                      })
                    ) : (
                      <div className="text-gold text-center">
                        No Order Found !!!
                      </div>
                    )}
                    </p> */}
                </div>
              </div>
              {isOwnDelivery !== DELIVERY_PARTNER_TYPE.OWN &&
                <TrackDeliverPartner
                  oneOrder={OrderOneOrder}
                  trackId={trackId}
                  trackStatusDetails={trackStatusDetails}
                  handleStatusChange={handleStatusChange}
                />
              }
              {isOwnDelivery === DELIVERY_PARTNER_TYPE.OWN &&
                <>
                  <ReviewOrderRate
                    oneOrder={OrderOneOrder}
                    trackId={trackId}
                  />
                  {OrderOneOrder.orderStatus !== "DELIVERED" &&
                    <NormalButton
                      primary
                      label="Change Status"
                      className="px-md-5 px-4 order-track"
                      onClick={() => handleStatusDelivered(trackId)}
                    />
                  }
                  <NormalButton
                    viewButton
                    label="Back"
                    className="px-md-5 px-4 order-track mx-3"
                    onClick={() => history.push("/index/activeProfile")}
                  />
                </>
              }
            </>
          )}
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOneOrderFoodApi,
      getDeliveryStatus,
      getDeliveryStatusChangeApi: getDeliveryStatusChange,
      trackOwnDeliveryOrderStatus,
      changeStatusDelivered
    },
    dispatch
  );
};

export const ChefTrackOrderComp = connect(
  null,
  mapDispatchToProps
)(ChefTrackOrderClass);
