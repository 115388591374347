import React, { useEffect, useState } from "react";
import { Navbar } from "component/common/Navbar";
import "assets/scss/layouts/WebsiteLayout.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Footer } from "component/common/Footer";
import { history } from "service/helpers";
import Deactivate from "component/Deactivate";

export const WebsiteLayout = (props) => {
  let { children } = props;
  const [profileMenu, setProfileMenu] = useState(false);

  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    const userRole = userDetails?.userTypeRole || "";
    if (
      window.location.href.split(0).includes("/index/activeProfile") &&
      userRole === "chef"
    ) {
      // window.addEventListener("beforeunload", handleBeforeUnload);
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function (event) {
        history.push(`/index/activeProfile`);
      });
    } else if (
      window.location.href.split(0).includes("/index/customerProfile") &&
      userRole === "customer"
    ) {
      // window.addEventListener("beforeunload", handleBeforeUnload);
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function (event) {
        history.push(`/index/customerProfile`);
      });
    }
  }, [window.location.href]);

  //handle Profile Menu
  const handleProfileMenu = (event, active) => {
    event.stopPropagation();
    setProfileMenu(active);
  };

  console.log(window.location.href.split(0).includes("/index/cart"));

  return (
    <div id="main-content" onClick={(e) => handleProfileMenu(e, false)}>
      <div className="content-wrapper">
        <div className="content-layout">
          <Navbar props={props} />
          <div id="sub-content">{children}</div>
          <Footer history={props.history} />
        </div>
      </div>
      {/* <Deactivate /> */}
    </div>
  );
};
