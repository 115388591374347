import React, { useState } from "react";
import { verifyUser, resendOtpEmail, resendOtpMobile } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import UnderTrial from "./userTrial/index";
import { validationRules } from "./validate";
import { NormalButton } from "component/common/NormalButton";
import { history } from "service/helpers";
import { useLocation } from "react-router-dom";

const CreateaccountClass = ({
  verifyUserApi,
  resendOtpEmail,
  resendOtpMobile,
}) => {
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [isresend, setIsresend] = useState(false);
  const [isresendMob, setIsresendMob] = useState(false);
  const [isTrial, setisTrial] = useState(false);
  const [error, setErrors] = useState({});

  const [emailResendTimer, setEmailResendTimer] = useState(0); 
const [mobileResendTimer, setMobileResendTimer] = useState(0);

  
  const [otp, setOtp] = useState({ userId: "", emailotp: "", mobileotp: "" });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  localStorage.setItem("userTypeRole", query.get("userTypeRole"));
  localStorage.setItem("name", query.get("name"));

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };

  //handle Change
  const handleChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setOtp((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //let userId = localStorage.getItem("userId");
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    let mobileNumber = localStorage.getItem("mobileNumber");
    let email = localStorage.getItem("email");
    let password = localStorage.getItem("password");
    let confirmPassword = localStorage.getItem("confirmPassword");
    let currency = localStorage.getItem("currency");
    let countryCode = localStorage.getItem("countryCode");
    let service = localStorage.getItem("service");
    let body = {
      mobileotp: otp.mobileotp,
      emailotp: otp.emailotp,
      userTypeRole: query.get("userTypeRole"),
      mobileNumber: mobileNumber.trim(),
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      currency: currency,
      countryCode,
      service,
    };
    if (!validateFields(body)) return;

    // if (query.get("userTypeRole") == "customer") {
    //   body.userId = userId;
    // } else {
    //   body.chefId = userId;
    // }

    setisButtonClicked(true);
    verifyUserApi(body)
      .then((data) => {
        setisButtonClicked(false);
        localStorage.setItem("userId", data._id);
        if (query.get("userTypeRole") === "customer") {
          history.replace("/");
        } else if (query.get("userTypeRole") === "chef") {
          setisTrial(true);
        }
      })
      .catch(() => setisButtonClicked(false));
  };
  const reSendOtp = (e, type) => {
    e.preventDefault();
  
    if (type === "email" && emailResendTimer > 0) return; 
    if (type === "mobile" && mobileResendTimer > 0) return; 
  
    let body = {
      email: query.get("email"),
      mobileNumber: query.get("mobileNumber").trim(),
      countryCode: localStorage.getItem("countryCode"),
    };
  
    let api = type === "email" ? resendOtpEmail : resendOtpMobile;
    let handleLoader = type === "email" ? setIsresend : setIsresendMob;
  
    handleLoader(true);
  
    api(body)
      .then((data) => {
        if (data) {
          handleLoader(false);
  
          if (type === "email") {
            setEmailResendTimer(60); 
            const interval = setInterval(() => {
              setEmailResendTimer((prev) => {
                if (prev <= 1) {
                  clearInterval(interval);
                  return 0;
                }
                return prev - 1;
              });
            }, 1000);
          } else if (type === "mobile") {
            setMobileResendTimer(60); 
            const interval = setInterval(() => {
              setMobileResendTimer((prev) => {
                if (prev <= 1) {
                  clearInterval(interval);
                  return 0;
                }
                return prev - 1;
              });
            }, 1000);
          }
        }
      })
      .catch(() => handleLoader(false));
  };
  
  const handleHidePhoneNumber = () => {
    var mobile = query.get("mobileNumber");
    let res =
      localStorage.getItem("countryCode") +
      " " +
      mobile[0] +
      mobile[1] +
      mobile[2] +
      "*".repeat(mobile.length - 6) +
      mobile.slice(-3);
    // var res = m[1] + '*'.repeat(m[2].length) + m[3]
    return res;
  };
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <h3 className="fw-700 fs-24 line-height-28 text-black-18 mb-3 pb-1 text-md-start text-center">
            Verify User
          </h3>
          <p className="fs-16 fw-500 line-height-26 text-black-25 mb-3">
            We’ve sent a One Time Password (OTP) to your mobile number and Email
            ID. Please enter it to complete verification
          </p>
          <div className="pt-1">
            <p className="fw-500 fs-18 line-height-20 text-grey-6a">
              {query.get("email")}
            </p>
            <label>Email OTP</label>
            <div className="input-group fs-16">
              <input
                className="form-control"
                name="emailotp"
                placeholder="Enter email otp"
                value={otp.emailotp}
                onChange={handleChange}
              />
            </div>
            {error.emailotp && <ErrorComponent message={error.emailotp[0]} />}
            <div className="text-grey-6a fs-14 mt-2">
              Not recieved your code?{" "}
              <span
  className={`text-c1 fw-600 cursor-pointer ${
    emailResendTimer > 0 ? "disabled" : ""
  }`}
  onClick={(e) => {
    if (emailResendTimer === 0) reSendOtp(e, "email");
  }}
>
  {emailResendTimer > 0 ? `Resend code in ${emailResendTimer}s` : "Resend code"}
</span>
            </div>
          </div>
          <div className="pt-4">
            <p className="fw-500 fs-18 line-height-20 text-grey-6a">
              {handleHidePhoneNumber()}
            </p>
            <label>Mobile OTP</label>
            <div className="input-group fs-16">
              <input
                className="form-control"
                name="mobileotp"
                placeholder="Enter mobile otp"
                value={otp.mobileotp}
                onChange={handleChange}
              />
            </div>
            {error.mobileotp && <ErrorComponent message={error.mobileotp[0]} />}
            <div className="text-grey-6a fs-14 mt-2">
              Not recieved your code?{" "}
              <span
  className={`text-c1 fw-600 cursor-pointer ${
    mobileResendTimer > 0 ? "disabled" : ""
  }`}
  onClick={(e) => {
    if (mobileResendTimer === 0) reSendOtp(e, "mobile");
  }}
>
  {mobileResendTimer > 0 ? `Resend code in ${mobileResendTimer}s` : "Resend code"}
</span>

            </div>
          </div>
          <div className="mt-4">
            <div className="w-100">
              <NormalButton
                primary
                label="Verify"
                className="w-100 fw-700"
                onClick={handleSubmit}
                disabled={isButtonClicked}
              />
            </div>
          </div>
        </div>
      </div>
      {isTrial && <UnderTrial />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { verifyUserApi: verifyUser, resendOtpEmail, resendOtpMobile },
    dispatch
  );
};

export const CreateaccountComp = connect(
  null,
  mapDispatchToProps
)(CreateaccountClass);
