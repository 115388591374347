import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'

import OrderCreationComp from 'component/OrderCreation'
export class OrderCreationClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props
    return <OrderCreationComp orderId={params.orderId} />
  }
}
export const OrderCreation = connect(null, null)(OrderCreationClass)
