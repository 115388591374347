import React from "react";
import "./style.scss";

export const Licence = () => {
  return (
    <div className="licence_container_background ">
      <div className="container licence_container ">
        <div className="text-primary-color licences-heading  line-height-42 fw-700 text-center ">
        Licenses & Registrations
        </div>

        <div className="last_line_licence line-height-20 fs-16 fw-700 ">
          <span className=" text-black-25   line-height-28 licence_text">
            FSSAI License Number &nbsp;&nbsp;
          </span>

          <span className="text-gold  line-height-28 licence_number">
            12421999000927
          </span>
        </div>
      </div>
    </div>
  );
};

export const LicenceComp = Licence;
