import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NormalButton } from "component/common";
import { handleAddToCart, getAllCartById } from "action/cartAct";
import { addToLocalCart } from "reducer/local_cart";
import { Toast } from "service/toast";
import { useDispatch } from "react-redux";
import { useRecoilState } from "recoil";
import { cartItems } from "coils";

const AddToButton = ({
  id,
  ChefDetail,
  handleAddToCart,
  list = {},
  totalCartLocal,
  getAllCartById,
  quantity,
  isCartcountChanged,
  setIsCartcountChanged,
  disabled,
}) => {
  let isAddedCart = JSON.parse(localStorage.getItem("recoil-persist"));
  const [allCartItems, setAllCartItems] = useRecoilState(cartItems);
  const [isAddingCart, setIsaddingCart] = useState(!!allCartItems.includes(id));
  const authToken = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  const { licenseExpiryDate } = ChefDetail?.LicenseDetails || {};
  const currentDate = new Date();
  const givenDate = new Date(licenseExpiryDate);

  useEffect(() => {
    handleGetAllCart(true);
    setIsaddingCart(!!allCartItems.includes(id)); 
  }, [isAddingCart]);

  const handleAddToCartButton = async () => {
    setIsaddingCart(true);
    setIsCartcountChanged(false);
    if (!!authToken) {
      let body = { userId: userDetails?._id, foodId: id, quantity: quantity };
      await handleAddToCart(body)
        .then((data) => {
          if (!!data) {
            setIsaddingCart(true);
            setAllCartItems([...allCartItems, data.data.foodId]);
            handleGetAllCart();
          } else {
            setIsaddingCart(false);
          }
        })
        .catch(() => setIsaddingCart(false));
    } else {
      // setIsaddingCart(true);

      list["chefId"] = ChefDetail._id;
      list["businessNameSlug"] = ChefDetail.businessNameSlug;
      // list["quantity"] = quantity ;
      if (totalCartLocal?.length === 0) {
        setAllCartItems([...allCartItems, id]);
        dispatch(addToLocalCart(list));
      } else {
        const incart = totalCartLocal?.filter(
          (item) => item.chefId !== ChefDetail._id
        );
        const checkType = totalCartLocal?.find(
          (item) => item.typeOfMenu !== list.typeOfMenu
        );
        if (incart.length > 0) {
          setIsaddingCart(false);
          Toast({
            type: "error",
            message:
              "Our system only allows you to add items from a single chef to your order. Please remove any items from a different chef and place them in a separate order.",
          });
        } else if (checkType) {
          setIsaddingCart(false);
          Toast({
            type: "error",
            message:
              'Can\'t add different types of Menu. Please select menu only from either "Available Now" or "Advance Order"',
          });
        } else {
          setAllCartItems([...allCartItems, id]);
          dispatch(addToLocalCart(list));
        }
      }
      //setIsaddingCart(false);
    }
  };


  //get all cart
  const handleGetAllCart = async (intialCall = false) => {
    let body = { userId: userDetails?._id };
    await getAllCartById(body).then(({ data }) => {
      if (intialCall) {
        let result = data?.map(({ _id }) => _id);
        setAllCartItems(result);
        
      }
    });
  };

  if (
    ChefDetail.availabilityStatus &&
    ChefDetail.isChefAvailable &&
    givenDate > currentDate
  ) {
    return (
      <NormalButton
        primary
        label={isCartcountChanged ? 'add' : isAddingCart ? 'added' : 'add'}
        className="fw-700 fs-16 add-to-cart"
        disabled={disabled || (isCartcountChanged && quantity !== 0 ? false : isAddingCart ? true : false)}
        // loader={isAddingCart}
        onClick={() => handleAddToCartButton()}
      />
    );
  } else {
    return (
      <NormalButton
        primary
        label={"Unavailable"}
        className="fw-700 fs-16 add-to-cart"
        disabled={true}
        onClick={() => handleAddToCartButton()}
      />
    );
  }
};
const mapStateToProps = (state) => ({
  totalCartLocal: state.localCart,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ handleAddToCart, getAllCartById }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AddToButton);
