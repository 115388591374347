import React, { useState } from "react";
import "./style.scss";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";  
import CountryCurreny  from "./CountryCurreny.json";

export const CountryCodeDropDown = ({
  onClick,
  value = "",
  disabled,
  readOnly,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchvalue, setSearchValue] = useState("");
  const [options, setOptions] = useState([...CountryCurreny.CountryCurreny]);
  const toggle = () => setIsOpen((prevState) => !prevState);

  //handle Input Change
  const handleChange = ({ target: { value } }) => {
    setSearchValue(value);
    let tempArray = CountryCurreny.filter((data) => {
      if (searchvalue === "") {
        return data;
      } else if (
        data.name
          .toLocaleLowerCase()
          .includes(searchvalue.toLocaleLowerCase()) ||
        data.dial_code
          .toLocaleLowerCase()
          .includes(searchvalue.toLocaleLowerCase())
      ) {
        return data;
      }
    });
    setOptions([...tempArray]);
  };
  return (
    <div className="d-flex align-items-center custom-country-code-input h-100">
      <Dropdown
        isOpen={isOpen}
        toggle={() => toggle()}
        className="custom-dropdown"
      >
        <DropdownToggle
          tag="div"
          className={
            "custom-dropdown-text d-flex align-items-center justify-content-center w-100 cursor-pointer " +
            disabled
          }
          disabled={disabled}
        >
          {value}
          <i className="fs-28 icon-arrow-down" />
        </DropdownToggle>
        <DropdownMenu className="custom-dropdown-menu">
          <div className="p-2 mt-1">
            <input
              placeholder="Search..."
              value={searchvalue}
              onChange={handleChange}
              className="form-control"
              disabled={disabled}
            />
          </div>
          <div className="scroll-country-list">
            {options.length > 0 ? (
              options.map((option, index) => (
                <DropdownItem
                  className="custom-dropdown-item"
                  key={index}
                  onClick={() => onClick(option.dial_code, option.currency)}
                  disabled={disabled}
                >
                  <p className="mb-0 pl-3 fs-14">
                    {option.name} ({option.dial_code})
                  </p>
                </DropdownItem>
              ))
            ) : (
              <DropdownItem className="custom-dropdown-item" disabled>
                No Options
              </DropdownItem>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
