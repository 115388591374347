import React, { useState } from "react";
import "./style.scss";
import { Accordion } from "../../component/Faq/accordion";

export const Faq = () => {
  const [active, setactive] = useState(1);
  return (
    <div className="privacy_container_background ">
      <div className="container privacy_container ">
        <h1 className="text-primary-color line-height-42 fs-31 fw-700 text-center">
          Frequently Asked Questions
        </h1>
        <div className="bg-white py-4 px-lg-4 px-3 first_line_privacy faq-line text-black-25 line-height-20 fs-16 fw-400 ">
          <div className="row col-12 mx-auto col-lg-9">
            <h1
              className={`col-6 line-height-42 fs-20 fw-700 text-center pb-md-3 pb-2 ${
                active === 1 ? "text-primary-color faq-active" : "text-grey-9d"
              }`}
              onClick={() => setactive(1)}
            >
              FAQs for Chefs
            </h1>
            <h1
              className={`col-6  line-height-42 fs-20 fw-700 text-center  pb-3 ${
                active === 2 ? "text-primary-color faq-active" : "text-grey-9d"
              }`}
              onClick={() => setactive(2)}
            >
              FAQs for Customers
            </h1>
          </div>
          {active === 1 ? (
            <Accordion />
          ) : (
            <>
              <div className="container px-md-5">
                <div className="pt-md-4 mt-3 fs-20 fw-700 col-lg-10 col-12 mx-auto d-flex justify-content-center query-block">
                  Please send your query to{" "}
                  <a
                    href="mailto:support@thecheflane.com"
                    className="ms-2 text-gold "
                  >
                    support@thecheflane.com
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const FaqComp = Faq;
