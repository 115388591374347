export const chefDataType = {
  chefData: "CHEF_DATA",
};
export const notificationType = {
  notificationListData: "NOTIFICATION_LIST_DATA",
};
export const userLocationType = {
  userLocation: "USER_LOCATION",
  currentPageChef: "CURRENT_PAGE_CHEF",
  currentPageMenu: "CURRENT_PAGE_MENU",
};
export const localCartType = {
  totalCartLocal: "TOTAL_CART_LOCAL",
  add: "ADD_CART_LOCAL",
  remove: "REMOVE_CART_LOCAL",
  incQty: "INCREASE_CART_LOCAL",
  decQty: "DECREASE_CART_LOCAL",
  activeChef: "ACTIVE_CHEF_LOCAL",
};
export const CartDataType = {
  totalCart: "TOTAL_CART",
};
