import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NormalButton } from "component/common/NormalButton";
import { history } from "service/helpers";
import correct from "../../../assets/images/correct.png";
import wrong from "../../../assets/images/wrong.png";
import "./style.scss";
import { viewChangeService } from "action/AuthAct";
import { ternaryCondition } from "service/helperFunctions";
import { serviceSubscriptions } from "action/OrderReviewAct";
const TableOptions = ({ viewChangeService, serviceSubscriptionsApi }) => {
  const [isbuttonClicked, setIsbuttonClicked] = useState(false);
  const [packageDetails, setPackageDetails] = useState([]);

  useEffect(() => {
    serviceSubscriptionsApi().then(({ subscriptions = [], pricing = [] }) => {
      setPackageDetails([...subscriptions]);
    });
  }, [serviceSubscriptionsApi]);

  const serviceChange = () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    let body = {
      email: userDetails?.email,
      serviceType: localStorage.getItem("service"),
    };
    viewChangeService(body)
      .then(() => {
        setIsbuttonClicked(false);
        history.push(`/index/profile`);
      })
      .catch(() => setIsbuttonClicked(false));
  };

  const handleSelectPlan = (serviceUser) => {
    localStorage.setItem("service", serviceUser);
    setIsbuttonClicked(true);
    if (localStorage.getItem("userId")) {
      serviceChange();
    } else {
      history.push(`/auth/signup`);
    }
  };

  return (
    <div className="bg-yellow-ff">
      <div className="container pb-5 Table">
        <div className="row d-flex justify-content-center">
          <h1 className=" fw-700 text-primary-color text-center Compare_different_Packages pb-4 pt-5">
            Compare different Packages
          </h1>
          <div class="col-lg-10 col-12">
            <div class="card border-0 ">
              <div class="card-body">
                <table class="table table-bordered ">
                  <thead>
                    <tr className="heading">
                      <th scope="col">
                        <p className="mb-2 fs-20 fw-700 text-black-25">
                          Packages
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto my-2 fs-20 fw-700 text-black-25">
                          Listing
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto my-2 fs-20 fw-700 text-black-25">
                          ListingPLUS
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packageDetails.map((list, index) => (
                      <tr key={index}>
                        <th
                          className={`fs-18 fw-700 text-black-25 ${
                            index === 0 ? "py-3" : ""
                          }`}
                          scope="row"
                        >
                          {list.title}
                        </th>
                        <td
                          className={`text-center fs-16 fw-400 text-black-25 ${
                            index === 0 ? "py-3" : ""
                          }`}
                        >
                          {!list.listingPrice && list.title === "Pricing" ? (
                            "Free"
                          ) : list.listingPrice && list.title === "Pricing" ? (
                            <> &#8377; 1000 / M{/* && &#8377; 10000 / Y  */}</>
                          ) : (
                            <img
                              alt="listing icon"
                              src={ternaryCondition(
                                list.listing,
                                correct,
                                wrong
                              )}
                            />
                          )}
                        </td>
                        <td
                          className={`text-center fs-16 fw-400 text-black-25 ${
                            index === 0 ? "py-3" : ""
                          }`}
                        >
                          {!list.listingPlusPrice &&
                          list.title === "Pricing" ? (
                            "Free"
                          ) : list.listingPlusPrice &&
                            list.title === "Pricing" ? (
                            <> &#8377; 1000 / M{/* && &#8377; 10000 / Y */}</>
                          ) : (
                            <img
                              alt="listing icon"
                              src={ternaryCondition(
                                list.listingPlus,
                                correct,
                                wrong
                              )}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th scope="row " />
                      <td className="text-center">
                        <div className="mt-3 d-flex justify-content-center">
                          <NormalButton
                            primary
                            disabled={isbuttonClicked}
                            label="Select"
                            className="px-5  fw-700 width-140"
                            onClick={() => handleSelectPlan("LISTING")}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="mt-3 pt-0 d-flex justify-content-center">
                          <NormalButton
                            primary
                            disabled={isbuttonClicked}
                            label="Select"
                            className="px-5 fw-700 width-140"
                            onClick={() => handleSelectPlan("LISTING PLUS")}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-end">
              <a
                href="/index/t&c"
                className="fs-16 line-height-20 fw-400 text-black-25 pt-3 cursor-pointer hover-black-25"
                target="_blank"
              >
                Terms &amp; Conditions apply
              </a>
            </div>
            <div className="text-center pt-0">
              {/* <p className="fs-24 fw-700 text-black-25 line-height-32 ">
                Sign up and avail 3 months free with The Chef Lane{" "}
              </p> */}
              {/* <NormalButton
                onClick={() => history.push(`/auth/signup`)}
                primary
                label="Get started "
                className="my-4 col-md-2  mx-auto fw-700 getstarted  line-height-18 cursor-pointer"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { viewChangeService, serviceSubscriptionsApi: serviceSubscriptions },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(TableOptions);
