import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./style.scss";
import "slick-carousel/slick/slick-theme.css";
import { history } from "service/helpers";
import ArrowBack from "assets/images/ArrowBack.png";
import ArrowNext from "assets/images/ArrowNext.png";
import Yellow from "assets/images/Yellow.png";
import Blue from "assets/images/blue.png";
import Green from "assets/images/Green.png";
import Pink from "assets/images/Pink.png";

export const ArrowButtonPrevious = ({ imgSrc, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        border: "none",
        position: "absolute",
        top: window.innerWidth > 600 ? "35%" : "40%",
        right: window.innerWidth > 600 ? "96.5%" : "94.5%",
        zIndex: "2",
      }}
    >
      <img
        src={imgSrc}
        alt={""}
        style={{
          width: window.innerWidth > 600 ? "48px" : "30px",
          height: window.innerWidth > 600 ? "48px" : "30px",
        }}
      />
    </button>
  );
};

export const ArrowButtonNext = ({ imgSrc, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        border: "none",
        position: "absolute",
        top: window.innerWidth > 600 ? "35%" : "40%",
        left: window.innerWidth > 600 ? "96.5%" : "94.5%",
        zIndex: "2",
      }}
    >
      <img
        src={imgSrc}
        alt={""}
        style={{
          width: window.innerWidth > 600 ? "48px" : "30px",
          height: window.innerWidth > 600 ? "48px" : "30px",
        }}
      />
    </button>
  );
};

const Offers = ({ offerListData }) => {
  const offerImg = [
    {
      id: 1,
      img: Yellow,
    },
    {
      id: 2,
      img: Blue,
    },
    {
      id: 3,
      img: Green,
    },
    {
      id: 4,
      img: Pink,
    },
  ];
  var settings = {
    slidesPerView: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    // fade: true,
    speed: 500,
    autoplaySpeed: 3000,
    prevArrow: (
      <ArrowButtonPrevious imgSrc={ArrowBack} imgAlt="previous-button" />
    ),
    nextArrow: <ArrowButtonNext imgSrc={ArrowNext} imgAlt="next-button" />,
  };

  return (
    <React.Fragment>
      {offerListData.length > 0 && (
        <div className="offer_div pb-5">
          <div className="row mx-auto">
            <div className="col px-0 m-0">
              <p className="fs-36 fw-700 line-height-42 text-primary-color text-center pt-4">
                Offers
              </p>
              {/* {offerImg.map((x) => {
                return ( */}
              <div className="col-md-8 mx-auto">
                <Slider {...settings}>
                  {offerListData &&
                    offerListData.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{
                              backgroundImage: `url(${
                                index == 0
                                  ? Yellow
                                  : index == 1
                                  ? Pink
                                  : index == 2
                                  ? Green
                                  : index == 3
                                  ? Blue
                                  : index == 4
                                  ? Yellow
                                  : index == 5
                                  ? Pink
                                  : index == 6
                                  ? Green
                                  : index == 7
                                  ? Blue
                                  : index == 8
                                  ? Yellow
                                  : Pink
                              })`,
                              border: "none",
                            }}
                            className=""
                          >
                            <div className="card-offer">
                              <div className="">
                                <p className="forChef m-0">
                                  For Chef Registration
                                </p>
                                <p className="off_per m-0">
                                  Get{" "}
                                  <span>{item?.offerPercentage + "% "}</span>
                                  Instant Discount
                                </p>
                              </div>
                              <div className="card_button">
                                <h6 className="m-0 offer_name">
                                  {item?.title.toUpperCase() + " OFF"}
                                </h6>
                                <button
                                  onClick={() =>
                                    window.open(
                                      "/auth/login?userTypeRole=chef"
                                    )
                                  }
                                  className="offer_button ms-3"
                                >
                                  Grab Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
              {/* );
              })} */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Offers;
