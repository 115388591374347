import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import whatsapp from "assets/images/whatsappChat.png";
import "./style.scss";
import { NormalButton } from "component/common/NormalButton";
import orderplace from "assets/images/foodordered.jpg";
import orderDelivered from "assets/images/fooddelivered.jpg";
import marker from "assets/svg/marker.svg";
import { getChefById } from "action/CustomerDetailsAct";
import { history } from "service/helpers";
const ReviewOrderRate = ({
  oneOrder,
  getChefById,
  trackId,
  trackStatusDetails,
  handleStatusChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ChefwhatsappNumber, setChefwhatsappNumber] = useState("");

  let orderDetails = oneOrder?.items[0];
  //life cycle
  useEffect(() => {
    getChefByIdFunction();
  }, []);
  const getChefByIdFunction = () => {
    let id = oneOrder.chefId._id;
    getChefById(id)
      .then(({ data }) => {
        if (!!data) {
          setChefwhatsappNumber(data.whatsappNumber);
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      <div className="">
        <div className="main_container container-track pt-5 mb-4">
          <div className="container padding-bottom-3x mb-1">
            <div className="card mb-3">
              <div className="card-body p-md-4 py-4 px-2">
                <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                  {orderDetails.orderStatus === "Deliverd" ? (
                    <div
                      className={`step order-placed ${
                        trackStatusDetails.state === "created" ||
                        trackStatusDetails.state === "queued"
                          ? "completed"
                          : ""
                      }`}
                    >
                      <div className="step-icon-wrap">
                        <div className="step-icon" />
                      </div>
                      <h4 className="step-title fs-16 text-black fw-700 ">
                        Order Accepted
                      </h4>
                    </div>
                  ) : (
                    <div
                      className={`step order-place ${
                        trackStatusDetails.state === "created" ||
                        trackStatusDetails.state === "runner_accepted" ||
                        trackStatusDetails.state === "pickup_complete" ||
                        trackStatusDetails.state === "started_for_delivery" ||
                        trackStatusDetails.state === "reached_for_delivery" ||
                        trackStatusDetails.state === "delivered"
                          ? "completed"
                          : ""
                      }`}
                    >
                      <div className="step-icon-wrap">
                        <div className="step-icon-image">
                          {" "}
                          <img
                            src={orderplace}
                            className="w-100"
                            alt="orderplace"
                          />
                        </div>
                      </div>
                      <h4 className="step-title fs-16 text-black fw-700 mt-5 pt-3">
                        Order Created
                      </h4>
                    </div>
                  )}
                  <div
                    className={`step preparing-order ${
                      trackStatusDetails.state === "queued" ||
                      trackStatusDetails.state === "runner_accepted" ||
                      trackStatusDetails.state === "reached_for_pickup" ||
                      trackStatusDetails.state === "pickup_complete" ||
                      trackStatusDetails.state === "started_for_delivery" ||
                      trackStatusDetails.state === "reached_for_delivery" ||
                      trackStatusDetails.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Waiting for Dunzo partner to accept
                    </h4>
                  </div>
                  <div
                    className={`step preparing-order ${
                      trackStatusDetails.state === "runner_accepted" ||
                      trackStatusDetails.state === "reached_for_pickup" ||
                      trackStatusDetails.state === "pickup_complete" ||
                      trackStatusDetails.state === "started_for_delivery" ||
                      trackStatusDetails.state === "reached_for_delivery" ||
                      trackStatusDetails.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Order Accepted
                    </h4>
                  </div>
                  <div
                    className={`step preparing-order ${
                      trackStatusDetails.state === "reached_for_pickup" ||
                      trackStatusDetails.state === "pickup_complete" ||
                      trackStatusDetails.state === "started_for_delivery" ||
                      trackStatusDetails.state === "reached_for_delivery" ||
                      trackStatusDetails.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Reached for Pickup
                    </h4>
                  </div>
                  <div
                    className={`step packed-order ${
                      trackStatusDetails.state === "pickup_complete" ||
                      trackStatusDetails.state === "started_for_delivery" ||
                      trackStatusDetails.state === "reached_for_delivery" ||
                      trackStatusDetails.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Picked order
                    </h4>
                  </div>
                  <div
                    className={`step delivery-order ${
                      trackStatusDetails.state === "started_for_delivery" ||
                      trackStatusDetails.state === "reached_for_delivery" ||
                      trackStatusDetails.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Out for delivery
                    </h4>
                  </div>
                  <div
                    className={`step delivery-order ${
                      trackStatusDetails.state === "reached_for_delivery" ||
                      trackStatusDetails.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Reached for delivery
                    </h4>
                  </div>
                  {trackStatusDetails.state === "delivered" ? (
                    <div className="step delivered-order order-rating">
                      <div className="step-icon-wrap">
                        <div className="step-icon-image">
                          <img src={orderDelivered} className="w-100" alt="" />
                        </div>
                      </div>
                      <h4 className="step-title fs-16 text-black mt-5 pt-4 fw-700">
                        Order Delivered
                      </h4>
                    </div>
                  ) : (
                    <div
                      className={`step delivered-order ${
                        trackStatusDetails.state === "delivered"
                          ? "completed"
                          : ""
                      }`}
                    >
                      <div className="step-icon-wrap">
                        <div className="step-icon" />
                      </div>
                      <h4 className="step-title fs-16 text-black fw-700">
                        Order Delivered
                      </h4>
                    </div>
                  )}
                </div>
                <NormalButton
                  viewButton
                  label="Back"
                  className="px-md-5 px-4 order-track me-3 mt-2"
                  onClick={() => history.push("/index/activeProfile")}
                />
                {trackStatusDetails.state !== "delivered" ? (
                  <NormalButton
                    primary
                    label="Change Status"
                    className="px-md-5 px-4 order-track mt-2"
                    onClick={() => handleStatusChange(trackId)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {trackStatusDetails.state === "runner_accepted" ||
      trackStatusDetails.state === "reached_for_pickup" ||
      trackStatusDetails.state === "pickup_complete" ||
      trackStatusDetails.state === "started_for_delivery" ||
      trackStatusDetails.state === "reached_for_delivery" ? (
        <div>
          <h4 className="step-title fs-16 text-primary-color fw-700">
            Delivery Partner Name:{" "}
            <span className="text-black text-capitalize">
              {trackStatusDetails.runner.name}
            </span>
          </h4>
          <h4 className="step-title fs-16 text-primary-color fw-700 mb-3">
            Delivery Partner Phone Number:{" "}
            <span className="text-black text-capitalize">
              {trackStatusDetails.runner.phone_number}
            </span>
          </h4>
          {trackStatusDetails.trackingUrl && (
            <a
              className="text-primary-color bg-white order-link px-2 py-2"
              target="_blank"
              href={trackStatusDetails?.trackingUrl}
            >
              <img src={marker} alt="marker" />
              Tracking Order
            </a>
          )}
        </div>
      ) : (
        ""
      )}
      {!isLoading && (
        <div className="whatsappImage">
          <a
            href={`https://api.whatsapp.com/send?phone=${ChefwhatsappNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="whatsapp" />
          </a>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getChefById }, dispatch);
};

export default connect(null, mapDispatchToProps)(ReviewOrderRate);
