import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { history } from "service/helpers";
import { Navbar } from "component/common/Navbar";
import { NormalButton } from "component/common";
import { PageIcons } from "component/common";
import { useRecoilState } from "recoil";
import { cartItems } from "coils";
import { getOrderDetailsAct } from "action/CustomerDetailsAct";
import { Toast } from "service/toast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { clearCart, getAllCartById } from "action/cartAct";


const OrderplacedClass = ({ orderId, getOrderDetails, getAllCartById, clearCart }) => {
  const [allCartItems, setAllCartItems] = useRecoilState(cartItems);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderIds, setOrderIds] = useState(null);

  const { search } = useLocation();

  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const targetSegment = pathSegments.includes("subscription") ? true : false;
  const authToken = localStorage.getItem("authToken");
  const userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));



  const handleClearCart = async () => {
    let id = userDetails?._id;
    await clearCart(id)
      .then((e) => console.log(e))
      .catch((e) => console.log(e));
  };


  useEffect(() => {
    handleClearCart()
  }, []);


  // useEffect(() => {
  //   clearCart();
  // }, []);



  // const clearCart = () => {
  //   setAllCartItems([]);
  // };

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId).then(({ data }) => setOrderDetails(data));

    }
  }, [orderId, getOrderDetails]);



  const captureFunc = useCallback(async () => {
    const searchParams = new URLSearchParams(search);
    const razorpayPaymentId = searchParams.get("razorpay_payment_id");
    const razorpayPaymentLinkId = searchParams.get("razorpay_payment_link_id");
    const razorpayPaymentLinkReferenceId = searchParams.get(
      "razorpay_payment_link_reference_id"
    );
    const razorpayPaymentLinkStatus = searchParams.get(
      "razorpay_payment_link_status"
    );
    const razorpaySignature = searchParams.get("razorpay_signature");
    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Basic ${authToken}`,
      "Content-Type": "application/json",
    };

    let body = {
      // orderId: orderId,
      // paymentOrderId: id,
      // paymentDate: new Date(),
      payment_link_id: razorpayPaymentLinkId,
      payment_id: razorpayPaymentId,
      payment_link_reference_id: orderId,
      payment_link_status: razorpayPaymentLinkStatus,
      signature: razorpaySignature,
    };
    let sub = true;
    try {
      const response = await axios.post(
        targetSegment
          ? "https://preprodapis.thecheflane.com/api/v1/chef/verifySubScription"
          : "https://preprodapis.thecheflane.com/api/v1/razorpay/verifyPayment",
        body,
        {
          headers,
        }
      );
      const apiOrderId = response?.data?.data?.orderId;
       // Extract orderId from the response
       setOrderIds(apiOrderId);

      // if (razorpayPaymentLinkStatus === "Paid") {
      //   Toast({ type: "success", message: "Order placed successfully" });
      // }
    } catch (err) {
      console.log(err);
    }

    // try {
    //   const data = await paymentDetailCapture({ ...body, headers });
    //   if (data) {
    //     const { orderId } = data?.data || {};
    //     setSuccessOrderId(orderId);
    //     localStorage.removeItem('sessionId');
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }, [authToken, window.location.pathname]);

  useEffect(async () => {
    await captureFunc();
    let User = { userId: userDetails?._id }
    getAllCartById(User);
  }, [captureFunc]);

  // useEffect(() => {
  //   window.location.reload("/index/customerProfile");
  // }, []);

const handleBacktoHome = () => {
  history.push("/index/customerProfile")
  window.location.reload()
}

  return (
    <div className="orderplaced">
      <div className="d-flex w-md-100 h-100 ">
        <div className="col-lg-12 col-12 mx-auto px-3 login-area">
          <div className=" mx-auto col-lg-7 col-12 h-100 ">
            <div className="d-flex align-items-center flex-column w-100">
              <Navbar />
              {
                !orderIds?"":
              <>
                <p className="fs-42 fw-700 line-height-48 text-black-33 mt-4 mb-2">
                  Woo Hoo! Order placed successfully!
                </p>
                <p className="col-12 col-sm-6 fs-16 fw-400 line-height-18 text-center text-grey-4f">
                  Your order {orderIds} with{" "}
                  <span className="fs-18 fw-700 text-gold mb-0 text-capitalize">
                    {orderDetails?.chefId?.businessName}{" "}

                  </span>
                  has been placed.
                </p>
              
              
              </>
              }
            
              <div className="col-sm-6 col-12 py-5 mb-5 text-center">
                <NormalButton
                  optionButton
                  label="Back to home"
                  className="fw-700 mt-2 text-primary-color"
                  onClick={handleBacktoHome}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrderDetails: getOrderDetailsAct, getAllCartById, clearCart }, dispatch);
};

let component = OrderplacedClass;

export const OrderplacedComp = connect(null, mapDispatchToProps)(component);
