// export const navigatorGeoLocation = (lat, log) => {
//   var address;

//   //   const displayLocation = (latitude, longitude) => {
//   var geocoder;
//   geocoder = new window.google.maps.Geocoder();
//   var latlng = new window.google.maps.LatLng(lat, log);

//   return geocoder.geocode({ latLng: latlng }, function (results, status) {
//     var add;
//     if (status === window.google.maps.GeocoderStatus.OK) {
//       if (results[0]) {
//         var add = results[0].formatted_address;
//         var value = add.split(",");

//         let count = value.length;

//         let city = value[count - 3];

//         address = city;
//         add = city;
//       } else {
//       }
//     } else {
//       console.log("Geocoder failed due to: " + status);
//     }
//   });
//   //   }
// };


export const navigatorGeoLocation = (lat, lng) => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const formattedAddress = results[0].formatted_address;
          const value = formattedAddress.split(",");
          const count = value.length;
          const city = value[count - 3];
          resolve(city);
        } else {
          reject(new Error("No results found"));
        }
      } else {
        reject(new Error("Geocoder failed due to: " + status));
      }
    });
  });
};


