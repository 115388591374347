// import React, { useState, useEffect, useRef } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { TimePicker } from "antd";
// import moment from "moment";
// import { CHIEF_AVAILBILITY_MAX_HR } from "utils/constants";
// import "./styles.scss";
// import { CommonTableLoader } from "component/common/CommonTableLoader";
// import { NormalButton } from "component/common";
// import { Toast } from "service/toast";
// import {
//   getAvailabilityTime,
//   editAvailabilityTime,
// } from "action/availabilityTimeAct";

// function OwnPartner({ getAvailabilityTime, editAvailabilityTime }) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [availabilityTimeList, setAvailabilityTimeList] = useState([]);

//   useEffect(() => {
//     getAvailabilityTimeFun();
//   }, []);

//   //new Request Api Call Func
//   const getAvailabilityTimeFun = async (id) => {
//     setIsLoading(true);
//     let body = {
//       chefId: localStorage.getItem("userId"),
//       page: 1,
//     };
//     getAvailabilityTime(body)
//       .then(({ data }) => {
//         setIsLoading(false);
//         if (data) {
//           setAvailabilityTimeList(
//             data.availabilityDetails.map((date) => ({ ...date, checked: true }))
//           );
//         } else {
//           setAvailabilityTimeList([]);
//         }
//       })
//       .catch(() => setIsLoading(false));
//   };

//   const onClickDay = (day) => {
//     let availabilityTimeArray = [...availabilityTimeList];
//     let index = availabilityTimeArray.findIndex((a) => a.day === day.value);
//     if (index >= 0) {
//       availabilityTimeArray[index].checked =
//         !availabilityTimeArray[index].checked;
//     } else {
//       availabilityTimeArray.push({
//         day: day.value,
//         startTime: "",
//         endTime: "",
//         checked: true,
//       });
//     }
//     setAvailabilityTimeList(availabilityTimeArray);
//   };

//   const onStartTimeChange = (day, value) => {
//     let availabilityTimeArray = [...availabilityTimeList];
//     let index = availabilityTimeArray.findIndex((a) => a.day === day);
//     if (value) {
//       let obj = availabilityTimeArray[index];
//       if (obj.endTime) {
//         availabilityTimeArray[index]["endTime"] = "";
//       }
//       availabilityTimeArray[index]["startTime"] = moment(value).format("HH:mm");
//     } else {
//       availabilityTimeArray[index]["startTime"] = "";
//     }
//     setAvailabilityTimeList(availabilityTimeArray);
//   };

//   const onEndTimeChange = (day, value) => {
//     let availabilityTimeArray = [...availabilityTimeList];
//     let index = availabilityTimeArray.findIndex((a) => a.day === day);
//     if (value) {
//       let obj = availabilityTimeArray[index];
//       if (obj.startTime) {
//         availabilityTimeArray[index]["endTime"] = moment(value).format("HH:mm");
//       }
//     } else {
//       availabilityTimeArray[index]["endTime"] = "";
//     }
//     setAvailabilityTimeList(availabilityTimeArray);
//   };

//   const updateOnClick = () => {
//     let availabilityTimeArray = [...availabilityTimeList];
//     let finded = availabilityTimeArray.find((a) => !a.startTime || !a.endTime);
//     if (finded) {
//       let weekDayFinded = weekList.find((w) => w.value === finded.day);
//       let message = `Please update the ${
//         !finded.startTime ? "Start Time" : "End Time"
//       } for the selected day`;
//       Toast({ type: "error", message: message });
//     } else {
//       let checkedAvailableDates = availabilityTimeList
//         .filter((date) => date.checked)
//         .map((finalDate) => {
//           delete finalDate.checked;
//           return finalDate;
//         });
//       const requestPayload = {
//         chefId: localStorage.getItem("userId"),
//         availabilityDetails: [...checkedAvailableDates],
//       };
//       editAvailabilityTime({ ...requestPayload }).then(() => {
//         getAvailabilityTimeFun();
//       });
//     }
//   };

//   let weekList = [
//     {
//       day: "Sunday",
//       value: "SUN",
//     },
//     {
//       day: "Monday",
//       value: "MON",
//     },
//     {
//       day: "Tuesday",
//       value: "TUE",
//     },
//     {
//       day: "Wednesday",
//       value: "WED",
//     },
//     {
//       day: "Thursday",
//       value: "THU",
//     },
//     {
//       day: "Friday",
//       value: "FRI",
//     },
//     {
//       day: "Saturday",
//       value: "SAT",
//     },
//   ];
//   const timeOptions = [];
//   for (let hour = 0; hour < 24; hour++) {
//     for (let minute = 0; minute < 60; minute += 15) {
//       // You might adjust this based on your requirements
//       const formattedHour = hour.toString().padStart(2, "0");
//       const formattedMinute = minute.toString().padStart(2, "0");
//       const time = `${formattedHour}:${formattedMinute}`;
//       timeOptions.push(time);
//     }
//   }
//   timeOptions.push("24:00");

//   return (
//     <>
//       <div className="rate_detail_inner_container">
//         <div>
//           <div className="d-flex align-items-center justify-content-between title-rateing">
//             <div className="header">Store Timing</div>
//           </div>
//           <hr></hr>
//           {isLoading ? (
//             <CommonTableLoader tdLength={6} />
//           ) : (
//             <div>
//               <div className="d-flex">
//                 <div className="col-md-4" style={{ padding: 10 }}></div>
//                 <div className="col-md-4 ms-3" style={{ padding: 10 }}>
//                   Start Time
//                 </div>
//                 <div className="col-md-4 ms-3" style={{ padding: 10 }}>
//                   End Time
//                 </div>
//               </div>
//               {weekList.map((w, index) => {
//                 let finded = availabilityTimeList.find(
//                   (a) => a.day === w.value
//                 ) || { checked: false };
//                 let startTime =
//                   finded && finded.startTime
//                     ? moment(finded.startTime, "HH:mm")
//                     : null;
//                 let endTime =
//                   finded && finded.endTime
//                     ? moment(finded.endTime, "HH:mm")
//                     : null;

//                 return (
//                   <div key={index} className="row">
//                     <div className="col-md-4" style={{ padding: 10 }}>
//                       <input
//                         type="checkbox"
//                         checked={finded?.checked}
//                         onClick={() => onClickDay(w)}
//                       />{" "}
//                       {w.day}
//                     </div>
//                     <div className="col-md-4" style={{ padding: 10 }}>
//                       <TimePicker
//                         placeholder="Select Start Time"
//                         disabled={!finded?.checked}
//                         options={timeOptions}
//                         format={"HH:mm"}
//                         value={startTime}
//                         onChange={(value) => onStartTimeChange(w.value, value)}
//                         showNow={false}
//                         use12Hours={false}
//                       />
//                     </div>
//                     <div className="col-md-4" style={{ padding: 10 }}>
//                       <TimePicker
//                         placeholder="Select End Time"
//                         disabled={!finded?.checked}
//                         format={"HH:mm"}
//                         use12Hours={false}
//                         options={timeOptions}
//                         // disabledHours={(params) => {
//                         //   let hours = new Array(24)
//                         //     .fill(1)
//                         //     .map((i, index) => index);

//                         //   if (startTime) {
//                         //     let closingHr =
//                         //       startTime.minutes() != 0
//                         //         ? CHIEF_AVAILBILITY_MAX_HR + 1
//                         //         : CHIEF_AVAILBILITY_MAX_HR;
//                         //     return hours
//                         //       .splice(
//                         //         hours.findIndex(
//                         //           (value) =>
//                         //             value ==
//                         //             new Date(
//                         //               moment(startTime, "HH:mm")
//                         //             ).getHours()
//                         //         )
//                         //       )
//                         //       .concat(hours)
//                         //       .splice(closingHr);
//                         //   } else {
//                         //     return [];
//                         //   }
//                         // }}
//                         disabledMinutes={(hour) => {
//                           if (startTime) {
//                             if (startTime.minutes() != 0) {
//                               let hours = new Array(24)
//                                 .fill(1)
//                                 .map((i, index) => index);

//                               let startHrs = hours
//                                 .splice(
//                                   hours.findIndex(
//                                     (value) =>
//                                       value ==
//                                       new Date(
//                                         moment(startTime, "HH:mm")
//                                       ).getHours()
//                                   )
//                                 )
//                                 .concat(hours);

//                               let lastHr = startHrs[CHIEF_AVAILBILITY_MAX_HR];

//                               let firstHr = startHrs[0];
//                               let minutes = new Array(60)
//                                 .fill(1)
//                                 .map((i, index) => index);
//                               let startMintues = startTime.minutes();
//                               if (lastHr == hour) {
//                                 return minutes.splice(startMintues);
//                               } else if (firstHr == hour) {
//                                 return minutes.slice(0, startMintues);
//                               }
//                             } else {
//                               return [];
//                             }
//                           } else {
//                             return [];
//                           }
//                         }}
//                         value={endTime}
//                         onChange={(value) => onEndTimeChange(w.value, value)}
//                         showNow={false}
//                       />
//                     </div>
//                   </div>
//                 );
//               })}
//               <div style={{ textAlign: "center", marginTop: 20 }}>
//                 <NormalButton
//                   primary
//                   label="Update"
//                   className="fw-700 mx-3"
//                   onClick={() => updateOnClick()}
//                 />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     { getAvailabilityTime, editAvailabilityTime },
//     dispatch
//   );
// };

// export default connect(null, mapDispatchToProps)(OwnPartner);


import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TimePicker } from "antd";
import moment from "moment";
import { CHIEF_AVAILBILITY_MAX_HR } from "utils/constants";
import "./styles.scss";
import { CommonTableLoader } from "component/common/CommonTableLoader";
import { NormalButton } from "component/common";
import { Toast } from "service/toast";
import {
  getAvailabilityTime,
  editAvailabilityTime,
} from "action/availabilityTimeAct";
import debounce from 'lodash/debounce';

function OwnPartner({ getAvailabilityTime, editAvailabilityTime }) {
  const [isLoading, setIsLoading] = useState(false);
  const [availabilityTimeList, setAvailabilityTimeList] = useState([]);

  useEffect(() => {
    getAvailabilityTimeFun();
  }, []);

  // Fetch availability times

  const getAvailabilityTimeFun = async (id) => {
    setIsLoading(true);
    let body = {
      chefId: localStorage.getItem("userId"),
      page: 1,
    };
    getAvailabilityTime(body)
      .then(({ data }) => {
        setIsLoading(false);
        if (data) {
          setAvailabilityTimeList(
            data[0].availabilityDetails.map((date) => ({ ...date, checked: true }))  ||
            data.availabilityDetails.map((date) => ({ ...date, checked: true }))
          );
        } else {
          setAvailabilityTimeList([]);
        }
      })
      .catch(() => setIsLoading(false));
  };


  // Handle day selection
  const onClickDay = (day) => {
    setAvailabilityTimeList((prevList) => {
      const existing = prevList.find((a) => a.day === day.value);
      if (existing) {
        existing.checked = !existing.checked;
        return [...prevList];
      } else {
        return [
          ...prevList,
          {
            day: day.value,
            startTime: "",
            endTime: "",
            checked: true,
          },
        ];
      }
    });
  };

  // Handle start time change
  const onStartTimeChange = (day, value) => {
    setAvailabilityTimeList((prevList) => {
      const updated = prevList.map((a) =>
        a.day === day
          ? {
              ...a,
              startTime: value ? moment(value).format("HH:mm") : "",
              endTime: a.endTime && value ? "" : a.endTime,
            }
          : a
      );
      return updated;
    });
  };

  // Handle end time change
  const onEndTimeChange = (day, value) => {
    setAvailabilityTimeList((prevList) => {
      const updated = prevList.map((a) =>
        a.day === day
          ? {
              ...a,
              endTime: value ? moment(value).format("HH:mm") : "",
            }
          : a
      );
      return updated;
    });
  };

  // Handle update button click
  const updateOnClick = debounce(() => {
    const invalidEntry = availabilityTimeList.find(
      (a) => !a.startTime || !a.endTime
    );
    if (invalidEntry) {
      const message = `Please update the ${
        !invalidEntry.startTime ? "Start Time" : "End Time"
      } for the selected day`;
      Toast({ type: "error", message });
      return;
    }
    const checkedAvailableDates = availabilityTimeList
      .filter((date) => date.checked)
      .map(({ checked, ...rest }) => rest);
    const requestPayload = {
      chefId: localStorage.getItem("userId"),
      availabilityDetails: checkedAvailableDates,
    };
    editAvailabilityTime(requestPayload)
      .then(() => {
        Toast({ type: "success", message: "Availability updated successfully" });
        getAvailabilityTimeFun();
      })
      .catch(() => Toast({ type: "error", message: "Failed to update availability" }));
  }, 500); // Debounce time can be adjusted

  const weekList = [
    { day: "Sunday", value: "SUN" },
    { day: "Monday", value: "MON" },
    { day: "Tuesday", value: "TUE" },
    { day: "Wednesday", value: "WED" },
    { day: "Thursday", value: "THU" },
    { day: "Friday", value: "FRI" },
    { day: "Saturday", value: "SAT" },
  ];

  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      timeOptions.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  timeOptions.push("24:00");

  return (
    <div className="rate_detail_inner_container">
      <div>
        <div className="d-flex align-items-center justify-content-between title-rateing">
          <div className="header">Store Timing</div>
        </div>
        <hr />
        {isLoading ? (  
          <CommonTableLoader tdLength={6} />
        ) : (
          <div>
            <div className="d-flex">
              <div className="col-md-4" style={{ padding: 10 }}></div>
              <div className="col-md-4 ms-3" style={{ padding: 10 }}>Start Time</div>
              <div className="col-md-4 ms-3" style={{ padding: 10 }}>End Time</div>
            </div>
            {weekList.map((w, index) => {
              const finded = availabilityTimeList.find((a) => a.day === w.value) || { checked: false };
              const startTime = finded.startTime ? moment(finded.startTime, "HH:mm") : null;
              const endTime = finded.endTime ? moment(finded.endTime, "HH:mm") : null;

              return (
                <div key={index} className="row">
                  <div className="col-md-4" style={{ padding: 10 }}>
                    <input
                      type="checkbox"
                      checked={finded.checked}
                      onChange={() => onClickDay(w)}
                    />{" "}
                    {w.day}
                  </div>
                  <div className="col-md-4" style={{ padding: 10 }}>
                    <TimePicker
                      placeholder="Select Start Time"
                      disabled={!finded.checked}
                      format={"HH:mm"}
                      value={startTime}
                      onChange={(value) => onStartTimeChange(w.value, value)}
                      showNow={false}
                      use12Hours={false}
                    />
                  </div>
                  <div className="col-md-4" style={{ padding: 10 }}>
                    <TimePicker
                      placeholder="Select End Time"
                      disabled={!finded.checked}
                      format={"HH:mm"}
                      use12Hours={false}
                      value={endTime}
                      onChange={(value) => onEndTimeChange(w.value, value)}
                      showNow={false}
                    />
                  </div>
                </div>
              );
            })}
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <NormalButton
                primary
                label="Update"
                className="fw-700 mx-3"
                onClick={updateOnClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getAvailabilityTime, editAvailabilityTime },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(OwnPartner);
