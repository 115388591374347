import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { SuccessOrderComp } from 'component/SuccessOrder'

export class SuccessOrderClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props
    return <SuccessOrderComp orderId={params.orderId} />
  }
}
export const SuccessOrder = connect(null, null)(SuccessOrderClass)
