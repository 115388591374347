import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NormalButton,
  CommonPageLoader,
  NormalDate,
  NormalInput,
} from "component/common";
import { NormalSelect } from "component/common/NormalSelect";
import OrderAvailable from "./OrderAvailable";
import OrderAdvanced from "./OrderAdvanced";
import OrderServedAvailable from "./OrderServedAvailable";
import moment from "moment";
import {
  getAllOrderChef,
  getAllActiveOrderChef,
  orderUpdateStatus,
  assigntoOwnDeliveryPartner,
  trackOwnDeliveryOrderStatus,
} from "action/ChefDetailsAct";
import SimpleReactValidator from "simple-react-validator";
import { DELIVERY_PARTNER_TYPE } from "utils/constants";

import { history } from "service/helpers";
import {
  listOwnDeliveryPartner,
  addDeliveryPartner,
} from "action/ownPartnerAct";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "service/toast";
import { debounceFunction } from "../../../service/helperFunctions";
import { handleForbiddenCharacters } from "service/utilities";

function ChefOrder({
  getAllOrderChef,
  getAllActiveOrderChef,
  currentPageOrderChef,
  orderUpdateStatus,
  listOwnDeliveryPartner,
  chefDetails,
  assigntoOwnDeliveryPartner,
  trackOwnDeliveryOrderStatus,
  addDeliveryPartner,
}) {
  const [selectfilter, updateName] = useState("");
  const [selectfilterHistory, setSelectfilterHistory] = useState("");
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [pageMeta, setPageMeta] = useState(null);
  const [activelist, setactivelist] = useState([]);
  const [pageViewMore, setpageViewMore] = useState(1);

  const [acceptLoader, setAcceptLoader] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [acceptDate, setAcceptDate] = useState(new Date());
  const [currentIndex, setCurrentIndex] = useState(null);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [intiateOrder, setIntiateOrder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // own delivery partner actions

  const [show, setShow] = useState(false);
  const [deliveryPartnerList, setDeliveryPartnerList] = useState([]);
  const [selectedDeliveryPartner, setSelectedDeliveryPartner] = useState("");
  const [ownDeliveryOrder, setOwnDeliveryOrder] = useState({});
  const [formData, setFormData] = useState({ phoneNumber: "", name: "" });
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchOrder, setSearchOrder] = useState("");
  const [msg, setMsg] = useState("");

  const deliveryPartnerType = chefDetails?.deliveryPartners;
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const handleClose = () => {
    setShow(false);
  };

  const handleAddClose = () => {
    setShowAddModal(false);
  };

  const FilterList = [
    { label: "All Orders", value: "" },
    // { label: "Accepted", value: "ACCEPT",  },
    // { label: "Decline",  value: "DECLINE",  },
    // { label: "Preparing", value: "PREPARING" },
    { label: "Packed", value: "PREPARING" },
    { label: "Served", value: "PACKED" },
  ];
  const FilterListHistory = [
    { label: "All Orders", value: "" },
    { label: "Declined", value: "DECLINE" },
    { label: "Delivered", value: "DELIVERED" },
  ];

  //life cycle
  useEffect(() => {
    if (currentPageOrderChef) {
      fetchOrder(currentPageOrderChef);
    } else {
      fetchOrder();
    }
  }, [selectfilterHistory, pageViewMore]);

  useEffect(() => {
    if (searchOrder?.length === 0) {
      fetchOrder(1);
      setSearchOrder("");
      setpageViewMore(1);
    }
  }, [searchOrder]);

  useEffect(() => {
    orderActiveApi();
  }, [selectfilter]);

  useEffect(() => {
    orderActiveApi();
  }, [intiateOrder]);

  const fetchOrder = async (page = 1, search = "") => {
    const step = 10;
    const newPage = Math.ceil((pageViewMore + 2) / step);
    setCurrentPage(newPage);
    let query = {
      page: newPage === page ? page : newPage,
      limit: 10,
      chefId: localStorage.getItem("userId"),
      orderStatus: selectfilterHistory,
      searchKey: search,
    };
    setIsLoadingHistory(true);
    await getAllOrderChef(query)
      .then(({ data }) => {
        if (data) {
          setList(data?.list || []);
          setPageMeta(data?.pageMeta || {});
        } else {
          setList([]);
        }
        setIsLoadingHistory(false);
      })
      .catch(() => setIsLoadingHistory(false));
  };

  const handleOrderSearch = (val = "") => {
    if (val?.length >= 6) {
      fetchOrder(1, val);
    } else {
      Toast({ type: "error", message: "Please enter a 6-Digit Order no!" });
    }
    if (val?.length === 0 || searchOrder === "") {
      fetchOrder();
    }
  };

  const orderActiveApi = async () => {
    let query = {
      chefId: localStorage.getItem("userId"),
      orderStatus: selectfilter,
    };
    setIsLoading(true);
    await getAllActiveOrderChef(query)
      .then(({ data }) => {
        if (data) {
          setactivelist(data);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };
  const orderActiveApiFormChild = async () => {
    let query = { chefId: localStorage.getItem("userId"), page: 2 };
    await getAllActiveOrderChef(query)
      .then(({ data }) => {
        if (data) {
          setactivelist(data);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleViewMore = () => {
    setpageViewMore(pageViewMore + 2);
  };

  //set filter list
  const setFilterList = ({ target: { value } }) => {
    updateName(value);
  };

  //Add Order handleUpdateStatus
  const handleUpdateStatus = async (status, item) => {
    let query = {
      orderId: item._id,
      orderStatus: status,
      foodId: item?.items[0].foodId._id,
      orderAcceptedDate: acceptDate,
    };
    let setLoader =
      status === "ACCEPT"
        ? setAcceptLoader
        : status === "DECLINE"
          ? setCancelLoader
          : setFormLoader;
    if (validator.current.allValid() || status === "DECLINE") {
      setLoader(true);
      await orderUpdateStatus(query)
        .then(() => {
          orderActiveApiFormChild();
          setAcceptDate(new Date());
          setCurrentIndex(null);
          if (status === "SERVED") {
            fetchOrder();
          }
          setLoader(false);
        })
        .catch(() => setLoader(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };

  const onAssignDelivery = (item) => {
    setShow(true);
    fetchDeliveryPartners();
    setOwnDeliveryOrder(item);
  };

  const fetchDeliveryPartners = () => {
    setIsLoading(true);
    let body = {
      chefId: localStorage.getItem("userId"),
      status: "true",
      page: 1,
    };
    listOwnDeliveryPartner(body)
      .then(({ list, pageMeta }) => {
        setIsLoading(false);
        if (list.length) {
          setDeliveryPartnerList(list);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const assignOrderToPartner = () => {
    if (selectedDeliveryPartner) {
      const { _id } = ownDeliveryOrder;
      let requestObj = {
        orderId: _id,
        deliveryPartnerId: selectedDeliveryPartner,
        addressId: activelist[0]?.userId?._id,
      };
      setIsLoading(true);
      assigntoOwnDeliveryPartner(requestObj)
        .then(() => {
          orderActiveApiFormChild();
          setAcceptDate(new Date());
          setCurrentIndex(null);
          setIsLoading(false);
          setSelectedDeliveryPartner("");
          setOwnDeliveryOrder({});
          setShow(false);
        })
        .catch(() => setIsLoading(false));
    } else {
      Toast({
        type: "error",
        message: "Please select one delivery partner",
      });
    }
  };

  const addDeliveryPartnerOnClick = () => {
    setFormData({ phoneNumber: "", name: "" });
    setShowAddModal(true);
  };

  const handlePartner = () => {
    if (validator.current.allValid()) {
      const requestPayload = {
        chefId: localStorage.getItem("userId"),
        name: formData.name,
        mobileNumber: formData.phoneNumber,
      };
      addDeliveryPartner({ ...requestPayload })
        .then(() => {
          fetchDeliveryPartners();
          handleAddClose();
        })
        .catch(() => setShowAddModal(true));
    } else {
      validator.current.showMessages();
    }
  };

  return (
    <>
          <div className="chef_detail">
        <div className="chef_detail_inner_container">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="header">Active orders</div>
            <div className="col-3 select-filter-active">
              <NormalSelect
                placeholder="Filter"
                name="selectfilter"
                options={FilterList}
                value={selectfilter}
                onChange={setFilterList}
                className="form-control"
              />
            </div>
          </div>
        </div>
        {!isLoading ? (
          activelist.length > 0 ? (
            activelist.map((item, index) => {
              return (
                <div
                  id="order-custom"
                  className="chef_detail_inner_container mt-3"
                >
                  <div className="availble-advance-order">
                    <div className="row mt-2">
                      {item?.items.filter((item) => {
                        return item.foodId?.typeOfMenu === "Available Now";
                      }).length > 0 && (
                          <>
                            <div className="bitesheading col-12 line-height-36 fs-18 fw-700 text-primary-color mb-2 p-0">
                              Available Now
                            </div>
                            <div className="col-xs-12 col-sm-12 col-lg-12 mb-2 bg-grey-f0 p-3">
                              <div className="d-flex">
                                <div className="bitesheading col-5 col-md-6 line-height-36 fs-18 fw-700 text-gold mb-2 d-flex align-items-center flex-wrap">
                                  <span>Order ID: {item.orderId}</span>
                                </div>
                                <div className="col-5 col-md-6 d-flex justify-content-between align-items-center mb-2">
                                  <div className="ms-auto px-3 ">Status</div>
                                  <div className="">
                                    {item.orderStatus === "DECLINE" ? (
                                      <div className="">
                                        <NormalButton
                                          disabled={true}
                                          label="Declined"
                                          className="clear text-black-25 line-height-18 bg-white fs-16 me-3 fw-400 py-2 px-4"
                                        />
                                      </div>
                                    ) : item.orderStatus === "REQUEST" ? (
                                      <div className="d-flex justify-content-center">
                                        {/* {orderDetailsActive.orderStartDate && ( */}
                                        <>
                                          <NormalButton
                                            disabled={cancelLoader}
                                            label="Decline"
                                            className="clear text-black-25 line-height-18 bg-white fs-16 me-3 fw-400 py-2 px-4"
                                            onClick={() =>
                                              handleUpdateStatus("DECLINE", item)
                                            }
                                          />
                                          <NormalButton
                                            disabled={acceptLoader}
                                            onClick={() =>
                                              handleUpdateStatus("ACCEPT", item)
                                            }
                                            label="Accept"
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </>
                                        {/* )} */}
                                      </div>
                                    ) : // : item.orderStatus === "ACCEPT" ? (
                                      //   <div className="">
                                      //     <NormalButton
                                      //       disabled={formLoader}
                                      //       onClick={() => {
                                      //         handleUpdateStatus("PREPARING", item);
                                      //       }}
                                      //       label="Ready"
                                      //       className="clear text-white line-height-18 fs-16 bg-green-1b fw-400 py-2 px-4"
                                      //     />
                                      //   </div>
                                      // )
                                      item.orderStatus === "ACCEPT" ||
                                        item.orderStatus === "PREPARING" ? (
                                        <div className="">
                                          <NormalButton
                                            disabled={formLoader}
                                            onClick={() =>
                                              handleUpdateStatus("PACKED", item)
                                            }
                                            label="Packed"
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </div>
                                      ) : item.orderStatus === "PACKED" ? (
                                        <div className="">
                                          <NormalButton
                                            onClick={() =>
                                              handleUpdateStatus("SERVED", item)
                                            }
                                            disabled={
                                              item.orderInitiated || intiateOrder
                                            }
                                            label="Served"
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </div>
                                      ) : (
                                        <div className="">
                                          <NormalButton
                                            disabled={true}
                                            label="Delivered"
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="text-end p-2">
                                {((item.orderStatus === "PACKED" &&
                                  item.deliveryType != "OWN") ||
                                  (item.orderStatus === "PACKED" &&
                                    item.deliveryPartnerId)) && (
                                    <NormalButton
                                      primary
                                      label="Track Order"
                                      className="px-5 order-track ff"
                                      onClick={() =>
                                        history.push(
                                          `/index/cheftrackorder/${item._id}/track`,
                                          { isOwnDelivery: deliveryPartnerType }
                                        )
                                      }
                                      disabled={item.orderInitiated ? false : true}
                                    />
                                  )}
                                {item.orderStatus === "PACKED" &&
                                  item.deliveryType == "OWN" &&
                                  !item.deliveryPartnerId && (
                                    <NormalButton
                                      primary
                                      label="Assign Delivery Partner"
                                      className="px-5 order-track ff"
                                      onClick={() => onAssignDelivery(item)}
                                      disabled={
                                        item.orderInitiated ? false : true
                                      }
                                    />
                                  )}
                              </div>

                              <div className="d-md-flex  d-lg-flex  d-none mt-4">
                                <div className="col-lg-4">
                                  <h4 className="fs-14 fw-700"> Placed By</h4>
                                  <div className="fs-14 fw-500 text-grey-7c">
                                    {item.userId?.firstName}
                                  </div>
                                  <div className="fs-14 fw-700 text-grey-7c">
                                    {item.addressId?.address1}
                                  </div>
                                  <div className="fs-14 fw-700 text-grey-7c">
                                    {item.addressId?.city} ,
                                    {item.addressId?.state} ,
                                    {item.addressId?.pincode}
                                  </div>
                                  <div className="fs-14 fw-700 text-grey-7c mb-2">
                                    Ph. No: {item.userId?.countryCode}{" "}
                                    {item.userId?.mobileNumber}
                                  </div>
                                </div>
                                <div className="col-lg-4 text-center">
                                  <h4 className="fs-14 fw-700 text-nowrap">
                                    {" "}
                                    Placed at{" "}
                                  </h4>
                                  <p className="fs-14 fw-700 text-grey-7c">
                                    {" "}
                                    {moment(item.orderStartDate).format("LT")}
                                  </p>
                                </div>
                                <div className="col-lg-4 d-md-block  d-lg-block   d-none  col-xs-12 col-sm-12  ps-4">
                                  <div className=" Select Date">
                                    {item.orderStatus === "REQUEST" ? (
                                      <p className="fs-14 text-gray-black text-right">
                                        {" "}
                                      </p>
                                    ) : (
                                      <p className="fs-14 text-gray-black text-right">
                                        {" "}
                                        {item.orderStatus === "DECLINE"
                                          ? "Declined "
                                          : item.orderStatus === "PACKED"
                                            ? "Packed on "
                                            : "Accepted on "}
                                        {item.orderStatus === "ACCEPT" ? (
                                          <>
                                            {moment(
                                              item.orderAcceptedDate
                                            ).format("Do MMM YYYY")}
                                            , <br />
                                            at{" "}
                                            {moment(
                                              item.orderAcceptedDate
                                            ).format("LT")}
                                          </>
                                        ) : (
                                          <>
                                            {moment(item.orderEndDate).format(
                                              "Do MMM YYYY"
                                            )}
                                            , <br />
                                            at{" "}
                                            {moment(item.orderEndDate).format(
                                              "LT"
                                            )}
                                          </>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {item.items
                                .filter((item) => {
                                  return (
                                    item.foodId?.typeOfMenu === "Available Now"
                                  );
                                })
                                .map((food, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <OrderAvailable
                                        food={food}
                                        orderDetailsActive={item}
                                        orderUpdateStatus={orderUpdateStatus}
                                        OrderActiveApi={orderActiveApiFormChild}
                                      />
                                    </React.Fragment>
                                  );
                                })}
                              <div className="row mx-1">
                                <div className="col-12 col-md-6 col-lg-3 px-md-4 p-3 chef_detail_inner_container justify-content-center align-item-center ms-auto">
                                  {/* <div className="d-flex justify-content-between">
                                  <div>Tax </div>
                                  <div>₹{item.tax}</div>
                                </div>
                                <hr /> */}
                                  <div className="d-flex justify-content-between">
                                    <div>Delivery charges </div>
                                    <div>₹{item.deliveryCharges}</div>
                                  </div>
                                  <hr />
                                  <div className="d-flex justify-content-between">
                                    <div className="fw-500">Total </div>
                                    <div className="fw-500">
                                      ₹{item.cartValue}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    <div className="row">
                      {item?.items.filter((item) => {
                        return item.foodId?.typeOfMenu !== "Available Now";
                      }).length > 0 && (
                          <>
                            <div className="bitesheading col-10 line-height-36 fs-18 fw-700 text-primary-color mb-2">
                              Advanced order
                            </div>
                            <div className="col-xs-12 col-sm-12 col-lg-12 mb-3 bg-grey-f0 p-3  ">
                              <div className="d-flex">
                                <div className="bitesheading col-5 col-md-6 line-height-36 fs-18 fw-700 text-gold mb-2 d-flex align-items-center flex-wrap">
                                  <span>Order ID: {item.orderId}</span>
                                </div>
                                <div className="col-5 col-md-6 d-flex justify-content-between align-items-center">
                                  <div className="ms-auto px-3 ">Status</div>
                                  <div className="mb-2 ">
                                    {item.orderStatus === "DECLINE" ? (
                                      <div className="">
                                        <NormalButton
                                          disabled={true}
                                          label="Declined"
                                          className="clear text-black-25 line-height-18 bg-white fs-16 me-3 fw-400 py-2 px-4"
                                        />
                                      </div>
                                    ) : item.orderStatus === "REQUEST" ? (
                                      <div className="d-flex justify-content-center">
                                        <NormalButton
                                          disabled={cancelLoader}
                                          label="Decline"
                                          className="clear text-black-25 line-height-18 bg-white fs-16 me-3 fw-400 py-2 px-4"
                                          onClick={() =>
                                            handleUpdateStatus("DECLINE", item)
                                          }
                                        />
                                        <NormalButton
                                          disabled={acceptLoader}
                                          onClick={() =>
                                            handleUpdateStatus("ACCEPT", item)
                                          }
                                          label="Accept"
                                          className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                        />
                                      </div>
                                    ) : //item.orderStatus === "ACCEPT" ? (
                                      //   <div className="">
                                      //     <NormalButton
                                      //       disabled={formLoader}
                                      //       onClick={() => {
                                      //         handleUpdateStatus("PREPARING", item);
                                      //       }}
                                      //       label="Ready"
                                      //       className="clear text-white line-height-18 fs-16 bg-green-1b fw-400 py-2 px-4"
                                      //     />
                                      //   </div>
                                      // ) :
                                      item.orderStatus === "ACCEPT" ||
                                        item.orderStatus === "PREPARING" ? (
                                        <div className="">
                                          <NormalButton
                                            disabled={formLoader}
                                            onClick={() =>
                                              handleUpdateStatus("PACKED", item)
                                            }
                                            label="Packed"
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </div>
                                      ) : item.orderStatus === "PACKED" ? (
                                        <div className="">
                                          <NormalButton
                                            onClick={() =>
                                              handleUpdateStatus("SERVED", item)
                                            }
                                            label="Served"
                                            disabled={
                                              item.orderInitiated || intiateOrder
                                            }
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </div>
                                      ) : (
                                        <div className="">
                                          <NormalButton
                                            disabled={true}
                                            label="Delivered"
                                            className="clear text-white line-height-18 fs-16 bg-primary-color fw-400 py-2 px-4"
                                          />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="text-end p-2">
                                {((item.orderStatus === "PACKED" &&
                                  item.deliveryType != "OWN") ||
                                  (item.orderStatus === "PACKED" &&
                                    item.deliveryPartnerId)) && (
                                    <NormalButton
                                      primary
                                      label="Track Order"
                                      className="px-5 order-track"
                                      onClick={() => {
                                        history.push(
                                          `/index/cheftrackorder/${item._id}/track`,
                                          { isOwnDelivery: deliveryPartnerType }
                                        );
                                      }}
                                      disabled={item.orderInitiated ? false : true}
                                    />
                                  )}
                                {item.orderStatus === "PACKED" &&
                                  item.deliveryType == "OWN" &&
                                  !item.deliveryPartnerId && (
                                    <NormalButton
                                      primary
                                      label="Assign Delivery Partner"
                                      className="px-5 order-track ff"
                                      onClick={() => onAssignDelivery(item)}
                                      disabled={
                                        item.orderInitiated ? false : true
                                      }
                                    />
                                  )}
                              </div>
                              <div className="col-4 d-md-none  d-block col-xs-12 col-sm-12 mb-2">
                                <div className="Select Date">
                                  {item.orderStatus === "REQUEST" ? (
                                    <>
                                      <span className="fs-14 fw-bold text-gray-black">
                                        Select Date
                                      </span>
                                      <NormalDate
                                        value={
                                          acceptDate && index === currentIndex
                                            ? new Date(acceptDate)
                                            : item?.orderAcceptedDate
                                              ? new Date(item?.orderAcceptedDate)
                                              : new Date()
                                        }
                                        minDate={new Date()}
                                        placeholder="Enter Date"
                                        name="date"
                                        isIcon={true}
                                        className="form-control mini-date"
                                        showYearDropdown={false}
                                        onChange={(e) => {
                                          setAcceptDate(e.target.value);
                                          setCurrentIndex(index);
                                        }}
                                      />
                                      {validator.current.message(
                                        "Date",
                                        acceptDate,
                                        "required"
                                      )}
                                    </>
                                  ) : (
                                    <p className="fs-14 text-gray-black text-right">
                                      {""}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="d-md-flex  d-lg-flex   d-none mt-4">
                                <div className="col-lg-4">
                                  <h4 className="fs-14 fw-700"> Placed By</h4>
                                  <div className="fs-14 fw-500 text-grey-7c">
                                    {item.userId?.firstName}
                                  </div>
                                  <div className="fs-14 fw-700 text-grey-7c">
                                    {item.addressId?.address1}
                                  </div>
                                  <div className="fs-14 fw-700 text-grey-7c">
                                    {item.addressId?.city} ,
                                    {item.addressId?.state} ,
                                    {item.addressId?.pincode}
                                  </div>
                                  <div className="fs-14 fw-700 text-grey-7c mb-2">
                                    Ph. No: {item.userId?.countryCode}{" "}
                                    {item.userId?.mobileNumber}
                                  </div>
                                </div>
                                <div className="col-lg-4 text-center">
                                  <h4 className="fs-14 fw-700 text-nowrap">
                                    Placed at{" "}
                                  </h4>
                                  <p className="fs-14 fw-700 text-grey-7c">
                                    {" "}
                                    {moment(item.orderStartDate).format("LT")}
                                  </p>
                                </div>
                                <div className="col-lg-4 d-md-block  d-lg-block   d-none col-xs-12 col-sm-12 ps-4">
                                  <div className="Select Date">
                                    {item.orderStatus === "REQUEST" ? (
                                      <>
                                        <span className="fs-14 fw-bold text-gray-black">
                                          Select Date
                                        </span>
                                        <NormalDate
                                          value={
                                            acceptDate && index === currentIndex
                                              ? new Date(acceptDate)
                                              : item?.orderAcceptedDate
                                                ? new Date(item?.orderAcceptedDate)
                                                : new Date()
                                          }
                                          minDate={new Date()}
                                          placeholder="Enter Date"
                                          name="date"
                                          isIcon={true}
                                          className="form-control mini-date"
                                          showYearDropdown={false}
                                          onChange={(e) => {
                                            setAcceptDate(e.target.value);
                                            setCurrentIndex(index);
                                          }}
                                        />
                                        {validator.current.message(
                                          "Date",
                                          acceptDate,
                                          "required"
                                        )}
                                      </>
                                    ) : (
                                      <p className="fs-14 text-gray-black text-right">
                                        {" "}
                                        {item.orderStatus === "DECLINE"
                                          ? "Declined "
                                          : "Your Order is arriving on "}
                                        {item.orderStatus === "ACCEPT" ? (
                                          <>
                                            {moment(
                                              item.orderAcceptedDate
                                            ).format("Do MMM YYYY")}
                                            , <br />
                                            at{" "}
                                            {moment(
                                              item.orderAcceptedDate
                                            ).format("LT")}
                                          </>
                                        ) : (
                                          <>
                                            {moment(item.orderEndDate).format(
                                              "Do MMM YYYY"
                                            )}
                                            , <br />
                                            at{" "}
                                            {moment(item.orderEndDate).format(
                                              "LT"
                                            )}
                                          </>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {item.items
                                .filter((item) => {
                                  return (
                                    item.foodId?.typeOfMenu !== "Available Now"
                                  );
                                })
                                .map((food, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <OrderAdvanced
                                        food={food}
                                        orderDetailsActive={item}
                                        orderUpdateStatus={orderUpdateStatus}
                                        OrderActiveApi={orderActiveApiFormChild}
                                      />
                                    </React.Fragment>
                                  );
                                })}
                              <div className="row mx-1">
                                <div className="col-12 col-md-6 col-lg-3 px-md-4 p-3 chef_detail_inner_container justify-content-center align-item-center ms-auto">
                                  {/* <div className="d-flex justify-content-between">
                                  <div>Tax </div>
                                  <div>₹{item.tax}</div>
                                </div>
                                <hr /> */}
                                  <div className="d-flex justify-content-between">
                                    <div>Delivery charges </div>
                                    <div>₹{item.deliveryCharges}</div>
                                  </div>
                                  <hr />
                                  <div className="d-flex justify-content-between">
                                    <div className="fw-500">Total </div>
                                    <div className="fw-500">
                                      ₹{item.cartValue}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-gold text-center mt-2">No Order Found !!!</div>
          )
        ) : (
          <CommonPageLoader />
        )}
      </div>
      <div className="chef_detail mt-5">
        <div className="chef_detail_inner_container">
          <div className="d-flex align-items-center justify-content-between mb-2">
            {searchOrder?.length === 0 ? (
              <div className="header">
                Order History of page - {currentPage}
              </div>
            ) : (
              <div className="header">Order History</div>
            )}
            {/* <div className="select-filter-active">
              <NormalSelect
                placeholder="Filter"
                name="selectfilter"
                options={FilterListHistory}
                value={selectfilterHistory}
                onChange={(e) => {
                  let {
                    target: { value },
                  } = e;
                  setSelectfilterHistory(value);
                }}
                className="form-control filter-select"
              />

              <NormalInput
                type="number"
                placeholder="Search order"
                value={searchOrder}
                onChange={(e) => setSearchOrder(e.target.value)}
                name="searchOrder"
                className="mt-0"
              />

              <NormalButton
                primary
                label="Search"
                className="mx-auto d-block mt-3"
                onClick={() => handleOrderSearch(searchOrder)}
              />
            </div> */}

            <div className="d-md-flex  justify-content-between align-items-center">
              <div className="mx-md-2 my-2">
                <NormalInput
                  type="number"
                  placeholder="Search order"
                  value={searchOrder}
                  onChange={(e) => setSearchOrder(e.target.value)}
                  name="searchOrder"
                  className=""
                  isMarginTop={false}
                />
              </div>
              <NormalButton
                primary
                label="Search"
                className=""
                onClick={() => handleOrderSearch(searchOrder)}
              />

              <div className="select-filter-active ps-2">
                <NormalSelect
                  placeholder="Filter"
                  name="selectfilter"
                  options={FilterListHistory}
                  value={selectfilterHistory}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    setSelectfilterHistory(value);
                  }}
                  className="form-control filter-select"
                />
              </div>
            </div>
          </div>
        </div>
        {!isLoadingHistory ? (
          list.length > 0 ? (
            <>
              {list.slice(0, pageViewMore).map((item, index) => {
                return (
                  <div
                    id="order-custom"
                    className="chef_detail_inner_container mt-3"
                    key={index}
                  >
                    <div className="availble-advance-order">
                      <div className="row mt-2">
                        <div className="col-xs-12 col-sm-12 col-lg-12 mb-2 bg-grey-f0 p-3 ">
                          <div className="d-flex">
                            <div className="bitesheading col-6 line-height-36 fs-18 fw-700 text-gold mb-2 d-flex align-items-center flex-wrap">
                              <span>Order ID: {item.orderId}</span>
                            </div>
                            <div className="col-6 d-flex justify-content-between align-items-center">
                              <div className="ms-auto px-3 d-none d-md-block">
                                Status
                              </div>
                              <div className="d-none d-md-block">
                                <button className="clear text-white line-height-18 DeclineOrderBtnClass fs-16 fw-400 py-2 px-3">
                                  {item.orderStatus === "DECLINE"
                                    ? "Declined"
                                    : "Delivered"}{" "}
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="d-md-flex  d-lg-flex   d-none mt-4">
                            <div className="col-lg-4">
                              <h4 className="fs-14 fw-700"> Placed By</h4>
                              <div className="fs-14 fw-500 text-grey-7c">
                                {item.userId?.firstName}
                              </div>
                              <div className="fs-14 fw-700 text-grey-7c me-4">
                                {item.addressId?.address1}
                              </div>
                              <div className="fs-14 fw-700 text-grey-7c">
                                {item.addressId?.city} ,{item.addressId?.state}{" "}
                                ,{item.addressId?.pincode}
                              </div>
                              <div className="fs-14 fw-700 text-grey-7c mb-2">
                                Ph. No: {item.userId?.countryCode}{" "}
                                {item.userId?.mobileNumber}
                              </div>
                            </div>
                            <div className="col-lg-4 text-center">
                              <h4 className="fs-14 fw-700 text-nowrap">
                                Placed at
                              </h4>
                              <p className="fs-14 fw-700 text-grey-7c">
                                {moment(item.orderStartDate).format("LT")}
                              </p>
                            </div>
                            <div className="col-lg-4">
                              <p className="fs-14 text-gray-black ">
                                {item.orderStatus === "DECLINE"
                                  ? "Declined on "
                                  : item.orderStatus === "PACKED"
                                    ? "Packed on "
                                    : item.orderStatus === "DELIVERED"
                                      ? "Delivered on "
                                      : "Accepted on "}
                                <>
                                  {moment(item.orderEndDate).format(
                                    "Do MMM YYYY"
                                  )}
                                  , <br />
                                  at {moment(item.orderEndDate).format("LT")}
                                </>
                              </p>
                            </div>
                          </div>
                          {item.items.length > 0 &&
                            item.items.map((food, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <OrderServedAvailable
                                    food={food}
                                    orderDetails={item}
                                  />
                                </React.Fragment>
                              );
                            })}
                          <div className="row mx-1">
                            <div className="col-12 col-md-6 col-lg-3 px-md-4 p-3 chef_detail_inner_container justify-content-center align-item-center ms-auto">
                              {/* <div className="d-flex justify-content-between">
                                <div>Tax </div>
                                <div>₹{item.tax}</div>
                              </div>
                              <hr /> */}
                              <div className="d-flex justify-content-between">
                                <div>Delivery charges </div>
                                <div>₹{item.deliveryCharges}</div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div className="fw-500">Total </div>
                                <div className="fw-500">₹{item.cartValue}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="text-gold text-center mt-2">No Order Found !!!</div>
          )
        ) : (
          <CommonPageLoader />
        )}
        {list.length > 0 ? (
          <div className="d-flex justify-content-center align-items-center mt-4 order-served-viewmore">
            <NormalButton
              label="View More"
              className="clear text-primary-color border-1 line-height-18 fs-16 bg-white fw-700 py-2 px-4"
              onClick={handleViewMore}
              disabled={list.length === pageViewMore ? true : false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="container-fluid container-lg py-4 minimum-height">
        {!isLoading && (
          <div className="row">
            <Modal show={show} backdrop={true} keyboard={false}>
              <Modal.Header>
                <Modal.Title>
                  <b>Select Delivery Partner</b>
                </Modal.Title>
                <button
                  className="col-md-1"
                  style={{
                    color: "#fff",
                    border: "none",
                    backgroundColor: "#08316d",
                  }}
                  onClick={handleClose}
                >
                  &#10006;
                </button>
              </Modal.Header>
              <Modal.Body>
                <select
                  class="form-select form-select-md mb-3"
                  onChange={(e) =>
                    setSelectedDeliveryPartner(e.currentTarget.value)
                  }
                >
                  <option selected>select Partner</option>
                  {deliveryPartnerList?.map((partner) => (
                    <option value={partner._id}>{partner.name}</option>
                  ))}
                </select>
              </Modal.Body>
              <Modal.Footer className="pt-0">
                <div className="d-flex justify-content-between w-100">
                  <NormalButton
                    primary
                    label="Add Delivery Partner"
                    className="fw-700"
                    onClick={addDeliveryPartnerOnClick}
                  />

                  <NormalButton
                    primary
                    label="Assign Order"
                    className="fw-700"
                    disabled={isLoading || !!!selectedDeliveryPartner}
                    onClick={assignOrderToPartner}
                  />
                </div>
              </Modal.Footer>
            </Modal>

            {showAddModal && (
              <Modal show={showAddModal} onHide={handleAddClose} size="md">
                <Modal.Header className="bg-primary-color rounded-top">
                  <Modal.Title className="text-white">
                    {"Add Partner"}
                  </Modal.Title>
                  <span className="cursor-pointer" onClick={handleAddClose}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ color: "#ffffff" }}
                    />
                  </span>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#F0F2F5" }}>
                  <div className="row">
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>
                          Name<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Partner Name"
                          onChange={(e) => {
                            let {
                              target: { value },
                            } = e;

                            setFormData((prevState) => ({
                              ...prevState,
                              name: value,
                            }));
                          }}
                          value={formData.name}
                        />

                        {validator.current.message(
                          "Name",
                          formData.name,
                          "required|min:3"
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>
                          Phone Number<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Partner PhoneNumber"
                          onChange={(e) => {
                            let {
                              target: { value },
                            } = e;

                            setFormData((prevState) => ({
                              ...prevState,
                              phoneNumber: value,
                            }));
                          }}
                          value={formData.phoneNumber}
                        />
                        {validator.current.message(
                          "PhoneNumber",
                          formData.phoneNumber,
                          "required|min:10|max:10"
                        )}
                      </Form.Group>
                    </div>

                    {/* <div className="col-12">
                      <Form.Group>
                        <Form.Label>
                          Email ID<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Email ID"
                          onChange={(e) => {
                            let {
                              target: { value },
                            } = e;
                            setFormData((prevState) => ({
                              ...prevState,
                              email: value,
                            }));
                          }}
                          value={formData.email}
                        />
                        {validator.current.message(
                          "Email",
                          formData.email,
                          "required|email"
                        )}
                      </Form.Group>
                    </div> */}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex mt-3">
                    <NormalButton
                      label="Cancel"
                      className="white-btn mr-3"
                      disabled={isLoading}
                      onClick={() => handleAddClose()}
                    />
                    <span className="m-2" />

                    <NormalButton
                      primary
                      label={"Add"}
                      className=""
                      disabled={isLoading}
                      onClick={() => handlePartner()}
                    />
                  </div>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPageOrderChef: state.commonStore.currentPageOrderChef,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllOrderChef,
      getAllActiveOrderChef,
      orderUpdateStatus,
      listOwnDeliveryPartner,
      assigntoOwnDeliveryPartner,
      trackOwnDeliveryOrderStatus,
      addDeliveryPartner,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChefOrder);
