import {
  chefDataType,
  notificationType,
  userLocationType,
  CartDataType,
  localCartType,
} from "service/actionType";
import { Toast } from "service/toast";

const initialState = {
  chefData: {},
  notificationListData: {},
  userLocation: {},
  currentPageChef: 1,
  currentPageMenu: 1,
  currentPageOrderCustomer: 1,
  currentPageOrderChef: 1,
  totalCart: [],

  addCartLocal: [],
  activeChef: {},
};

const handleActiveChef = (state, payload) => {
  if (state?.activeChef?._id === payload._id) {
    let chef = state?.activeChef;
    Toast({
      type: "success",
      message: `You have Already added ${chef?.businessName} Food`,
      time: 1000,
    });
    return payload;
  } else {
    return state?.activeChef;
  }
};
const commonReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case chefDataType.chefData:
      return {
        ...state,
        chefData: action.payload,
      };
    case notificationType.notificationListData:
      return {
        ...state,
        notificationListData: action.payload,
      };
    case userLocationType.userLocation:
      return {
        ...state,
        userLocation: action.payload,
      };
    case userLocationType.currentPageChef:
      return {
        ...state,
        currentPageChef: action.payload,
      };
    case userLocationType.currentPageMenu:
      return {
        ...state,
        currentPageMenu: action.payload,
      };
    case userLocationType.currentPageOrderCustomer:
      return {
        ...state,
        currentPageOrderCustomer: action.payload,
      };
    case userLocationType.currentPageOrderChef:
      return {
        ...state,
        currentPageOrderChef: action.payload,
      };
    case CartDataType.totalCart:
      return {
        ...state,
        totalCart: action.payload,
      };
    case localCartType.totalCartLocal:
      localStorage.setItem("localCart", JSON.stringify(action.payload));
      if (action.payload?.length === 0) {
        return [];
      } else {
        return {
          ...state,
          totalCartLocal: action.payload,
        };
      }
    case localCartType.activeChef:
      return handleActiveChef(state, action.payload);
    default:
      return state;
  }
};

export default commonReducer;
