import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { List } from "../common/List";
import { Cards } from "../common/Cards";
import Slider from "react-slick";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import landingBg from "assets/images/landingbgImage.png";
import landingBg1 from "assets/images/landingbgImage1.png";
import landingBg2 from "assets/images/landingbgImage2.png";
import landingBg3 from "assets/images/landingbgImage3.png";
import mobileLandingBg from "assets/images/mobileLandingBg.jpg";
import mobileLandingBg1 from "assets/images/mobileLandingBg1.jpg";
import mobileLandingBg2 from "assets/images/mobileLandingBg2.jpg";
import mobileLandingBg3 from "assets/images/mobileLandingBg3.jpg";
import { userLocationType } from "service/actionType";
import { history } from "service/helpers";
import { Toast } from "service/toast";
import { logout } from "service/utilities";
import { Slick1 } from "component/common/Slick1";
import Offers from "component/Landing/Offers";
import { testimonialList, offersList, getAllTestimonial } from "action/OrderReviewAct";
import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import {
  extractAddressFields,
  debounceFunction,
} from "service/helperFunctions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCarousel from "./Testimonial";

const bannerData = [
  {
    title: "The Chef Lane",
    description: "Home-cooked food for every mood",
    bgImage: landingBg,
    mobileBg: mobileLandingBg,
  },
  {
    title: "The Chef Lane",
    description: "From their homes to yours",
    bgImage: landingBg1,
    mobileBg: mobileLandingBg1,
  },
  {
    title: "The Chef Lane",
    description: "Healthy, hygienic & homely",
    bgImage: landingBg2,
    mobileBg: mobileLandingBg2,
  },
  {
    title: "The Chef Lane",
    description: "A cornucopia of cuisine in your city",
    bgImage: landingBg3,
    mobileBg: mobileLandingBg3,
  },
];

const LandingClass = (props) => {
  const [address, setAddress] = useState("");
  const [timeoutForMap, setTimeoutForMap] = useState(false);
  const [testimonilaData, setTestimonilaData] = useState([]);
  const [offerListData, setOfferListData] = useState([]);

  // set lat long value
  const handleLatLng = (data) => {
    let newData = [];
    Object.keys(data).forEach((item) => newData.push(data[item]));
  };
  const handleFind = (e) => {
    let location = e;
    if (Object.keys(location).length) {
      props.updatePageCount(1);
      history.push(
        `/index/userDetail?area=${location.area}&city=${location.city}&state=${location.state}&country=${location.region}`
      );
    } else if (address !== "") {
      Toast({ type: "error", message: "Please select from drop-down" });
    }
  };
  const handleFindError = () => {
    if (address !== "")
      Toast({ type: "error", message: "Please select from drop-down" });
  };

  const params = {
    slidesPerView: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 5000,
  };

  const params1 = {
    slidesPerView: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    fade: false,
    speed: 500,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    if (!!userDetails && userDetails?.userTypeRole === "chef") {
      logout();
    }
    const timer = setTimeout(() => {
      setTimeoutForMap(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    debounceFunction(() => getLocation(), 1000);
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, null);
    }
  };

  const showPosition = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    setTimeout(() => {
      navigatorGeoLocation(lat, lon).then((response) => {
        let data = response.results;
        let address = extractAddressFields(data[0].address_components);
        if (!!address) {
          props.testimonialListApi(address.city).then((data) => {
            setTestimonilaData(data);
          });
        }
      });
    }, 100);
  };

  const getOffersList = () => {
    props
      .offersListApi({ page: 1, limit: 10000 })
      .then((data) => {
        if (data.list.length > 0) {
          setOfferListData(data.list);
        }
      })
      .catch((e) => console.log("e :>> ", e));
  };

  useEffect(() => {
    getOffersList();
  }, []);

  return (
    <div className="Landing w-100">

      {/* <Slider {...params} className="Landing-slider">
        {bannerData.map((list, index) => (
          <div key={index}>
            <div
              className="chef chef-mobile"
              style={{ backgroundImage: `url(${list.mobileBg})` }}
            >
              {offerListData.map((offer, offerIndex) => (
                <div
                  key={offerIndex}
                  className="fw-700 text-danger text-center"
                  style={{ zIndex: "1" }}
                >
                  {offer.title}
                </div>
              ))}
              <div className="container h-100">
                <div className="row h-100">
                  <div className="my-auto">
                    <div className="fw-700 text-white text-center">
                      <p className="fs-41 mb-2 pb-1 line-height-48">
                        {list.title}
                      </p>
                      <p className="fs-18 fw-400 mb-2 pb-1 line-height-20">
                        {list.description}
                      </p>
                      <div className="maxWidth-500 mx-auto">
                        {timeoutForMap && (
                          <LocationSearchInput
                            isMap={"landing"}
                            address={address}
                            handleAddress={(e) => handleFind(e)}
                            placeholder=""
                            handleSelect={(address) => setAddress(address)}
                            handleLatLng={handleLatLng}
                            handleFindError={handleFindError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="chef chef-web"
              style={{ backgroundImage: `url(${list.bgImage})` }}
            >
              <div className="container h-100">
                <div className="row h-100">
                  <div className="my-auto">
                    <div className="fw-700 text-white text-center">
                      <p className="fs-41 mb-2 pb-1 line-height-48">
                        {list.title}
                      </p>
                      <p className="fs-18 fw-400 mb-2 pb-1 line-height-20">
                        {list.description}
                      </p>
                      <div className="maxWidth-500 mx-auto">
                        {timeoutForMap && (
                          <LocationSearchInput
                            isMap={"landing"}
                            address={address}
                            handleAddress={(e) => handleFind(e)}
                            placeholder=""
                            handleSelect={(address) => setAddress(address)}
                            handleLatLng={handleLatLng}
                            handleFind={handleFind}
                            handleFindError={handleFindError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider> */}

      <Slider {...params} className="Landing-slider">
        {bannerData.map((list, index) => (
          <div key={index}>
            <div
              className="chef chef-mobile"
              style={{ backgroundImage: `url(${list.mobileBg})` }}
            >
              <div className="container h-100 mb-5 pb-2">
                {/* <Slider {...params1} className="">
                  {offerListData.map((offer, offerIndex) => (
                    <div key={offerIndex} className="">
                      <div className="fw-700 p-2 text-center bg-transparent">
                        <button
                          className="button"
                          onClick={() =>
                            history.push("/auth/login?userTypeRole=chef")
                          }
                        >
                          <b>Offer For Chef Registration</b> <br />
                          {offer.title.charAt(0).toUpperCase() +
                            offer.title.slice(1)}
                          {"  - "}
                          {offer.offerPercentage}%
                        </button>
                      </div>
                    </div>
                  ))}
                </Slider> */}
                <div className="row h-100">
                  <div className="my-auto">
                    <div className="fw-700 text-white text-center">
                      <p className="fs-41 mb-2 pb-1 line-height-48">
                        {list.title}
                      </p>
                      <p className="fs-18 fw-400 mb-2 pb-1 line-height-20">
                        {list.description}
                      </p>
                      <div className="maxWidth-500 mx-auto mb-5">
                        {timeoutForMap && (
                          <LocationSearchInput
                            isMap={"landing"}
                            address={address}
                            handleAddress={(e) => handleFind(e)}
                            placeholder=""
                            handleSelect={(address) => setAddress(address)}
                            handleLatLng={handleLatLng}
                            handleFindError={handleFindError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="chef chef-web"
              style={{ backgroundImage: `url(${list.bgImage})` }}
            >
              {/* <Slider {...params1} className="Landing-slider text-center">
                {offerListData.map((offer, offerIndex) => (
                  <div
                    key={offerIndex}
                    className="offer-slide maxWidth-800 w-100"
                  >
                    <div className="fw-700 p-2 text-center bg-transparent d-flex align-items-stretch justify-content-center">
                      <button
                        className="button mx-3"
                        onClick={() =>
                          history.push("/auth/login?userTypeRole=chef")
                        }
                      >
                        <b>Offer For Chef Registration</b> <br />
                        {offer.title.charAt(0).toUpperCase() +
                          offer.title.slice(1)}
                        {"  - "}
                        {offer.offerPercentage}%
                      </button>
                    </div>
                  </div>
                ))}
              </Slider> */}
              <div className="container h-100">
                <div className="row h-100">
                  <div className="m-auto">
                    <div className="fw-700 text-white text-center">
                      <div></div>
                      <p className="fs-41 mb-2 pb-1 line-height-48">
                        {list.title}
                      </p>
                      <p className="fs-18 fw-400 mb-2 pb-1 line-height-20">
                        {list.description}
                      </p>
                      <div className="maxWidth-500 mx-auto">
                        {timeoutForMap && (
                          <LocationSearchInput
                            isMap={"landing"}
                            address={address}
                            handleAddress={(e) => handleFind(e)}
                            placeholder=""
                            handleSelect={(address) => setAddress(address)}
                            handleLatLng={handleLatLng}
                            handleFind={handleFind}
                            handleFindError={handleFindError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
                  
      <Offers offerListData={offerListData} />
      <Cards />
      <List />
      <TestimonialCarousel />
      {/* <Slick1 testimonilaData={testimonilaData} /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      offersListApi: offersList,
      testimonialListApi: testimonialList,
      getAllTestimonial,
      updatePageCount: () =>
        dispatch({ type: userLocationType.currentPageChef, payload: 1 }),
    },
    dispatch
  );
};

export const LandingComp = connect(null, mapDispatchToProps)(LandingClass);
