import React, { useState, useEffect } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSubScription } from "action/OrderReviewAct";
import arrowBack1 from "assets/images/arrowBack1.png";
import moment from "moment";
import { NormalButton } from "component/common";
import axios from "axios";

function Subscription({ getSubScriptionApi }) {
  const [subscriptionData, setSubscriptionData] = useState({});
  const [view, setView] = useState(false);
  const [renderPage, setRenderPage] = useState(false);
  const chefId = localStorage.getItem("userId");

  //life cycle
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getSubScriptionApi({ chefId: localStorage.getItem("userId") })
      .then(({ data }) => {
        if ({ data }) {
          setSubscriptionData(data);
        }
      })
      .catch((e) => console.log("e :>> ", e))
      .finally(() => setRenderPage(true));
  };

  const offerPlan = () => {
    let plan = subscriptionData?.offerPlan;

    let offer = {
      "1M": "1 Month",
      "2M": "2 Months",
      "3M": "3 Months",
      "4M": "4 Months",
      "5M": "5 Months",
      "6M": "6 Months",
      "7M": "7 Months",
      "8M": "8 Months",
      "9M": "9 Months",
      "10M": "10 Months",
      "11M": "11 Months",
      "1Y": "1 Year",
      "1L": "Life Time",
    };

    return offer[plan];
  };

  const handleDownloadInvoice = async (chefId) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://preprodapis.thecheflane.com/api/v1/chef/subscriptionPDF?chefId=${chefId}`,
        {
          headers: headers,
          responseType: "blob",
        }
      );
      if (response?.status === 200) {
        const jsonData = await response.data.text();
        const parsedData = JSON.parse(jsonData);
        window.open(parsedData?.url);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      {renderPage && (
        <>
          {" "}
          <div className="rate_detail_inner_container">
            <div>
              <div className="d-flex align-items-center justify-content-between title-rateing position-relative">
                {view ? (
                  <div className="header d-flex align-items-center">
                    <img
                      src={arrowBack1}
                      alt=""
                      onClick={() => setView(false)}
                      style={{ cursor: "pointer" }}
                    />
                    <p className="m-0 px-3">Subscription Details</p>

                    <div className="alignDownloadInvoiceBtn">
                      {/* <a
                        className={`commonButton border mx-2 download-btn text-white text-decoration-none position-relative px-5`}
                        href={downloadExcelData}
                      >
                        <span className="px-2">Download as</span>
                      </a> */}
                      <NormalButton
                        primary
                        label="Download Invoice"
                        onClick={() => handleDownloadInvoice(chefId)}
                        className="fw-700"
                      />
                    </div>
                    <hr />
                  </div>
                ) : (
                  <div className="header">Subscription</div>
                )}
              </div>
            </div>
          </div>
          {!view && (
            <div>
              <div className="subscription_card mt-4">
                <div>
                  <div className="offer_content">
                    <p className="m-0">
                      {subscriptionData?.title?.toUpperCase()}
                    </p>
                    <button
                      className={
                        subscriptionData?.status === "Active"
                          ? "active_offer"
                          : ""
                      }
                    >
                      Active
                    </button>
                  </div>
                  {subscriptionData?.offerPercentage && (
                    <div className="offer_per mt-3">
                      {subscriptionData?.offerPercentage} % OFF on Chef
                      Registration
                    </div>
                  )}
                </div>
                <div className="view_button-loc">
                  <button onClick={() => setView(true)} className="View_button">
                    View
                  </button>
                </div>
              </div>
            </div>
          )}
          {view && (
            <>
              <div className="subscription_details">
                {subscriptionData?.title && (
                  <>
                    <div>
                      <p>Subscription Id</p>
                      <span>{subscriptionData?.invoiceId}</span>
                    </div>
                    <div>
                      <p>Offer Title</p>
                      <span>{subscriptionData?.title.toUpperCase()}</span>
                    </div>
                    <div>
                      <p>Offer Details</p>
                      <span>
                        {subscriptionData?.offerPercentage} % OFF on Chef
                        Registration
                      </span>
                    </div>
                    <div>
                      <p>Duration</p>
                      <span>{offerPlan()}</span>
                    </div>
                  </>
                )}
                <div>
                  <p>Start Date</p>
                  <span>
                    {moment(subscriptionData?.subscriptionStartDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </div>
                <div>
                  <p>End Date</p>
                  <span>
                    {offerPlan() === "Life Time"
                      ? "-"
                      : moment(subscriptionData?.subscriptionEndDate).format(
                        "DD-MM-YYYY"
                      )}
                  </span>
                </div>
                <div>
                  <p>Amount Paid</p>
                  <span> &#8377; {subscriptionData?.totalNetAmount}</span>
                </div>
                <div>
                  <p>Status</p>
                  <span>{subscriptionData?.status}</span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSubScriptionApi: getSubScription }, dispatch);
};

export default connect(null, mapDispatchToProps)(Subscription);
