import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { CommonPageLoader, NormalButton, NormalDate } from "component/common";
import BasicDetail from "./BasicDetail/BasicDetail";
import MenuDetails from "./Menu";
import ChefOrder from "./ChefOrder";
import RateReview from "./RateReview";
import Subscription from "./Subscription";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "./sideBar";
import UnderTrial from "./userTrial";
import { getSingle, chefEditUpdate, deleteChef } from "action/ChefDetailsAct";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { fileUpload } from "action/FileUpload";
import logo from "assets/images/alertLogo.png";
import "./BasicDetail/styles.scss";
import { Toast } from "service/toast";
import { logout } from "service/utilities";
import OwnPartner from "./OwnPartner";
import AvailabilityTime from "./AvailabilityTime";
import { useLocation } from 'react-router-dom';

function ActiveProfileComponent({
  getSingle,
  data,
  fileUpload,
  chefEditUpdate,
  deleteChef,
}) {
  let userDetails = data;

  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLicense, setIsLicense] = useState(false);
  const [formData, setFormData] = useState({LicenseDetails: {}, bankDetails: {}});
  const [active, setActive] = useState("basicDetail");
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [toastView, setToastView] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const [trialExpiredData, setTrialExpiredData] = useState();
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [deactivateLoader, setdeactivateLoader] = useState(false);
  const referenceFile = useRef();
  const [licenseformData, setLicenseformData] = useState({
    LicenseDetails: {}, 
  });
  const [bankDetailsformData, setBankDetailsformData] = useState({ bankDetails: {},
  });
  const [isBankDtl, setIsBankDtl] = useState(false);

  const location = useLocation();
  const isRedirect = location?.state?.isRedirect;
  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  useLayoutEffect(() => { }, [formData, localStorage.getItem("call")]);

  
  
  useEffect(() => {
    if (
      data?.bankDetails?.adminApproved === null &&
      data?.LicenseDetails?.adminApproved === "APPROVED"
    ) {
      setIsBankDtl(true);
    } else {
      setIsBankDtl(false);
    }
  }, [userDetails]);


  useEffect(() => {
    fetchChefDetails();
  }, [active,isRedirect]);

  const fetchChefDetails = async () => {
    let id = localStorage.getItem("userId");
    !id && logout();
    setIsLoading(true);
    id &&
      (await getSingle(id)
        .then((response) => {

          let { checkLicenseExpired, data, trialExpiredDays, trialExpired } =
            response;

          if (!!data && !data.deactivateId) {
            let { LicenseDetails, bankDetails } = data;
if(bankDetails.adminApproved === null &&
  data.service !== "LISTING"){
            data.bankDetails = {
          accountName: "",
          accountNumber: "",
          ifsc: "",
          re_enter_accountNumber: "",
          adminApproved: false,
          isEdit: false,
        };
        setIsSubmitted(true);
        setFormData(data);
  setIsBankDtl(true);

}
            if (data.status === "PENDING") {
              if (!toastView) {
                setToastView(true);
                Toast({
                  type: "info",
                  message: "Your profile is waiting for approval.",
                  time: 2000,
                });
              }
            }
            if (
              data.status === "SUSPENDED" ||
              checkLicenseExpired ||
              (data.status === "PENDING" &&
                LicenseDetails.adminApproved === "REJECTED" &&
                data.service !== "LISTING")
            ) {
              data.LicenseDetails = {
                licenseNumber: "",
                licenseExpiryDate: null,
                licenseFileUrl: "",
                adminApproved: false,
                isEdit: false,
              };
              setIsSubmitted(true);
              setFormData(data);
              setIsLicense(true);
              // setIsBankDtl(true);
            }  

// else if (
//       bankDetails.adminApproved === null &&
//     data.service !== "LISTING"
// ) {
//   data.bankDetails = {
//     accountName: "",
//     accountNumber: "",
//     ifsc: "",
//     re_enter_accountNumber: "",
//     adminApproved: false,
//     isEdit: false,
//   };
//   setIsSubmitted(true);
//   setFormData(data);
// }

            else {
              data.isEdit = false;
              setFormData(data);
            }
            if (trialExpired || trialExpiredDays < 8) {
              setIsTrialExpired(true);
              let obj = {
                trialExpired,
                trialExpiredDays,
              };
              setTrialExpiredData(obj);
            }
          } else {
            if (data.deactivateId) {
              Toast({ type: "error", message: "Account is deactivated", toastId: 'success1' });
            }
            logout();
          }
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false)));
  };

  

  const handleFileUpload = async (event, name) => {
    var data = new FormData();
    let file = {};
    file = event.target.files[0];
    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      Toast({
        type: "error",
        message: "Please upload document file that is 5MB or less",
      });
    } else {
      setIsDocumentuploading(true);

      await fileUpload(data)
        .then((data) => {
          let { LicenseDetails } = { ...licenseformData };
          LicenseDetails.licenseFileUrl = data?.data?.url;
          setLicenseformData((prevState) => ({
            ...prevState,
            LicenseDetails,
          }));

          setIsDocumentuploading(false);
        })
        .catch(() => setIsDocumentuploading(false));
    }
  };

  const submitBasicDetails = async () => {
    let flag = validatorLicence.current.allValid();
    if (flag) {
      setIsFormLoading(true);
  
      // Create an empty object to store only modified details
      // formData
      // const formData = {};
      
      // Add the user ID
      formData["masterChefId"] = localStorage.getItem("userId");
      formData.isEdit = true;
      formData.isListingToPlus = true;
            // Conditionally add LicenseDetails if present in licenseformData
      if (licenseformData.LicenseDetails.isEdit) {
        formData.LicenseDetails = {
          ...licenseformData.LicenseDetails,
          adminApproved: null,
        };
      }
  
      // Conditionally add bankDetails if present in bankDetailsformData
      if (bankDetailsformData.bankDetails.isEdit) {
        formData.bankDetails = {
          ...bankDetailsformData.bankDetails,
          adminApproved: null,
        };
      }
  
      // Remove unnecessary fields if they exist in formData
      delete formData["_id"];
      delete formData["__v"];
  
      // Proceed with the request using formData
      await chefEditUpdate(formData)
        .then(() => {
          setIsFormLoading(false);
          setIsSubmitted(true);
          window.location.reload();
        }) 
        .catch(() => setIsFormLoading(false))
        .finally(() => {
          setFormData((prevState) => ({
            ...prevState,
            LicenseDetails: licenseformData.LicenseDetails || prevState.LicenseDetails,
            bankDetails: bankDetailsformData.bankDetails || prevState.bankDetails,
          }));
        });
    } else {
      validatorLicence.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };
  

  // const submitBasicDetails = async () => {
  //   let flag = validatorLicence.current.allValid();
  //   if (flag) {
  //     setIsFormLoading(true);

  //     formData.LicenseDetails = licenseformData.LicenseDetails;
  //     formData.bankDetails = bankDetailsformData.bankDetails;

  //     formData["masterChefId"] = localStorage.getItem("userId");
  //     formData.LicenseDetails.isEdit = true;
  //     formData.LicenseDetails.adminApproved = null;
  //     formData.bankDetails.isEdit = true;
  //     formData.bankDetails.adminApproved = null;
  //     formData.isEdit = true;
  //     formData.isListingToPlus = true;
  //     delete formData["_id"];
  //     delete formData["__v"];
  //     await chefEditUpdate(formData)
  //       .then(() => {
  //         setIsFormLoading(false);
  //         setIsSubmitted(true);
  //         window.location.reload();
  //       }) 
  //       .catch(() => setIsFormLoading(false))
  //       .finally(() => {
  //         setFormData((prevState) => ({
  //           ...prevState,
  //           LicenseDetails: licenseformData.LicenseDetails,
  //           bankDetails: bankDetailsformData.bankDetails,
  //         }));
  //       });
  //   } else {
  //     validatorLicence.current.showMessages();
  //     setUpdateValidator(!updateValidator);
  //   }
  // };

  const setFormDatePending = (data) => {
    setLicenseformData({ 
      LicenseDetails: formData?.LicenseDetails });
          setBankDetailsformData({
            bankDetails: formData?.bankDetails
  });

    if(!formData?.LicenseDetails?.adminApproved || formData?.LicenseDetails?.adminApproved==="REJECTED"){
      setIsLicense(true);
    }
    else{
      setIsLicense(false);
    }

    if(!formData?.bankDetails?.adminApproved || formData?.bankDetails?.adminApproved==="REJECTED"){
      setIsBankDtl(true);
    }
    else{
            setIsBankDtl(false);
    }

  };

  return (
    <section>
      {isTrialExpired && (
        <UnderTrial
          formData={formData}
          isTrialExpired={isTrialExpired}
          setIsTrialExpired={setIsTrialExpired}
          trialExpiredData={trialExpiredData}
        />
      )}

<div className="container-fluid container-lg py-4 minimum-height">
  {!isLoading ? (
    <div className="row">
      {isLicense || isBankDtl ? (
        <Modal
          show={isLicense || isBankDtl}
          backdrop={true}
          keyboard={false}
          className="deactivate-modal license-model"
          centered
        >
          <Modal.Header className="border-0 bg-white p-0">
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isFormLoading ? (
              <div className="col-sm-12 edit-width-ad">
                <CommonPageLoader />
              </div>
            ) : (
              <>
                {isSubmitted && formData.status === "PENDING" ? (
                  <>
                    {isLicense && 
                      <p className="my-5 fs-20 text-black px-5">
                        Your license is under review
                      </p>
                    }
                    {isBankDtl && 
                      <p className="my-5 fs-20 text-black px-5">
                        Your Bank Details are under review
                      </p>
                    }
                </>
                ) : (
                  <>
                    <div id="custom-profile" className="bg-white text-start pb-1">
                      <div className="text-center">
                        <img src={logo} alt="logo" />
                      </div>

                        {isLicense &&
                          <p className="my-5 fs-20 text-black text-center">
                            There is a due for your certification. Please upload to take orders.
                          </p>
                        }
                        
                        {isLicense &&
                          <>
                            <div className="row">
                                                          <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>License Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter License Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { LicenseDetails } = {
                                        ...licenseformData,
                                      };
                                      LicenseDetails.licenseNumber   = value;
LicenseDetails.isEdit = true;
                                      setLicenseformData((prevState) => ({
                                        ...prevState,
                                        LicenseDetails,
                                      }));
                                    }}
                                    value={
                                      licenseformData.LicenseDetails
                                        .licenseNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "License Number",
                                    licenseformData.LicenseDetails
                                      .licenseNumber,
                                    "required|integer|max:20"
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>License Expiry Date</Form.Label>
                                  <NormalDate
                                    placeholder="Enter Date"
                                    name="licenseExpiryDate"
                                    value={
                                      licenseformData.LicenseDetails
                                        .licenseExpiryDate &&
                                      new Date(
                                        licenseformData.LicenseDetails.licenseExpiryDate
                                      )
                                    }
                                    isIcon={false}
                                    minDate={new Date(moment().add(10, "days"))}
                                    className="form-control mini-date"
                                    showYearDropdown={true}
                                    onChange={(e) => {
                                      let { LicenseDetails } = {
                                        ...licenseformData,
                                      };
                                      LicenseDetails.licenseExpiryDate =
                                        e.target.value;
                                        LicenseDetails.isEdit = true;
                                      setLicenseformData((prevState) => ({
                                        ...prevState,
                                        LicenseDetails,
                                      }));
                                    }}
                                  />

                                  {validatorLicence.current.message(
                                    "License Expiry",
                                    licenseformData.LicenseDetails
                                      .licenseExpiryDate,
                                    "required"
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="custom-file-upload license-upload">
                                  <Form.Group className="mb-0">
                                    <Form.Label>License File</Form.Label>
                                    <div className="position-relative">
                                      <Form.Control
                                        autoComplete="off"
                                        disabled={isDocumentuploading}
                                        type="file"
                                        ref={referenceFile}
                                        placeholder="Enter..."
                                        accept="application/pdf,image/*"
                                        className="custom-edited-upload"
                                        onChange={(e) => {
                                          e.target.files[0] &&
                                            handleFileUpload(e, "licenseFile");
                                        }}
                                      />
                                      <p className="fileNAme">
                                        {isDocumentuploading ? (
                                          "loading..."
                                        ) : (
                                          <>
                                            {licenseformData.LicenseDetails
                                              .licenseFileUrl
                                              ? licenseformData.LicenseDetails.licenseFileUrl
                                                .split("/")
                                                .pop()
                                                .split("--")
                                                .pop()
                                              : ""}
                                          </>
                                        )}
                                      </p>
                                      <NormalButton
                                        label="Upload"
                                        className="fw-700 fs-14 file-btn"
                                        disabled={isDocumentuploading}
                                        onClick={() =>
                                          referenceFile.current.click()
                                        }
                                      />
                                      <p className="fs-12 alert-for-upload">
                                        <sup className="text-danger fs-14">
                                          *
                                        </sup>
                                        Please upload file that is 5MB or less
                                      </p>
                                      <div className="mt-2">
                                        {validatorLicence.current.message(
                                          "License File",
                                          licenseformData.LicenseDetails
                                            .licenseFileUrl,
                                          "required"
                                        )}
                                      </div>
                                    </div>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>

                          </>
                        }
                      <div className="row">
                        
                        {isBankDtl &&
                          <p className="my-5 fs-20 text-black text-center">
                            There is a due for your Bank Details. Please upload to take orders.
                          </p>
                        }
                        {isBankDtl &&
                          <>
                            <div className="row">
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Account Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account name holder"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.accountName = value;
                                      bankDetails.isEdit = true;
                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .accountName
                                    }
                                  /> 
                                  {validatorLicence.current.message(
                                    "Account Name",
                                    bankDetailsformData.bankDetails.accountName,
                                    "required|min:5|max:20|alpha"
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>IFSC Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter ifsc code"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.ifsc = value;
                                      bankDetails.isEdit = true;
                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .ifsc
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Name",
                                    bankDetailsformData.bankDetails.ifsc,
                                    "required|alpha_num|max:11"
                                  )}
                                </Form.Group>
                              </div>

                            </div>
                            <div className="row">
                            <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.accountNumber = value;
                                      bankDetails.isEdit = true;
                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .accountNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Number",
                                    bankDetailsformData.bankDetails
                                        .accountNumber,
                                        "required|integer|max:20"

                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Re-enter Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.re_enter_accountNumber = value;
                                      bankDetails.isEdit = true;
                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .re_enter_accountNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Number",
                                    bankDetailsformData.bankDetails
                                        .re_enter_accountNumber,
                                    "required|integer|max:20"
                                  )}
                                </Form.Group>
                              </div>
                            </div>

                          </>
                        }
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex pb-3">
                        <NormalButton
                          disabled={isFormLoading}
                          primary
                          label="Update"
                          className="px-5"
                          onClick={() => submitBasicDetails()}
                        />
                      </div>
                    </Modal.Footer>
                  </>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <>
          <div className="col-md-3">
            <SideBar
              active={active}
              setActive={setActive}
              data={formData}
              setShowDeactivate={setShowDeactivate}
              setFormDatePending={setFormDatePending}
            />
          </div>
          <div className="col-md-9">
            {active === "basicDetail" || active === "address" ? (
              <BasicDetail
                active={active}
                dataFromParent={formData}
                fetchChefDetailsParent={fetchChefDetails}
              />
            ) : active === "menu" ? (
              <MenuDetails dataFromParent={formData} />
            ) : active === "chefOrder" ? (
              <ChefOrder chefDetails={formData} />
            ) : active === "rateReview" ? (
              <RateReview />
            ) : active === "availabilityTime" ? (
              <AvailabilityTime />
            ) : active === "ownDelivery" ? (
              <OwnPartner />
            ) : (
              <Subscription />
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <CommonPageLoader />
  )}
</div>


      {/* <div className="container-fluid container-lg py-4 minimum-height">
        {!isLoading ? (
          <div className="row">
            {isLicense ? (
              <Modal
                show={isLicense}
                backdrop={true}
                keyboard={false}
                className="deactivate-modal license-model"
                centered
              >
                <Modal.Header className=" border-0 bg-white p-0">
                  <Modal.Title> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isFormLoading ? (
                    <div className="col-sm-12 edit-width-ad">
                      <CommonPageLoader />
                    </div>
                  ) : (
                    <>
                      {isSubmitted && formData.status === "PENDING" ? (
                        <p className="my-5 fs-20 text-black px-5">
                          Your license is under review !
                        </p>
                        
                      ) : (
                        <>
                          <div
                            id="custom-profile"
                            className="bg-white text-start pb-1"
                          >
                            <div className="text-center ">
                              <img src={logo} alt="logo" />
                            </div>
                            <p className="my-5 fs-20 text-black text-center">
                              There is a due for your certification <br />
                              please upload to take orders.
                            </p>

                            <div className="row">
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>License Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter License Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { LicenseDetails } = {
                                        ...licenseformData,
                                      };
                                      LicenseDetails.licenseNumber = value;

                                      setLicenseformData((prevState) => ({
                                        ...prevState,
                                        LicenseDetails,
                                      }));
                                    }}
                                    value={
                                      licenseformData.LicenseDetails
                                        .licenseNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "License Number",
                                    licenseformData.LicenseDetails
                                      .licenseNumber,
                                    "required|integer|max:20"
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>License Expiry Date</Form.Label>
                                  <NormalDate
                                    placeholder="Enter Date"
                                    name="licenseExpiryDate"
                                    value={
                                      licenseformData.LicenseDetails
                                        .licenseExpiryDate &&
                                      new Date(
                                        licenseformData.LicenseDetails.licenseExpiryDate
                                      )
                                    }
                                    isIcon={false}
                                    minDate={new Date(moment().add(10, "days"))}
                                    className="form-control mini-date"
                                    showYearDropdown={true}
                                    onChange={(e) => {
                                      let { LicenseDetails } = {
                                        ...licenseformData,
                                      };
                                      LicenseDetails.licenseExpiryDate =
                                        e.target.value;

                                      setLicenseformData((prevState) => ({
                                        ...prevState,
                                        LicenseDetails,
                                      }));
                                    }}
                                  />

                                  {validatorLicence.current.message(
                                    "License Expiry",
                                    licenseformData.LicenseDetails
                                      .licenseExpiryDate,
                                    "required"
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="custom-file-upload license-upload">
                                  <Form.Group className="mb-0">
                                    <Form.Label>License File</Form.Label>
                                    <div className="position-relative">
                                      <Form.Control
                                        autoComplete="off"
                                        disabled={isDocumentuploading}
                                        type="file"
                                        ref={referenceFile}
                                        placeholder="Enter..."
                                        accept="application/pdf,image/*"
                                        className="custom-edited-upload"
                                        onChange={(e) => {
                                          e.target.files[0] &&
                                            handleFileUpload(e, "licenseFile");
                                        }}
                                      />
                                      <p className="fileNAme">
                                        {isDocumentuploading ? (
                                          "loading..."
                                        ) : (
                                          <>
                                            {licenseformData.LicenseDetails
                                              .licenseFileUrl
                                              ? licenseformData.LicenseDetails.licenseFileUrl
                                                .split("/")
                                                .pop()
                                                .split("--")
                                                .pop()
                                              : ""}
                                          </>
                                        )}
                                      </p>
                                      <NormalButton
                                        label="Upload"
                                        className="fw-700 fs-14 file-btn"
                                        disabled={isDocumentuploading}
                                        onClick={() =>
                                          referenceFile.current.click()
                                        }
                                      />
                                      <p className="fs-12 alert-for-upload">
                                        <sup className="text-danger fs-14">
                                          *
                                        </sup>
                                        Please upload file that is 5MB or less
                                      </p>
                                      <div className="mt-2">
                                        {validatorLicence.current.message(
                                          "License File",
                                          licenseformData.LicenseDetails
                                            .licenseFileUrl,
                                          "required"
                                        )}
                                      </div>
                                    </div>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Modal.Footer>
                            <div className="d-flex pb-3">
                              <NormalButton
                                disabled={isFormLoading}
                                primary
                                label="Update"
                                className="px-5 "
                                onClick={() => submitBasicDetails()}
                              />
                            </div>
                          </Modal.Footer>
                        </>
                      )}
                    </>
                  )}
                </Modal.Body>
              </Modal>
            ) : 
            
            (isBankDtl ? (
              <Modal
                show={isBankDtl}
                backdrop={true}
                keyboard={false}
                className="deactivate-modal license-model"
                centered
              >
                <Modal.Header className=" border-0 bg-white p-0">
                  <Modal.Title> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isFormLoading ? (
                    <div className="col-sm-12 edit-width-ad">
                      <CommonPageLoader />
                    </div>
                  ) : (
                    <>
                      {isSubmitted && formData.status === "PENDING" ? (
                        <p className="my-5 fs-20 text-black px-5">
                          Your Bank Details is under review !
                        </p>
                      ) : (
                        <>
                          <div
                            id="custom-profile"
                            className="bg-white text-start pb-1"
                          >
                            <div className="text-center ">
                              <img src={logo} alt="logo" />
                            </div>
                            <p className="my-5 fs-20 text-black text-center">
                              There is a due for your Bank Details <br />
                              please upload to take orders.
                            </p>

                            <div className="row">
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Account Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account name holder"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.accountName = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .accountName
                                    }
                                  /> 
                                  {validatorLicence.current.message(
                                    "Account Name",
                                    bankDetailsformData.bankDetails.accountName,
                                    "required|min:5|max:20|alpha"
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>IFSC Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter ifsc code"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.ifsc = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .ifsc
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Name",
                                    bankDetailsformData.bankDetails.ifsc,
                                    "required|alpha_num|max:11"
                                  )}
                                </Form.Group>
                              </div>

                            </div>
                            <div className="row">
                            <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.accountNumber = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .accountNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Number",
                                    bankDetailsformData.bankDetails
                                        .accountNumber,
                                        "required|integer|max:20"

                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Re-enter Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.re_enter_accountNumber = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .re_enter_accountNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Number",
                                    bankDetailsformData.bankDetails
                                        .re_enter_accountNumber,
                                    "required|integer|max:20"
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <Modal.Footer>
                            <div className="d-flex pb-3">
                              <NormalButton
                                disabled={isFormLoading}
                                primary
                                label="Update"
                                className="px-5 "
                                onClick={() => submitBasicDetails()}
                              />
                            </div>
                          </Modal.Footer>
                        </>
                      )}
                    </>
                  )}
                </Modal.Body>
              </Modal>
            ) 
                    :
            (
              <>
                <div className="col-md-3">
                  <SideBar
                    active={active}
                    setActive={setActive}
                    data={formData}
                    setShowDeactivate={setShowDeactivate}
                    setFormDatePending={setFormDatePending}
                  />
                </div>
                <div className="col-md-9 ">
                  {active === "basicDetail" || active === "address" ? (
                    <BasicDetail
                      active={active}
                      dataFromParent={formData}
                      fetchChefDetailsParent={fetchChefDetails}
                    />
                  ) : active === "menu" ? (
                    <MenuDetails dataFromParent={formData} />
                  ) : active === "chefOrder" ? (
                    <ChefOrder chefDetails={formData} />
                  ) : active === "rateReview" ? (
                    <RateReview />
                  ) : active === "availabilityTime" ? (
                    <AvailabilityTime />
                  ) : active === "ownDelivery" ? (
                    <OwnPartner />
                  ) : (
                    <Subscription />
                  )}
                </div>
              </>
            ))
            }
          </div> 
        ) : (
          <CommonPageLoader />
        )}
      </div> */}

      {/* Bank */}
      {/* <div className="container-fluid container-lg py-4 minimum-height">
        {!isLoading ? (
          <div className="row">
            {isBankDtl ? (
              <Modal
                show={isBankDtl}
                backdrop={true}
                keyboard={false}
                className="deactivate-modal license-model"
                centered
              >
                <Modal.Header className=" border-0 bg-white p-0">
                  <Modal.Title> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isFormLoading ? (
                    <div className="col-sm-12 edit-width-ad">
                      <CommonPageLoader />
                    </div>
                  ) : (
                    <>
                      {isSubmitted && formData.status === "PENDING" ? (
                        <p className="my-5 fs-20 text-black px-5">
                          Your Bank Details is under review !
                        </p>
                      ) : (
                        <>
                          <div
                            id="custom-profile"
                            className="bg-white text-start pb-1"
                          >
                            <div className="text-center ">
                              <img src={logo} alt="logo" />
                            </div>
                            <p className="my-5 fs-20 text-black text-center">
                              There is a due for your Bank Details <br />
                              please upload to take orders.
                            </p>

                            <div className="row">
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Account Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account name holder"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.accountName = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .accountName
                                    }
                                  /> 
                                  {validatorLicence.current.message(
                                    "Account Name",
                                    bankDetailsformData.bankDetails.accountName,
                                    "required|min:5|max:20|alpha"
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>IFSC Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter ifsc code"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.ifsc = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .ifsc
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Name",
                                    bankDetailsformData.bankDetails.ifsc,
                                    "required|alpha_num|max:11"
                                  )}
                                </Form.Group>
                              </div>

                            </div>
                            <div className="row">
                            <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.accountNumber = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .accountNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Number",
                                    bankDetailsformData.bankDetails
                                        .accountNumber,
                                        "required|integer|max:20"

                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>Re-enter Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Account Number"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { bankDetails } = {
                                        ...bankDetailsformData,
                                      };
                                      bankDetails.re_enter_accountNumber = value;

                                      setBankDetailsformData((prevState) => ({
                                        ...prevState,
                                        bankDetails,
                                      }));
                                    }}
                                    value={
                                      bankDetailsformData.bankDetails
                                        .re_enter_accountNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "Account Number",
                                    bankDetailsformData.bankDetails
                                        .re_enter_accountNumber,
                                    "required|integer|max:20"
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <Modal.Footer>
                            <div className="d-flex pb-3">
                              <NormalButton
                                disabled={isFormLoading}
                                primary
                                label="Update"
                                className="px-5 "
                                onClick={() => submitBasicDetails()}
                              />
                            </div>
                          </Modal.Footer>
                        </>
                      )}
                    </>
                  )}
                </Modal.Body>
              </Modal>
            ) : (
              <>
                <div className="col-md-3">
                  <SideBar
                    active={active}
                    setActive={setActive}
                    data={formData}
                    setShowDeactivate={setShowDeactivate}
                    setFormDatePending={setFormDatePending}
                  />
                </div>
                <div className="col-md-9 ">
                  {active === "basicDetail" || active === "address" ? (
                    <BasicDetail
                      active={active}
                      dataFromParent={formData}
                      fetchChefDetailsParent={fetchChefDetails}
                    />
                  ) : active === "menu" ? (
                    <MenuDetails dataFromParent={formData} />
                  ) : active === "chefOrder" ? (
                    <ChefOrder chefDetails={formData} />
                  ) : active === "rateReview" ? (
                    <RateReview />
                  ) : active === "availabilityTime" ? (
                    <AvailabilityTime />
                  ) : active === "ownDelivery" ? (
                    <OwnPartner />
                  ) : (
                    <Subscription />
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <CommonPageLoader />
        )}
      </div> */}

      <Modal
        show={showDeactivate}
        onHide={() => setShowDeactivate(false)}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal"
        centered
      >
        <Modal.Body>
          <div className="alert-deActive mt-5">
            <h4 className="mb-0">
              Are you Sure you want to
              <br /> Delete?
            </h4>
          </div>
          <Modal.Footer>
            <div className="d-flex deactivate-block">
              <NormalButton
                label="Yes, Delete"
                className="w-100 white-btn mr-3"
                disabled={deactivateLoader}
                onClick={() => {
                  setdeactivateLoader(true);

                  let id = formData._id;
                  deleteChef(id)
                    .then((data) => {
                      if (data.deletedData) {
                        setShowDeactivate(false);
                        Toast({ type: "error", message: "Account is deactivated", toastId: 'success1' });
                        logout();
                      }
                      setdeactivateLoader(false);
                    })
                    .catch(() => {
                      setdeactivateLoader(false);
                      setShowDeactivate(true);
                    });
                }}
              />
              <span className="m-2" />
              <NormalButton
                primary
                label="No, Keep my Account"
                className="w-100 fw-700"
                disabled={deactivateLoader}
                onClick={() => setShowDeactivate(false)}
              />
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={toastView}
        onHide={() => setToastView(false)}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal"
        centered
      >
        <Modal.Body>
          <div className="alert-deActive mt-5">
            <h4 className="mb-0">
              kkfk
            </h4>
          </div>
        </Modal.Body>
      </Modal> */}
    </section>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSingle, fileUpload, chefEditUpdate, deleteChef },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ActiveProfileComponent);
