import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
// import Form from "react-bootstrap/Form";
import { Form, InputGroup, Dropdown } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";

import plus from "../../../assets/images/plus.png";
import {
  NormalButton,
  NormalToggleSwitch,
  NormalRadio,
  NormalDate,
  NormalCustomCheckbox,
  CommonPageLoader,
  ImageCropComponent,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chefUpdate, getCusineList, businessTypeList } from "action/ChefDetailsAct";
import { fileUpload, imageUpload } from "action/FileUpload";
import facebook from "assets/images/facebook.png";
import Instagram from "assets/images/Instagram.png";
import closeGray from "assets/images/closeGray.png";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import moment from "moment";
import { Toast } from "service/toast";
import { handleNameField } from "service/helperFunctions";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import axios from 'axios';

const regExp = /[a-zA-Z]/g;
function BasicDetail({
  getCusineList,
  resetFormData,
  fileUpload,
  imageUpload,
  chefUpdate,
  businessTypeList,
  setrenderCusinieList,
  selectedCuisineList = [],
  setSelectedCuisineList,
  handleFormChange,
  formData,
  setFormData,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showPlatformAlert, setShowPlatformAlert] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const referenceFile = useRef();
  const [showImageCrop, setShowImageCrop] = useState(false);

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const handleClose = () => {
    setShow(false);
    setSearch("");
  };
  const handleShow = () => setShow(true);
  const [cuisineList, setcuisineList] = useState([]);
  const reference = useRef();
  const [croppingImage, setCroppingImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isuploading, setIsuploading] = useState(false);
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [search, setSearch] = useState("");
  const [errorFacebook, setErrorFacebook] = useState(true);
  const [errorInstagram, setErrorInstagram] = useState(true);

  const [businessTypes, setBusinessTypes] = useState([]);
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState([]);
  const [isBusinessTypeValid, setIsBusinessTypeValid] = useState(true);


  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorLicence = useRef(
    new SimpleReactValidator({

      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  //life cycle
  useEffect(() => {
    fetchCusineList();
  }, []);
  const fetchCusineList = async () => {
    setIsLoading(true);
    await getCusineList()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.length) {
          setcuisineList(data);
          // setFormData({ ...newForm });
          // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        }
      })
      .catch(() => setIsLoading(false));
  };
  const handleBeforeImageCropping = (event) => {
    let file = event.target.files[0];
    if (file) {
      setCroppingImage(file);
      event.target.value = "";
      setShowImageCrop(true);
    }
  };
  const handleFileUpload = async (event, name = "chefImage") => {
    let keySet = name === "chefImage" ? setIsuploading : setIsDocumentuploading;
    var data = new FormData();
    let file = {};
    file = name === "chefImage" ? event : event.target.files[0];
    let api = name === "chefImage" ? imageUpload : fileUpload;

    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      if (name === "chefImage")
        Toast({
          type: "error",
          message: "Please upload image file that is 5MB or less",
        });
      else
        Toast({
          type: "error",
          message: "Please upload document file that is 5MB or less",
        });
    } else {
      keySet(true);

      await api(data)
        .then((data) => {

          if (name === "chefImage") {
            setFormData((prevState) => ({
              ...prevState,
              chefImage: data?.data?.url,
            }));
          } else {
            let { LicenseDetails } = { ...formData };
            LicenseDetails.licenseFileUrl = data?.data?.url;
            setFormData((prevState) => ({
              ...prevState,
              LicenseDetails,
            }));
          }
          keySet(false);
        })
        .catch(() => keySet(false));
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = async () => {
    businessTypeList().then(res=> {
      setBusinessTypes(res?.data)
      setFilteredBusinessTypes(res?.data)
    }).catch()

     };



  const handleChangeBusiness = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, businessType: value });

    // Filter the business types based on the input
    const filtered = businessTypes.filter(type =>
      type.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredBusinessTypes(filtered);
    setDropdownOpen(filtered.length > 0 && value.length > 0);

    // Validate the input against the dropdown options
    const isValid = businessTypes.some(type => type.name.toLowerCase() === value.toLowerCase());
    setIsBusinessTypeValid(isValid);
  };


  const mapCusine = () => {
    setShow(false);
    setrenderCusinieList(selectedCuisineList);
  };

  const handleSelectedCuisine = (e) => {
    if (e?.target?.value) {
      let value = e?.target?.name;
      setSelectedCuisineList([...selectedCuisineList, value]);
    } else {
      setSelectedCuisineList(
        selectedCuisineList.filter((item) => item !== e.target.name)
      );
    }
  };
  const isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  const submitBasicDetails = async () => {

    const accountNumberValidationMessage = validateAccountNumbersMatch();
    if (accountNumberValidationMessage !== true) {
      Toast({
        type: 'error',
        message: accountNumberValidationMessage,
      });
      // setSubmit(false);
      return;
    }
    let flag =
      formData.service !== "LISTING PLUS" && formData.countryCode !== "+91"
        ? validator.current.allValid()
        : validator.current.allValid() && validatorLicence.current.allValid();

    let socialMediaFlag = true;
    let socialMediaFlagInsta = true;
    formData.AddressDetails = {
      primary: false,
      country: formData?.AddressDetails?.country ?? "",
      state: formData?.AddressDetails?.state ?? "",
      address1: formData?.AddressDetails?.address1 ?? "",
      address2: formData?.AddressDetails?.address2 ?? "",
      city: formData?.AddressDetails?.city ?? "",
      area: formData?.AddressDetails?.area ?? "",
      pincode: formData?.AddressDetails?.pincode ?? "",
      location: { lat: "", long: "" },
    };

    if (
      formData?.facebook?.facebookLink !== "" &&
      formData?.facebook?.facebookLink !== undefined
    ) {
      setErrorFacebook(isUrlValid(formData?.facebook?.facebookLink));
      socialMediaFlag = isUrlValid(formData?.facebook?.facebookLink);
    }
    if (
      formData?.instagram?.instagramLink !== "" &&
      formData?.instagram?.instagramLink !== undefined
    ) {
      setErrorInstagram(isUrlValid(formData?.instagram?.instagramLink));
      socialMediaFlagInsta = isUrlValid(formData?.instagram?.instagramLink);
    }
    if (flag && socialMediaFlag && socialMediaFlagInsta) {
      setIsLoading(true);
      formData.firstName = handleNameField(formData.firstName);
      formData.lastName = handleNameField(formData.lastName);
      formData.cuisine = selectedCuisineList;
      // if (formData.deliveryPartners === "OWN") {
      //   formData.ownDeliveryPartnerDetails = [
      //     {
      //       "_id": "",
      //       "name": "",
      //       "mobileNumber": ""
      //     }
      //   ];
      // }

      // await chefUpdate(formData)
      //   .then(() => {
      //     setIsLoading(false);
      handleFormChange("addressDetails");
      // })
      // .catch(() => setIsLoading(false));
    }
    else {
      if (
        (formData.service === "LISTING PLUS" ||
          formData.countryCode === "+91") &&
        formData.service !== "LISTING"
      ) {
        validatorLicence.current.showMessages();
        scrollTop("FoodLicense");
      }
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please complete all required fields.",
      });
      if (
        formData.service !== "LISTING" &&
        formData.countryCode === "+91" &&
        validatorLicence.current.allValid()
      )
        scrollTop();
      if (formData.countryCode !== "+91") scrollTop();
    }
  };

  if (
    formData.countryCode === undefined ||
    formData.countryCode === "" ||
    formData.whatsappCountryCode === undefined ||
    formData.whatsappCountryCode === ""
  ) {
    setFormData((prevState) => ({
      ...prevState,
      countryCode: "+91",
      whatsappCountryCode: "+91",
    }));
  }

  const scrollTop = (key = "custom-profile") => {
    let element = document.getElementById(key);
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };
  const handleSearch = (e) => {
    let {
      target: { value },
    } = e;
    setSearch(value);
  };
  const handleChange = (e) => {
    let {
      target: { name, value },
    } = e;

    if (name === "countryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        mobileNumber: "",
      }));

      if (formData.sameAsMobileNumber) {
        setFormData((prevState) => ({
          ...prevState,
          sameAsMobileNumber: e.target.value,
          whatsappNumber: "",
          whatsappCountryCode: value,
        }));
      }
    }

    if (name === "whatsappCountryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        //whatsappNumber: formData.mobileNumber,
        whatsappNumber: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (formData.sameAsMobileNumber && name === "mobileNumber") {
      setFormData((prevState) => ({
        ...prevState,
        whatsappNumber: value,
      }));
    }
  };

  const validateAccountNumbersMatch = () => {
    if (formData.bankDetails.accountNumber && formData.bankDetails.re_enter_accountNumber) {
      if (formData.bankDetails.accountNumber !== formData.bankDetails.re_enter_accountNumber) {
        return "The account number and re-enter account number must be same.";
      }
    }


    return true;
  };

  return (
    <div className="basic_detail_container">

      <div className="container-fluid container-lg">
        <div className="basic_detail_inner_container">
          <div className="header" id="custom-profile">
            <div>Basic Details</div>
            <hr></hr>
          </div>
          <div className="profile_pic_container">
            <div className="profile_pic">
              <div className="box">
                {isuploading ? (
                  <CommonPageLoader />
                ) : (
                  <>
                    {!!formData.chefImage ? (
                      <img src={formData.chefImage} alt="Thumb" />
                    ) : (
                      <span className="d-flex">
                        <img src={userUpload} alt="userUpload" />
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="availability ">
                <Form.Label className="mb-3">Availability </Form.Label>
                <div className="d-flex align-items-center">
                  <NormalToggleSwitch
                    name="availability "
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        availabilityStatus: e.target.checked,
                      }))
                    }
                    checked={formData.availabilityStatus}
                  />
                  <div className="check-value">
                    {formData.availabilityStatus ? "YES" : "NO"}
                  </div>
                </div>
              </div>
            </div>

            <div className="upload_btn">
              <input
                title=""
                id="chefImage"
                ref={reference}
                onChange={(e) => handleBeforeImageCropping(e)}
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                className="d-none"
              />

              <NormalButton
                label=" Upload Image"
                disabled={isuploading}
                className="w-100 fw-700 outline-gray-btn"
                onClick={() => reference.current.click()}
              />
              <p className="fs-12 alert-for-upload-profile mb-0 mt-2">
                <sup className="text-danger fs-14">*</sup>
                Please upload image file that is 5MB or less
              </p>
              {validator.current.message(
                "Image",
                formData.chefImage,
                "required"
              )}
            </div>
          </div>

          <div className="body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    First Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    onChange={(e) => handleChange(e)}
                    value={formData.firstName}
                  />
                  {validator.current.message(
                    "First Name",
                    formData.firstName,
                    "required|min:3|max:20"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Last Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    placeholder="Enter Last Name"
                    onChange={(e) => handleChange(e)}
                    value={formData.lastName}
                  />
                  {validator.current.message(
                    "Last Name",
                    formData.lastName,
                    "required|max:20"
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Brand Name/ Business Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="businessName"
                    autoComplete="off"
                    placeholder="Enter Brand Name"
                    onChange={(e) => handleChange(e)}
                    value={formData.businessName}

                  />
                  {validator.current.message(
                    "Business Name",
                    formData.businessName,
                    "required|min:3|max:50"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Mobile Number<sup>*</sup>
                  </Form.Label>
                  <div className="d-flex country-mobile">
                    <div className="country-code-dropdown">
                      <CountryCodeDropDown
                        onClick={(e) =>
                          handleChange({
                            target: { name: "countryCode", value: e },
                          })
                        }
                        value={
                          formData.countryCode === undefined ||
                            formData.countryCode === ""
                            ? "+91"
                            : formData.countryCode
                        }
                        disabled={formData.loginType === false ? true : false}
                      />
                    </div>
                    <Form.Control
                      autoComplete="nope"
                      disabled={formData.loginType === false ? true : false}
                      type="number"
                      name="mobileNumber"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => handleChange(e)}
                      value={formData.mobileNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {validator.current.message(
                    "Mobile Number",
                    formData.mobileNumber,
                    "required"
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Email ID<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    type="email"
                    name="email"
                    placeholder="Enter Email ID"
                    onChange={(e) => handleChange(e)}
                    value={formData.email}
                  />
                  {validator.current.message(
                    "Email",
                    formData.email,
                    "required|email"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Whatsapp Number
                    <sup>*</sup>
                  </Form.Label>
                  <div className="d-flex country-mobile">
                    <div className="country-code-dropdown">
                      <CountryCodeDropDown
                        onClick={(e) =>
                          handleChange({
                            target: { name: "whatsappCountryCode", value: e },
                          })
                        }
                        value={
                          formData?.whatsappCountryCode
                            ? formData?.whatsappCountryCode
                            : "+91"
                        }
                        disabled={formData.sameAsMobileNumber}
                      />
                    </div>

                    <Form.Control
                      autoComplete="nope"
                      disabled={formData.sameAsMobileNumber}
                      type="number"
                      name="whatsappNumber"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => {
                        if (!regExp.test(e.currentTarget.value)) {
                          handleChange(e);
                        }
                      }}
                      value={formData.whatsappNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {validator.current.message(
                    "Whatsapp Number",
                    formData.whatsappNumber,
                    "required|min:10|max:10|Numeric"
                  )}
                </Form.Group>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                <Form.Group className="mb-0">
                  <NormalCustomCheckbox
                    name="sameAsMobileNumber"
                    checked={formData.sameAsMobileNumber}
                    value={formData.sameAsMobileNumber}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      if (value) {
                        setFormData((prevState) => ({
                          ...prevState,
                          sameAsMobileNumber: e.target.value,
                          whatsappNumber: formData.mobileNumber,
                          whatsappCountryCode: formData?.countryCode,
                        }));
                      } else {
                        setFormData((prevState) => ({
                          ...prevState,
                          sameAsMobileNumber: e.target.checked,
                          whatsappNumber: "",
                          whatsappCountryCode: "",
                        }));
                      }
                    }}
                    label={"Same as mobile number"}
                    disable={!formData.mobileNumber}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-3">
              <Form.Group>
                <Form.Label>
                  Business Type<sup>*</sup>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="businessType"
                    autoComplete="off"
                    placeholder="Enter Business Type"
                    onChange={handleChangeBusiness}
                    value={formData.businessType}
                    className="businessType-color"
                  />

                  <Dropdown onToggle={(isOpen) => setDropdownOpen(isOpen)} show={dropdownOpen}>
                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="border-0">
                      {dropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                    </Dropdown.Toggle>
                    {filteredBusinessTypes.length > 0 && (
                      <Dropdown.Menu className="business-dropdown-menu">
                        {filteredBusinessTypes.map((type, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setFormData({ ...formData, businessType: type.name });
                              setDropdownOpen(false);
                              setIsBusinessTypeValid(true); // Set valid when an item is selected
                            }}
                          >
                            {type.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </InputGroup>

                {!isBusinessTypeValid && (
                  <div className="text-danger mt-2 fs-12">
                    Please select a valid business type from the list.
                  </div>
                )}

                {validator.current.message(
                  "Business Type",
                  formData.businessType,
                  "required|min:3|max:50"
                )}
              </Form.Group>
            </div>


            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8">
                <Form.Group>
                  <Form.Label>
                    About me<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    maxLength="500"
                    type="text"
                    className="w-100"
                    autoComplete="off"
                    placeholder="About you in 500 characters or less"
                    onChange={(e) => {
                      let { AboutDetails } = { ...formData };
                      let {
                        target: { value },
                      } = e;
                      AboutDetails.About = value;
                      setFormData((prevState) => ({
                        ...prevState,
                        AboutDetails,
                      }));
                    }}
                    value={formData.AboutDetails.About}
                  />
                  {validator.current.message(
                    "About me",
                    formData.AboutDetails.About,
                    "required|min:10|max:500"
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="cusine-blocks">
                  <Form.Label className="mb-0">
                    Cuisine<sup>*</sup>
                  </Form.Label>

                  <div className="cuisine_list">
                    <span className="button-width">
                      <NormalButton
                        label="Add Cuisine"
                        className="fw-700 outline-gray-btn"
                        onClick={handleShow}
                        prefix={plus}
                      />
                    </span>
                    {selectedCuisineList?.map((item) => (
                      <div className="cusine_item">
                        {item}&ensp;
                        <b
                          className="rmv_cuisine_item"
                          onClick={() => {
                            setSelectedCuisineList(
                              selectedCuisineList.filter((items) => items !== item)
                            );
                            setrenderCusinieList(selectedCuisineList);
                          }}
                        >
                          &#10006;
                        </b>
                      </div>
                    ))}
                  </div>
                  {validator.current.message(
                    "Cuisine",
                    selectedCuisineList,
                    "required"
                  )}
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop={true}
                    keyboard={false}
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <b>Select Cuisine</b>
                      </Modal.Title>
                      <span class="cursor-pointer" onClick={handleClose}>
                        <img src={closeBtn} alt="icon" />
                      </span>
                    </Modal.Header>

                    <Modal.Body>
                      <div className="model_body">
                        <div className="search-outer">
                          <div class="input-group  search-input-group">
                            <input
                              type="text"
                              id="searchValue"
                              name="searchValue"
                              autoComplete="off"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                              className="form-control border-right-0 searchInput"
                              placeholder="Search"
                              aria-label="Search"
                              aria-describedby="basic-addon2"
                            />
                            <div class="input-group-append ">
                              <span
                                class="input-group-text bg-white border-left-0 cursor-pointer"
                                onClick={() => setSearch("")}
                              >
                                <img
                                  className="mailIcon"
                                  src={closeGray}
                                  alt="search"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="list-body">
                          <div className="row">
                            {cuisineList
                              .filter((item) =>
                                search === ""
                                  ? item
                                  : String(item)
                                    .toLowerCase()
                                    .match(search.toLowerCase())
                              )
                              .map((item) => {
                                return (
                                  <div className="col-6 mb-2">
                                    <NormalCustomCheckbox
                                      name={item}
                                      checked={selectedCuisineList.includes(item)}
                                      value={selectedCuisineList.includes(item)}
                                      onChange={(e) => handleSelectedCuisine(e)}
                                      label={item}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="pt-0">
                      <div className="d-flex justify-content-start w-100">
                        <NormalButton
                          label="Discard"
                          className="white-btn mr-3"
                          onClick={() => {
                            setSelectedCuisineList([]);
                            setShow(false);
                            setSearch("");
                          }}
                        />
                        <span className="m-2" />

                        <NormalButton
                          primary
                          label="Save"
                          className="fw-700"
                          disabled={isLoading}
                          onClick={() => {
                            mapCusine();
                            setSearch("");
                          }}
                        />
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* <div className="row">
            <div className="col-sm-12 col-md-8">
              <Form.Group>
                <Form.Label>
                  Delivery Partnersss<sup>*</sup>
                </Form.Label>
                <div>
                  <span className="mx-2">
                    <NormalRadio
                      className=" border-0 rounded-0"
                      label="Own delivery partners"
                      name="OWN"
                      value={formData.deliveryPartners === "OWN"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          deliveryPartners: e.target.name,
                          cashOnDelivery: false,
                        }));
                      }}
                      checked={formData.deliveryPartners === "OWN"}
                      disabled={false}
                    />
                  </span>
                  <span className="mx-4">
                    <NormalRadio
                      className=" border-0 rounded-0"
                      label="Platform delivery partners"
                      name="PLATFORM"
                      value={formData.deliveryPartners === "PLATFORM"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          deliveryPartners: e.target.name,
                        }));
                        setShowPlatformAlert(true);
                      }}
                      checked={formData.deliveryPartners === "PLATFORM"}
                    />
                  </span>
                </div>

                {validator.current.message(
                  "delivery partners",
                  formData.deliveryPartners,
                  "required"
                )}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-8">
              {formData.deliveryPartners === "PLATFORM" ||
              formData.deliveryPartners === "OWN" ? (
                <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                  <NormalCustomCheckbox
                    name="cashOnDelivery"
                    checked={formData.cashOnDelivery}
                    value={formData.cashOnDelivery}
                    onChange={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        cashOnDelivery: !formData.cashOnDelivery,
                      }))
                    }
                    label="Allow Cash on Delivery"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}

          <br />
          <div className="header" id="FoodLicense">
            <div>Food License</div>
            <hr></hr>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-5 col-lg-3">
              <Form.Group>
                <Form.Label>
                  License Number
                  {formData.service === "LISTING"
                    ? ""
                    : (formData.service === "LISTING PLUS" ||
                      formData.countryCode === "+91") && <sup>*</sup>}
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="Enter License Number"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { LicenseDetails } = { ...formData };
                    LicenseDetails.licenseNumber = value;

                    setFormData((prevState) => ({
                      ...prevState,
                      LicenseDetails,
                    }));
                  }}
                  value={formData.LicenseDetails.licenseNumber}
                />
                {formData.service === "LISTING PLUS" &&
                  validatorLicence.current.message(
                    "License Number",
                    formData.LicenseDetails.licenseNumber,
                    "required|max:20"
                  )}
              </Form.Group>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-5">
              <div className="custom-file-upload error-file-license">
                <Form.Group>
                  <Form.Label>
                    License File
                    {formData.service === "LISTING"
                      ? ""
                      : (formData.service === "LISTING PLUS" ||
                        formData.countryCode === "+91") && <sup>*</sup>}
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      disabled={isDocumentuploading}
                      type="file"
                      ref={referenceFile}
                      placeholder="Enter..."
                      accept="application/pdf,image/*"
                      className="custom-edited-upload"
                      onChange={(e) => {
                        e.target.files[0] && handleFileUpload(e, "licenseFile");
                      }}
                    />
                    <p className="fileNAme">
                      {isDocumentuploading ? (
                        "loading..."
                      ) : (
                        <>
                          {formData.LicenseDetails.licenseFileUrl
                            ? formData.LicenseDetails.licenseFileUrl
                              .split("/")
                              .pop()
                              .split("--")
                              .pop()
                            : ""}
                        </>
                      )}
                    </p>
                    <NormalButton
                      label="Upload"
                      className="fw-700 fs-14 file-btn"
                      disabled={isDocumentuploading}
                      onClick={() => referenceFile.current.click()}
                    />
                    <p className="fs-12 alert-for-upload mb-0">
                      {formData.service !== "LISTING" &&
                        formData.countryCode === "+91" && (
                          <sup className="text-danger fs-14">*</sup>
                        )}
                      Please upload file that is 5MB or less
                    </p>
                    {formData.service === "LISTING PLUS" &&
                      validatorLicence.current.message(
                        "License File",
                        formData.LicenseDetails.licenseFileUrl,
                        "required"
                      )}
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-5 col-lg-3">
              <Form.Group>
                <Form.Label>
                  License Expiry Date
                  {formData.service === "LISTING"
                    ? ""
                    : (formData.service === "LISTING PLUS" ||
                      formData.countryCode === "+91") && <sup>*</sup>}
                </Form.Label>
                <NormalDate
                  placeholder="Enter Date"
                  name="licenseExpiryDate"
                  showYearDropdown={true}
                  value={
                    formData.LicenseDetails.licenseExpiryDate &&
                    new Date(formData.LicenseDetails.licenseExpiryDate)
                  }
                  isIcon={false}
                  minDate={new Date(moment().add(10, "days"))}
                  className="form-control mini-date"
                  onChange={(e) => {
                    let { LicenseDetails } = { ...formData };
                    LicenseDetails.licenseExpiryDate = e.target.value;

                    setFormData((prevState) => ({
                      ...prevState,
                      LicenseDetails,
                    }));
                  }}
                />
                {formData.service === "LISTING PLUS" &&
                  validatorLicence.current.message(
                    "License Expiry",
                    formData.LicenseDetails.licenseExpiryDate,
                    "required"
                  )}
              </Form.Group>
            </div>
          </div>


          <div className="header" id="bankDetails">
            <div>Bank Details</div>
            <hr></hr>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-4">
              <Form.Group>
                <Form.Label>
                  Bank IFSC Code<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="Enter IFSC Code"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { bankDetails } = { ...formData };
                    // let bankDetails = {};
                    bankDetails.ifsc = value;

                    setFormData((prevState) => ({
                      ...prevState,
                      bankDetails,
                    }));
                  }}
                  value={formData?.bankDetails?.ifsc}
                />
                {
                  // formData.service === "LISTING PLUS" &&
                  validator.current.message(
                    "IFSC Code",
                    formData.bankDetails.ifsc,
                    "required|alpha_num|min:11|max:11"
                  )}
              </Form.Group>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
              <div className="custom-file-upload error-file-license">
                <Form.Group>
                  <Form.Label>
                    Account Number<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Account Number"
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { bankDetails } = { ...formData };
                      // let bankDetails = {};
                      bankDetails.accountNumber = value;

                      setFormData((prevState) => ({
                        ...prevState,
                        bankDetails,
                      }));
                    }}
                    value={formData?.bankDetails?.accountNumber}
                  />
                  {
                    // formData.service === "LISTING PLUS" &&
                    validator.current.message(
                      "Account Number",
                      formData.bankDetails.accountNumber,
                      "required|min:8|max:17|Numeric"
                    )}
                </Form.Group>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-4">
              <Form.Group>
                <Form.Label>
                  Re-enter Account Number<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="ReEnter the Account Number"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { bankDetails } = { ...formData };
                    // let bankDetails = {};
                    bankDetails.re_enter_accountNumber = value;

                    setFormData((prevState) => ({
                      ...prevState,
                      bankDetails,
                    }));
                  }}
                  value={formData?.bankDetails?.re_enter_accountNumber}
                />
                {
                  // formData.service === "LISTING PLUS" &&
                  validator?.current.message(
                    "ReEnter the Account Number",
                    formData.bankDetails.re_enter_accountNumber,
                    "required|min:8|max:17|Numeric"
                  )}

              </Form.Group>
              {/* Custom validation for matching account numbers */}
              {
                <div className="text-danger fs-12">
                  {
                    (
                      validateAccountNumbersMatch() !== true && validateAccountNumbersMatch()
                    )
                  }
                </div>
              }
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
              <div className="custom-file-upload error-file-license">
                <Form.Group>
                  <Form.Label>
                    Beneficiary Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter the Beneficiary Name"
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { bankDetails } = { ...formData };
                      // let bankDetails = {};
                      bankDetails.accountName = value;

                      setFormData((prevState) => ({
                        ...prevState,
                        bankDetails,
                      }));
                    }}
                    value={formData?.bankDetails?.accountName}
                  />
                  {
                    // formData.service === "LISTING PLUS" &&
                    validator.current.message(
                      "Beneficiary Name",
                      formData.bankDetails.accountName,
                      "required|min:5|max:20|alpha"
                    )}
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="header">
            <div>Social Media</div>
            <hr></hr>
          </div>

          <div className="body">
            <div className="item3">
              <span
                className={`text-nowrap test-instagram ${!errorFacebook ? "test-facebook" : ""
                  }`}
              >
                <img className="me-1" src={facebook} alt="facebook" />
                &ensp;Facebook -&ensp;
              </span>
              <div
                className={`col-12 col-xs-12 col-md-12 instagram-facebook col-lg-7 ${!errorFacebook ? "test-facebook" : ""
                  }`}
              >
                <Form.Group className="mb-0">
                  <Form.Control
                    className="mb-0"
                    type="text"
                    autoComplete="off"
                    placeholder="Enter complete URL of your Facebook Profile Page"
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { facebook } = { ...formData };
                      facebook.facebookLink = value;
                      if (value === "") {
                        setErrorFacebook(true);
                      }
                      setFormData((prevState) => ({
                        ...prevState,
                        facebook,
                      }));
                    }}
                    value={formData?.facebook?.facebookLink}
                  />
                  <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                    Enter URL in format www.facebook.com/thecheflane
                  </p>
                  {!errorFacebook && (
                    <span class="error-message fs-12">
                      Please enter a valid Facebook URL
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="item3">
              <span className="text-nowrap test-instagram">
                <img className="" src={Instagram} alt="Instagram" />
                &ensp;Instagram -&ensp;
              </span>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 instagram-facebook ">
                <Form.Group className="mb-0">
                  <Form.Control
                    type="text"
                    className="mb-0"
                    autoComplete="off"
                    placeholder="Enter complete URL of your Instagram Profile Page"
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { instagram } = { ...formData };
                      instagram.instagramLink = value;
                      if (value === "") {
                        setErrorInstagram(true);
                      }
                      setFormData((prevState) => ({
                        ...prevState,
                        instagram,
                      }));
                    }}
                    value={formData?.instagram?.instagramLink}
                  />
                  <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                    Enter URL in format www.instagram.com/the.chef.lane
                  </p>
                  {!errorInstagram && (
                    <span class="error-message fs-12">
                      Please enter a valid Instagram URL
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <NormalButton
            label="Discard"
            className="white-btn mr-3"
            onClick={() => resetFormData()}
          />
          <span className="m-2" />

          <NormalButton
            primary
            label="Next"
            className="fw-700"
            disabled={isLoading || isDocumentuploading}
            onClick={() => submitBasicDetails()}
          />
        </div>
        <ImageCropComponent
          setShowImageCrop={setShowImageCrop}
          showImageCrop={showImageCrop}
          handleFileUpload={handleFileUpload}
          setCroppingImage={setCroppingImage}
          croppingImage={croppingImage}
        />
        <Modal
          show={showPlatformAlert}
          onHide={() => setShowPlatformAlert(false)}
          backdrop={true}
          keyboard={false}
          className="deactivate-modal"
          centered
          size="md"
        >
          <Modal.Body>
            <div className="p-md-5 p-3">
              <h4 className="mb-2 fs-18">
                Choosing platform delivery partners, may result in limited
                radius for delivery.
              </h4>
              <NormalButton
                primary
                label="Close"
                className="fw-700 px-4 mt-3 mb-0"
                onClick={() => setShowPlatformAlert(false)}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div >
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefUpdate,
      fileUpload,
      imageUpload,
      getCusineList,
      businessTypeList,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(BasicDetail);
