export const validationRules = () => {
  let textValidation = {
    format: {
      pattern: /^[a-zA-z ]*$/,
      flags: "i",
      message: "must contain only alphabets and spaces",
    },
  }
  return {
    email: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      email: true,
    },
    firstName: {
      presence: {
        allowEmpty: false,
        message: "^First name is required",
      },
      ...textValidation,
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: "^Last name is required",
      },
      ...textValidation,
    },
    country: {
      presence: {
        allowEmpty: false,
        message: "^Country is required",
      },
      ...textValidation,
    },
    addressState: {
      presence: {
        allowEmpty: false,
        message: "^State is required",
      },
      ...textValidation,
    },
    addressPincode: {
      presence: {
        allowEmpty: false,
        message: "^Pincode is required",
      },
      ...textValidation,
      length: {
        minimum: 6,
        maximum: 6,
        message: "^Please enter valid pincode",
      },
    },
    addressCity: {
      presence: {
        allowEmpty: false,
        message: "^City is required",
      },
      ...textValidation,
    },
    addressOne: {
      presence: {
        allowEmpty: false,
        message: "^Address is required",
      },
      ...textValidation,
    },
    addressMobile: {
      presence: {
        allowEmpty: false,
        message: "^Mobile number is required",
      },
      format: {
        pattern: /^[0-9]*$/,
        flags: "i",
        message: "^Please enter valid mobile number",
      },
      length: {
        minimum: 10,
        maximum: 10,
        message: "^Please enter valid mobile number",
      },
    },
  };
};
