import React, { Component } from "react";

import { ChefTrackOrderComp } from "component/TrackOrder/ChefTrackorder";

export class ChefTrackOrderClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props;
    return <ChefTrackOrderComp trackId={params.trackId} type={params.type} />;
  }
}
export const ChefTrackOrder = ChefTrackOrderClass;
