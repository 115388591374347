import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { NormalButton } from "component/common";
import { api } from "service/api";
import { ownDeliveryPartner } from "service/apiVariables";
import { Toast } from "service/toast";
import { history } from "service/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

function OwnPartnerOrderComp() {
  const [otp, setOtp] = useState(null);
  const [address, setAddress] = useState({});
  const [orderDelivered,setOrderDelivered ] = useState(false)
  const queryParams = new URLSearchParams(window ? window.location.search : {});
 
  useEffect(() => {
    const orderId = queryParams.get("orderId");
    if (!!orderId) {
      const requestObj = ownDeliveryPartner.verifyOwnPartnerOrder;
      api({ ...requestObj, api: requestObj.api + orderId })
        .then((res) => {
          const { data } = res;
          
          if(data?.orderStatus === "DELIVERED") {
            setOrderDelivered(true)
          }else {
            if (data?.addressId) {
              setAddress({
                ...data?.addressId,
                orderStartDate: data.orderStartDate,
              });
            }
          }
        })
        .catch((err) => {
          history.push(`/`)
        });
    } else {
      history.push(`/`)
    }
  }, []);

  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const submitOtp = () => {
    const orderId = queryParams.get("orderId");

    let body = {
      OTP: otp,
      orderId: orderId,
    };
    api({ ...ownDeliveryPartner.finishOwnPartnerOrder, body })
      .then((res) => {
        Toast({ type: "success", message: "Order Closed Successfully" });
        setOrderDelivered(true)
      })
      .catch((err) => {
        Toast({ type: "success", message: err?.message });
      })
      .finally(() => setOtp(""));
  };

  return (
    <div className="w-100 h-100">
      <div className="w-50 mx-auto">
      
      {orderDelivered &&    <div className="card shadow text-center p-4 my-4">
          <div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "#00ff59", width: "75px", height: "75px", margin:"24px 0" }}
            />

            <h3> Order Successfully Delivered </h3>
          </div>
        </div> }

        {!orderDelivered && <>
        <div className="card shadow row my-4  d-block p-2 py-4">
          <div className="col-12 me-4">
            <h4 className="fs-14 fw-700"> Order Placed By</h4>
            <div className="fs-14 h3 fw-4=500 text-grey-7c">
              {address?.firstName}
            </div>
            <div className="d-flex">
              <p className="fs-14 fw-700 text-grey-7c me-4">
                {address?.address1}
              </p>
              <p className="fs-14 fw-700 text-grey-7c">
                {address?.city},{address?.state},{address?.pincode}
              </p>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center mb-3">
            <h4 className="fs-14 fw-700 text-nowrap m-0"> Placed at</h4>
            <div className="d-flex">
              <div className="mx-5 fs-14">
                {" "}
                {moment(address.orderStartDate).format("hA")}
              </div>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center">
            <h4 className="fs-14 fw-700 m-0"> Contact Detail </h4>
            <div className="d-flex">
              <div className="mx-4 fs-14"> {address?.mobileNumber} </div>
            </div>
          </div>
        </div>
 
         <div className="otp-section">
          <div className="w-50 mx-auto d-flex flex-column align-items-center">
            <div>

        <label>Mobile OTP</label>
        <div className="input-group fs-16">
          <input
            className="form-control"
            name="mobileotp"
            placeholder="Enter mobile otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          </div>
        </div>
        <div>
        <NormalButton
          disabled={!(otp?.length == 4)}
          primary
          label="Verify Order"
          className="px-5 my-4 text"
          onClick={() => submitOtp()}
        />
        {validatorLicence.current.message("OTP", otp, "required|min:4|max:4")}
        </div>
        </div>

       
      </div>
      </>}
      </div>
    </div>
  );
}

export const OwnPartnerOrder = connect(null, null)(OwnPartnerOrderComp);
