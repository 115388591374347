import { addQuery } from "service/helperFunctions";
import { offerList, orderReviewApi } from "../service/apiVariables";

// create customer
export const AddReview =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.reviewAddApi, body })
        .then((response) => {
          resolve(response);
          if (!!response.data) {
            Toast({ type: "success", message: response?.message });
          }
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// review List
export const listReview =
  (params, body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      // Update the query and body parameters dynamically
      orderReviewApi.reviewListApi.addQuery = {
        key: "search",
        payload: params.search,
      };
      orderReviewApi.reviewListApi.addBody = {
        key: "page",
        payload: body.page,
      };
      orderReviewApi.reviewListApi.addBody = {
        key: "chefIdTestimonial",
        payload: body.chefIdTestimonial,
      };

      api({ ...orderReviewApi.reviewListApi })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//publish Testimonials
export const publishTestimonials =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.publishTestimonials, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// create customer
export const viewPublishTestimonial =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.publishedViewListTestimonial, body })
        .then((response) => resolve(response))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

  // view all testimonial
export const getAllTestimonial =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.getAllTestimonial, body })
        .then((response) => resolve(response))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//testimonial List
export const testimonialList =
  (city) =>
  (dispatch, getState, { api, Toast }) => {
    orderReviewApi.testimonialList.city = city;
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.testimonialList })
        .then(({ data }) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//service Subscriptions
export const serviceSubscriptions =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(body, orderReviewApi.serviceSubscriptions);
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.serviceSubscriptions })
        .then((data) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//change Service
export const changeService =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.changeService, body })
        .then(({ data }) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//service Payment
export const servicePayment =
  (body, query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, orderReviewApi.servicePayment);
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.servicePayment, body })
        .then(({ data }) => resolve(data))
        .catch((data) => {
          Toast({
            type: "error",
            message: data.message || data.data?.message,
          });
          resolve(data);
        });
    });
  };

//capture Payment Details
export const capturePaymentDetails =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.capturePaymentDetails, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// Capture Payment Failure Details
export const capturePaymentFailureDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, orderReviewApi.capturePaymentFailureDetails);
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.capturePaymentFailureDetails })
        .then(({ data }) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// offersList
export const offersList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, offerList.getOfferList);
    return new Promise((resolve, reject) => {
      api({ ...offerList.getOfferList })
        .then(({ data }) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// offer with payment
export const offerSubScription =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, orderReviewApi.getSubscription);
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.getSubscription })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// subscription details
export const getSubScription =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, orderReviewApi.getSubscriptionDetails);
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.getSubscriptionDetails })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//continue as listing
export const continueAsListingApi =
  (body, id) =>
  (dispatch, getState, { api, Toast }) => {
    let { continueAsListing } = { ...orderReviewApi };
    continueAsListing.id = id;
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.continueAsListing, body })
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          Toast({
            type: "error",
            message: data.message || data.data?.message,
          });
          resolve(data);
        });
    });
  };

//service Payment
export const freeSubscription =
  (body, query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, orderReviewApi.servicePayment);
    return new Promise((resolve, reject) => {
      api({ ...orderReviewApi.triggerLifeTimeSubscription, body })
        .then(({ data }) => resolve(data))
        .catch((data) => {
          Toast({
            type: "error",
            message: data.message || data.data?.message,
          });
          resolve(data);
        });
    });
  };
