import React, { useState } from "react";
import { signUpChef } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import { validationRules } from "./validate";
import { NormalButton } from "component/common/NormalButton";
import { history } from "service/helpers";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
import OrSeperator from "component/common/OrSeperator";
import { handleNameField } from "service/helperFunctions";
import ReCAPTCHA from "react-google-recaptcha";

const SignupClass = (props) => {
  const [signupDetails, setSignupDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    chef: "chef",
    countryCode: "+91",
    currency: "INR",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [error, setErrors] = useState({});

  const [reCaptchaFailed, setreCaptchaFailed] = useState(true);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  //handle Change
  const handleChange = ({ target: { name, value, currency } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    if (name === "countryCode") {
      setSignupDetails((prevState) => ({
        ...prevState,
        [name]: value,
        currency: currency.code,
        mobileNumber: "",
      }));
    } else {
      setSignupDetails((prevState) => ({ ...prevState, [name]: value }));
    }
    setErrors({ ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };

  function onChangeCaptcha(value) {
    if (value === null) {
      setreCaptchaFailed(true);
    } else {
      setreCaptchaFailed(false);
    }
  }
  

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      ...signupDetails,
      userTypeRole: signupDetails.chef,
    };

    if (!validateFields(body)) return;
    body.firstName = handleNameField(body.firstName);
    body.lastName = handleNameField(body.lastName);
    // body.mobileNumber = signupDetails.countryCode + signupDetails.mobileNumber;
    body.mobileNumber = signupDetails.mobileNumber;
    body.countryCode = signupDetails.countryCode;
    body.email = signupDetails.email;
    body.password = signupDetails.password;
    body.confirmPassword = signupDetails.confirmPassword;
    body.currency = signupDetails.currency;
    delete body.chef;

    setisButtonClicked(true);
    props
      .signUpApiCall(body)
      .then(() => {
        //localStorage.setItem("userId", data._id);
        localStorage.setItem("firstName", body.firstName);
        localStorage.setItem("lastName", body.lastName);
        localStorage.setItem("mobileNumber", body.mobileNumber);
        localStorage.setItem("email", body.email);
        localStorage.setItem("password", body.password);
        localStorage.setItem("confirmPassword", body.confirmPassword);
        localStorage.setItem("currency", body.currency);
        localStorage.setItem("countryCode", body.countryCode);
        history.push(
          `/auth/createaccount?name=${
            body.firstName
          }&mobileNumber=${encodeURIComponent(
            body.mobileNumber
          )}&email=${encodeURIComponent(body.email)}&userTypeRole=${
            body.userTypeRole
          }`
        );
        setisButtonClicked(false);
      })
      .catch(() => setisButtonClicked(false));
  };

  return (
    <div className="row">
      <div className="col-md-6 col-12 mx-auto sign-up-account">
        <h3 className="fw-700 fs-24 line-height-28 text-black-18 text-md-start text-center">
          Create Chef Account
        </h3>
        <div className="mt-3 pt-1">
          <label>First Name</label>
          <div className="input-group">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="firstName"
              placeholder="Enter first name"
              value={signupDetails.firstName}
              onChange={handleChange}
            />
          </div>
          {error.firstName && <ErrorComponent message={error.firstName[0]} />}
        </div>
        <div className="mt-3 pt-1">
          <label>Last Name</label>
          <div className="input-group">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="lastName"
              placeholder="Enter last name"
              value={signupDetails.lastName}
              onChange={handleChange}
            />
          </div>
          {error.lastName && <ErrorComponent message={error.lastName[0]} />}
        </div>
        <div className="mt-3 pt-1">
          <label>Mobile Number</label>
          <div className="input-group position-relative phone-number-input">
            <input
              type="number"
              className="form-control bg-white fs-16"
              name="mobileNumber"
              placeholder="Enter Number"
              value={signupDetails.mobileNumber}
              onChange={handleChange}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />

            <div className="country-code-dropdown">
              <CountryCodeDropDown
                onClick={(e, cur) => {
                  handleChange({
                    target: {
                      name: "countryCode",
                      value: e,
                      currency: cur,
                    },
                  });
                }}
                value={signupDetails.countryCode}
              />
            </div>
          </div>
          {error.mobileNumber && (
            <ErrorComponent message={error.mobileNumber[0]} />
          )}
        </div>
        <div className="mt-3 pt-1">
          <label>Email</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              name="email"
              placeholder="Enter email"
              value={signupDetails.email}
              onChange={handleChange}
            />
          </div>
          {error.email && <ErrorComponent message={error.email[0]} />}
        </div>
        <div className="mt-3 pt-1">
          <label>Password</label>
          <div className="input-group password-field position-relative">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              name="password"
              placeholder="Enter password..."
              value={signupDetails.password}
              onChange={handleChange}
            />
            <div className="input-group-addon right">
              <i
                onClick={() => setPasswordVisible(!passwordVisible)}
                className={`cursor-pointer text-gold icon-${
                  passwordVisible ? "eye" : "eye-hide"
                } fs-18`}
              />
            </div>
          </div>
          {error.password && <ErrorComponent message={error.password[0]} />}
        </div>
        <div className="mt-3 pt-1">
          <label>Confirm Password</label>
          <div className="input-group password-field position-relative">
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              className="form-control"
              name="confirmPassword"
              placeholder="Enter password..."
              value={signupDetails.confirmPassword}
              onChange={handleChange}
            />
            <div className="input-group-addon right">
              <i
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
                className={`cursor-pointer text-gold icon-${
                  confirmPasswordVisible ? "eye" : "eye-hide"
                } fs-18`}
              />
            </div>
          </div>
          {error.confirmPassword && (
            <ErrorComponent message={error.confirmPassword[0]} />
          )}
        </div>
        <div className="mt-3 pt-1 d-flex justify-content-center">
          <ReCAPTCHA
            sitekey="6Lf7Po8gAAAAAG7XwUxeNjPaBX0wNlZQ_ume6px_"
            onChange={onChangeCaptcha}
          />
        </div>
      </div>
      <p className="text-center fs-16 mt-3 mb-0 text-secondary-color">
        We will send you OTP to your phone number and email ID
      </p>
      <div className="col-md-6 col-12 mx-auto mt-md-4 my-4">
        <div className="w-100">
          <NormalButton
            primary
            label="Continue"
            className="w-100 fw-700"
            onClick={handleSubmit}
            disabled={isButtonClicked || reCaptchaFailed}
            //disabled={isButtonClicked}
          />
        </div>
      </div>
      <div className="col-12 text-center mb-md-4 mb-3">
        <h6 className="conditions-apply fs-16 mb-0">
          By continuing, you agree to the{" "}
          <a
            target="_blank"
            className="text-primary-color cursor-pointer terms"
            href="/index/t&c"
          >
            Terms & Conditions
          </a>{" "}
          of use and{" "}
          <a
            target="_blank"
            className="text-primary-color cursor-pointer terms"
            href="/index/pp"
          >
            Privacy Policy
          </a>
          .
        </h6>
      </div>

      <div className="col-md-10 col-12 text-center my-md-4 my-3 mx-auto">
        <OrSeperator title="Already have an account" />
      </div>
      <div className="col-md-6 col-12 mx-auto">
        <NormalButton
          viewButton
          label="Login"
          className="w-100 fw-700"
          onClick={() => history.push("/auth/login?userTypeRole=chef")}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signUpApiCall: signUpChef,
    },
    dispatch
  );
};

let component = SignupClass;
export const SignupComp = connect(null, mapDispatchToProps)(component);
