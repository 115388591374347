import React, { useState, useEffect, useRef } from "react";
import { login, loginCartDetails } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import { validationRules } from "./validate";
import { NormalButton } from "component/common/NormalButton";
import SignUpOptions from "./SignUpOptions";
import { useLocation } from "react-router-dom";
import OrSeperator from "component/common/OrSeperator";
import { logout } from "service/utilities";
import { Toast } from "service/toast";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";
import { defaultValidator } from "service/helperFunctions";
import Modal from "react-bootstrap/Modal";
import closeBtn from "assets/images/closeWhite.png";
import { handleAddToCartAll } from "action/cartAct";

const LoginClass = (props) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [logindetails, setLoginDetails] = useState({
    emailIdOrMobileNumber: "",
    password: "",
  });
  const [open, setOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [error, setErrors] = useState({});
  const [reCaptchaFailed, setreCaptchaFailed] = useState(true);
  const [cartDetails, setCartDetails] = useState(null);
  const [userId, setUserId] = useState(null);

const [modal, setModal] = useState(false)
const handleClosefunc = () => setModal(false);

const modalRef = useRef(null);

const toggle = () => setModal(!modal)

const handleClickOutside = (event) => {
  if (modalRef.current && !modalRef.current.contains(event.target)) {
    setModal(false);
  }else {
    console.log('Clicked inside modal');
  }
};

useEffect(() => {
  if (modal) {
    document.addEventListener('click', handleClickOutside);
  } else {
    document.removeEventListener('click', handleClickOutside);
  }
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [modal]);


  const handleClose = () => {
    setOpen(false);
  };

  //handle Change
  const handleChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setLoginDetails((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" || e.key === "enter") {
      if (!reCaptchaFailed) {
        handleSubmit(e);
      }
    }
  };

  const facebookResponse = (response) => {
    if (response?.userID) {
      const tokenBlob = new Blob(
        [
          JSON.stringify(
            {
              accessToken: response.accessToken,
              userId: response.userID,
              userTypeRole: query.get("userTypeRole"),
              service: "LISTING",
            },
            null,
            2
          ),
        ],
        { type: "application/json" }
      );
      const options = {
        method: "POST",
        body: tokenBlob,
        mode: "cors",
        cache: "default",
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}auth/facebook/login`,
        options
      ).then((r) => {
        r.json().then(({ data, msg, message }) => {
          if (message === "Access Token must be required") {
            Toast({ type: "error", message: message });
          } else {
            Toast({ type: "success", message: msg ? msg : message });
          }
          localStorage.setItem("userId", data?._id);
          localStorage.setItem("name", data?.firstName);
          data && localStorage.setItem("userDetailsWeb", JSON.stringify(data));
          localStorage.setItem("userTypeRole", query.get("userTypeRole"));
          localStorage.setItem("authToken", response?.tokenId);
          if (data?.userTypeRole === "customer") {
            history.replace("/");
          } else if (data?.userTypeRole === "chef") {
            if (data?.status === "APPROVED") {
              history.replace("/index/activeProfile");
            } else {
              history.replace("/index/home");
            }
          }
        });
      });
    }
  };

  const googleResponse = (response) => {
    if (response?.profileObj) {
      const tokenBlob = new Blob(
        [
          JSON.stringify(
            {
              token: response.tokenId,
              userTypeRole: query.get("userTypeRole"),
              service: "LISTING",
            },
            null,
            2
          ),
        ],
        { type: "application/json" }
      );
      const options = {
        method: "POST",
        body: tokenBlob,
        mode: "cors",
        cache: "default",
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}auth/google/login`,
        options
      ).then((r) => {
        r.json().then((data) => {
          if (
            data.message === "Access Token must be required" ||
            defaultValidator(
              data.message === "Account Deactivated",
              data.message === "Your account has been deactivated."
            )
          ) {
            Toast({ type: "error", message: data.message });
          } else {
            Toast({
              type: "success",
              message: data.msg ? data.msg : data?.message,
            });
          }
          localStorage.setItem("userId", data?.data?._id);
          localStorage.setItem("name", data?.data?.firstName);
          data?.data &&
            localStorage.setItem("userDetailsWeb", JSON.stringify(data?.data));
          localStorage.setItem("userTypeRole", query.get("userTypeRole"));
          localStorage.setItem("authToken", response?.tokenId);
          if (data?.data?.userTypeRole === "customer") {
            history.replace("/");
          } else if (data?.data?.userTypeRole === "chef") {
            if (data?.data?.status === "APPROVED") {
              history.replace("/index/activeProfile");
            } else if (
              data?.data?.status === "NEWREQUEST" ||
              data?.data?.status === "SUSPENDED"
            ) {
              history.replace("/index/profile");
            } else {
              history.replace("/index/home");
            }
          }
        });
      });
    }
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      emailIdOrMobileNumber: logindetails.emailIdOrMobileNumber,
      password: logindetails.password,
      userTypeRole: query.get("userTypeRole"),
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    props
      .loginApiCall(body)
      .then(({ data }) => {
        setisButtonClicked(false);
        localStorage.setItem("userId", data._id);
        localStorage.setItem("name", data.name);
        localStorage.setItem("userTypeRole", query.get("userTypeRole"));

        if (data.userTypeRole === "customer") {
          setUserId(data?._id);
          props.cartDetails(data?._id).then((cartData) => {
            if (cartData?.data !== null) {
              setCartDetails(cartData);
              setOpen(true);
            } else {
              history.replace("/");
            }
          });
        } else if (data.userTypeRole === "chef") {
          if (data.status === "APPROVED") {
            history.replace("/index/activeProfile");
          } else {
            history.replace("/index/profile");
          }
        }
      })
      .catch(() => setisButtonClicked(false));
  };

  const handleCartSubmit = async (type) => {

    const foodItems =
      cartDetails?.data?.map((item) => {
        return {
          id: item?.foodId,
          quantity: item?.quantity ? item.quantity : 1,
        };
      }) || [];

    let body = {
      userId: userId,
      foodId: foodItems,
      del: type === 1 ? "no" : "yes",
    };


    try {
      await props.handleAddToCartAll(body);
      if (type === 1) {
        history.replace("/");
      } else {
                history.push("/index/cart");
                // cartData.data.quantity
      }
    } catch (error) {
      console.error("Error handling cart submission:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!!token) {
      logout();
    }
  }, []);

  function onChangeCaptcha(value) {
    if (value === null) {
      setreCaptchaFailed(true);
    } else {
      setreCaptchaFailed(false);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-8 col-12 mx-auto " ref={modalRef}>
          <h3 className="fw-700 fs-24 line-height-28 text-black-18 text-md-start text-center mb-4">
            Login
          </h3>
          <div className="d-flex justify-content-center align-items-center mb-4 px-2 google-facebook">
            <div className="col-12 col-sm-6 google-signin me-2">
              <GoogleLogin
                clientId="377800051763-mrqr8beaufh15kikrp5tnavvnj6mmvc1.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={googleResponse}
                onFailure={googleResponse}
                cookiePolicy={"single_host_origin"}
                prompt="select_account"
              />
            </div>
            <div className="col-12 col-sm-6 facebook-signin">
              <FacebookLogin
                appId="556836402559037"
                autoLoad={false}
                fields="name,email,picture"
                callback={facebookResponse}
                icon="fa-facebook"
              />
            </div>
          </div>
          <div className="fs-18 text-black-25 mb-5 fw-700 line-border px-2">
            <span>or</span>
          </div>
        </div>
        <div className="col-md-8 col-12 mx-auto">
          <div className="mt-3 pt-1">
            <label>Email ID</label>
            <div className="input-group fs-16">
              <input
                className="form-control"
                name="emailIdOrMobileNumber"
                placeholder="Enter email..."
                value={logindetails.emailIdOrMobileNumber}
                onChange={handleChange}
                onKeyPress={handleKeypress}
              />
            </div>
            {error.emailIdOrMobileNumber && (
              <ErrorComponent message={error.emailIdOrMobileNumber[0]} />
            )}
          </div>
          <div className="mt-4 pt-1">
            <div className="d-flex justify-content-between">
              <label>Password</label>
              <p
                className="mb-0 text-primary-color fs-14 fw-600 cursor-pointer"
                onClick={() =>
                  history.push(
                    `/auth/resetpassword?userTypeRole=${query.get(
                      "userTypeRole"
                    )}`
                  )
                }
              >
                Forgot?
              </p>
            </div>

            <div className="input-group password-field position-relative">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control"
                name="password"
                placeholder="Enter password..."
                value={logindetails.password}
                onChange={handleChange}
                onKeyPress={handleKeypress}
              />
              <div className="input-group-addon right">
                <i
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className={`cursor-pointer text-gold icon-${
                    passwordVisible ? "eye" : "eye-hide"
                  } fs-18`}
                />
              </div>
            </div>
            {error.password && <ErrorComponent message={error.password[0]} />}
            <div className="mt-3 pt-1 d-flex justify-content-center w-100">
              <ReCAPTCHA
                sitekey="6Lf7Po8gAAAAAG7XwUxeNjPaBX0wNlZQ_ume6px_"
                onChange={onChangeCaptcha}
              />
            </div>

            <div className="w-100 py-1">
              <NormalButton
                primary
                label="Continue"
                className="my-md-5 my-4 w-100 fw-700"
                onClick={handleSubmit}
                disabled={isButtonClicked || reCaptchaFailed}
              />
            </div>
          </div>
        </div>

        <div className="col-md-10 col-12 text-center mb-3 mx-auto">
          <OrSeperator title="Don’t have an account? Signup" />
        </div>
        <div className="col-md-8 col-12 mx-auto">
          <SignUpOptions toggle={toggle} modal={modal} disabled={isButtonClicked} />
        </div>
        <div className="col-12 text-center">
          <h6 className="conditions-apply fs-16 mb-0">
            By continuing, you agree to the{" "}
            <a
              target="_blank"
              className="text-primary-color cursor-pointer terms"
              href="/index/t&c"
            >
              Terms & Conditions
            </a>{" "}
            of use and{" "}
            <a
              target="_blank"
              className="text-primary-color cursor-pointer terms"
              href="/index/pp"
            >
              Privacy Policy
            </a>
            .
          </h6>
        </div>
      </div>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <b>Confirmation</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cartDetails ? (
            <div className="">
              {cartDetails?.message} Do you want to continue ?
            </div>
          ) : (
            <div className="">Loading cart details...</div>
          )}
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="d-flex justify-content-center w-100">
            <NormalButton
              label="No"
              className="white-btn mr-3"
              onClick={() => handleCartSubmit(1)}
            />
            <span className="m-2" />
            <NormalButton
              primary
              label="Yes"
              className="fw-700"
              onClick={() => handleCartSubmit(2)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { loginApiCall: login, cartDetails: loginCartDetails, handleAddToCartAll },
    dispatch
  );
};

export const LoginComp = connect(null, mapDispatchToProps)(LoginClass);
