import axios from "axios";
import { history } from "../service/helpers";
import { useLocation } from "react-router";

//axios Instance
export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

export const handleForbiddenCharacters = (e, type, min = 1, max = 250) => {
  const forbiddenChars = [
    '-',
    '+',
    '#',
    '$',
    '%',
    '^',
    '*',
    '!',
    '(',
    ')',
    '?',
    '/',
    ';',
    ',',
    '>',
    '<'
  ];
  if (e.key === 'Backspace' || e.key === 'Tab') {
    return;
  }
  if (forbiddenChars.includes(e.key)) {
    e.preventDefault();
  } else if (type === 'number' && ['e', '@', '&'].includes(e.key)) {
    e.preventDefault();
  } else if (type === 'number' && !/\d/.test(e.key)) {
    e.preventDefault();
  }
  const currentValue = e.target.value + e.key;
  if (currentValue.length < min || currentValue.length > max) {
    e.preventDefault();
  }
};

//logout
export const logout = () => {
  setTimeout(() => {
    axiosInstance.defaults.headers.common["Authorization"] = "";
    localStorage.removeItem("authToken");
    localStorage.removeItem("userDetailsWeb");
    localStorage.removeItem("chefdetail");
    localStorage.removeItem("name");
    localStorage.removeItem("userId");
    localStorage.removeItem("userTypeRole");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("confirmPassword");
    localStorage.removeItem("countryCode");
    localStorage.removeItem("mobileNumber");
    localStorage.setItem("logout", true);
    localStorage.removeItem("localCart");
    history.push("/");
    window.location.reload(true);
  }, 1000);
};

export const useParamQuery = (val) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get(val);
  return paramValue;
};
