import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { history } from "service/helpers";
import { Navbar } from "component/common/Navbar";
import { NormalButton } from "component/common";
import { PageIcons } from "component/common";
import { getOrderDetailsAct } from "action/CustomerDetailsAct";
import { localCartType } from "service/actionType";
import { clearCart, paymentDetailCapture } from "action/cartAct";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { Toast } from "service/toast";
import {
  changeService,
} from "action/OrderReviewAct";


const SuccessOrderClass = ({
  orderId,
  getOrderDetails,
  clearCart,
  paymentDetailCapture,
  changeServiceApi,
}) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [successOrderId, setSuccessOrderId] = useState('');
  const [isSubcriptionSuccess, setIsSubcriptionSuccess] = useState(false);
  const [subScriptionPopup, setSubScriptionPopup] = useState(false);
  const [typeSelected, setTypeSelected] = useState(null);
  const userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  const authToken = localStorage.getItem("authToken");
  const getPaymentId = JSON.parse(localStorage.getItem("sessionId"));
  const { id } = getPaymentId || "";
  const { search } = useLocation();
  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/');
  const targetSegment = pathSegments.includes('subscription') ? true : false;
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {

    if (orderId) {
      getOrderDetails(orderId).then(({ data }) => setOrderDetails(data));
    }
  }, [orderId, getOrderDetails]);

  const captureFunc = useCallback(async () => {


    const searchParams = new URLSearchParams(search);

    const razorpayPaymentId = searchParams.get('razorpay_payment_id');
    const razorpayPaymentLinkId = searchParams.get('razorpay_payment_link_id');
    const razorpayPaymentLinkReferenceId = searchParams.get('razorpay_payment_link_reference_id');
    const razorpayPaymentLinkStatus = searchParams.get('razorpay_payment_link_status');
    const razorpaySignature = searchParams.get('razorpay_signature');


    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Basic ${authToken}`,
      "Content-Type": "application/json",
    };

    let body = {
      // orderId: orderId,
      // paymentOrderId: id,
      // paymentDate: new Date(),

      "payment_link_id": razorpayPaymentLinkId,
      "payment_id": razorpayPaymentId,
      "payment_link_reference_id": orderId,
      // "payment_link_reference_id": userDetails?.subscriptionId,
      "payment_link_status": razorpayPaymentLinkStatus,
      "signature": razorpaySignature,
    };
    let sub = true
    try {
      const response = await axios.post(
        targetSegment ? 'https://preprodapis.thecheflane.com/api/v1/chef/verifySubScription' :

          "https://preprodapis.thecheflane.com/api/v1/razorpay/verifyPayment",
        body,
        {
          headers,
        }
      );
      setIsSubcriptionSuccess(true)
    } catch (err) {
      console.log(err);
      setIsSubcriptionSuccess(false)
    }


    // try {
    //   const data = await paymentDetailCapture({ ...body, headers });
    //   if (data) {
    //     const { orderId } = data?.data || {};
    //     setSuccessOrderId(orderId);
    //     localStorage.removeItem('sessionId');
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }, [authToken, search, targetSegment, orderDetails?.chefId?._id, history]);

  // const handleClearCart = useCallback(() => {
  //   if (authToken) {
  //     let id = userDetails?._id;
  //     clearCart(id)
  //       .then(() => {
  //         dispatch({ type: localCartType.totalCartLocal, payload: [] });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     dispatch({ type: localCartType.totalCartLocal, payload: [] });
  //   }
  // }, [window.location.pathname]);

  // useEffect(() => {
  //   Toast({ type: "success", message: "Order is sucessfully placed" });
  //   setTimeout(() => { history.push("/index/activeProfile") }, 5000)
  // }, [
  // handleClearCart, localStorage.getItem('reloadCount'), authToken
  // ]);

  useEffect(() => {
    const handleCaptureFunc = async () => {
      await captureFunc();
  
      if (isSubcriptionSuccess) {
        setIsLoading(true);
  
        Toast({ type: "success", message: "Subscription is successfully paid" });
  
        let payload = { chefId: userDetails?._id, service: "LISTING PLUS" };
        await changeServiceApi(payload);
  
        setIsLoading(false);
  
        if (window.opener) {
          window.opener.postMessage("paymentComplete", "*");
        }
  
        window.close();
      } else {
        console.error("Payment verification failed, not navigating.");
      }
    };
  
    handleCaptureFunc();
  }, [captureFunc, userDetails?._id, isSubcriptionSuccess]);

  
  useEffect(() => {
    const reloadCount = localStorage.getItem('reloadCount');
    if (reloadCount < 1) {
      localStorage.setItem('reloadCount', String(Number(reloadCount) + 1));
      window.location.reload();
    } else {
      localStorage.removeItem('reloadCount');
    }
  }, []);

  return (
    <div className="orderplaced">
      <div className="d-flex w-md-100 h-100 ">
        <div className="col-lg-12 col-12 mx-auto px-3 login-area">
          <div className=" mx-auto col-lg-7 col-12 h-100 ">
            <div className="d-flex align-items-center flex-column w-100">
              <Navbar />
              {/* <>
                <p className="fs-42 fw-700 line-height-48 text-black-33 mt-4 mb-2">
                  Woo Hoo! Order placed successfully!
                </p>
                <p className="col-12 col-sm-6 fs-16 fw-400 line-height-18 text-center text-grey-4f">
                  Your order {successOrderId} with{" "}
                  <span className="fs-18 fw-700 text-gold mb-0 text-capitalize">
                    {orderDetails?.chefId?.businessName}{" "}
                  </span>
                  has been placed.
                </p>
              </> */}
              {/* <div className="col-sm-6 col-12 py-5 mb-5 text-center">
                <NormalButton
                  optionButton
                  label="Back to home"
                  className="fw-700 mt-2 text-primary-color"
                  onClick={() => {
                    history.push("/index/activeProfile");
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderDetails: getOrderDetailsAct, clearCart, paymentDetailCapture,
      changeServiceApi: changeService,
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  totalCartLocal: state.localCart,
  userCart: state.commonStore.totalCart,
});

let component = SuccessOrderClass;

export const SuccessOrderComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
