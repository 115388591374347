import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { PrivacyPolicyComp } from "component/PrivacyPolicy";
export class PrivacyPolicyClass extends Component {
  componentDidMount() {
    let element = document.getElementById("privacy-policy");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <div id="privacy-policy">
        <PrivacyPolicyComp />
      </div>
    );
  }
}
export const PrivacyPolicy = connect(null, null)(PrivacyPolicyClass);
