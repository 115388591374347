import { addQuery } from "service/helperFunctions";
import { customerApi } from "../service/apiVariables";

import { userLocationType } from "service/actionType";

// create customer
export const createCustomer =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.createCustomer, body })
          .then((response) => {
            resolve(response);
            localStorage.setItem("userDetailsWeb", JSON.stringify(body));
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
// get single customer details
export const getCustomerById =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getCustomerById, body })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
// get single chef details
export const getChefByIds =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      customerApi.getChefByIds._id = id;
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getChefByIds })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//get chefinfo details
export const getChefById =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getChefById, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get all order user
export const getAlluserOrder =
  (query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, customerApi.getAlluserOrder);
        api({ ...customerApi.getAlluserOrder })
          .then((data) => {
            let page =
              data && data.data && data.data.pageMeta
                ? data.data.pageMeta.page
                : 1;
            dispatch({
              type: userLocationType.currentPageMenu,
              payload: page,
            });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get active order user
export const getAllActiveOrder =
  (query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, customerApi.getAllActiveOrder);
        api({ ...customerApi.getAllActiveOrder })
          .then((data) => {
            let page =
              data && data.data && data.data.pageMeta
                ? data.data.pageMeta.page
                : 1;
            dispatch({
              type: userLocationType.currentPageMenu,
              payload: page,
            });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get get one order api
export const getOneOrderApi =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getOneOrderApi, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get get one order api
export const getOneOrderFoodApi =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getOneOrderFoodApi, id })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

//get Order Details
export const getOrderDetailsAct =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      customerApi.getOrderDetailsApi.orderId = id;
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getOrderDetailsApi })
          .then((response) => resolve(response))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

// get get delete order api
export const getDeleteOrder =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...customerApi.getDeleteOrder, id })
          .then((response) => {
            resolve(response);
            let { message } = response;
            Toast({ type: "success", message });
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };
