import React from "react";
import "./customMarker.scss";

export const Marker = (props) => {
  const {
    color,
    key,
    infoWindowContent,
    name,
    colorMarker = false,
    children = "",
    onClick = "",
    isInfo = true,
    infoWindowOpen = "",
  } = props;
  return (
    <div key={key} className="customMarker" title={name} onClick={onClick}>
      {colorMarker && (
        <div
          className="colorMarker"
          style={{ backgroundColor: color, cursor: "pointer" }}
          title={name}
        ></div>
      )}

      {!!children && children}
      {isInfo && infoWindowOpen && (
        <div className="info-window">
          <div className="inner-class">
            {infoWindowContent}
            <span className="rectang"></span>
          </div>
        </div>
      )}
    </div>
  );
};
