import { generateQuery } from "service/helperFunctions";

//auth Api
export const authApi = {
  loginApi: {
    api: "auth/login",
    method: "post",
    baseURL: "auth",
  },
  signUpApi: {
    api: "user/sendOTPtoUser",
    method: "post",
    baseURL: "auth",
  },
  signUpChefApi: {
    api: "chef/sendOTPtoChef",
    method: "post",
    baseURL: "auth",
  },
  verifyUserApi: {
    api: "otp/verify",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordApi: {
    api: "auth/forgotPassword",
    method: "post",
    baseURL: "auth",
  },
  resetPasswordApi: {
    api: "auth/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  chefChangePasswordPostLogin: {
    api: "chef/changePassword",
    method: "post",
    baseURL: "auth",
  },
  userChangePasswordPostLogin: {
    api: "user/changePassword",
    method: "post",
    baseURL: "auth",
  },
  userSwitch: {
    api: "user/getUserByChefId",
    method: "post",
    baseURL: "auth",
  },
  resendOtpMobile: {
    api: "otp/resendMobileOtp",
    method: "put",
    baseURL: "auth",
  },
  resendOtpEmail: {
    api: "otp/resendEmailOtp",
    method: "put",
    baseURL: "auth",
  },
  getNotificationCount: {
    url: "getNotificationCount",
    method: "get",
    baseURL: "normal",
    chefId: null,
    get api() {
      return this.url + "/" + this.chefId;
    },
  },
  getNotificationList: {
    url: "getAllNotification",
    method: "get",
    baseURL: "normal",
    query: {
      page: 4,
      limit: 10,
    },
    chefId: null,
    get api() {
      return this.url + "/" + this.chefId + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateNotificationList: {
    api: "updateNotification",
    method: "put",
    baseURL: "auth",
  },
  sendOtpContactEmail: {
    api: "contactus/otp/sendEmailOtp",
    method: "post",
    baseURL: "auth",
  },
  sendOtpVerifyContact: {
    api: "contactus/otp/verify/emailOtp",
    method: "post",
    baseURL: "auth",
  },
  sendOtpAddressEmail: {
    api: "address/otp/sendEmailOtp",
    method: "post",
    baseURL: "auth",
  },
  sendOtpVerifyAddressEmail: {
    api: "address/otp/verify/emailOtp",
    method: "post",
    baseURL: "auth",
  },
  sendOtpAddressMobile: {
    api: "address/otp/sendMobileOtp",
    method: "post",
    baseURL: "auth",
  },
  sendOtpVerifyAddressMobile: {
    api: "address/otp/verify/mobileOtp",
    method: "post",
    baseURL: "auth",
  },

  cartDetails: {
    api: "cart/details",
    method: "get",
    baseURL: "normal",
    _id: null,
    get fullApi() {
      return `${this.api}/${this._id}`;
    },
  },
};

// file upload api
export const uploadsApi = {
  imageUpload: {
    api: "fileUpload/singleImage",
    method: "post",
    baseURL: "normal",
  },
  fileUpload: {
    api: "fileUpload/file",
    method: "post",
    baseURL: "normal",
  },
};
// customer apis
export const customerApi = {
  getAllCountries: {
    url: "getAllCountries",
    method: "get",
    baseURL: "normal",
    query: {
      userId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStatebyCountryCode: {
    url: "getStatebyCountryCode",
    method: "get",
    baseURL: "normal",
    query: {
      userId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllActiveAddress: {
    url: "address",
    method: "get",
    baseURL: "normal",
    query: {
      userId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteActiveAddress: {
    url: "address",
    method: "delete",
    baseURL: "normal",
    query: {
      userId: null,
      addressId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createAddress: {
    api: "address",
    method: "post",
    baseURL: "normal",
  },
  updateAddress: {
    api: "address",
    method: "put",
    baseURL: "normal",
  },
  createPayment: {
    api: "razorpay/createPayment",
    method: "post",
    baseURL: "normal",
  },
  verifyPayment: {
    api: "razorpay/verifyPayment",
    method: "post",
    baseURL: "normal",
  },
  createCustomer: {
    api: "user/createprofile",
    method: "put",
    baseURL: "normal",
  },
  getCustomerById: {
    api: "user/getUserByUserId",
    method: "post",
    baseURL: "normal",
  },
  getChefByIds: {
    url: `chef/getone/Profile`,
    method: "get",
    baseURL: "normal",
    chefId: null,
    get api() {
      return this.url + "/" + this.chefId;
    },
  },

  // getNotificationCount: {
  //   url: "getNotificationCount",
  //   method: "get",
  //   baseURL: "normal",
  //   chefId: null,
  //   get api() {
  //     return this.url + "/" + this.chefId;
  //   },
  // },
  getChefById: {
    api: "user/chef",
    method: "get",
    baseURL: "normal",
  },
  getAlluserOrder: {
    url: "order/user/getAllOrder",
    method: "get",
    baseURL: "normal",
    query: {
      page: 1,
      limit: 10,
      userId: null,
      orderStatus: null,
      searchKey: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllActiveOrder: {
    url: "order/user/getAllOrder/active",
    method: "get",
    baseURL: "normal",
    query: {
      userId: null,
      orderStatus: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getOneOrderApi: {
    api: "order/getOrderById",
    method: "get",
    baseURL: "normal",
  },
  getOrderDetailsApi: {
    url: "order/getParticularOrder?orderId=",
    method: "get",
    baseURL: "normal",
    orderId: null,
    get api() {
      return this.url + this.orderId;
    },
  },
  getOneOrderFoodApi: {
    api: "order/trackOrderbyId",
    method: "get",
    baseURL: "normal",
  },
  getDeleteOrder: {
    api: "order/deleteOrder",
    method: "put",
    baseURL: "normal",
  },
};
// cart management apis
export const cartManageApi = {
  addToCart: {
    api: "cart/addToCart",
    method: "post",
    baseURL: "normal",
  },
  getAllCart: {
    api: "cart/getAllCart",
    method: "post",
    baseURL: "normal",
  },
  deleteCart: {
    api: "cart/deleteCart",
    method: "post",
    baseURL: "normal",
  },
  clearCart: {
    api: "cart/clear",
    method: "delete",
    baseURL: "normal",
  },
  priceSummery: {
    api: "cart/priceSummary",
    method: "post",
    baseURL: "normal",
  },
  addAllCart: {
    api: "cart/addCartEndUser",
    method: "post",
    baseURL: "normal",
  },
  createOrder: {
    api: "order/addOrder",
    method: "post",
    baseURL: "normal",
  },
  getPaymentDetail: {
    api: "order/start/payment",
    method: "post",
    baseURL: "normal",
  },
  codPayment: {
    api: "order/cashOnDelivery",
    method: "post",
    baseURL: "normal",
  },

  paymentDetailCapture: {
    api: "razorpay/paymentCapture",
    method: "post",
    baseURL: "normal",
  },
};
// chefdetailsApi
export const chefdetailsApi = {
  getMenuApi: {
    url: "user/viewMenuWithChef",
    method: "post",
    baseURL: "normal",
    query: {
      page: 1,
      limit: 10,
      filter: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMenuWithChef: {
    api: "user/getone/ChefProfileByBrandUrl",
    method: "get",
    baseURL: "normal",
  },
  getCusineList: {
    api: "menu/cuisineList",
    method: "get",
    baseURL: "normal",
  },
  foodlabelList: {
    api: "menu/foodlabelList",
    method: "get",
    baseURL: "normal",
  },
  createDish: {
    api: "menu/createMenu",
    method: "post",
    baseURL: "normal",
  },
  updateDish: {
    api: "menu/updateMenu",
    method: "put",
    baseURL: "normal",
  },
  menuAvailability: {
    api: "menu/availability",
    method: "put",
    baseURL: "normal",
  },
  deleteDish: {
    api: "menu/deleteMenu",
    method: "delete",
    baseURL: "normal",
  },

  chefUpdate: {
    api: "chef/createChefProfile",
    method: "put",
    baseURL: "normal",
  },

  businessTypeList: {
    api: "business/all",
    method: "get",
    baseURL: "normal",
  },

  chefQuite: {
    api: "/chef/quietPortal",
    method: "post",
    baseURL: "normal",
  },
  deactivateChef: {
    api: "chef/deactivateChef",
    method: "put",
    baseURL: "normal",
  },
  deleteChef: {
    api: "chef/deleteChef",
    method: "delete",
    baseURL: "normal",
  },
  deleteUser: {
    api: "user/deleteUser",
    method: "delete",
    baseURL: "normal",
  },
  getByBrandName: {
    api: "chef/bySlug",
    method: "post",
    baseURL: "normal",
  },
  chefEditUpdate: {
    api: "chef/editChefProfile",
    method: "put",
    baseURL: "normal",
  },
  getSingle: {
    api: "chef/getone/Profile",
    method: "get",
    baseURL: "normal",
  },
  getSinglePending: {
    api: "chef/getone/ProfilePending",
    method: "get",
    baseURL: "normal",
  },
  getPdf: {
    api: "download/file",
    method: "get",
    baseURL: "normal",
  },
  chefCompleted: {
    api: "chef/profileComplete",
    method: "put",
    baseURL: "normal",
  },

  verifySubScriptionDetail: {
    api: "chef/verifySubScription",
    method: "post",
    baseURL: "normal",
  },

  getChefDetailsApi: {
    url: "user/viewChefWithMenu",
    method: "get",
    baseURL: "normal",
    query: {
      country: "",
      state: "",
      city: "",
      area: "",
      page: 1,
      limit: 10,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllOrderChef: {
    url: "order/chef/getAllOrder",
    method: "get",
    baseURL: "normal",
    query: {
      page: 1,
      chefId: null,
      limit: 10,
      orderStatus: null,
      searchKey: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllActiveOrderChef: {
    url: "order/chef/getAllOrder/active",
    method: "get",
    baseURL: "normal",
    query: {
      chefId: null,
      orderStatus: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getOrderUpdateStatus: {
    api: "order/getOrderById",
    method: "get",
    baseURL: "normal",
  },
  orderUpdateStatus: {
    api: "order/updateOrderStatus",
    method: "put",
    baseURL: "normal",
  },
  contactUsApi: {
    api: "user/contactUs",
    method: "post",
    baseURL: "normal",
  },
  filterCuisineApi: {
    url: "user/viewChefWithFilter",
    method: "post",
    baseURL: "normal",
    query: {
      page: 1,
      limit: 10,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  paymentSubscription: {
    api: "razorpay/startSubscription",
    method: "post",
    baseURL: "normal",
  },
  quitChef: {
    url: "chef/quit/",
    method: "get",
    baseURL: "normal",
    chefId: null,
    get api() {
      return this.url + this.chefId;
    },
  },
  checkDeliveryPartnerAvailablity: {
    api: "chef/checkAddressAvailability",
    method: "post",
    baseURL: "normal",
  },
  assignOrderToOwnPartner: {
    api: "order/assignDeliveryPartner",
    method: "post",
    baseURL: "normal",
  },
  trackOwnPartnerOrderStatus: {
    url: "order/ownDeliveryStatus",
    method: "get",
    baseURL: "normal",
    query: {
      orderId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

// customer apis
export const orderReviewApi = {
  reviewAddApi: {
    api: "testimonial/addTestimonial",
    method: "post",
    baseURL: "normal",
  },
  reviewListApi: {
    url: "chef/getTestimonial",
    method: "post",
    baseURL: "normal",
    body: {
      page: 1,
      chefIdTestimonial: null,
    },
    query: {
      search: null, // Define search in the query
    },
    get api() {
      return this.url + generateQuery(this.query); // generateQuery constructs the query string
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload; // Update query parameters dynamically
    },
    set addBody({ key, payload }) {
      this.body[key] = payload; // Update body parameters dynamically
    },
  },
 
  publishTestimonials: {
    api: "chef/testimonialPublishOrUnpublish",
    method: "put",
    baseURL: "normal",
  },
  publishedViewListTestimonial: {
    api: "chef/publishedTestimonial",
    method: "post",
    baseURL: "normal",
  },

  getAllTestimonial: {
    api: "testimonial/getAllTestimonial",
    method: "get",
    baseURL: "normal",
  },

  subscriptionInitiate: {  
  api: "chef/subscriptionInitiate",
    method: "post",
    baseURL: "normal",
  },

  testimonialList: {
    url: "testimonial/getTestimonials?city=",
    method: "get",
    baseURL: "normal",
    city: null,
    get api() {
      return this.url + this.city;
    },
  },
  serviceSubscriptions: {
    url: "chef/serviceSubscriptions",
    method: "get",
    baseURL: "normal",
    query: {
      chefId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  changeService: {
    api: "chef/changeService",
    method: "put",
    baseURL: "normal",
  },
  servicePayment: {
    url: "chef/listingPlusPayment",
    method: "post",
    baseURL: "normal",
    query: {
      chefId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  continueAsListing: {
    url: "user/continueListing",
    method: "post",
    baseURL: "normal",
    id: null,
    get api() {
      return this.url;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  capturePaymentDetails: {
    api: "chef/capturePaymentDetails",
    method: "post",
    baseURL: "normal",
  },
  capturePaymentFailureDetails: {
    url: "chef/cancelledSubscriptions",
    method: "post",
    baseURL: "normal",
    query: { chefId: null, subscriptionId: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSubscription: {
    url: "chef/subscriptionsByOffer",
    method: "get",
    baseURL: "normal",
    query: { chefId: null, offerId: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSubscriptionDetails: {
    url: "chef/subscriptionDetailByChef",
    method: "get",
    baseURL: "normal",
    query: { chefId: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  triggerLifeTimeSubscription: {
    url: "chef/lifeTimeSubscription",
    method: "post",
    baseURL: "normal",
    query: {
      chefId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

// customer apis
export const planPackages = {
  getPackages: {
    api: "chef/getPackages",
    method: "get",
    baseURL: "normal",
  },
  getListingGmail: {
    api: "chef/change-service",
    method: "put",
    baseURL: "normal",
  },
};

// delivery partner apis
export const deliveryPartners = {
  createInitial: {
    api: "chef/V1/dunzoPostTask",
    method: "post",
    baseURL: "normal",
  },
  deliveryStatus: {
    api: "chef/V1/TrackStatus",
    method: "get",
    baseURL: "normal",
  },

  deliveryStatusChange: {
    api: "chef/V1/testMode/task/nextState",
    method: "put",
    baseURL: "normal",
  },
  deliveryCustomer: {
    api: "order/trackOrder",
    method: "post",
    baseURL: "normal",
  },
  stopDeliveryPartner: {
    api: "order/stopDeliveryPartner",
    method: "post",
    baseURL: "normal",
  },
};
export const offerList = {
  getOfferList: {
    url: "admin/listUserOffers",
    method: "get",
    baseURL: "normal",
    query: { page: "", limit: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const ownDeliveryPartner = {
  getOwnDelivery: {
    url: "chef/ownPartner",
    method: "get",
    baseURL: "normal",
    query: { page: "", limit: "", search: "", chefId: "", status: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addOwnDelivery: {
    api: "chef/ownPartner",
    method: "post",
    baseURL: "normal",
  },
  editOwnDelivery: {
    api: "chef/ownPartner/",
    method: "put",
    baseURL: "normal",
  },
  deleteOwnDelivery: {
    url: "chef/ownPartner/",
    method: "delete",
    baseURL: "normal",
    query: {
      id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  finishOwnPartnerOrder: {
    api: "/order/stopDeliveryPartner",
    method: "post",
    baseURL: "normal",
  },
  verifyOwnPartnerOrder: {
    api: "/order/getOrderById/",
    method: "get",
    baseURL: "normal",
  },
};

export const availabilityTime = {
  getAvailabilityTime: {
    url: "chef/getChefAvailabilityData",
    method: "get",
    baseURL: "normal",
    query: { chefId: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  editAvailabilityTime: {
    api: "chef/updateChefAvailability/",
    method: "put",
    baseURL: "normal",
  },
};
