import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { UnderReviewComp } from "component/UnderReview";
export class UnderReviewClass extends Component {
  render() {
    return <UnderReviewComp />;
  }
}
export const UnderReview = connect(null, null)(UnderReviewClass);
