import React, { useEffect, useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { NormalButton } from "component/common";
import { history } from "service/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import closeBtn from "assets/images/closeGray.png";
import logo from "assets/svg/Trialended.svg";
import correct from "../../../assets/images/correct.png";
import wrong from "../../../assets/images/wrong.png";
import { deleteChef, paymentSubscription } from "action/ChefDetailsAct";
import { viewPlanPackage } from "action/AuthAct";
import { Toast } from "service/toast";
import { options, redirectToCheckout } from "utils/constants";
import axios from "axios";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
function UnderTrial({
  formData,
  paymentSubscription,
  deleteChef,
  isTrialExpired,
  viewPlanPackage,
  setIsTrialExpired,
  trialExpiredData,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAlert, setIsAlert] = useState(true);
  const [isQuit, setIsQuit] = useState(false);
  const [isblocked, setIsblocked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    planId: "",
    planName: "",
    pricing: "",
  });
  const [packageData, setPackageData] = useState([]);
  //life cycle
  useEffect(() => {
    packagePlanLoad();
  }, []);
  //package load function
  const packagePlanLoad = async () => {
    setIsLoading(true);
    await viewPlanPackage()
      .then(({ data }) => {
        if (data) {
          setPackageData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => setIsLoading(false));
  };

  //handle stripe payment
  const makeStripePayment = async (datum) => {
    const { data } = datum || {};
    localStorage.setItem("sessionId", JSON.stringify(datum));
    try {
      const session = data;
      const result = redirectToCheckout({
        sessionId: session?.id,
      });
      if (result?.error) {
        console.log(result.error);
      }
    } catch (error) {
      console.error("Error making payment:", error);
    }
  };

  const getPlanPrice = async (planObj) => {
    let body = {
      currency: formData.currency || '',
      amount: +planObj.amount || '',
      name: planObj.planName || '',
      planId: planObj.planId || '',
      chefId: formData._id,
    };
    try {
      // alert("hello")
      const response = await axios.post(
        "https://preprodapis.thecheflane.com/api/v1/chef/subscriptionInitiate",
        body,
        {
          headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${options.clientSecret}`,
          },
        }
      );
      makeStripePayment(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  //handlePayment
  const handlePayment = () => {
    if (!!selectedPlan.planId) {
      let payload = {
        planId: selectedPlan.planId,
        planName: selectedPlan.planName,
        amount: Number(selectedPlan.pricing) * 100,
        currency: formData.currency,
      };
      paymentSubscription(payload).then(({ data }) => {
        // displayRazorpay(data);
        // localStorage.setItem('subscriptionData', JSON.stringify(data));
        getPlanPrice(payload);
      });
    } else {
      Toast({ type: "error", message: "Please choose a plan." });
    }
  };

  //razor pay
  const displayRazorpay = async (data) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let amount = Number(selectedPlan.pricing) * 100;
    const options = {
      key: process.env.REACT_APP_RAZORPAYKEY,
      amount: amount ? amount : "",
      planId: data.plan_id,
      currency: formData.currency,
      name: "The Chef Lane",
      image: logo,
      theme: { color: "#08316d" },
      handler: function (res) {
        captureFunc(res);
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  const captureFunc = async (result) => {
    if (result.razorpay_payment_id) {
      Toast({ type: "success", message: "Plan activated successfully." });
      setIsTrialExpired(false);
      // let responseData = {
      //   planId: result.plan_id,
      //   planName: selectedPlan,
      //   amount: selectedPlan,
      //   currency: formData.currency,
      // }
      // paymentSubscription(responseData).then(() => {
      //   // history.push(`/index/orderplaced/${orderData.orderId}`)
      //   setIsblocked(false)
      // })
    } else {
      Toast({ type: "error", message: "Payment Failed!" });
    }
  };

  return (
    <div>
      <Modal
        show={isAlert}
        //   onHide={() => setIsAlert(true)}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal"
        centered
      >
        <Modal.Header className="bg-white border-0">
          <Modal.Title></Modal.Title>
          {trialExpiredData.trialExpiredDays < 8 &&
            !trialExpiredData.trialExpired ? (
            <span class="cursor-pointer" onClick={() => setIsAlert(false)}>
              <img src={closeBtn} alt="icon" />
            </span>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          {trialExpiredData.trialExpiredDays < 8 &&
            !trialExpiredData.trialExpired ? (
            <div className="alert-deActive mt-3 pb-4">
              <p className="mb-2 fs-24 text-black">
                Dear User your 3 months Trial ends in <br />7 Days Kindly pay to
                continue using The Chef Lane.
              </p>
            </div>
          ) : (
            <div className="alert-deActive mt-3 pb-4">
              {!isQuit ? (
                <>
                  <img src={logo} alt="logo" />
                  <p className="mb-2 fs-20 text-black mt-3">
                    Dear User thank you for trying The Chef Lane’s 3 Months
                    Trial.
                    <br />
                    Hope you enjoyed. We would like to inform you that your
                    Trial ended.
                    <br />
                  </p>
                  <p className="mb-2 fs-20 text-black">
                    To continue enjoying your benefits please pay and proceed..
                  </p>
                </>
              ) : (
                <>
                  <p className="mb-2 fs-20 text-black">
                    Are you sure you want to quit?
                  </p>
                  <p className="mb-2 fs-20 text-black">
                    This will result in deactivation of your account
                  </p>
                </>
              )}
            </div>
          )}
          <Modal.Footer>
            {!isTrialExpired ? (
              <div className="d-flex pb-3">
                <NormalButton
                  primary
                  label="Pay Now"
                  className="px-5 w-100 fw-700"
                  disabled={isLoading}
                  onClick={() => {
                    setIsAlert(false);
                    setIsblocked(true);
                  }}
                />

                <span className="m-4" />
                <NormalButton
                  label="Later"
                  className="px-5 white-btn mr-3"
                  onClick={() => setIsAlert(false)}
                />
              </div>
            ) : (
              <>
                {!isQuit ? (
                  <div className="d-flex pb-3">
                    <NormalButton
                      primary
                      label="Pay Now"
                      className="px-5 fw-700"
                      disabled={isLoading}
                      onClick={() => {
                        setIsAlert(false);
                        setIsblocked(true);
                      }}
                    />

                    <span className="m-4" />
                    <NormalButton
                      label=" Quit "
                      className="px-5 quite_width white-btn mr-3"
                      onClick={() => setIsQuit(true)}
                    />
                  </div>
                ) : (
                  <div className="d-flex pb-3">
                    <NormalButton
                      label="Yes, I want to Quit"
                      className="px-3 fw-700 white-btn"
                      disabled={isLoading}
                      onClick={() => {
                        setIsLoading(true);

                        let id = formData._id;
                        deleteChef(id)
                          .then((data) => {
                            if (data) {
                              setIsLoading(false);
                              localStorage.clear();
                              history.push("/");
                              window.location.reload();
                            }
                          })
                          .catch(() => {
                            setIsLoading(false);
                          });
                      }}
                    />
                    <span className="m-4" />
                    <NormalButton
                      primary
                      label="No, I want to pay"
                      className="px-3  mr-3"
                      onClick={() => setIsblocked(true)}
                    />
                  </div>
                )}
              </>
            )}
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Modal
        show={isblocked}
        //   onHide={() => setIsAlert(true)}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal"
        centered
        size="xl"
      >
        <Modal.Header className="bg-white border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlanTable
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
            currency={formData.currency}
            packageData={packageData}
          />
          <Modal.Footer>
            <div className="d-flex pb-3">
              <NormalButton
                primary
                label="Pay Now"
                className="px-5 w-100 fw-700"
                disabled={isLoading}
                onClick={() => {
                  handlePayment();
                }}
              />
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      paymentSubscription,
      deleteChef,
      viewPlanPackage,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(UnderTrial);

const PlanTable = ({
  setSelectedPlan,
  selectedPlan,
  currency,
  packageData,
}) => {
  return (
    <div id="Package">
      <div className="container Table">
        <div className="row d-flex justify-content-center">
          <div className="fs-36 fw-700 text-primary-color d-flex justify-content-center pb-5 pt-1">
            <img src={logo} alt="logo" />
          </div>
          <div class="col-lg-11 col-12">
            <div class="card">
              <div class="card-body p-0">
                <table class="table table-bordered ">
                  <thead>
                    <tr className="heading">
                      <th scope="col" className="">
                        <p className="mb-0 fs-18 fw-700 text-black-25 text-start">
                          Packages
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto mb-0 fs-18 fw-700 text-black-25 text-nowrap">
                          Listing
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto mb-0 fs-18 fw-700 text-black-25 text-nowrap">
                          ListingPLUS
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="w-40">
                      <th className="fs-18 py-4 fw-700 text-black-25 text-start">
                        {packageData.packages[0]}
                      </th>

                      <td className="text-center py-4">
                        {packageData.Listing[0].Listing ? (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={correct}
                          />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={wrong}
                          />
                        )}
                      </td>
                      <td className="text-center py-4">
                        {packageData.ListingPlus[0].Listing ? (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={correct}
                          />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={wrong}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-start"
                        scope="row"
                      >
                        {packageData.packages[1]}
                      </th>
                      <td className="text-center">
                        {!packageData.Listing[1]["order delver"] ? (
                          <img alt="wrong" className="icon" src={wrong} />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={correct}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {packageData.ListingPlus[1]["order delver"] ? (
                          <img alt="correct " className="icon" src={correct} />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={wrong}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-start"
                        scope="row"
                      >
                        {packageData.packages[2]}
                      </th>
                      <td className="text-center">
                        {packageData.Listing[2]["Unlimited updates to Menu"] ? (
                          <img alt="correct" className="icon" src={correct} />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={wrong}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {packageData.ListingPlus[2][
                          "Unlimited updates to Menu"
                        ] ? (
                          <img alt="correct" className="icon" src={correct} />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={wrong}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-start"
                        scope="row"
                      >
                        {packageData.packages[3]}
                      </th>
                      <td className="text-center fs-16 fw-400 text-black-25">
                        {" "}
                        {currency +
                          " " +
                          packageData.Listing[3]["Monthy Pricing"]}
                        <div className="mt-2">
                          <NormalButton
                            primary
                            label={
                              selectedPlan.planId ===
                                packageData.Listing[3]["planId"]
                                ? "Selected"
                                : "Select"
                            }
                            className="px-3 "
                            onClick={() => {
                              selectedPlan.planId =
                                packageData.Listing[3]["planId"];
                              selectedPlan.planName = "Listing";
                              selectedPlan.pricing =
                                packageData.Listing[3]["Monthy Pricing"];
                              setSelectedPlan((prevState) => ({
                                ...prevState,
                                selectedPlan,
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td className="text-center fs-16 fw-400 text-black-25">
                        Coming soon
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-start"
                        scope="row"
                      >
                        {packageData.packages[4]}
                      </th>
                      <td className="text-center fs-16 fw-400 text-black-25 ">
                        {currency +
                          " " +
                          packageData.Listing[4]["Yearly Pricing"]}
                        <div className="mt-2">
                          <NormalButton
                            primary
                            label={
                              selectedPlan.planId ===
                                packageData.Listing[4]["planId"]
                                ? "Selected"
                                : "Select"
                            }
                            className="px-3 fw-500 fs-14"
                            onClick={() => {
                              selectedPlan.planId =
                                packageData.Listing[4]["planId"];
                              selectedPlan.planName = "Listing";
                              selectedPlan.pricing =
                                packageData.Listing[4]["Yearly Pricing"];
                              setSelectedPlan((prevState) => ({
                                ...prevState,
                                selectedPlan,
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td className="text-center fs-16 fw-400 text-black-25">
                        Coming soon
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-start"
                        scope="row "
                      >
                        {packageData.packages[5]}
                      </th>
                      <td className="text-center">
                        {!packageData.Listing[5]["Transaction Fees"] ? (
                          <img alt="wrong" className="icon" src={wrong} />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={correct}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {!packageData.ListingPlus[5]["Transaction Fees"] ? (
                          <img alt="wrong" className="icon" src={wrong} />
                        ) : (
                          <img
                            alt="correct"
                            className="text-center icon"
                            src={correct}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-end">
              <a
                href="/index/t&c"
                className="fs-16 line-height-20 fw-400 text-black-25 pt-3 cursor-pointer hover-black-25"
                target="_blank"
              >
                Terms &amp; Conditions apply
              </a>
            </div>
            {/* <div className="text-center pt-5">
              <p className="fs-24 fw-700 text-black-25 line-height-28 ">
                Sign Up and avail the 3 Months Free Trial with The Chef Lane{" "}
              </p>
              <NormalButton
                onClick={() => history.push(`/index/profile`)}
                primary
                label="Get started "
                className="my-4 col-md-2  mx-auto fw-700  fs-16 line-height-18 cursor-pointer"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
