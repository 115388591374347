import React from 'react'
import './style.scss'
import placeOrder from 'assets/images/placeOrder.png'
import dish from 'assets/images/DishImage.png'
import chef_icon from 'assets/images/chef_Image.png'

export const Cards = () => {
  return (
    <div className="bg-gold-ff how-it-works">
      <div className="container">
        <div className="row py-md-5 py-4 d-flex justify-content-center">
          <div className="col-12 mx-auto">
            <h1 className="d-flex justify-content-center pb-4">
              Let’s Get Started
            </h1>
          </div>
          <div className="col-lg-11 col-12 mx-auto">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="d-flex flex-column align-items-center px-md-2">
                  <img alt="chef" className="mx-auto h-100" src={chef_icon} />
                  <p className="pt-4  fs-18 mb-2">
                    {' '}
                    <b> Find Chefs Across Locations </b>
                  </p>
                  <p className=" px-md-3 mx-auto mb-md-3 mb-4">
                    {''}
                    {''}
                    We have home chefs with professional training and passionate
                    home chefs with years of experience filling happy bellies.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="d-flex flex-column align-items-center px-md-2">
                  <img alt="chef" className="mx-auto h-100" src={dish} />
                  <p className="pt-4 fs-18 mb-2">
                    <b> Find Your Food </b>
                  </p>
                  <p className="px-md-3 mx-auto mb-md-3 mb-4">
                    What are you in the mood for today? North-Indian or
                    South-Indian, Chinese or Continental, Mexican or Tibetan? A
                    cozy meal for 2 or a feast for many? Want your food ASAP or
                    over the weekend? We have it all!
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="d-flex flex-column align-items-center px-md-2">
                  <img alt="chef" className="mx-auto h-100" src={placeOrder} />
                  <p className="pt-4 fs-18 mb-2">
                    <b> Place Your Order </b>
                  </p>
                  <p className="px-md-3 mx-auto mb-md-3 mb-4">
                    Contact your chosen chef directly or via social media. You
                    can even order online. You choose what works for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
