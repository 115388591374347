const listData = [
  {
    key: 1,
    pointone: "#vocalforlocal",
    codesandbox: require("../assets/images/codesandbox.png"),
    isForAll: true,
  },
  {
    key: 2,
    pointone: "Because home food is soul food!",
    title: "Ready to join The Chef Lane and start cooking? Here’s how to do it",
    codesandbox: require("../assets/images/codesandbox.png"),
    isForAll: true,
  },
  {
    key: 3,
    pointone:
      "All our home chefs are registered with the relevant food safety authority",
    codesandbox: require("../assets/images/codesandbox.png"),
    isForAll: false,
  },

  {
    key: 4,
    pointone:
      "Home Chefs bring a tried and tested repertoire of home-cooked delicacies from their homes to yours",
    codesandbox: require("../assets/images/codesandbox.png"),
    isForAll: true,
  },
];
export default listData;
