import {
  authApi,
  planPackages,
  deliveryPartners,
} from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";
import { notificationType } from "service/actionType";
// login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.loginApi, body })
        .then((response) => {
          let { msg, message, data, token, checkLicenseExpired } = response;
          localStorage.setItem("authToken", token);
          localStorage.setItem("userDetailsWeb", JSON.stringify(data));
          localStorage.setItem("userId", data._id);
          if (checkLicenseExpired) {
            Toast({
              type: "error",
              message: "License has been Expired.",
              time: 3000,
            });
          } else {
            Toast({ type: "success", message: msg || message });
          }
          resolve(response);
        })
        .catch(({ msg, message }) => {
          reject(Toast({ type: "error", message: msg || message }));
        });
    });
  };
export const userSwitch =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.userSwitch, body })
        .then(({ msg, message, data, token }) => {
          // localStorage.setItem("authToken", token);
          if (data !== null) {
            localStorage.setItem("userDetailsWeb", JSON.stringify(data));
            localStorage.setItem("userId", data._id);
            Toast({ type: "success", message: msg || message });
          }
          resolve(data);
        })
        .catch(({ msg, message }) => {
          reject(Toast({ type: "error", message: msg || message }));
        });
    });
  };
export const signUp =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.signUpApi, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch((data) => {
          reject(Toast({ type: "error", message: data.message }));
        });
    });
  };
export const signUpChef =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.signUpChefApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
export const resendOtpMobile =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resendOtpMobile, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ errorMessage }) => {
          reject(Toast({ type: "error", message: errorMessage }));
        });
    });
  };
export const resendOtpEmail =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resendOtpEmail, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ errorMessage }) => {
          reject(Toast({ type: "error", message: errorMessage }));
        });
    });
  };

export const verifyUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.verifyUserApi, body })
        .then(({ token, data, message }) => {
          token && localStorage.setItem("authToken", token);
          data && localStorage.setItem("userDetailsWeb", JSON.stringify(data));
          message && Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const forgotPassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.forgotPasswordApi, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const resetPassword =
  (body, token) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resetPasswordApi, body, token })
        .then(({ message, data }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const chefChangePasswordPostLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.chefChangePasswordPostLogin, body })
        .then(({ message }) => {
          Toast({ type: "success", message });
          resolve();
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//user Change Password Post Login
export const userChangePasswordPostLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.userChangePasswordPostLogin, body })
        .then(({ message }) => {
          Toast({ type: "success", message });
          resolve();
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//get Notification Count
export const getNotificationCount =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { getNotificationCount } = authApi;
      getNotificationCount.chefId = id;
      api({ ...authApi.getNotificationCount })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//get Notification List
export const getNotificationList =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { getNotificationList } = authApi;
      getNotificationList.chefId = id;
      addQuery(query, getNotificationList);
      api({ ...authApi.getNotificationList })
        .then((data) => {
          let payload = data.data;
          dispatch({
            type: notificationType.notificationListData,
            payload,
          });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//update Notification List
export const updateNotificationList =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.updateNotificationList, body })
        .then((data) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//get Chef  packeges plan api
export const viewPlanPackage =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...planPackages.getPackages })
        .then((data) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const viewChangeService =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...planPackages.getListingGmail, body })
        .then((data) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
//contact otp

export const sendOtpContactEmail =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.sendOtpContactEmail, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//contact Verify otp

export const sendOtpVerifyContact =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.sendOtpVerifyContact, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//address email otp
export const sendOtpEmailAddress =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.sendOtpAddressEmail, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//address verify email otp
export const sendOtpVerifyEmailAddress =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.sendOtpVerifyAddressEmail, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//address mobile otp
export const sendOtpphoneAddress =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.sendOtpAddressMobile, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//address verify mobile otp
export const sendOtpVerifyphoneAddress =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.sendOtpVerifyAddressMobile, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//delivery partner intial connection
export const DeliverypartnerConnection =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...deliveryPartners.createInitial, body })
        .then((response) => resolve(response))
        .catch(({ message, data }) =>
          reject(Toast({ type: "error", message: message || data?.message }))
        );
    });
  };
//delivery patrner status
export const getDeliveryStatus =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...deliveryPartners.deliveryStatus, id })
        .then((response) => resolve(response))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//delivery patrner status change status
export const getDeliveryStatusChange =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...deliveryPartners.deliveryStatusChange, id })
        .then((response) => resolve(response))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//delivery patrner status
export const getDeliveryCustomer =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...deliveryPartners.deliveryCustomer, body })
        .then((response) => resolve(response))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const changeStatusDelivered =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...deliveryPartners.stopDeliveryPartner, body })
        .then((response) => resolve(response))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const loginCartDetails =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { cartDetails } = authApi;
      cartDetails._id = id;
      api({ ...authApi.cartDetails, api: cartDetails.fullApi })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
