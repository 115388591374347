import React, { Component } from "react";
import down_arrow from "../../../assets/images/down_arrow.png";
import triangle_arrow from "../../../assets/images/triangle_arrow.png";
import user from "../../../assets/images/user.png";
import Spinner from "react-bootstrap/Spinner";
export class NormalButton extends Component {
  render() {
    const {
      className = "",
      label = "",
      onClick,
      id,
      disabled = false,
      normal = false,
      viewButton = false,
      optionButton = false,
      primary = false,
      isArrow = false,
      triangle = false,
      prefix = null,
      isuser = false,
      whiteBtn = false,
      loader = false,
    } = this.props;

    return (
      <>
        <button
          id={id}
          className={`btn cursor-pointer
      ${normal ? "normal-btn" : ""}
      ${primary ? "primary-btn" : ""}
      ${whiteBtn ? "white-bg-btn" : ""}
      ${viewButton ? "viewButton" : ""}
        ${optionButton ? "optionButton" : ""}

      ${className}`}
          onClick={onClick}
          disabled={disabled}
        >
          {!!prefix && (
            <span className="prefix">
              <img src={prefix} alt="plus"></img>
            </span>
          )}
          <span>{label}</span>
          {loader && (
            <span className="p-1">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
          )}
          {isuser ? (
            <span className="">
              <img src={user} alt="user" />
            </span>
          ) : null}
          {triangle ? (
            <span className="mx-2">
              <img src={triangle_arrow} alt="arrow" />
            </span>
          ) : null}
          {isArrow ? (
            <span className="mx-1">
              <img src={down_arrow} alt="arrow" />
            </span>
          ) : null}
        </button>
      </>
    );
  }
}
