import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { UserDetailComp } from "component/UserDetail/UserDetail";
import { Helmet } from "react-helmet";
export class UserDetailClass extends Component {
  render() {

    return(
    <>
    <Helmet>

    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8190557623119050"
     crossorigin="anonymous"></script>
      <ins class="adsbygoogle"
          style="display:block"
          data-ad-format="fluid"
          data-ad-layout-key="-h1-l+1y-dn+nf"
          data-ad-client="ca-pub-8190557623119050"
          data-ad-slot="3523733183"></ins>
      </Helmet>
      <UserDetailComp />
    </>
    );

  }
}
export const UserDetail = connect(null, null)(UserDetailClass);
