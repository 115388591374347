import React, { useState, useEffect } from "react";
import AdressDetail from "./AddressDetail/AdressDetail";
import BasicDetail from "./BasicDetail/BasicDetail";
import UnderReview from "./underReview";
import { CommonPageLoader } from "component/common";
import { MenuDetailsComp } from "./Menu/MenuDetails";
import ProfileNavBar from "./ProfileNavBar/ProfileNavBar";
import { getSingle } from "action/ChefDetailsAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "service/utilities";

const initalState = {
  _id: localStorage.getItem("userId"),
  firstName: "",
  lastName: "",
  businessName: "",
  businessType: "",
  mobileNumber: "",
  whatsappNumber: "",
  sameAsMobileNumber: false,
  deliveryPartners: "OWN",
  email: "",
  chefImage: "",
  availabilityStatus: false,
  licenseExpiryDate: "",
  AboutDetails: {
    About: "",
    adminApproved: false,
    isEdit: false,
  },
  cuisine: [],
  LicenseDetails: {
    licenseNumber: "",
    licenseExpiryDate: null,
    licenseFileUrl: "",
    adminApproved: false,
    isEdit: false,
  },
  bankDetails: {
    accountNumber: "",
    ifsc: '',
    accountName: '',
    re_enter_accountNumber: "",
    adminApproved: false,
    isEdit: false,
    isNewRequest: false,
  },
  facebook: {
    facebookLink: "",
    adminApproved: false,
    isEdit: false,
  },
  instagram: {
    instagramLink: "",
    adminApproved: false,
    isEdit: false,
  },
  AddressDetails: {
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    area: "",
    pincode: "",
    location: {
      lat: "",
      long: "",
    },
  },
};
function ChefProfileComponent({ getSingle }) {
  const [currentForm, setcurrentForm] = useState("basicDetails");
  const [formData, setFormData] = useState(initalState);
  const [renderCuisnineList, setrenderCusinieList] = useState([]);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchChefDetails();
  }, []);
  const resetFormData = () => {
    let newForm = Object.assign({}, initalState);
    setFormData({ ...newForm });
  };
  const handleFormChange = (formStep) => {
    setcurrentForm(formStep);
  };
  const fetchChefDetails = async () => {
    let id = localStorage.getItem("userId");
    setIsLoading(true);
    await getSingle(id)
      .then(({ data }) => {

        setIsLoading(false);
        if (!!data && !data.deactivateId) {
          let newForm = Object.assign(formData, data);
          setFormData({ ...newForm });
          setSelectedCuisineList(!!newForm.cuisine ? newForm.cuisine : []);
          // setrenderCusinieList(!!newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        } else {
          //logout();
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      {!isLoading ? (
        <div className="bg-yellow-BF">
          {formData.isprofileCompleted === "COMPLETED" ? (
            <UnderReview formData={formData} />
          ) : (
            <>
              <ProfileNavBar
                currentForm={currentForm}
                setcurrentForm={setcurrentForm}
              />
              {currentForm === "basicDetails" ? (
                <BasicDetail
                  resetFormData={resetFormData}
                  setSelectedCuisineList={setSelectedCuisineList}
                  selectedCuisineList={selectedCuisineList}
                  setrenderCusinieList={setrenderCusinieList}
                  renderCuisnineList={renderCuisnineList}
                  handleFormChange={handleFormChange}
                  formData={formData}
                  setFormData={setFormData}
                />
              ) : currentForm === "addressDetails" ? (
                <AdressDetail
                  handleFormChange={handleFormChange}
                  formData={formData}
                  setFormData={setFormData}
                />
              ) : (
                <MenuDetailsComp
                  fetchChefDetails={fetchChefDetails}
                  data={formData}
                  handleFormChange={handleFormChange}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <CommonPageLoader />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSingle }, dispatch);
};
export default connect(null, mapDispatchToProps)(ChefProfileComponent);
