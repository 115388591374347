import React, { Component } from "react";
// Redux Connection
import { LicenceComp } from "component/Licences";

export class LicenceClass extends Component {
  componentDidMount() {
    let element = document.getElementById("license");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <div id="license">
        <LicenceComp />
      </div>
    );
  }
}
export const Licence = LicenceClass;
