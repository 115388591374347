import { loadStripe } from "@stripe/stripe-js";

//export const FRONT_END_PORT = "http://doodlebluelive.com:2013/";
export const FRONT_END_PORT = "https://preprod.thecheflane.com/";
// export const FRONT_END_PORT = 'https://www.thecheflane.com/'
export const DELIVERY_PARTNER_TYPE = {
  OWN: "OWN",
  PLATFORM: "PLATFORM"
}

export const CHIEF_AVAILBILITY_MAX_HR = 20


export const stripePromiseKey = loadStripe(
  "pk_test_51Jo4mYSAQWuAsv8cbgZfx43RPJIFVmFvEiA8RK1sQjsHv8Bz9VgU9gyYvkSEM1lPrA3BieSlj9ZhKE8XsVAgZCsD00Z3HPM3Ge"
);

export const options = {
  clientSecret:
    "sk_test_51Jo4mYSAQWuAsv8cnl7z3Gntt59U57qun6AAy68O9NFhhfrBL1kr1bJ9MoKUxnrpryfTytze04s2kvbURuJ2zx2L00v12DxaSv",
};


export const getStripe = () => {
  return stripePromiseKey;
};

export const generateStripe = require('stripe')('sk_test_51Jo4mYSAQWuAsv8cnl7z3Gntt59U57qun6AAy68O9NFhhfrBL1kr1bJ9MoKUxnrpryfTytze04s2kvbURuJ2zx2L00v12DxaSv');

export const redirectToCheckout = async (checkoutOptions) => {
  try {
    const stripe = await getStripe();
    const data = await stripe.redirectToCheckout(checkoutOptions);
    alert('session calling...')
    console.log('stripeCheckoutRes', data);
    localStorage.setItem('sessionData', data);
    return { success: true };
  } catch (err) {
    console.error("An unexpected error occurred", err);
    return { success: false, error: "An unexpected error occurred" };
  }
};

