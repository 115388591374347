const listDataChef = [
  {
    key: 1,
    pointone:
      "Sign up - Provide contact details, describe your business and upload your menu",
    title: "Ready to join The Chef Lane and start cooking? Here’s how to do it",
    codesandbox: require("../assets/images/codesandbox.png"),
  },
  {
    key: 2,
    pointone:
      "We set up your Profile - so you don't have to create or maintain a website",
    codesandbox: require("../assets/images/codesandbox.png"),
  },
  {
    key: 3,
    pointone:
      "Share the webpage on social media and with customers for easy access to your menu",
    codesandbox: require("../assets/images/codesandbox.png"),
  },
  {
    key: 4,
    pointone: "Get Cooking - You’re in Business",
    codesandbox: require("../assets/images/codesandbox.png"),
  },
];
export default listDataChef;
