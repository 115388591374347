import React, { useEffect, useState } from "react";
import { resetPassword } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import { validationRules } from "./validate";
import { NormalButton } from "component/common/NormalButton";

const ForgotpasswordClass = ({ resetPasswordApiCall }) => {
  const [changePassword, setChangePassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setErrors] = useState({});

  function useQueryParams() {
    const params = new URLSearchParams(window ? window.location.search : {});

    return new Proxy(params, {
      get(target, prop) {
        return target.get(prop);
      },
    });
  }

  const { token } = useQueryParams();

  useEffect(() => {
    let token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      // history.push("/");
    }
  }, []);

  //handle Change
  const handleChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setChangePassword((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" || e.key === "enter") {
      handleSubmit(e);
    }
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      password: changePassword.newPassword,
      confirmPassword: changePassword.confirmNewPassword,
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    resetPasswordApiCall(body, token)
      .then(() => {
        setisButtonClicked(false);
        history.push("/");
      })
      .catch(() => setisButtonClicked(false));
  };

  return (
    <div className="row">
      <div className="col-md-6 col-12 mx-auto">
        <h3 className="fw-700 fs-24 line-height-28 text-black-18 text-md-start text-center">
          Change Password
        </h3>

        <div className="mt-3 pt-1">
          <label>New Password</label>
          <div className="input-group password-field position-relative">
            <input
              onKeyPress={handleKeypress}
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              name="newPassword"
              placeholder="Enter password..."
              value={changePassword.newPassword}
              onChange={handleChange}
            />
            <div className="input-group-addon right">
              <i
                onClick={() => setPasswordVisible(!passwordVisible)}
                className={`cursor-pointer text-gold icon-${passwordVisible ? "eye" : "eye-hide"
                  } fs-18`}
              />
            </div>
          </div>
          {error.password && <ErrorComponent message={error.password[0]} />}
        </div>
        <div className="mt-3 pt-1">
          <label>Confirm New Password</label>
          <div className="input-group password-field position-relative">
            <input
              onKeyPress={handleKeypress}
              type={confirmPasswordVisible ? "text" : "password"}
              className="form-control"
              name="confirmNewPassword"
              placeholder="Enter password..."
              value={changePassword.confirmNewPassword}
              onChange={handleChange}
            />
            <div className="input-group-addon right">
              <i
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
                className={`cursor-pointer text-gold icon-${confirmPasswordVisible ? "eye" : "eye-hide"
                  } fs-18`}
              />
            </div>
          </div>
          {error.confirmPassword && (
            <ErrorComponent message={error.confirmPassword[0]} />
          )}
        </div>
        <div className="mt-md-4 my-4">
          <div className="w-100">
            <NormalButton
              primary
              label="Submit"
              className="w-100 fw-700"
              onClick={handleSubmit}
              disabled={isButtonClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPasswordApiCall: resetPassword,
    },
    dispatch
  );
};

export const ForgotpasswordComp = connect(
  null,
  mapDispatchToProps
)(ForgotpasswordClass);
