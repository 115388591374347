import React, { useEffect, useState } from "react";
import pdfHead from "../../../assets/images/pdfHead.png";
import nonvegIcon from "../../../assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import { CommonPageLoader } from "component/common";
import getSymbolFromCurrency from "currency-symbol-map";
import "./style.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMenuWithChef } from "action/ChefDetailsAct";
import { convertToSlug } from "service/helperFunctions";
import { FRONT_END_PORT } from "utils/constants";

function ChefPdf(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    // fetchChefDetails()
  }, []);

  let {
    chefDetails = {},
    AvailableDishList = [],
    AdvanceOrderList = [],
  } = data;

  var divImage = {
    width: "96px",
    height: "104px",
    // backgroundImage: 'url(' + imageUrl + ');',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="section-to-print d-flex flex-column justify-content-center my-3 container">
      {!isLoading ? (
        <div
          id="pdfChef"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            width: "100%",
          }}
        >
          <div
            id="pdfChef-head"
            className="graph-image graph-7"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "60% auto",
              width: "100%",
              backgroundPosition: "right bottom",
            }}
          >
            <div id="pageHeader"></div>
          </div>

          <div id="padding-for-page">
            <div
              id="pdfChef-wraper"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                width: "100%",
              }}
            >
              <div id="pageHeader"></div>
              <div
                id="head-pdf"
                style={{
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    height: "120px",
                  }}
                >
                  <img
                    src={pdfHead}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  // marginTop: '160px',
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    paddingTop: "12mm",
                    paddingLeft: "30mm",
                    paddingRight: "30mm",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ paddingTop: "0px" }}>
                    <img src={chefDetails.chefImage} alt="" style={divImage} />
                  </div>
                  <div
                    style={{
                      // display: 'flex',

                      // width: '100%',
                      // alignItems: 'flex-start',
                      // flexDirection: 'column',
                      paddingLeft: "70px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "500",
                        margin: "20px 0px 8px 0px",
                        textAlign: "left",
                      }}
                    >
                      Business Name: {chefDetails.businessName}
                    </div>

                    <div
                      style={{
                        fontWeight: "500",
                        width: "500px",
                        margin: "15px 0px",
                        textAlign: "left",
                      }}
                    >
                      Website :{" "}
                      {!!chefDetails.businessName && (
                        <span style={{ fontWeight: "700", color: "#D8AF49" }}>
                          {`${FRONT_END_PORT}${convertToSlug(
                            chefDetails.businessName
                          )}`}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "700",
                    color: "#08316D",
                    paddingTop: "60px",
                    fontSize: "24px",
                    lineHeight: "28px",
                  }}
                >
                  Food Menu
                </div>
              </div>
              <div
                className="food-content"
                style={{
                  paddingTop: "12mm",
                  paddingLeft: "30mm",
                  paddingRight: "30mm",
                }}
              >
                <div>
                  {AvailableDishList.length > 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            color: "#08316D",
                            fontSize: "24px",
                            lineHeight: "28px",
                          }}
                        >
                          Immediate Order
                        </div>
                        <div
                          style={{
                            fontWeight: "700",
                            color: "#08316D",
                            fontSize: "24px",
                            lineHeight: "28px",
                          }}
                        >
                          Price
                        </div>
                      </div>
                      {AvailableDishList.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                width: "64%",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "700",
                                  color: "#08316D",
                                  fontSize: "16px",
                                  lineHeight: "18px",
                                  marginTop: "30px",
                                }}
                              >
                                {item.name}
                                <span>
                                  <img
                                    src={
                                      item?.dishCategory === "Non Veg"
                                        ? nonvegIcon
                                        : vegIcon
                                    }
                                    alt=""
                                    style={{
                                      marginLeft: "13px",
                                      width: "13px",
                                      height: "13px",
                                    }}
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  fontWeight: "400",
                                  color: "#7C7C7C",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  paddingTop: "15px",
                                  paddingBottom: "5px",
                                  textAlign: "justify",
                                }}
                              >
                                {item.about}{" "}
                              </div>
                              <div
                                className="cousine-section-pdf"
                                style={{
                                  display: "flex",
                                }}
                              >
                                {item.preference.length &&
                                  item.preference.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          lineHeight: "16px",
                                          color: "#D8AF49",
                                        }}
                                      >
                                        {(index ? ", " : "") + item}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <div
                              style={{
                                fontWeight: "700",
                                color: "#08316D",
                                fontSize: "16px",
                                lineHeight: "18px",
                                marginTop: "30px",
                              }}
                            >
                              <span>
                                {item.currency
                                  ? getSymbolFromCurrency(item.currency)
                                  : "₹"}

                                {item.price}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div>
                  {AdvanceOrderList.length > 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            color: "#08316D",
                            fontSize: "24px",
                            lineHeight: "28px",
                            paddingTop: "30px",
                          }}
                        >
                          Advanced Order
                        </div>
                      </div>
                      {
                        // AdvanceOrderList.filter((item, index) => index < 2).map(
                        AdvanceOrderList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "64%",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "700",
                                    color: "#08316D",
                                    fontSize: "16px",
                                    lineHeight: "18px",
                                    marginTop: "30px",
                                  }}
                                >
                                  {item.name}
                                  <img
                                    src={
                                      item?.dishCategory === "Non Veg"
                                        ? nonvegIcon
                                        : vegIcon
                                    }
                                    alt=""
                                    style={{
                                      marginLeft: "10px",
                                      width: "13px",
                                      height: "13px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontWeight: "400",
                                    color: "#7C7C7C",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    paddingTop: "15px",
                                    paddingBottom: "5px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {item.about}{" "}
                                </div>
                                <div
                                  className="cousine-section-pdf"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {item.preference.length &&
                                    item.preference.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            fontWeight: "500",
                                            color: "#08316D",
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            color: "#D8AF49",
                                          }}
                                        >
                                          {(index ? ", " : "") + item}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "700",
                                  color: "#08316D",
                                  fontSize: "16px",
                                  lineHeight: "18px",
                                  marginTop: "30px",
                                }}
                              >
                                <span>
                                  {item.currency
                                    ? getSymbolFromCurrency(item.currency)
                                    : "₹"}

                                  {item.price}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      }
                    </>
                  )}
                </div>
              </div>
              <div
                className="footer-print"
                style={{
                  textAlign: "center",
                  paddingTop: "99px",
                  color: "#08316D",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    paddingTop: "99px",
                    color: "#08316D",
                    fontWeight: "600",
                    lineHeight: "20px",
                    textDecoration: "underline",
                  }}
                >
                  www.thecheflane.com
                </span>{" "}
                |{" "}
                <span
                  style={{
                    textAlign: "center",
                    paddingTop: "99px",
                    color: "#08316D",
                    fontWeight: "600",
                    lineHeight: "20px",
                    textDecoration: "underline",
                  }}
                >
                  support@thecheflane.com
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CommonPageLoader />
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMenuWithChef,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ChefPdf);
