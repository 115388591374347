import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { NormalButton, PageIcons } from "component/common";
import { CommonPageLoader } from "component/common/CommonPageLoader";
import ReviewOrderRate from "./ReviewOrderRate";
import { getOneOrderFoodApi, getChefById } from "action/CustomerDetailsAct";
import { history } from "service/helpers";

const TrackOrderClass = ({
  getOneOrderFoodApi,
  trackId,
  getChefById,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [OrderOneOrder, setOneOrder] = useState([]);
  const [ChefBusinnessName, setChefBusinnessName] = useState("");

  //life cycle
  useEffect(() => {
    getOneOrderFoodApiFunction();
  }, []);

  const getOneOrderFoodApiFunction = async () => {
    let id = trackId;
    setIsLoading(true);
    getOneOrderFoodApi(id)
      .then(({ data }) => {
        setOneOrder(data);
        getChefByIdFunction(data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const getChefByIdFunction = async (data) => {
    let id = data.chefId._id;
    getChefById(id)
      .then(({ data }) => {
        if (!!data) {
          setChefBusinnessName(data.businessName);
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <div className="trackorder-container position-relative pt-3 pb-2">
      <div className="container pb-3">
        <div className="pt-4">
          {isLoading ? (
            <CommonPageLoader />
          ) : (
            <>
              <div className="menucard_description ml-5">
                <div className="d-flex">
                  <div className="bitesheading col-lg-12  col-md-12 col-10   line-height-28 fs-24 fw-700 text-primary-color">
                    Track Order
                  </div>
                </div>
                <div className="Order-track mt-3">
                  <p className="fs-16 fw-400 text-grey-4f mb-2">
                    Your Order has been placed successfully with Chef lane{" "}
                    <span class="fw-700 text-gold text-capitalize">
                      {ChefBusinnessName}
                    </span>
                    <br /> Please find the below order number{" "}
                    {OrderOneOrder?.orderId}
                  </p>
                </div>
                <div className="order-items">
                  <p className="fs-16 fw-400 text-grey-4f order-items-num">
                    Order Items -
                    {OrderOneOrder.items?.length > 0 ? (
                      OrderOneOrder.items?.map((item, index) => (
                        <span key={index}>
                          {" "}
                          {item.foodId?.name} X {item.quantity}
                        </span>
                      ))
                    ) : (
                      <div className="text-gold text-center mt-2">
                        No Order Found !!!
                      </div>
                    )}
                  </p>
                </div>
              </div>
              <ReviewOrderRate
                oneOrder={OrderOneOrder}
                trackId={trackId}
                type={type}
              />
              {OrderOneOrder?.deliveryType === "OWN" &&
                (OrderOneOrder?.orderStatus === "PACKED" ||
                  OrderOneOrder?.orderStatus === "DELIVERED") && (
                  <div>
                    <h4 className="step-title fs-16 text-primary-color fw-700">
                      Delivery Partner Name:{" "}
                      <span className="text-black text-capitalize">
                        {OrderOneOrder &&
                          OrderOneOrder?.deliveryPartnerId &&
                          OrderOneOrder?.deliveryPartnerId?.name
                          ? OrderOneOrder?.deliveryPartnerId?.name
                          : "-"}
                      </span>
                    </h4>
                    <h4 className="step-title fs-16 text-primary-color fw-700 mb-3">
                      Delivery Partner Phone Number:{" "}
                      <span className="text-black text-capitalize">
                        {OrderOneOrder &&
                          OrderOneOrder?.deliveryPartnerId &&
                          OrderOneOrder?.deliveryPartnerId?.mobileNumber
                          ? OrderOneOrder?.deliveryPartnerId?.mobileNumber
                          : "-"}
                      </span>
                    </h4>
                  </div>
                )}
              <NormalButton
                viewButton
                label="Back"
                className="px-md-5 px-4 order-track"
                onClick={() => history.push("/index/customerProfile")}
              />
            </>
          )}
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOneOrderFoodApi, getChefById }, dispatch);
};

export const TrackOrderComp = connect(
  null,
  mapDispatchToProps
)(TrackOrderClass);
