import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { ResetpasswordComp } from "component/auth/login/index";
export class ResetPasswordClass extends Component {
  render() {
    return (
      <ResetpasswordComp
        token={new URLSearchParams(this.props.location.search).get("token")}
      />
    );
  }
}
export const ResetPassword = connect(null, null)(ResetPasswordClass);
