import { addQuery } from "service/helperFunctions";
import { ownDeliveryPartner } from "../service/apiVariables";

// list Own Delivery Partner
export const listOwnDeliveryPartner =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      addQuery(body, ownDeliveryPartner.getOwnDelivery)
      return new Promise((resolve, reject) => {
        api({ ...ownDeliveryPartner.getOwnDelivery })
          .then((response) => {
            resolve(response);
            if (!!response.data) {
              Toast({ type: "success", message: response?.message });
            }
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };


// Add Own Partner
export const addDeliveryPartner =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...ownDeliveryPartner.addOwnDelivery, body })
          .then(({ data, message }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };



export const editDeliveryPartner =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {

        api({ ...ownDeliveryPartner.editOwnDelivery, body, api: ownDeliveryPartner.editOwnDelivery.api + body.id })
          .then(({ data, message }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };



export const deleteDeliveryPartner =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery({ id: body.id }, ownDeliveryPartner.deleteOwnDelivery)
        api({ ...ownDeliveryPartner.deleteOwnDelivery, body })
          .then(({ data, message }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };