import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { fileUpload, imageUpload } from "action/FileUpload";
import { handleNameField } from "service/helperFunctions";
import { NormalButton } from "component/common";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deactivateChef, getCusineList } from "action/ChefDetailsAct";
import { createCustomer } from "action/CustomerDetailsAct";
import AddressDetails from "../AddressDetail";
import { logout } from "service/utilities";
import { Toast } from "service/toast";
const regExp = /[a-zA-Z]/g;

const initalState = {
  _id: localStorage.getItem("userId"),
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
  userTypeRole: "customer",
  AddressDetails: {
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    area: "",
    pincode: "",
    location: {
      lat: "",
      long: "",
    },
  },
};
function BasicDetail({
  createCustomer,
  getCusineList,
  active,
  deactivateChef,
  dataFromParent,
  fetchChefDetailsParent,
}) {
  const [showShare, setShowShare] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [formData, setFormData] = useState(initalState);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [deactivateLoader, setdeactivateLoader] = useState(false);

  const [isCopy, setIsCopy] = useState(false);

  const wrapperRef = useRef(null);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  //life cycle
  useEffect(() => {
    if (active) {
      setIsEdit(false);
    }
    if (Object.keys(dataFromParent).length) fetchChefDetails();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [active]);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowShare(false);
      setIsCopy(false);
    }
  };
  const fetchChefDetails = async () => {
    let newForm = Object.assign(formData, dataFromParent);
    // setFormData({ ...newForm })
    setFormData((prevState) => ({
      ...prevState,
      ...newForm,
    }));
    setSelectedCuisineList(newForm.cuisine ? newForm.cuisine : []);
  };

  const submitBasicDetails = async () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      formData["userTypeRole"] = "customer";
      formData["isNewRequest"] = false;
      formData["firstName"] = handleNameField(formData.firstName);
      formData["lastName"] = handleNameField(formData.lastName);
      delete formData["__v"];
      await createCustomer(formData)
        .then(() => {
          setIsLoading(false);
          setIsEdit(false);
          fetchChefDetailsParent();
        })
        .catch(() => setIsLoading(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };
  const handleChange = (e) => {
    let {
      target: { name, value },
    } = e;

    if (name === "countryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        mobileNumber: "",
      }));
    }
  };

  return (
    <>
      <div id="custom-profile-customer">
        <div className="basic_detail_inner_container">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="header">Basic Details</div>
              {!isEdit && (
                <NormalButton
                  primary
                  label="Edit"
                  className="fw-700"
                  disabled={isLoading}
                  onClick={() => setIsEdit(true)}
                />
              )}
            </div>
            <hr></hr>
          </div>

          <div className="body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 d-block d-lg-none">
                <div className="d-flex align-items-start justify-content-end"></div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    autoComplete="off"
                    placeholder={`${isEdit ? "Enter First Name" : ""}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        firstName: value,
                      }));
                    }}
                    value={formData.firstName}
                  />
                  {validator.current.message(
                    "First Name",
                    formData.firstName,
                    "required|min:3|max:20"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6">
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    autoComplete="off"
                    placeholder={`${isEdit ? "Enter Last Name" : ""}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        lastName: value,
                      }));
                    }}
                    value={formData.lastName}
                  />
                  {validator.current.message(
                    "Last Name",
                    formData.lastName,
                    "required|max:20"
                  )}
                </Form.Group>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 d-none d-lg-block">
                <div className="d-flex align-items-start justify-content-end"></div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <Form.Group>
                  <Form.Label>Email ID</Form.Label>
                  <a
                    className=" email text-grey-7c text-underline "
                    href={`mailto:${formData.email}`}
                  >
                    <Form.Control
                      className={!isEdit ? "remove-height cursor-pointer" : ""}
                      disabled={true}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="email"
                      placeholder={`${isEdit ? "Enter Email ID" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          email: value,
                        }));
                      }}
                      value={formData.email}
                    />
                  </a>
                  {validator.current.message(
                    "Email",
                    formData.email,
                    "required|email"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6">
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <div className="d-flex country-mobile custom-number">
                    <div
                      className={`country-code-dropdown ${!isEdit ? "border-block" : ""
                        }`}
                      style={{ border: isEdit ? "1px solid #ced4da" : "" }}
                    >
                      <CountryCodeDropDown
                        onClick={(e) => {
                          handleChange({
                            target: {
                              name: "countryCode",
                              value: e,
                            },
                          });
                        }}
                        value={
                          formData.countryCode === undefined ||
                            formData.countryCode === ""
                            ? "+91"
                            : formData.countryCode
                        }
                        disabled={isEdit === false ? true : false}
                      />
                    </div>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      // disabled={true}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter Mobile Number" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        if (!regExp.test(value)) {
                          setFormData((prevState) => ({
                            ...prevState,
                            mobileNumber: value,
                          }));
                        }
                      }}
                      value={formData.mobileNumber}
                    />
                    {validator.current.message(
                      "Mobile Number",
                      formData.mobileNumber,
                      "required|phone|max:13"
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <AddressDetails
            isEdit={isEdit}
            validator={validator}
            formData={formData}
            setFormData={setFormData}
            updateValidator={updateValidator}
          />
        </div>
        {isEdit && (
          <div className="d-flex mt-3">
            <NormalButton
              label="Cancel"
              className="white-btn mr-3"
              onClick={() => {
                setIsEdit(false);
                fetchChefDetailsParent();
              }}
            />
            <span className="m-2" />

            <NormalButton
              primary
              label="Save"
              className="fw-700"
              disabled={isLoading}
              onClick={() => submitBasicDetails()}
            />
          </div>
        )}

        <Modal
          show={showDeactivate}
          onHide={() => setShowDeactivate(false)}
          backdrop={true}
          keyboard={false}
          className="deactivate-modal"
          centered
        >
          <Modal.Body>
            <div className="alert-deActive mt-5">
              <h4 className="mb-0">
                Are you Sure you want to
                <br /> Delete?
              </h4>
            </div>
            <Modal.Footer>
              <div className="d-flex deactivate-block">
                <NormalButton
                  label="Yes, Delete"
                  className="w-100 white-btn mr-3"
                  disabled={deactivateLoader}
                  onClick={() => {
                    setdeactivateLoader(true);
                    let body = {
                      chefId: localStorage.getItem("userId"),
                      deactivateId: true,
                    };
                    deactivateChef(body)
                      .then((data) => {
                        if (data) {
                          setdeactivateLoader(false);
                          setShowDeactivate(false);
                          Toast({ type: "error", message: "Account is deactivated", toastId: 'success1' });
                          logout();
                        }
                      })
                      .catch(() => {
                        setdeactivateLoader(false);
                        setShowDeactivate(true);
                      });
                  }}
                />
                <span className="m-2" />
                <NormalButton
                  primary
                  label="No, Keep my Account"
                  className="w-100 fw-700"
                  disabled={deactivateLoader}
                  onClick={() => setShowDeactivate(false)}
                />
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createCustomer,
      fileUpload,
      imageUpload,
      getCusineList,
      deactivateChef,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(BasicDetail);
