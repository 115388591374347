import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { extractAddressFields } from "../../../service/helperFunctions";
import "./style.scss";
import { NormalButton } from "component/common/NormalButton";
import currentLocation from "assets/svg/currentLocation.svg";
import { Toast } from "service/toast";

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    let { address } = this.props;
    this.state = {
      address: address ? address : "",
      googleMapsReady: false,
    };
  }

  componentDidMount() {
    this.setState({ googleMapsReady: true });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.address !== prevState.address) {
      this.setState({ address: this.props.address });
    }
  }
  handleChange = (address) => {
    this.setState({ address });
    this.props.handleSelect(address);
  };

  handleSelect = (address, placeId) => {
    this.setState({ address });
    this.props.handleSelect(address);
    if (placeId !== null) {
      geocodeByAddress(address)
        .then((results) => {
          let data = extractAddressFields(results[0].address_components);
          if (this.props.handleAddress) {
            this.props.handleAddress(data);
          }
          getLatLng(results[0]).then((latDetails) =>
            this.props.handleLatLng(latDetails)
          );
        })
        .then((latLng) => console.log("Success", latLng))
        .catch((error) => {
          console.error("Error", error);
        });
    } else if (address !== "") {
      Toast({
        type: "error",
        message: "Please select from drop-down",
      });
    }
  };

  render() {
    let {
      address,
      isMap = false,
      disabled = false,
      handleCurrentLocation,
      getLocationLoader = false,
      handleFindError,
    } = this.props;
    // const searchOptions = {
    //   componentRestrictions: { country: ['in'] },
    //   // radius: 2000,
    //   // types: ['city']
    // }
    return (
      <>
        {isMap === "map" ? (
          <div className="position-for-map">
            <div className="search-auto-location ">
              {this.state.googleMapsReady && (
                <PlacesAutocomplete
                  value={!!address ? address : this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  debounce={1500}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="normal-input-search">
                      <input
                        {...getInputProps({
                          placeholder: "Enter location...",
                          className: "location-search-input",
                        })}
                        className="searchBox pl-3 pr-5 reset-height fs-14"
                        disabled={disabled}
                      />
                      <div className="input-search-icon-map">
                        <i className="icon-search fs-24" />
                      </div>
                      {/* <div
                        style={{ display: 'none' }}
                        className="verify d-flex align-items-center newEdit cursor-pointer"
                        onClick={() => handleCurrentLocation()}
                        title="Current location"
                      >
                        <img
                          src={currentLocation}
                          alt="icons"
                          className={
                            getLocationLoader ? "opacity-5" : "opacity-1"
                          }
                        />
                      </div> */}
                      <div className="autocomplete-dropdown-container px-2 bg-white">
                        {loading && (
                          <div className="text-black py-2">Loading...</div>
                        )}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              borderBottom: "1px sold",
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              borderBottom: "1px sold",
                            };
                          return (
                            <div
                              key={index}
                              className="search_options_map"
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <div className="text-black py-2 px-1 fs-14 border-bottom">
                                {suggestion.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
          </div>
        ) : isMap === "landing" ? (
          <div className="search-auto-location">
            {this.state.googleMapsReady && (
              <PlacesAutocomplete
                value={!!address ? address : this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                debounce={1500}

              // searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="normal-input-landing">
                    <input
                      {...getInputProps({
                        placeholder: "Enter City/Area/State/Country...",
                        className: "location-search-input",
                      })}
                      onKeyPress={this.handleKeypress}
                      className="searchBox pl-3 pr-5"
                      style={{ color: '#01050d' }}
                    />
                    <div className="input-search-icon">
                      <i className="icon-search fs-24" />
                    </div>
                    <div className="verify d-flex align-items-center">
                      <i className="icon-location fs-20 h-auto me-3 d-md-block " />
                      {address === "" ? (
                        <NormalButton
                          className={address === "" ? "no-drop" : ""}
                          // disabled={address === ''}
                          primary
                          label="Find Chef"
                          onClick={() => {
                            // handleFindError()
                          }}
                        />
                      ) : (
                        <NormalButton
                          // disabled={address === ''}
                          primary
                          label="Find Chef"
                          onClick={() => {
                            handleFindError();
                          }}
                        />
                      )}
                    </div>
                    <div className="autocomplete-dropdown-container px-2 bg-white">
                      {loading && (
                        <div className="text-black py-2">Loading...</div>
                      )}
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            key={index}
                            className="search_options_map"
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <div className="text-black py-2 px-1 border-bottom text-start">
                              {suggestion.description}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )}
          </div>
        ) : isMap === "header" ? (
          <div className="">
            <div className="search-auto-location ">
              {this.state.googleMapsReady && (
                <PlacesAutocomplete
                  value={!!address ? address : this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  debounce={1500}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="normal-input-search">
                      <input
                        {...getInputProps({
                          placeholder: "Enter City/Area/State/Country...",
                          className: "location-search-input",
                        })}
                        className="searchBox pl-3 pr-5 reset-height-header fs-14"
                        disabled={disabled}
                      />
                      <div className="input-search-icon-header">
                        <i className="icon-search fs-24" />
                      </div>

                      <div className="autocomplete-dropdown-container px-2 bg-white">
                        {loading && (
                          <div className="text-black py-2">Loading...</div>
                        )}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              // borderBottom: '1px sold',
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              // borderBottom: '1px sold',
                            };
                          return (
                            <div
                              key={index}
                              className="search_options_map "
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <div className="text-black py-2 px-1 fs-14 border-bottom">
                                {suggestion.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
