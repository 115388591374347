import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { history } from "service/helpers";
import { Navbar } from "component/common/Navbar";
import { NormalButton } from "component/common";
import { PageIcons } from "component/common";
import { getOrderDetailsAct } from "action/CustomerDetailsAct";
import { capturePaymentDetails } from "action/OrderReviewAct";

const SuccessSubscriptionClass = ({
  orderId,
  getOrderDetails,
  capturePaymentDetailsApi,
}) => {
  const [orderDetails, setOrderDetails] = useState(null);
  //   const userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  //   const authToken = localStorage.getItem("authToken");
  const getSessionId = JSON.parse(localStorage.getItem("sessionId"));
  const chefId = localStorage.getItem("userId");
  const subscriptionData = JSON.parse(
    localStorage.getItem("subscriptionData")
  );
  const { id: stripeId } = subscriptionData || {};
  const { id } = getSessionId?.data || "";

  const captureFunc = (result) => {
    if (result) {
      let responseData = {
        razorpay_order_id: id || "",
        razorpay_payment_id: stripeId || "",
        razorpay_signature: stripeId || "",
        paymentDate: new Date(),
        chefId: chefId,
      };
      capturePaymentDetailsApi(responseData).then(() =>
        console.log(responseData)
      );
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId).then(({ data }) => setOrderDetails(data));
    }
    captureFunc(subscriptionData);
  }, [orderId, getOrderDetails]);

  return (
    <div className="orderplaced">
      <div className="d-flex w-md-100 h-100 ">
        <div className="col-lg-12 col-12 mx-auto px-3 login-area">
          <div className=" mx-auto col-lg-7 col-12 h-100 ">
            <div className="d-flex align-items-center flex-column w-100">
              <Navbar />
              <>
                <p className="fs-42 fw-700 line-height-48 text-black-33 mt-4 mb-2">
                  Woo Hoo! Subscribed successfully2!
                </p>
              </>
              <div className="col-sm-6 col-12 py-5 mb-5 text-center">
                <NormalButton
                  optionButton
                  label="Back to home"
                  className="fw-700 mt-2 text-primary-color"
                  onClick={() => {
                    localStorage.removeItem("sessionId");
                    localStorage.removeItem("subscriptionData");
                    history.push("/index/activeProfile");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderDetails: getOrderDetailsAct,
      capturePaymentDetailsApi: capturePaymentDetails,
    },
    dispatch
  );
};

let component = SuccessSubscriptionClass;

export const SuccessSubscriptionComp = connect(
  null,
  mapDispatchToProps
)(component);
