import React from "react";
import "./style.scss";

export const PrivacyPolicy = () => {
  return (
    <div className="privacy_container_background ">
      <div className="container privacy_container ">
        <h1 className="text-primary-color line-height-42 fs-31 fw-700 text-center ">
          Privacy Policy
        </h1>
        <div className="first_line_privacy text-black-25 line-height-20 fs-16 fw-400 ">
          <p>
            Welcome to The Chef Lane. Chef Lane Online Services Private Limited ("us", "we", or "our")
            operates The Chef Lane website (herein after referred to as the
            "Service"). Our Privacy Policy explains how we collect, use,
            disclose, and protect information that applies to our Service, and
            your choices about the collection and use of your information. This
            policy sets out how The Chef Lane collects and uses the information
            that we collect about you when you use The Chef Lane services. This
            policy also explains the choices that you can make about the way
            that we use your information.
          </p>
          <p>
            <b className="subheading">Definitions</b>
          </p>

          <ul className="line-height-24">
            <li>
              <b>Personal Data:</b> Personal Data means data about a living
              individual who can be identified from those data.
            </li>
            <li>
              <b>Usage Data:</b> Usage Data is data collected automatically
              either generated by the use of the Service or from the Service
              infrastructure itself.
            </li>
            <li>
              <b>Cookies :</b> Cookies are small pieces stored on your device
              (computer or mobile device).
            </li>
          </ul>
          <p>Information we collect and its use</p>
          <p>We collect the following types of information about you:</p>
          <p>Information you provide us directly</p>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include Email
            Address, Phone Number, Business Name, First and Last Name, Cookies
            and Usage Data.
          </p>
          <p>
            {" "}
            <b className="subheading">Usage Data</b>
          </p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access the Service by or through a
            mobile device ("Usage Data"). This Usage Data may include
            information such as your computer's Internet Protocol address (e.g.,
            IP address), browser type, browser version, the pages of our Service
            that you visit, the time and date of your visit, the time spent on
            those pages, unique device identifiers and other diagnostic data.
            When you access the Service with a mobile device, this Usage Data
            may include information such as the type of mobile device you use,
            your mobile device unique ID, the IP address of your mobile device,
            your mobile operating system, the type of mobile Internet browser
            you use, unique device identifiers and other diagnostic data.
          </p>
          <p>
            <b className="subheading">Cookies Information </b>
          </p>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information. Cookies are
            files with a small amount of data which may include an anonymous
            unique identifier. Cookies are sent to your browser from a website
            and stored on your device. Other tracking technologies are also used
            such as beacons, tags and scripts to collect and track information
            and to improve and analyse our Service. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service.
          </p>
          <p>
            <b className="subheading">Use of Data</b>
          </p>
          <p>Chef Lane Online Services Private Limited uses the collected data for various purposes:</p>

          <ul className="line-height-24">
            <li>To provide and maintain our Service.</li>
            <li>To notify you about changes to our Service.</li>
            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>To provide customer support.</li>
            <li>
              To gather analysis or valuable information so that we can improve
              our Service.
            </li>
            <li>To monitor the usage of our Service.</li>
            <li>To detect, prevent and address technical issues.</li>
            <li>
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information.
            </li>
          </ul>
          <p>
            <b className="subheading">Retention of Data</b>
          </p>
          <p>
            Chef Lane Online Services Private Limited will retain your Personal Data only for as long as
            is necessary for the purposes set out in this Privacy Policy. We
            will retain and use your Personal Data to the extent necessary to
            comply with our legal obligations (for example, if we are required
            to retain your data to comply with applicable laws), resolve
            disputes and enforce our legal agreements and policies.
          </p>
          <p>
            Chef Lane Online Services Private Limited will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of our Service, or we are legally
            obligated to retain this data for longer time periods.
          </p>
          <p>
            <b className="subheading">Transfer of Data</b>
          </p>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction. If you
            are located outside Tamilnadu, India and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to Tamilnadu, India and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Chef Lane Online Services Private Limited will take all the steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>
          <p>Who you may choose to share your User Content with</p>
          <p>
            If Chef Lane Online Services Private Limited is involved in a merger, acquisition or asset
            sale, your Personal Data may be transferred. We will provide notice
            before your Personal Data is transferred and becomes subject to a
            different Privacy Policy.
          </p>
          <p>Disclosure for Law Enforcement</p>
          <p>
            Under certain circumstances, Chef Lane Online Services Private Limited may be required to
            disclose your Content if required to do so by law or in response to
            valid requests by public authorities (e.g., a court or a government
            agency).
          </p>
          <p>
            <b className="subheading">Legal Requirements</b>
          </p>
          <p>
            Chef Lane Online Services Private Limited may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <ul className="line-height-24">
            <li>To comply with a legal obligation.</li>
            <li>
              {" "}
              To protect and defend the rights or property of Chef Lane Online Services Private Limited.
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service.
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public.
            </li>
            <li>To protect against legal liability.</li>
            <li>To monitor the usage of our Service.</li>
          </ul>
          <p>
            <b className="subheading">Security of Data</b>
          </p>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority.
          </p>
          <p>
            <b className="subheading">Service Providers</b>
          </p>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), provide the Service on our
            behalf, perform Service-related services or assist us in analysing
            how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <p>
            <b className="subheading">Analytics</b>
          </p>
          <p>
            We may use third-party Service Providers to monitor and analyse the
            use of our Service.
          </p>
          <ul className="line-height-24">
            <li>
              Google Analytics: Google Analytics is a web analytics service
              offered by Google that tracks and reports website traffic. Google
              uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google
              may use the collected data to contextualise and personalise the
              ads of its own advertising network. You can opt-out of having made
              your activity on the Service available to Google Analytics by
              installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js, and
              dc.js) from sharing information with Google Analytics about visits
              activity.
            </li>
          </ul>
          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy & Terms web page:
            <a
              href=" https://policies.google.com/privacy?hl=en"
              className="text-primary-color cursor-pointer "
              target="_blank"
            >
              https://policies.google.com/privacy?hl=en
            </a>
          </p>
          <p>
            <b className="subheading">Children's Privacy</b>
          </p>
          <p>
            The Chef Lane does not knowingly collect or solicit personal
            information from children under the age of 18 and the Service and
            its content are not directed at children under the age of 18. In the
            event that we learn that we have collected personal information from
            a child under age 18 without verification of parental consent, we
            will delete that information as quickly as possible. If you believe
            that we might have any information from or about a child under 18.
            Please contact us at{" "}
            <a
              className="text-primary-color mail cursor-pointe "
              href="mailto: support@thecheflane.com"
            >
              {" "}
              <b> support@thecheflane.com </b>
            </a>
          </p>
          <p>How long we keep your User Content</p>
          <p>
            Following termination or deactivation of your User account, The Chef
            Lane may retain your profile information and User Content for a
            commercially reasonable time, and for as long as we have a valid
            purpose to do so. In particular, The Chef Lane may retain your
            information for the purpose of comply with its legal and audit
            obligations, and for backup and archival purposes.
          </p>
          <p>
            Disclosure Text for Inclusion in Privacy Policy We may disclose to
            third party services certain personally identifiable information
            listed below:
          </p>
          <ul className="line-height-24">
            <li>
              information you provide us such as name, email, mobile phone
              number.
            </li>
            <li>
              information we collect as you access and use our service,
              including device information, location and network carrier.
            </li>
          </ul>
          <p>
            This information is shared with third party service providers so
            that we can:
          </p>

          <ul className="line-height-24">
            <li>personalise the app for you.</li>
            <li>perform behavioural analytics.</li>
          </ul>
          <p>
            <b className="subheading">Any Updates to this Privacy Policy</b>
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy. Please review
            this Privacy Policy periodically for any changes. Changes to this
            Privacy Policy are effective when they are posted on this page.
          </p>
          <p>
            <b className="subheading">Contact Us</b>
          </p>
          <p>
            For any questions about this Privacy Policy, please send an email to{" "}
            <a
              className="text-primary-color mail cursor-pointer "
              href="mailto: support@thecheflane.com"
            >
              {" "}
              <b> support@thecheflane.com </b>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export const PrivacyPolicyComp = PrivacyPolicy;
