import React from "react";
// Redux Connection
import ChefPdf from "component/common/ChefPdf";

export function ChefPdfPage({
  match: {
    params: { chefId, type },
  },
}) {
  // render() {
  return <ChefPdf chefId={chefId} type={type} />;
  // }
}
