import React, { useState, useEffect, useRef  } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NormalButton, NormalRadio } from "component/common";
import { TableWrapper } from "component/common/TableWrapper";
import { CommonTableLoader } from "component/common/CommonTableLoader";
import { NormalSearch } from "component/common/NormalSearch";
import { getSNo } from "service/helperFunctions";
import { listOwnDeliveryPartner, addDeliveryPartner, editDeliveryPartner, deleteDeliveryPartner } from "action/ownPartnerAct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form"
import SimpleReactValidator from "simple-react-validator";
import { faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";

function OwnPartner({ listOwnDeliveryPartner, addDeliveryPartner, editDeliveryPartner, deleteDeliveryPartner }) {

  const [pageMeta, setPageMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  
  const [isLoading, setIsLoading] = useState(false);
  const [ownDeliveryList, setOwnDeliveryList] = useState([]);
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState('')
  const [formData, setFormData] = useState({ phoneNumber: '', name: '', status: false });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const headerDetails = [
    { label: "S.No" },
    { label: "Name" },
    { label: "Phone" },
    // { label: "Email" },
    { label: "Status" },
    // { label: "Available" },
    { label: "Action" },
  ];


  //handle Input Search
  const handleInputSearch = ({ target: { value } }) => {
    setSearchValue(value);
  };
 

  useEffect(() => {
    getOwnpartnerList();
  }, [page, searchValue]);

  const handleEdit = (id) => {
    setEditId(id);
    const data = ownDeliveryList.find(datum => datum._id == id)
    setFormData({ name: data.name, phoneNumber: data.mobileNumber, status: data.status });
    setShow(true)
  }

  //new Request Api Call Func
  const getOwnpartnerList = async (id) => {
    setIsLoading(true);
    let body = {
      chefId: localStorage.getItem("userId"),
      page,
      search: searchValue,
      status: ""
    };
    listOwnDeliveryPartner(body)
      .then(({ list, pageMeta }) => {
        setIsLoading(false);
        const filteredList = list.filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setOwnDeliveryList(filteredList);
        setPageMeta(pageMeta);
      })
      .catch(() => setIsLoading(false));
  };

  const handleClose = () => {
    setFormData({ name: '', phoneNumber: '' })
    setEditId('')
    validator.current.hideMessages();
    setShow(false);
  }

  const handlePartner = () => {
    if (validator.current.allValid()) {
      const requestPayload = {
        chefId: localStorage.getItem("userId"),
        name: formData.name,
        mobileNumber: formData.phoneNumber,
        status: formData.status
      }

      const actionMethod = !!editId ? editDeliveryPartner : addDeliveryPartner

      if (!!editId) {
        requestPayload.id = editId
      }
      actionMethod({ ...requestPayload, })
        .then(() => {
          getOwnpartnerList();
          handleClose()
          // window.location.reload();
        })
        .catch(() => setShow(true));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        name: prevState.name,
      }));
      validator.current.showMessages();
      // window.location.reload();
    }
  }

  const handleDeleteConfirm = (id) => {
    setShowConfirm(true)
    setDeleteId(id)
  }

  const handleDelete = () => {
    const requestPayload = { id: deleteId }
    deleteDeliveryPartner(requestPayload)
      .then(() => {
        getOwnpartnerList();
        setShow(false);
        setShowConfirm(false)
        setDeleteId('')
      })
      .catch(() => setShow(false));
  }

  const handleClosePopupDeleteMenu = () => {
    setShowConfirm(false)
    setDeleteId('')
  }

  return (
    <>
      <div className="rate_detail_inner_container">
        <div>
          <div className="d-flex align-items-center justify-content-between title-rateing">
            <div className="header">Own Delivery Partners</div>
            <div className="tab-search my-3 d-flex justify-content-between">
              <NormalSearch
                placeholder="Search"
                value={searchValue}
                name="searchValue"
                onChange={handleInputSearch}
              />
              <NormalButton
                primary
                label="Add Partner"
                className="fw-700 mx-3"
                onClick={() => setShow(true)}
              />
            </div>
          </div>
          <hr></hr>
          <TableWrapper
            headerDetails={headerDetails}
            pageMeta={pageMeta}
            handlePageChange={setPage}
            sortActiveKey="name"
          >
            {isLoading ? (
              <CommonTableLoader tdLength={6} />
            ) : ownDeliveryList && ownDeliveryList.length !== 0 ? (
              ownDeliveryList.map((item, index) => {
                const {
                  mobileNumber = "",
                  name = "",
                  status,
                  _id,
                  availableStatus
                } = item;
                return (
                  <tr key={index}>
                    <td>{getSNo(pageMeta ? pageMeta.page : 1, 10, index)}</td>
                    <td>{name ? name : "-"}</td>
                    <td>{mobileNumber ? mobileNumber : "-"}</td>
                    {/* <td>{email ? email : "-"}</td> */}
                    <td>{status ? "Active" : "Inactive"}</td>
                    {/* <td>{availableStatus ? "Yes" : "No"}</td> */}
                    <td>
                      <div className="d-flex align-items-center justify-content-between w-50 mr-auto">
                        <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(_id)} className="cursor-pointer" />
                        <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteConfirm(_id)} className="cursor-pointer" />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <p className="py-5 fs-20 text-center text-gold-d8 line-height-23 fw-700">
                    No records found !!!
                  </p>
                </td>
              </tr>
            )}
          </TableWrapper>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header className="bg-primary-color rounded-top">
          <Modal.Title className="text-white">
            {editId === "" ? "Add Partner" : "Edit Partner"}
          </Modal.Title>
          <span className="cursor-pointer" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} style={{ color: "#ffffff", }} />
          </span>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#F0F2F5" }}>
          <div className="row">
            <div className="col-12">
              <Form.Group>
                <Form.Label>
                  Name<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Partner Name"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;

                    setFormData((prevState) => ({
                      ...prevState,
                      name: value,
                    }));
                  }}
                  value={formData.name}
                />

                {validator.current.message(
                  "Name",
                  formData.name,
                  "required|min:3"
                )}
              </Form.Group>
            </div>

            <div className="col-12">
              <Form.Group>
                <Form.Label>
                  Phone Number<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Partner PhoneNumber"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;

                    setFormData((prevState) => ({
                      ...prevState,
                      phoneNumber: value,
                    }));
                  }}
                  value={formData.phoneNumber}
                />
                {validator.current.message(
                  "PhoneNumber",
                  formData.phoneNumber,
                  "required|min:10|max:10"
                )}
              </Form.Group>
            </div>
{/* 
            <div className="col-12">
              <Form.Group>
                <Form.Label>
                  Email ID<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter Email ID"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    setFormData((prevState) => ({
                      ...prevState,
                      email: value,
                    }));
                  }}
                  value={formData.email}
                />
                {validator.current.message(
                  "Email",
                  formData.email,
                  "required|email"
                )}
              </Form.Group>
            </div> */}

            {editId &&
              <div className="d-flex flex-wrap">
                <span className="mx-2">
                  <NormalRadio
                    className=" border-0 rounded-0"
                    label="Active"
                    name="OWN"
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        status: true
                      }));
                    }}
                    checked={formData.status}
                  />
                </span>
                <span className="mx-4">
                  <NormalRadio
                    className=" border-0 rounded-0"
                    label="Inactive"
                    name="PLATFORM"
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        status: false
                      }));
                    }}
                    checked={!formData.status}
                  />
                </span>
              </div>
            }
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex mt-3">
            <NormalButton
              label="Cancel"
              className="white-btn mr-3"
              disabled={isLoading}
              onClick={() => handleClose()}
            />
            <span className="m-2" />

            <NormalButton
              primary
              label={editId === "" ? "Save" : "Update"}
              className=""
              disabled={isLoading}
              onClick={() => handlePartner()}
            />
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirm}
        onHide={handleClosePopupDeleteMenu}
        backdrop="static"
        keyboard={false}
        className="address-popup"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" text-center fw-700 fs-20">
          Are you Sure you want to Delete?
        </Modal.Body>
        <Modal.Footer>
          <NormalButton
            whiteBtn
            label={"Yes, Delete"}
            className="create-order-btn"
            onClick={() => { handleDelete() }}
          />
          <NormalButton
            primary
            label="No"
            onClick={handleClosePopupDeleteMenu}
            className="create-order-btn border-btn"
          />
        </Modal.Footer>
      </Modal>

    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ listOwnDeliveryPartner, addDeliveryPartner, editDeliveryPartner, deleteDeliveryPartner }, dispatch);
};

export default connect(null, mapDispatchToProps)(OwnPartner);