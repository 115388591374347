import React, { useState } from "react";
import "./style.scss";
import { NormalButton, NormalInput } from "component/common";
import ErrorComponent from "component/common/ErrorComponent";
import validate from "service/validation";
import { validationRules } from "./validate";
function Deliverydetails() {
  const [error, setErrors] = useState({});
  const [deliveryDetails, setDeliveryDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    addressState: "",
    addressMobile: "",
    addressOne: "",
    addressTwo: "",
    addressCity: "",
    addressPincode: "",
  });

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };

  //handle Input Change
  const handleInputChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);

    setDeliveryDetails((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };

  //handle Submit
  const handleSubmit = () => {
    let body = { ...deliveryDetails };
    if (!validateFields(body)) return;
  };

  return (
    <div className="cart_container delivery-details py-md-5">
      <div className="container">
        <div className="mx-md-5">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-12">
              <h4>Customer details</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-10 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="First Name"
                    name="firstName"
                    placeholder="Enter first name"
                    value={deliveryDetails.firstName}
                    onChange={handleInputChange}
                  />
                  {error.firstName && (
                    <ErrorComponent message={error.firstName[0]} />
                  )}
                </div>
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="Last Name"
                    name="lastName"
                    placeholder="Enter last name"
                    value={deliveryDetails.lastName}
                    onChange={handleInputChange}
                  />
                  {error.lastName && (
                    <ErrorComponent message={error.lastName[0]} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <NormalInput
                    type="number"
                    label="Mobile Number"
                    name="addressMobile"
                    placeholder="Enter Number"
                    value={deliveryDetails.addressMobile}
                    onChange={handleInputChange}
                  />
                  {error.addressMobile && (
                    <ErrorComponent message={error.addressMobile[0]} />
                  )}
                </div>
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="Email"
                    name="email"
                    placeholder="Enter email"
                    value={deliveryDetails.email}
                    onChange={handleInputChange}
                  />
                  {error.email && <ErrorComponent message={error.email[0]} />}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="Country"
                    name="country"
                    placeholder="Enter country"
                    value={deliveryDetails.country}
                    onChange={handleInputChange}
                  />
                  {error.country && (
                    <ErrorComponent message={error.country[0]} />
                  )}
                </div>
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="State"
                    name="addressState"
                    placeholder="Enter addressState"
                    value={deliveryDetails.addressState}
                    onChange={handleInputChange}
                  />
                  {error.addressState && (
                    <ErrorComponent message={error.addressState[0]} />
                  )}
                </div>
              </div>
              <div className="col-12 mt-3 pt-1">
                <NormalInput
                  label="Address 1"
                  name="addressOne"
                  placeholder="Enter address"
                  value={deliveryDetails.addressOne}
                  onChange={handleInputChange}
                />
                {error.addressOne && (
                  <ErrorComponent message={error.addressOne[0]} />
                )}
              </div>
              <div className="col-12">
                <NormalInput
                  label="Address 2"
                  name="addressTwo"
                  placeholder="Enter address"
                  value={deliveryDetails.addressTwo}
                  onChange={handleInputChange}
                />
                {error.addressTwo && (
                  <ErrorComponent message={error.addressTwo[0]} />
                )}
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="City"
                    name="addressCity"
                    placeholder="Enter addressCity"
                    value={deliveryDetails.addressCity}
                    onChange={handleInputChange}
                  />
                  {error.addressCity && (
                    <ErrorComponent message={error.addressCity[0]} />
                  )}
                </div>
                <div className="col-md-6 col-12">
                  <NormalInput
                    label="Pincode"
                    name="addressPincode"
                    placeholder="Enter pincode"
                    value={deliveryDetails.addressPincode}
                    onChange={handleInputChange}
                  />
                  {error.addressPincode && (
                    <ErrorComponent message={error.addressPincode[0]} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 text-center">
              <NormalButton
                primary
                label="Proceed to order summary"
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Deliverydetails;
