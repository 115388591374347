import React from 'react';
import ReactPaginate from 'react-paginate';

const Paginations = ({ postsPerPage, totalPosts, paginate ,itemOffset}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination-area flex-column">
            <div className="d-flex text-center w-100 justify-content-center pr-4">
                <p className="m-0 d-flex align-items-center">
        <ReactPaginate
            previousLabel={"Previous"}
            pageRangeDisplayed={postsPerPage}
            nextLabel={"Next"}
            pageCount={pageNumbers.length}
            onPageChange={paginate}
            containerClassName={"pagination"}
            previousLinkClassName={"btn btn-effect "}
            nextLinkClassName={" btn-effect "}
            disabledClassName={"disabled"}
            activeClassName={"current-page"}
            renderOnZeroPageCount={null}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            nextClassName="page-item"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            forcePage={itemOffset}
        />
                </p>
            </div>
        </div>

    );
};

export default Paginations;
