import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { CancelSubscriptionComp } from 'component/CancelSubscription'

export class CancelSubscriptionClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props
    return <CancelSubscriptionComp orderId={params.orderId} />
  }
}
export const CancelSubscription = connect(null, null)(CancelSubscriptionClass)
