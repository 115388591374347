import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import "./styles.scss";
import { Form, InputGroup, Dropdown } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import { fileUpload, imageUpload } from "action/FileUpload";
import plus from "../../../assets/images/plus.png";
import shareYellow from "../../../assets/images/shareYellow.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { convertToSlug, handleNameField } from "service/helperFunctions";
import { Toast } from "service/toast";
import ErrorComponent from "component/common/ErrorComponent";
import { validationRules } from "./validate";
import validate from "service/validation";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
import {
  NormalButton,
  NormalToggleSwitch,
  NormalCustomCheckbox,
  NormalDate,
  NormalRadio,
  CommonPageLoader,
  ImageCropComponent,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  chefEditUpdate,
  getSingle,
  deleteChef,
  getCusineList,
  getChefPdf,
  getSinglePending,
  checkDeliveryPartnerAvailablity,
  businessTypeList,
} from "action/ChefDetailsAct";
import facebook from "assets/images/facebook.png";

import Instagram from "assets/images/Instagram.png";
import closeGray from "assets/images/closeGray.png";
import AddressDetails from "../AddressDetail";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import copyIcon from "assets/images/copyIcon.png";
import moment from "moment";
import { FRONT_END_PORT } from "utils/constants";
import { logout } from "service/utilities";
import { useCallback } from "react";
// import Dropdown from 'react-bootstrap/Dropdown';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const regExp = /[a-zA-Z]/g;

const initalState = {
  _id: localStorage.getItem("userId"),
  firstName: "",
  lastName: "",
  businessName: "",
  businessType: "",
  mobileNumber: "",
  whatsappNumber: "",
  sameAsMobileNumber: false,
  deliveryPartners: "OWN",
  email: "",
  chefImage: "",
  availabilityStatus: false,
  licenseExpiryDate: null,
  AboutDetails: {
    About: "",
    adminApproved: false,
    isEdit: false,
  },
  cuisine: [],
  LicenseDetails: {
    licenseNumber: "",
    licenseExpiryDate: null,
    licenseFileUrl: "",
    adminApproved: false,
    isEdit: false,
  },
  bankDetails: {
    accountNumber: "",
    ifsc: "",
    accountName: "",
    re_enter_accountNumber: "",
    adminApproved: false,
    isEdit: false,
    isNewRequest: false,
  },
  facebook: {
    facebookLink: "",
    adminApproved: false,
    isEdit: false,
  },
  instagram: {
    instagramLink: "",
    adminApproved: false,
    isEdit: false,
  },
  AddressDetails: {
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    area: "",
    pincode: "",
    location: {
      lat: "",
      long: "",
    },
  },
};
function BasicDetail({
  chefEditUpdate,
  getCusineList,
  getSingle,
  active,
  fileUpload,
  imageUpload,
  deleteChef,
  dataFromParent,
  fetchChefDetailsParent,
  getChefPdf,
  businessTypeList,
  getSinglePending,
  checkDeliveryPartnerAvailablity,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);

  const [isFormEdited, setIsFormEdited] = useState(false);

  const [formData, setFormData] = useState(initalState);
  const [renderCuisnineList, setrenderCusinieList] = useState([]);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);
  const [cuisineList, setcuisineList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const reference = useRef();
  const referenceFile = useRef();
  const [selectedImage, setSelectedImage] = useState();
  const [croppingImage, setCroppingImage] = useState();
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [isuploading, setIsuploading] = useState(false);
  const [deactivateLoader, setdeactivateLoader] = useState(false);
  const [showPlatformAlert, setShowPlatformAlert] = useState(false);
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const [printModel, setPrintModel] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isPendingItems, setIsPendingItems] = useState(false);

  const [businessTypes, setBusinessTypes] = useState([]);

  const [errorFacebook, setErrorFacebook] = useState(true);
  const [errorInstagram, setErrorInstagram] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [autoApprove, setAutoApprove] = useState(false);
  const [isChanged, setIsChanged] = useState({
    num: false,
    file: false,
    date: false,
    ifsc: false,
    accntnum: false,
    reaccntnum: false,
    acntname: false,
  });
  const [errors, setErrors] = useState({
    accountNumber: "",
    re_enter_accountNumber: "",
  });

  const [formEditted, setFormEditted] = useState(false);

  const wrapperRef = useRef(null);
  const componentRef = useRef();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const handleAccountNumberChange = (e) => {
    const { value } = e.target;
    let errorMessage = "";

    if (!/^\d*$/.test(value)) {
      errorMessage = "Only numeric characters are allowed.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      accountNumber: errorMessage,
    }));

    if (!errorMessage) {
      let bankDetails = { ...formData.bankDetails, accountNumber: value };
      setFormData((prevState) => ({
        ...prevState,
        bankDetails,
        isEdit: true,
        status: "PENDING",
      }));
    }
  };

  const handleReEnterAccountNumberChange = (e) => {
    const { value } = e.target;
    let errorMessage = "";

    if (!/^\d*$/.test(value)) {
      errorMessage = "Only numeric characters are allowed.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      re_enter_accountNumber: errorMessage,
    }));

    if (!errorMessage) {
      let bankDetails = {
        ...formData.bankDetails,
        re_enter_accountNumber: value,
      };
      setFormData((prevState) => ({
        ...prevState,
        bankDetails,
        isEdit: true,
        status: "PENDING",
      }));
    }
  };

  const handleClose = () => {
    setSearch("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const checkActiveStatusCall = () => {
    if (submit) {
      getSingle(formData?._id).then((response) => {
        let { data } = response;
        localStorage.setItem("userId", data._id);
        if (data.deactivateId) {
          Toast({
            type: "error",
            message: "Account is deactivated",
            toastId: "success1",
          });
          logout();
        }
      });
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
  }, []);



  const fetchBusinessTypes = async () => {
    businessTypeList().then(res => {
      setBusinessTypes(res?.data)
    }).catch()
  };

  const checkActiveStatus = useCallback(() => {
    getSingle(localStorage.getItem("userId")).then((response) => {
      let { data } = response;
      setAutoApprove(data?.autoApprove);
      if (data.deactivateId) {
        Toast({
          type: "error",
          message: "Account is deactivated",
          toastId: "success1",
        });
        logout();
      }
    });
  }, [isEdit, submit]);

  useEffect(() => {
    checkActiveStatus();
  }, [checkActiveStatus]);

  useEffect(() => {
    if (active) {
      setIsEdit(false);
    }
    if (Object.keys(dataFromParent).length) {
      fetchChefDetails();
      fetchCusineList();
    }
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [active]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowShare(false);
      setIsCopy(false);
    }
  };

  const fetchChefDetails = async () => {
    let newForm;
    let changes = {};
    newForm = Object.assign(formData, dataFromParent);
    setFormData((prevState) => ({
      ...prevState,
      ...newForm,
    }));
    setSelectedCuisineList(newForm.cuisine ? newForm.cuisine : []);
    getSinglePending(formData?._id).then(({ data }) => {
      setDatas(data)
      // newForm = Object.assign(formData, data);
      // setFormData((prevState) => ({
      //   ...prevState,
      //   ...newForm,
      // }));
      // localStorage.setItem('cashOnDelivery', formData?.cashOnDelivery)
      // alert(JSON.stringify(data?.isEdit))
            if (
        data?.isEdit &&
        data?.LicenseDetails?.licenseNumber !==
        formData.LicenseDetails.licenseNumber 
              ) {
        changes.num = true;
        
      }
      if (
        data?.isEdit &&
        data?.LicenseDetails?.licenseFileUrl !==
        formData.LicenseDetails.licenseFileUrl 
              ) {
        changes.file = true;
      }
      if (
        data?.isEdit &&
        (data?.LicenseDetails?.licenseExpiryDate) !==
      (formData.LicenseDetails.licenseExpiryDate)  
            &&  (!!data?.LicenseDetails?.licenseExpiryDate) 
      ) {
                changes.date = true;
      }

      if (
        data?.isEdit &&
        data?.bankDetails?.ifsc !== formData.bankDetails.ifsc 
              ) {
        changes.ifsc = true;
      }
      if (
        data?.isEdit &&
        data?.bankDetails?.accountNumber !== formData.bankDetails.accountNumber 
              ) {
        changes.accntnum = true;
      }
      if (
        data?.isEdit &&
        data?.bankDetails?.re_enter_accountNumber !==
        formData.bankDetails.re_enter_accountNumber 
              ) {
        changes.reaccntnum = true;
      }
      if (
        data?.isEdit &&
        data?.bankDetails?.accountName !== formData.bankDetails.accountName 
              ) {
        changes.acntname = true;
      }
      if (Object.keys(changes).length > 0) {
        setIsChanged(changes);
      }
          });
  };

  const handleDownloadPdf = async (id) => {
    setIsDownload(true);
    await getChefPdf(id)
      .then((data) => {
        if (data.link) {
          var file_path = data.link;
          var a = document.createElement("A");
          a.href = file_path;
          a.target = "_blank";
          a.download = file_path.substr(file_path);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        setIsDownload(false);
      })
      .catch(() => setIsDownload(false));
  };

  //add get Cusion List
  const fetchCusineList = async () => {
    setIsLoading(true);
    await getCusineList()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.length) {
          setcuisineList(data);
          // setFormData({ ...newForm });
          // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleBeforeImageCropping = (event) => {
    let file = event.target.files[0];
    if (file) {
      setCroppingImage(file);
      event.target.value = "";
      setShowImageCrop(true);
    }
  };

  const handleFileUpload = async (event, name = "chefImage") => {
    let keySet = name === "chefImage" ? setIsuploading : setIsDocumentuploading;
    var data = new FormData();
    let file = {};
    file = name === "chefImage" ? event : event.target.files[0];

    setSelectedImage(file);
    let api = name === "chefImage" ? imageUpload : fileUpload;

    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      if (name === "chefImage")
        Toast({
          type: "error",
          message: "Please upload image file that is 5MB or less",
        });
      else
        Toast({
          type: "error",
          message: "Please upload document file that is 5MB or less",
        });
    } else {
      keySet(true);
      await api(data)
        .then((data) => {
          if (name === "chefImage") {
            setFormData((prevState) => ({
              ...prevState,
              [name]: data?.data?.url,
            }));
          } else {
            let { LicenseDetails } = { ...formData };
            LicenseDetails.licenseFileUrl = data?.data?.url;
            LicenseDetails.isEdit = true;
            LicenseDetails.adminApproved = null;
            setIsFormEdited = true;
            setFormData((prevState) => ({
              ...prevState,
              LicenseDetails,
              isEdit: true,
              status: "PENDING",
            }));
          }
          keySet(false);
        })
        .catch(() => keySet(false));
    }
  };

  const mapCusine = () => {
    setShow(false);
    setrenderCusinieList(selectedCuisineList);
  };

  const handleSelectedCuisine = (e) => {
    if (e?.target?.value) {
      let value = e?.target?.name;
      setSelectedCuisineList([...selectedCuisineList, value]);
    } else {
      setSelectedCuisineList(
        selectedCuisineList.filter((item) => item !== e.target.name)
      );
    }
    // let { cuisineDetails } = { ...formData }
    // cuisineDetails.isEdit = true
    // setFormData({ ...formData, cuisineDetails })
  };

  const isMandatoryFieldsChanged = () => {
    // Check if any of the fields have changed
    return (
      datas?.LicenseDetails?.licenseNumber !== formData.LicenseDetails.licenseNumber ||
      datas?.LicenseDetails?.licenseFileUrl !== formData.LicenseDetails.licenseFileUrl ||
      datas?.LicenseDetails?.licenseExpiryDate !== formData.LicenseDetails.licenseExpiryDate ||
      datas?.bankDetails?.ifsc !== formData.bankDetails.ifsc ||
      datas?.bankDetails?.accountNumber !== formData.bankDetails.accountNumber ||
      datas?.bankDetails?.re_enter_accountNumber !== formData.bankDetails.re_enter_accountNumber ||
      datas?.bankDetails?.accountName !== formData.bankDetails.accountName ||
      datas?.AboutDetails?.About !== formData?.AboutDetails?.About
    );
  };

  const isOptionalFieldsChanged = () => {
    return (
      datas?.firstName !== formData.firstName ||
      datas?.lastName !== formData.lastName ||
      datas?.businessName !== formData.businessName ||
      datas?.mobileNumber !== formData.mobileNumber ||
      datas?.whatsappNumber !== formData.whatsappNumber ||
      datas?.businessType !== formData.businessType ||
      datas?.email !== formData.email
    );
  };

  
  const submitBasicDetails = async () => {
    
    if (isMandatoryFieldsChanged() || isOptionalFieldsChanged() ) {
      setSubmit(true);

      
    
    // setFormEditted(true)

    const accountNumberValidationMessage = validateAccountNumbersMatch();
    if (accountNumberValidationMessage !== true) {
      Toast({
        type: "error",
        message: accountNumberValidationMessage,
      });
      setSubmit(false);
      return;
    }

    // let licenseExpiryDate = new Date(formData?.LicenseDetails?.licenseExpiryDate);
    // licenseExpiryDate.setDate(licenseExpiryDate?.getDate() + 1);
    // if (!!licenseExpiryDate) {
    //   formData.LicenseDetails.licenseExpiryDate = licenseExpiryDate?.toISOString() || null;
    // }

    let licenseExpiryDateString = formData?.LicenseDetails?.licenseExpiryDate;

    if (licenseExpiryDateString) {
      let licenseExpiryDate = new Date(licenseExpiryDateString);

      if (!isNaN(licenseExpiryDate.getTime())) {
        // Normalize to midnight UTC
        let normalizedDate = new Date(
          Date.UTC(
            licenseExpiryDate.getUTCFullYear(),
            licenseExpiryDate.getUTCMonth(),
            licenseExpiryDate.getUTCDate(),
            0,
            0,
            0,
            0
          )
        );

        // Convert to ISO string with time zone information
        formData.LicenseDetails.licenseExpiryDate =
          normalizedDate.toISOString();
      } else {
        console.error("Invalid date:", licenseExpiryDateString);
        formData.LicenseDetails.licenseExpiryDate = null; // or handle the error as needed
      }
    } else {
      console.error("License expiry date is missing");
      formData.LicenseDetails.licenseExpiryDate = null; // or handle the error as needed
    }

    let flag =
      formData.countryCode !== "+91"
        ? validator.current.allValid()
        : validator?.current?.allValid() &&
        validatorLicence?.current?.allValid();
    let socialMediaFlag = true;
    let socialMediaFlagInsta = true;
    if (
      formData.facebook.facebookLink !== "" &&
      formData.facebook.facebookLink !== undefined
    ) {
      setErrorFacebook(isUrlValid(formData.facebook.facebookLink));
      socialMediaFlag = isUrlValid(formData.facebook.facebookLink);
    }
    if (
      formData.instagram.instagramLink !== "" &&
      formData.instagram.instagramLink !== undefined
    ) {
      setErrorInstagram(isUrlValid(formData.instagram.instagramLink));
      socialMediaFlagInsta = isUrlValid(formData.instagram.instagramLink);
    }

    if (flag && socialMediaFlag && socialMediaFlagInsta) {
      setIsLoading(true);
      // let { cuisineDetails } = { ...formData }
      formData.cuisine = selectedCuisineList;
      formData["masterChefId"] = localStorage.getItem("userId");
      formData["isNewRequest"] = false;
      formData["firstName"] = handleNameField(formData.firstName);
      formData["lastName"] = handleNameField(formData.lastName);
      delete formData["_id"];
      delete formData["__v"];
      let isAlert = formData.isEdit;
      Object.assign(formData, { autoApprove: autoApprove });

           await chefEditUpdate(formData)
        .then((data) => {
          if (isAlert && autoApprove === false) {
            setIsPendingItems(true);
          } else {
            setIsLoading(false);
            setIsEdit(false);
            scrollTop();
            fetchChefDetailsParent();
            // window.location.reload();
          }
          // setIsEditAdmin(false)
          // handleFormChange("addressDetails");
        })
        .catch(() => setIsLoading(false));
    } else {
      if (formData.countryCode === "+91") {
        validatorLicence.current.showMessages();
        scrollTop("FoodLicense");
      }
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please complete all required fields.",
      });
      if (formData.countryCode === "+91" && validatorLicence.current.allValid())
        scrollTop();
      if (formData.countryCode !== "+91") scrollTop();
    }
  }
  else{
    Toast({
      type: "error",
      message: "No changes made, no need to update",
    });

  }
    };

  const handleSearch = (e) => {
    let {
      target: { value },
    } = e;
    setSearch(value);
  };
  const handleCopyText = (copyText) => {
    navigator.clipboard.writeText(copyText);
    setIsCopy(false);
  };

  const scrollTop = (key = "custom-profile") => {
    let element = document.getElementById(key);
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const handleChange = (e) => {
    let {
      target: { name, value },
    } = e;

    if (name === "countryCode") {
      if (formData.sameAsMobileNumber) {
        setFormData((prevState) => ({
          ...prevState,
          sameAsMobileNumber: e.target.value,
          whatsappNumber: formData.mobileNumber,
          whatsappCountryCode: value,
        }));
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        mobileNumber: "",
      }));
    }

    if (name === "whatsappCountryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        //whatsappNumber: formData.mobileNumber,
        whatsappNumber: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (formData.sameAsMobileNumber && name === "mobileNumber") {
      setFormData((prevState) => ({
        ...prevState,
        whatsappNumber: value,
      }));
    }
  };

  const changeDeliveryPartners = async (value) => {
    if (value === "OWN") {
      setFormData((prevState) => ({
        ...prevState,
        deliveryPartners: value,
        cashOnDelivery: false,
      }));
    } else {
      let {
        AddressDetails: {
          location: { lat, long },
        },
      } = formData;

      const requestObject = { lat, long };

      await checkDeliveryPartnerAvailablity(requestObject).then(async () => {
        setFormData((prevState) => ({
          ...prevState,
          deliveryPartners: value,
        }));
      });
    }
  };

  const validateAccountNumbersMatch = () => {
    if (
      formData.bankDetails.accountNumber &&
      formData.bankDetails.re_enter_accountNumber
    ) {
      if (
        formData.bankDetails.accountNumber !==
        formData.bankDetails.re_enter_accountNumber
      ) {
        return "The account number and re-enter account number must be same.";
      }
    }
    return true;
  };

  return (
    <>
      {active === "address" ? (
        <AddressDetails formData={formData} setFormData={setFormData} />
      ) : (
        <div id="custom-profile">
          <div className="basic_detail_inner_container">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="header">Basic Details</div>
                {!isEdit && (
                  <NormalButton
                    primary
                    label="Edit"
                    className="fw-700"
                    disabled={isLoading}
                    onClick={() => {
                      checkActiveStatusCall();
                      setIsEdit(true);
                    }}
                  />
                )}
              </div>
              <hr></hr>
            </div>
            <div className="profile_pic_container d-flex justify-content-between">
              <div className="d-flex profil-contain">
                <div className="profile_pic flex-column">
                  <div className="box">
                    {isuploading ? (
                      <CommonPageLoader />
                    ) : (
                      <>
                        {!!formData.chefImage ? (
                          <img src={formData.chefImage} alt="Thumb" />
                        ) : (
                          <span className="d-flex">
                            <img src={userUpload} alt="upload" />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {isEdit && (
                    <div className="upload_btn">
                      <input
                        title=""
                        id="chefImage"
                        ref={reference}
                        onChange={(e) => handleBeforeImageCropping(e)}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        className="d-none"
                      />

                      <NormalButton
                        label=" Upload Image"
                        className="w-100 fw-700 outline-gray-btn"
                        onClick={() => {
                          reference.current.click();
                        }}
                        disabled={isuploading}
                      />
                      {isEdit && (
                        <p className="fs-12 alert-for-upload-profile mb-0 mt-2">
                          <sup className="text-danger fs-14">*</sup>
                          Please upload image file that is 5MB or less
                        </p>
                      )}
                      {validator.current.message(
                        "Image",
                        formData.chefImage,
                        "required"
                      )}
                    </div>
                  )}
                </div>
                <div className="availability mb-3">
                  <div>
                    <Form.Label className="mb-3">Availability </Form.Label>
                    <div className="d-flex align-items-center">
                      <NormalToggleSwitch
                        name="availability "
                        onChange={(e) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            availabilityStatus: e.target.checked,
                          }))
                        }
                        checked={formData.availabilityStatus}
                        disabled={!isEdit}
                      />
                      <div className="check-value">
                        {formData.availabilityStatus ? "YES" : "NO"}
                      </div>
                    </div>
                  </div>
                  <div className="share-buttons">
                    {!isEdit && (
                      <div ref={wrapperRef} className="position-relative">
                        <span>
                          <NormalButton
                            label="Share"
                            className="w-100 fw-500 outline-gray-btn"
                            onClick={() => {
                              setShowShare(!showShare);
                              setIsCopy(false);
                            }}
                            prefix={shareYellow}
                          />
                        </span>
                        {showShare && (
                          <div className="share-position">
                            <NormalButton
                              disabled={isDownload}
                              label="as .pdf"
                              className="w-100 fw-500 outline-gray-btn"
                              onClick={() => {
                                handleDownloadPdf(formData._id);
                              }}
                            />
                            <div className="">
                              <NormalButton
                                label="Copy link"
                                className="w-100 fw-500 outline-gray-btn"
                                onClick={() => {
                                  handleCopyText(
                                    `${FRONT_END_PORT}${convertToSlug(
                                      formData.businessNameSlug
                                    )}`
                                  );
                                  setShowShare(false);
                                }}
                              />
                              {isCopy && (
                                <div className="position-copy">
                                  <p className="mb-0 text-gold">
                                    doodlebluelive.com:2013/
                                    {formData.businessName}
                                  </p>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleCopyText(
                                        `${FRONT_END_PORT}${convertToSlug(
                                          formData.businessNameSlug
                                        )}`
                                      );
                                    }}
                                  >
                                    <img src={copyIcon} alt="icon" />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {/* {!isEdit && (
                  <div className="mt-4">
                    <span>
                      <NormalButton
                        label="Delete Profile "
                        className="w-100 fw-700 outline-gray-btn"
                        onClick={() => setShowDeactivate(true)}
                      />
                    </span>
                  </div>
                )} */}
              </div>
            </div>
            {/* {formData.cashOnDelivery && !isEdit && (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <span className="label-txt fs-16 fw-600">
                    * Cash on Delivery Allowed
                  </span>
                </div>
              </div>
            )} */}
            <div className="body">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>First Name{isEdit && <sup>*</sup>}</Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter First Name" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          firstName: value,
                        }));
                      }}
                      value={formData.firstName}
                    />
                    {validator.current.message(
                      "First Name",
                      formData.firstName,
                      "required|min:3|max:20"
                    )}
                  </Form.Group>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>Last Name{isEdit && <sup>*</sup>}</Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter Last Name" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          lastName: value,
                        }));
                      }}
                      value={formData.lastName}
                    />
                    {validator.current.message(
                      "Last Name",
                      formData.lastName,
                      "required|max:20"
                    )}
                  </Form.Group>
                </div>
                {/* <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                  {(isEdit && formData.deliveryPartners === "PLATFORM") ||
                  (isEdit && formData.deliveryPartners === "OWN") ? (
                    <NormalCustomCheckbox
                      name="cashOnDelivery"
                      checked={formData.cashOnDelivery}
                      value={formData.cashOnDelivery}
                      onChange={() =>
                        setFormData((prevState) => ({
                          ...prevState,
                          cashOnDelivery: !formData.cashOnDelivery,
                        }))
                      }
                      label="Allow Cash on Delivery"
                    />
                  ) : (
                    ""
                  )}
                </div> */}
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>
                      Brand Name/ Business Name{isEdit && <sup>*</sup>}
                    </Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter Brand Name" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          businessName: value,
                        }));
                      }}
                      value={formData.businessName}
                    />
                    {validator.current.message(
                      "Business Name",
                      formData.businessName,
                      "required|min:3|max:50"
                    )}
                  </Form.Group>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>
                      Mobile Number{isEdit && <sup>*</sup>}
                    </Form.Label>
                    <div
                      className={
                        isEdit
                          ? "d-flex country-mobile "
                          : "d-flex country-mobile hide-box"
                      }
                    >
                      <div className="country-code-dropdown">
                        <CountryCodeDropDown
                          value={
                            formData.countryCode === undefined ||
                              formData.countryCode === ""
                              ? "+91"
                              : formData.countryCode
                          }
                          readOnly={isEdit ? false : true}
                          plaintext={isEdit ? false : true}
                          disabled={true}
                        />
                      </div>
                      <Form.Control
                        className={!isEdit ? "remove-height" : ""}
                        disabled={true}
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="text"
                        autoComplete="off"
                        placeholder={`${isEdit ? "Enter Mobile Number" : ""}`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          if (!regExp.test(value)) {
                            setFormData((prevState) => ({
                              ...prevState,
                              mobileNumber: value,
                            }));
                          }
                        }}
                        value={formData.mobileNumber}
                      />
                      {validator.current.message(
                        "Mobile Number",
                        formData.mobileNumber,
                        "required"
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>Email ID{isEdit && <sup>*</sup>}</Form.Label>
                    <a
                      className="email text-grey-7c text-underline"
                      href={`mailto:${formData.email}`}
                    >
                      <Form.Control
                        className={
                          !isEdit ? "remove-height cursor-pointer" : ""
                        }
                        disabled={true}
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="email"
                        placeholder={`${isEdit ? "Enter Email ID" : ""}`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          setFormData((prevState) => ({
                            ...prevState,
                            email: value,
                          }));
                        }}
                        value={formData.email}
                      />
                    </a>
                    {validator.current.message(
                      "Email",
                      formData.email,
                      "required|email"
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>
                      Whatsapp Number
                      {isEdit && <sup>*</sup>}
                    </Form.Label>
                    <div
                      className={
                        isEdit
                          ? "d-flex country-mobile flex-column"
                          : "d-flex country-mobile hide-box"
                      }
                    >
                      {!isEdit && (
                        <p className="d-flex fs-16 mb-0 align-items-center fw-600 me-1">
                          {" "}
                          {formData.whatsappCountryCode}
                        </p>
                      )}
                      <div className="d-flex">
                        {isEdit && (
                          <div className="country-code-dropdown">
                            {formData.whatsappNumber === "" && !isEdit ? (
                              ""
                            ) : (
                              <CountryCodeDropDown
                                onClick={(e) => {
                                  handleChange({
                                    target: {
                                      name: "whatsappCountryCode",
                                      value: e,
                                    },
                                  });
                                }}
                                value={
                                  formData?.whatsappCountryCode
                                    ? formData?.whatsappCountryCode
                                    : "+91"
                                }
                                disabled={formData.sameAsMobileNumber}
                              />
                            )}
                          </div>
                        )}
                        <Form.Control
                          className={!isEdit ? "remove-height" : ""}
                          disabled={formData.sameAsMobileNumber}
                          plaintext={isEdit ? false : true}
                          readOnly={isEdit ? false : true}
                          type="number"
                          autoComplete="nope"
                          placeholder={`${isEdit ? "Enter Number" : ""}`}
                          onChange={(e) => {
                            let {
                              target: { value },
                            } = e;
                            if (!regExp.test(value)) {
                              setFormData((prevState) => ({
                                ...prevState,
                                whatsappNumber: value,
                              }));
                            }
                          }}
                          value={formData.whatsappNumber}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                      </div>
                      <div>
                        {validator.current.message(
                          "Whatsapp Number",
                          formData.whatsappNumber,
                          "required|min:10|max:10"
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </div>
                {isEdit && (
                  <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center">
                    <Form.Group className="mb-0">
                      <NormalCustomCheckbox
                        name="sameAsMobileNumber"
                        checked={formData.sameAsMobileNumber}
                        value={formData.sameAsMobileNumber}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          if (value) {
                            setFormData((prevState) => ({
                              ...prevState,
                              sameAsMobileNumber: e.target.value,
                              whatsappNumber: formData.mobileNumber,
                            }));
                          } else {
                            setFormData((prevState) => ({
                              ...prevState,
                              sameAsMobileNumber: e.target.value,
                              whatsappNumber: "",
                            }));
                          }
                        }}
                        label={"Same as mobile number"}
                      />
                    </Form.Group>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>
                      Business Type{isEdit && <sup>*</sup>}
                    </Form.Label>

                    {isEdit ? (
                      <InputGroup>
                        <Form.Control
                          className={`${!isEdit ? "border-0 bg-transparent" : ""
                            } businessType-color`}
                          id="dropdown-basic"
                          plaintext={isEdit ? false : true}
                          readOnly={isEdit ? false : true}
                          type="text"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={formData.businessType}
                          placeholder="Enter Business Type"
                          onToggle={(isOpen) => setDropdownOpen(isOpen)}
                        />

                        <Dropdown
                          onToggle={(isOpen) => setDropdownOpen(isOpen)}
                        >
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                            className="border-0"
                          >
                            {dropdownOpen ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowUp />
                            )}
                          </Dropdown.Toggle>
                          {businessTypes.length > 0 && (
                            <Dropdown.Menu className="business-dropdown-menu ">
                              {businessTypes.map((type, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      businessType: type.name,
                                    });
                                    setDropdownOpen(false); // Close dropdown after selection
                                  }}
                                >
                                  {type.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </InputGroup>
                    ) : (
                      <div className="fw-700">{formData.businessType}</div>
                    )}

                    {/* {isEdit ? <InputGroup className="bg-none" >
                      <Dropdown
                        className={!isEdit ? "remove-height" : ""}
                        id="dropdown-basic"
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="text"
                        autoComplete="off"
                        onToggle={(isOpen) => setDropdownOpen(isOpen)}
                      >
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="border-0"
                        >
                          {formData.businessType || (isEdit ? "Enter Business Type" : "")}
                          {dropdownOpen ? <MdKeyboardArrowUp className="arrow-icon" /> : <MdKeyboardArrowUp />}

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setFormData({ ...formData, businessType: 'World Tour' })}>
                            World Tour
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setFormData({ ...formData, businessType: 'Share Market' })}>
                            Share Market
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setFormData({ ...formData, businessType: 'Technology' })}>
                            Technology
                          </Dropdown.Item>

                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup> : <div>
                      {formData.businessType}
                    </div>
                    } */}

                    {validator.current.message(
                      "Business Name",
                      formData.businessType,
                      "required|min:3|max:50"
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-10">
                  <Form.Group>
                    <Form.Label>About{isEdit && <sup>*</sup>}</Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height w-100" : "w-100"}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "About you in 500 characters or less" : ""
                        }`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        let { AboutDetails } = { ...formData };
                        AboutDetails.About = value;
                        AboutDetails.isEdit = true;
                        AboutDetails.adminApproved = null;
                        setFormData((prevState) => ({
                          ...prevState,
                          AboutDetails,
                          isEdit: true,
                          status: "PENDING",
                        }));
                      }}
                      value={formData.AboutDetails.About}
                    />
                    {validator.current.message(
                      "About me",
                      formData.AboutDetails.About,
                      "required|min:10|max:500"
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="cusine-blocks">
                <Form.Label className="mb-0">
                  Cuisine{isEdit && <sup>*</sup>}
                </Form.Label>
                {isEdit ? (
                  <div className="cuisine_list">
                    <span className="button-width">
                      <NormalButton
                        label="Add Cuisine"
                        className="w-100 fw-700 outline-gray-btn"
                        onClick={handleShow}
                        prefix={plus}
                      />
                      {validator.current.message(
                        "Cuisine",
                        selectedCuisineList,
                        "required"
                      )}
                    </span>
                    {selectedCuisineList?.map((item) => (
                      <div className="cusine_item">
                        {item}&ensp;
                        <b
                          className="rmv_cuisine_item"
                          onClick={() => {
                            setSelectedCuisineList(
                              selectedCuisineList.filter(
                                (items) => items !== item
                              )
                            );
                            setrenderCusinieList(selectedCuisineList);
                          }}
                        >
                          &#10006;
                        </b>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="mt-0">
                    {selectedCuisineList?.map((item, index) => (
                      <span className="fw-700 text-black-25" key={index}>
                        {(index ? ", " : "") + item}
                      </span>
                    ))}
                  </div>
                )}
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop={true}
                  keyboard={false}
                >
                  <Modal.Header>
                    <Modal.Title>
                      <b>Select Cuisine</b>
                    </Modal.Title>
                    <span className="cursor-pointer" onClick={handleClose}>
                      <img src={closeBtn} alt="icon" />
                    </span>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="model_body">
                      <div className="search-outer">
                        <div className="input-group  search-input-group">
                          <input
                            type="text"
                            id="searchValue"
                            name="searchValue"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                            className="form-control border-right-0 searchInput"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append ">
                            <span
                              className="input-group-text bg-white border-left-0 cursor-pointer"
                              onClick={() => setSearch("")}
                            >
                              <img
                                className="mailIcon"
                                src={closeGray}
                                alt="search"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="list-body">
                        <div className="row">
                          {cuisineList
                            .filter((item) =>
                              search === ""
                                ? item
                                : String(item)
                                  .toLowerCase()
                                  .match(search.toLowerCase())
                            )
                            .map((item) => {
                              return (
                                <div className="col-6 mb-2">
                                  <NormalCustomCheckbox
                                    name={item}
                                    checked={selectedCuisineList.includes(item)}
                                    value={selectedCuisineList.includes(item)}
                                    onChange={(e) => handleSelectedCuisine(e)}
                                    label={item}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="pt-0">
                    <div className="d-flex justify-content-start w-100">
                      <NormalButton
                        label="Discard"
                        className="white-btn mr-3"
                        onClick={() => {
                          setSelectedCuisineList([]);
                          setShow(false);
                          setSearch("");
                        }}
                      />
                      <span className="m-2" />

                      <NormalButton
                        primary
                        label="Save"
                        className="fw-700"
                        disabled={isLoading}
                        onClick={() => {
                          mapCusine();
                          setSearch("");
                        }}
                      />
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            {/* <br />
            <div className="row">
              <div className="col-sm-12 col-md-11">
                <Form.Group className="mb-0">
                  <Form.Label className="mb-0">
                    Delivery Partners{isEdit && <sup>*</sup>}
                  </Form.Label>
                  {isEdit ? (
                    <div className="d-flex flex-wrap">
                      <span className="mx-2">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label="Own delivery partners"
                          name="OWN"
                          value={formData.deliveryPartners === "OWN"}
                          onChange={(e) =>
                            changeDeliveryPartners(e.target.name)
                          }
                          checked={formData.deliveryPartners === "OWN"}
                          disabled={false}
                        />
                      </span>
                      <span className="mx-4">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label="Platform delivery partners"
                          name="PLATFORM"
                          value={formData.deliveryPartners === "PLATFORM"}
                          onChange={(e) =>
                            changeDeliveryPartners(e.target.name)
                          }
                          checked={formData.deliveryPartners === "PLATFORM"}
                        />
                      </span>
                    </div>
                  ) : (
                    <p className="fw-700 text-black-25 mb-0">
                      {formData.deliveryPartners === "OWN"
                        ? "Own delivery partners"
                        : "Use platform delivery partners"}
                    </p>
                  )}

                  {validator.current.message(
                    "Delivery Partnersmm",
                    formData.deliveryPartners,
                    "required"
                  )}
                </Form.Group>
              </div>
            </div> */}
            <br />
            {/* {formData.deliveryPartners === "OWN" && (
              <div className="row">
                <div className="col-sm-12 col-md-11">
                  <Form.Group className="mb-0">
                    <Form.Label className="mb-0">Delivery Charge</Form.Label>
                    {isEdit ? (
                      <div className="d-flex flex-wrap">
                        <span className="mx-2">
                          <Form.Control
                            className={!isEdit ? "remove-height" : ""}
                            plaintext={isEdit ? false : true}
                            readOnly={isEdit ? false : true}
                            type="text"
                            placeholder={`${isEdit ? "Enter Amount" : "-"}`}
                            onChange={(e) => {
                              let {
                                target: { value },
                              } = e;
                              if (
                                (!value ||
                                  value.match(/^\d{1,}(\.\d{0,4})?$/)) &&
                                value.length < 5
                              ) {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  deliveryCharge: value,
                                }));
                              }
                            }}
                            value={formData.deliveryCharge}
                          />
                        </span>
                      </div>
                    ) : (
                      <p className="fw-700 text-black-25 mb-0">
                        {formData.deliveryCharge}
                      </p>
                    )}
                  </Form.Group>
                </div>
              </div>
            )} */}

            {/* {(isEdit && formData.deliveryPartners !== "OWN") && (
              <div className="row">
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>
                      Name<sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Partner Name"
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;

                        setFormData((prevState) => ({
                          ...prevState,
                          name: value,
                        }));
                      }}
                      value={formData.name}
                    />

                    {validator.current.message(
                      "Name",
                      formData.name,
                      "required|min:3"
                    )}
                  </Form.Group>
                </div>

                <div className="col-12">
                  <Form.Group>
                    <Form.Label>
                      Phone Number<sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Partner PhoneNumber"
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;

                        setFormData((prevState) => ({
                          ...prevState,
                          phoneNumber: value,
                        }));
                      }}
                      value={formData.phoneNumber}
                    />
                    {validator.current.message(
                      "PhoneNumber",
                      formData.phoneNumber,
                      "required|min:10|max:10"
                    )}
                  </Form.Group>
                </div>
              </div>
            )} */}
          </div>

          <div className="basic_detail_inner_container mt-4">
            {/* <div className="header">
              <div>About me</div>
              <hr></hr>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-10">
                {isEdit ? (
                  <>
                    <Form.Group>
                      <Form.Label className="mb-0">
                        About me{isEdit && <sup>*</sup>}
                      </Form.Label>
                      <Form.Control
                        className={!isEdit ? "remove-height w-100" : "w-100"}
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="text"
                        placeholder={`${isEdit ? "About you in 500 characters or less" : ""
                          }`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AboutDetails } = { ...formData };
                          AboutDetails.About = value;
                          AboutDetails.isEdit = true;
                          AboutDetails.adminApproved = null;
                          setFormData((prevState) => ({
                            ...prevState,
                            AboutDetails,
                            isEdit: true,
                            status: "PENDING",
                          }));
                        }}
                        value={formData.AboutDetails.About}
                      />
                      {validator.current.message(
                        "About me",
                        formData.AboutDetails.About,
                        "required|min:10|max:500"
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <>
                    {formData.AboutDetails.About.length < 80 ? (
                      formData.AboutDetails.About || "-"
                    ) : (
                      <React.Fragment>
                        {formData.AboutDetails.About.substring(0, 79)}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={
                            <Tooltip>{formData.AboutDetails.About}</Tooltip>
                          }
                          containerPadding={20}
                        >
                          <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </>
                )}
              </div>
            </div> */}
            <br></br>
            <div className="header" id="FoodLicense">
              <div>Food License</div>
              <hr></hr>
            </div>
            <div className="row">
                           {
                            
                // formData?.LicenseDetails.isEdit === "true" ?
                !isEdit && 
                (isChanged.num || isChanged.file || isChanged.date) &&
                !autoApprove && datas?.LicenseDetails?.adminApproved !== "REJECTED" && <p>Waiting for approval..!</p>
                // : ""
              }
              <div
                className="col-sm-6 col-md-5 col-lg-4"
                id={
                  // formData?.service === "LISTING PLUS" ?
                  !isEdit &&
                  (isChanged.num || isChanged.file || isChanged.date) &&
                  // (formData.LicenseDetails.licenseNumber === "" || formData.LicenseDetails.licenseFileUrl === "" || formData.LicenseDetails.licenseExpiryDate === "") &&
                  !autoApprove && datas?.LicenseDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                  // : ""
                }
              >
                <Form.Group>
                  <Form.Label className="mb-0">
                    License Number
                    {isEdit && formData.service === "LISTING PLUS" && (
                      <sup>*</sup>
                    )}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${isEdit ? "Enter License Number" : "-"}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { LicenseDetails } = { ...formData };
                      LicenseDetails.licenseNumber = value;
                      LicenseDetails.isEdit = true;
                      LicenseDetails.adminApproved = null;
                      setFormData((prevState) => ({
                        ...prevState,
                        LicenseDetails,
                        isEdit: true,
                        status: "PENDING",
                      }));
                    }}
                    value={formData.LicenseDetails.licenseNumber}
                  />
                  {formData.service === "LISTING PLUS" &&
                    validator.current.message(
                      "License Number",
                      formData.LicenseDetails.licenseNumber,
                      "required|max:20"
                    )}
                </Form.Group>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-6"
                id={
                  // formData?.service === "LISTING PLUS" ?
                  !isEdit &&
                  (isChanged.num || isChanged.file || isChanged.date) &&
                  // (formData.LicenseDetails.licenseNumber === "" || formData.LicenseDetails.licenseFileUrl === "" || formData.LicenseDetails.licenseExpiryDate === "") &&
                  !autoApprove && datas?.LicenseDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                  // : ""
                }
              >
                <div className="custom-file-upload">
                  <Form.Group>
                    <Form.Label className="mb-0">
                      License File
                      {isEdit && formData.service === "LISTING PLUS" && (
                        <sup>*</sup>
                      )}
                    </Form.Label>
                    {isEdit ? (
                      <div className="position-relative">
                        <Form.Control
                          // className={!isEdit ? "remove-height" : ""}
                          disabled={isDocumentuploading}
                          type="file"
                          ref={referenceFile}
                          placeholder="Enter..."
                          className="custom-edited-upload"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            e.target.files[0] &&
                              handleFileUpload(e, "licenseFile");
                          }}
                        />
                        <p className="fileNAme text-black-25">
                          {isDocumentuploading ? (
                            "loading..."
                          ) : (
                            <>
                              {formData.LicenseDetails.licenseFileUrl
                                ? formData.LicenseDetails.licenseFileUrl
                                  .split("/")
                                  .pop()
                                  .split("--")
                                  .pop()
                                : ""}
                            </>
                          )}
                        </p>
                        <NormalButton
                          label="Upload"
                          className="fw-700 fs-14 file-btn"
                          disabled={isDocumentuploading}
                          onClick={() => referenceFile.current.click()}
                        />
                        {isEdit && (
                          <p className="fs-12 alert-for-upload mb-0">
                            Please upload file that is 5MB or less
                          </p>
                        )}
                      </div>
                    ) : (
                      <p
                        className={`${!isEdit &&
                          (isChanged.num || isChanged.file || isChanged.date) &&
                          !autoApprove &&
                          "disableLiscenceLnk"
                          } fw-700`}
                      >
                        {formData.LicenseDetails.licenseFileUrl ? (
                          <a
                            href={formData.LicenseDetails.licenseFileUrl}
                            download
                            target="_blank"
                          >
                            {formData.LicenseDetails.licenseFileUrl
                              .split("/")
                              .pop()
                              .split("--")
                              .pop()}
                          </a>
                        ) : (
                          "-"
                        )}
                      </p>
                    )}
                    {formData.service === "LISTING PLUS" &&
                      validator.current.message(
                        "License File",
                        formData.LicenseDetails.licenseFileUrl,
                        "required"
                      )}
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-6 col-md-5 col-lg-4"
                id={
                  // formData?.service === "LISTING PLUS" ?
                  !isEdit &&
                  (isChanged.num || isChanged.file || isChanged.date) &&
                  !autoApprove && datas?.LicenseDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                  // : ""
                }
              >
                <Form.Group>
                  <Form.Label className="mb-0">
                    License Expiry Date
                    {isEdit && formData.service === "LISTING PLUS" && (
                      <sup>*</sup>
                    )}
                  </Form.Label>
                  {formData.LicenseDetails?.licenseExpiryDate ? (
                    <NormalDate
                      isReadOnly={!isEdit}
                      placeholder="Enter Date"
                      name="licenseExpiryDate"
                      showYearDropdown={true}
                      value={
                        formData.LicenseDetails.licenseExpiryDate &&
                        new Date(formData.LicenseDetails.licenseExpiryDate)
                      }
                      isIcon={false}
                      minDate={new Date(moment().add(10, "days"))}
                      className="form-control mini-date"
                      onChange={(e) => {
                        let { LicenseDetails } = { ...formData };
                        LicenseDetails.licenseExpiryDate = e.target.value;
                        LicenseDetails.isEdit = true;
                        LicenseDetails.adminApproved = null;

                        setFormData((prevState) => ({
                          ...prevState,
                          LicenseDetails,
                          isEdit: true,
                          status: "PENDING",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {isEdit ? (
                        <NormalDate
                          isReadOnly={!isEdit}
                          placeholder="Enter Date"
                          name="licenseExpiryDate"
                          showYearDropdown={true}
                          value={
                            formData.LicenseDetails.licenseExpiryDate &&
                            new Date(formData.LicenseDetails.licenseExpiryDate)
                          }
                          isIcon={false}
                          minDate={new Date(moment().add(10, "days"))}
                          className="form-control mini-date"
                          onChange={(e) => {
                            let { LicenseDetails } = { ...formData };
                            LicenseDetails.licenseExpiryDate = e.target.value;
                            LicenseDetails.isEdit = true;
                            LicenseDetails.adminApproved = null;

                            setFormData((prevState) => ({
                              ...prevState,
                              LicenseDetails,
                              isEdit: true,
                              status: "PENDING",
                            }));
                          }}
                        />
                      ) : (
                        <p className="fw-700 text-black-25">-</p>
                      )}
                    </>
                  )}
                  {formData.service === "LISTING PLUS" &&
                    validator.current.message(
                      "License Expiry Date",
                      formData.LicenseDetails.licenseExpiryDate,
                      "required"
                    )}
                </Form.Group>
              </div>
            </div>

            <div className="header" id="bankDetails">
              <div>Bank Details</div>
              <hr></hr>
            </div>
            <div className="row">
              {!isEdit &&
                (isChanged.ifsc ||
                  isChanged.accntnum ||
                  isChanged.reaccntnum ||
                  isChanged.acntname) &&
                !autoApprove && datas?.bankDetails?.adminApproved !== "REJECTED" && <p>Waiting for approval..!</p>}
              <div
                className="col-sm-6 col-md-5 col-lg-4"
                id={
                  !isEdit &&
                  (isChanged.ifsc ||
                    isChanged.accntnum ||
                    isChanged.reaccntnum ||
                    isChanged.acntname) &&
                  !autoApprove && datas?.bankDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                }
              >
                <Form.Group>
                  <Form.Label className="mb-0">
                    Bank IFSC Code
                    {isEdit && <sup>*</sup>}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${isEdit ? "Enter IFSC Code" : "-"}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { bankDetails } = { ...formData };
                      if (value === bankDetails.ifsc) {
                        // If the value hasn't changed, show a toaster and skip the API call
                        Toast.info("No changes made to IFSC Code");
                        return; // Prevent further execution
                      }
                      bankDetails.ifsc = value;
                      bankDetails.isEdit = true;
                      isChanged.num = true;
                      bankDetails.adminApproved = null;
                      setFormData((prevState) => ({
                        ...prevState,
                        bankDetails,
                        isEdit: true,
                        status: "PENDING",
                      }));
                    }}
                    value={formData.bankDetails.ifsc}
                  />
                  {validator.current.message(
                    "IFSC Code",
                    formData.bankDetails.ifsc,
                    "required|alpha_num|min:11|max:11"
                  )}
                </Form.Group>
              </div>
              <div
                className="col-sm-6 col-md-5 col-lg-4"
                id={
                  !isEdit &&
                  (isChanged.ifsc ||
                    isChanged.accntnum ||
                    isChanged.reaccntnum ||
                    isChanged.acntname) &&
                  !autoApprove && datas?.bankDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                }
              >
                {/* <div className="custom-file-upload"> */}
                <Form.Group>
                  <Form.Label className="mb-0">
                    Account Number
                    {isEdit && <sup>*</sup>}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${isEdit ? "Enter the Account Number" : "-"}`}
                    onChange={handleAccountNumberChange}
                    // onChange={(e) => {
                    //   let {
                    //     target: { value },
                    //   } = e;
                    //   let { bankDetails } = { ...formData };
                    //   bankDetails.accountNumber = value;
                    //   bankDetails.isEdit = true;
                    //   bankDetails.adminApproved = null;
                    //   setFormData((prevState) => ({
                    //     ...prevState,
                    //     bankDetails,
                    //     isEdit: true,
                    //     status: "PENDING",
                    //   }));
                    // }}
                    value={formData.bankDetails.accountNumber}
                  />
                  {errors.accountNumber && (
                    <span className="error-message fs-12">
                      {errors.accountNumber}
                    </span>
                  )}
                  {validator.current.message(
                    "Account Number",
                    formData.bankDetails.accountNumber,
                    "required|min:8|max:17|numeric"
                  )}
                </Form.Group>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-6 col-md-5 col-lg-4"
                id={
                  !isEdit &&
                  (isChanged.ifsc ||
                    isChanged.accntnum ||
                    isChanged.reaccntnum ||
                    isChanged.acntname) &&
                  !autoApprove && datas?.bankDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                }
              >
                <Form.Group>
                  <Form.Label className="mb-0">
                    Re-enter Account Number
                    {isEdit && <sup>*</sup>}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${isEdit ? "ReEnter the Account Number" : "-"
                      }`}
                    onChange={handleReEnterAccountNumberChange}
                    // onChange={(e) => {
                    //   let {
                    //     target: { value },
                    //   } = e;
                    //   let { bankDetails } = { ...formData };
                    //   bankDetails.re_enter_accountNumber = value;
                    //   bankDetails.isEdit = true;
                    //   bankDetails.adminApproved = null;
                    //   setFormData((prevState) => ({
                    //     ...prevState,
                    //     bankDetails,
                    //     isEdit: true,
                    //     status: "PENDING",
                    //   }));
                    // }}
                    value={formData.bankDetails.re_enter_accountNumber}
                  />
                  {errors.re_enter_accountNumber && (
                    <span className="error-message fs-12">
                      {errors.re_enter_accountNumber}
                    </span>
                  )}
                  {validator.current.message(
                    "ReEnter the Account Number",
                    formData.bankDetails.re_enter_accountNumber,
                    "required|min:8|max:17|numeric|"
                  )}
                </Form.Group>
                {formData.service === "LISTING PLUS" && (
                  <div className="text-danger fs-12">
                    {
                      // validator.current.message
                      validateAccountNumbersMatch() !== true &&
                      validateAccountNumbersMatch()
                    }
                  </div>
                )}
              </div>
              <div
                className="col-sm-6 col-md-5 col-lg-4"
                id={
                  !isEdit &&
                  (isChanged.ifsc ||
                    isChanged.accntnum ||
                    isChanged.reaccntnum ||
                    isChanged.acntname) &&
                  !autoApprove && datas?.bankDetails?.adminApproved !== "REJECTED" &&
                  "blurLiscenceEdit"
                }
              >
                <Form.Group>
                  <Form.Label className="mb-0">
                    Beneficiary Name
                    {isEdit && <sup>*</sup>}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${isEdit ? "Enter the Beneficiary Name" : "-"
                      }`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { bankDetails } = { ...formData };
                      bankDetails.accountName = value;
                      bankDetails.isEdit = true;
                      bankDetails.adminApproved = null;
                      setFormData((prevState) => ({
                        ...prevState,
                        bankDetails,
                        isEdit: true,
                        status: "PENDING",
                      }));
                    }}
                    value={formData.bankDetails.accountName}
                  />
                  {validator.current.message(
                    "Beneficiary Name",
                    formData.bankDetails.accountName,
                    "required|min:5|max:20|alpha"
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="header">
              <div>Social Media</div>
              <hr></hr>
            </div>

            <div className="body">
              <div
                className={`item3 ${!isEdit ? "test-margin" : "text-social-margin"
                  }`}
              >
                <span
                  className={`text-nowrap test-instagram ${!errorFacebook ? "test-facebook" : ""
                    }`}
                >
                  <img className="me-1" src={facebook} alt="facebook" />
                  &ensp;Facebook -&ensp;
                </span>
                {isEdit ? (
                  <div
                    className={`col-12 col-xs-12 col-md-12 instagram-facebook col-lg-10 ${!errorFacebook ? "test-facebook" : ""
                      }`}
                  >
                    <Form.Group className="mb-0">
                      <Form.Control
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        className="mb-0"
                        type="text"
                        placeholder={`${isEdit
                            ? "Enter complete URL of your Facebook Profile Page"
                            : ""
                          }`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { facebook } = { ...formData };
                          facebook.facebookLink = value;
                          facebook.isEdit = true;
                          facebook.adminApproved = null;
                          if (value === "") {
                            setErrorFacebook(true);
                          }
                          setFormData((prevState) => ({
                            ...prevState,
                            facebook,
                            isEdit: true,
                            status: "PENDING",
                          }));
                        }}
                        value={formData.facebook.facebookLink}
                      />
                      <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                        Enter URL in format www.facebook.com/thecheflane
                      </p>

                      {!errorFacebook && (
                        <span className="error-message fs-12">
                          Please enter a valid Facebook URL
                        </span>
                      )}
                    </Form.Group>
                  </div>
                ) : (
                  formData.facebook?.facebookLink && (
                    <a
                      href={
                        formData.facebook.facebookLink.includes(
                          "http" || "https"
                        )
                          ? formData.facebook.facebookLink
                          : "https://" + formData.facebook.facebookLink
                      }
                      target="_blank"
                      className="fw-700 text-black-25"
                    >
                      {formData.facebook.facebookLink}
                    </a>
                  )
                )}
              </div>

              <div className={`item3 ${!isEdit ? "test-margin" : ""}`}>
                <span className="text-nowrap test-instagram">
                  <img className="" src={Instagram} alt="Instagram" />
                  &ensp;Instagram -&ensp;
                </span>
                {isEdit ? (
                  <div className="col-12 col-xs-12 col-md-12 instagram-facebook col-lg-10">
                    <Form.Group className="mb-0">
                      <Form.Control
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="text"
                        className="mb-0"
                        placeholder={`${isEdit
                            ? "Enter complete URL of your Instagram Profile Page"
                            : ""
                          }`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { instagram } = { ...formData };
                          instagram.instagramLink = value;
                          instagram.isEdit = true;
                          instagram.adminApproved = null;
                          if (value === "") {
                            setErrorInstagram(true);
                          }
                          setFormData((prevState) => ({
                            ...prevState,
                            instagram,
                            isEdit: true,
                            status: "PENDING",
                          }));
                        }}
                        value={formData.instagram.instagramLink}
                      />
                      <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                        Enter URL in format www.instagram.com/the.chef.lane
                      </p>

                      {!errorInstagram && (
                        <span className="error-message fs-12">
                          Please enter a valid Instagram URL
                        </span>
                      )}
                    </Form.Group>
                  </div>
                ) : (
                  formData.instagram?.instagramLink && (
                    <a
                      href={
                        formData.instagram.instagramLink.includes(
                          "http" || "https"
                        )
                          ? formData.instagram.instagramLink
                          : "https://" + formData.instagram.instagramLink
                      }
                      target="_blank"
                      className="fw-700 text-black-25"
                    >
                      {formData.instagram.instagramLink}
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
          {isEdit && (
            <div className="d-flex mt-3">
              <NormalButton
                label="Cancel"
                className="white-btn mr-3"
                onClick={() => {
                  setIsEdit(false);
                  fetchChefDetailsParent();
                }}
              />
              <span className="m-2" />

              <NormalButton
                primary
                label="Save"
                className="fw-700"
                // disabled={isLoading || isuploading}
                onClick={() => submitBasicDetails()}
              />
            </div>
          )}

          <Modal
            show={showDeactivate}
            onHide={() => setShowDeactivate(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Body>
              <div className="alert-deActive mt-5">
                <h4 className="mb-0">
                  Are you Sure you want to
                  <br /> Delete?
                </h4>
              </div>
              <Modal.Footer>
                <div className="d-flex deactivate-block">
                  <NormalButton
                    label="Yes, Delete"
                    className="w-100 white-btn mr-3"
                    disabled={deactivateLoader}
                    onClick={() => {
                      setdeactivateLoader(true);

                      let id = formData._id;
                      deleteChef(id)
                        .then((data) => {
                          if (data.deletedData) {
                            setShowDeactivate(false);
                            Toast({
                              type: "error",
                              message: "Account is deactivated",
                              toastId: "success1",
                            });
                            logout();
                          }
                          setdeactivateLoader(false);
                        })
                        .catch(() => {
                          setdeactivateLoader(false);
                          setShowDeactivate(true);
                        });
                    }}
                  />
                  <span className="m-2" />
                  <NormalButton
                    primary
                    label="No, Keep my Account"
                    className="w-100 fw-700"
                    disabled={deactivateLoader}
                    onClick={() => setShowDeactivate(false)}
                  />
                </div>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal
            show={showPlatformAlert}
            onHide={() => setShowPlatformAlert(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Header className="bg-white border-0">
              <Modal.Title></Modal.Title>
              <span
                className="cursor-pointer"
                onClick={() => setShowPlatformAlert(false)}
              >
                <img src={closeBtn} alt="icon" />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="alert-deActive mt-5 pb-5">
                <h4 className="mb-2">
                  Choosing platform delivery partners, may <br />
                  result in limited radius for delivery.
                </h4>
              </div>
              <Modal.Footer></Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal
            show={printModel}
            onHide={() => setPrintModel(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Header className="border-0">
              <Modal.Title></Modal.Title>
              <span
                className="cursor-pointer"
                onClick={() => setPrintModel(false)}
              >
                <img src={closeBtn} alt="icon" />
              </span>
            </Modal.Header>
            <Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal.Body>
          </Modal>
          <div className="test-pdf">
            <div style={{ display: "none" }}>
              {!!formData._id && (
                <ComponentToPrint ref={componentRef} chefId={formData._id} />
              )}
            </div>
          </div>
        </div >
      )
      }
      <Modal
        show={isPendingItems}
        onHide={() => setIsPendingItems(false)}
        backdrop={false}
        keyboard={false}
        className="deactivate-modal chef-approval-model"
        centered
      >
        <Modal.Body>
          <div className="alert-deActive mt-5">
            <h4 className="mb-0">
              The changes have been submitted
              <br />
              for approval.
            </h4>
          </div>
          <Modal.Footer>
            <div className="d-flex deactivate-block">
              <NormalButton
                primary
                label="Ok"
                className="w-100 fw-700"
                onClick={() => {
                  setIsLoading(false);
                  setIsEdit(false);
                  isChanged.num = true;
                  fetchChefDetailsParent();
                  setIsPendingItems(false);
                  scrollTop();
                }}
              />
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <ImageCropComponent
        setShowImageCrop={setShowImageCrop}
        showImageCrop={showImageCrop}
        handleFileUpload={handleFileUpload}
        setCroppingImage={setCroppingImage}
        croppingImage={croppingImage}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefEditUpdate,
      getSingle,
      fileUpload,
      imageUpload,
      getCusineList,
      deleteChef,
      getChefPdf,
      businessTypeList,
      getSinglePending,
      checkDeliveryPartnerAvailablity,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(BasicDetail);
class ComponentToPrint extends React.Component {
  render() {
    return <>{/* <ChefPdf chefId={this.props.chefId} /> */}</>;
  }
}
