import { localCartType } from "service/actionType";
import { Toast } from "service/toast";

const initialLocalCart = JSON.parse(localStorage.getItem("localCart"));
const initialState = !!initialLocalCart ? initialLocalCart : [];

export const addToLocalCart = (payload) => ({
  type: localCartType.add,
  payload,
});

export const cartPlus = (payload) => ({
  type: localCartType.incQty,
  payload,
});

export const cartMinus = (payload) => ({
  type: localCartType.decQty,
  payload,
});

export const cartRemove = (payload) => ({
  type: localCartType.remove,
  payload,
});
const remove = (state, payload) => {
  let finalState = state.filter((item) => item._id !== payload._id);
  localStorage.setItem("localCart", JSON.stringify(finalState));
  Toast({
    type: "success",
    message: "Item discarded from cart successfully!",
  });
  return finalState;
};
const increase = (state, payload) => {
  let finalState = state.map((item) => {
    if (item._id === payload._id) {
      Toast({
        type: "success",
        message: "Your cart has been successfully updated!",
        time: 1000,
      });
      return {
        ...item,
        quantity: !!item.quantity ? item.quantity + 1 : 2,
      };
    }
    return item;
  });
  localStorage.setItem("localCart", JSON.stringify(finalState));
  return finalState;
};
const decrease = (state, payload) => {
  let finalState = state.map((item) => {
    if (item._id === payload._id) {
      Toast({
        type: "success",
        message: "Your cart has been successfully updated!",
        time: 1000,
      });
      return {
        ...item,
        quantity: item.quantity - 1,
      };
    }
    return item;
  });
  localStorage.setItem("localCart", JSON.stringify(finalState));
  return finalState;
};

const add = (state, payload) => {
  const incart = state.filter((item) => item._id === payload._id);
  if (incart.length > 0) {
    Toast({ type: "success", message: "Added", time: 1000 });
    return state;
  } else {
    Toast({ type: "success", message: "Item added to cart!" });
    const initialLocalCart = JSON.parse(localStorage.getItem("localCart"))
      ? JSON.parse(localStorage.getItem("localCart"))
      : [];

    initialLocalCart.push(payload);
    localStorage.setItem("localCart", JSON.stringify(initialLocalCart));
  }
  return [...state, payload];
};

export default function localCart(state = initialState, action) {
  switch (action.type) {
    case localCartType.totalCartLocal:
      localStorage.setItem("localCart", JSON.stringify(action.payload));
      if (action.payload?.length === 0) {
        return [];
      } else {
        return {
          ...state,
          totalCartLocal: action.payload,
        };
      }
    case localCartType.add:
      return add(state, action.payload);
    case localCartType.remove:
      return remove(state, action.payload);
    case localCartType.incQty:
      return increase(state, action.payload);
    case localCartType.decQty:
      return decrease(state, action.payload);

    default:
      return state;
  }
}
