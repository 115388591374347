import { Navbar } from "component/common/Navbar";
import React, { Component } from "react";

import "../assets/scss/layouts/AuthLayout.scss";
import Deactivate from "component/Deactivate";

export class AuthLayout extends Component {
  render() {
    let { children } = this.props;
    return (
      <div className="center-view-login">
        <Navbar />
        <div className="content-area w-100">
          <div className="d-flex h-100 my-md-4 my-3">
            <div className="col-md-10 mx-auto col-lg-6 auth-layout">
              <div className="card px-md-4 px-2 mx-2">{children}</div>
            </div>
          </div>
        </div>
        {/* <Deactivate /> */}
      </div>
    );
  }
}
