import React, { useState, useEffect } from "react";
import "./style.scss";
import cheflist from "assets/images/customerConnection.png";
import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import { NormalButton } from "component/common/NormalButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handleAddToCart, deleteCart, getAllCartById } from "action/cartAct";
import { cartRemove, cartPlus, cartMinus } from "reducer/local_cart";
import { useDispatch } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { cartItems } from "../../coils";

const MenuCard = ({
  cartDetails = {},
  handleAddToCart,
  deleteCart,
  getAllCartById,
  fetchPriceSummery,
  setIsLoadingCart,
  setIsbuttonDisabled,
}) => {
  const [allCartItems, setAllCartItems] = useRecoilState(cartItems);
  const [counter, setCounter] = useState(1);
  const [learnMore, setLearnMore] = useState(false);
  const handleLearnmore = () => {
    setLearnMore(!learnMore);
  };
  const [isAddingCart, setIsaddingCart] = useState(false);
  const authToken = localStorage.getItem("authToken");
  let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));

  const dispatch = useDispatch();
  //life cycle
  useEffect(() => {
    setCounter(cartDetails.quantity ? cartDetails.quantity : 1);
  }, []);
  const handleQuantityChange = async (quantity) => {
    let body = {
      userId: cartDetails.userId,
      foodId: cartDetails?.foodId?._id,
      quantity: quantity,
    };
    let cartPayload = { userId: cartDetails.userId };
    setIsaddingCart(true);
    if (!!authToken) {
      await handleAddToCart(body)
        .then((data) => {
          if (!!data) {
            getAllCartById(cartPayload);
            fetchPriceSummery();
          }
          setIsbuttonDisabled && setIsbuttonDisabled(false);
          setIsaddingCart(false);
        })
        .catch(() => {
          setIsaddingCart(false);
          setIsbuttonDisabled && setIsbuttonDisabled(false);
        });
    } else {
      setIsbuttonDisabled && setIsbuttonDisabled(false);
      dispatch(cartPlus(cartDetails));
      setIsaddingCart(false);
    }
  };
  const handleDeleteCart = () => {
    setIsaddingCart(true);
    setIsLoadingCart && setIsLoadingCart(true);
    let body = {
      cartId: cartDetails._id,
      userId: userDetails?._id,
    };
    let cartPayload = { userId: userDetails?._id };
    if (!!authToken) {
      deleteCart(body)
        .then((data) => {
          if (!!data) {
            getAllCartById(cartPayload);
            const newCart = allCartItems.filter(
              (cartitem) => cartitem !== cartDetails?.foodId?._id
            );
            setIsLoadingCart && setIsLoadingCart(false);
            setAllCartItems(newCart);
            fetchPriceSummery();
            setIsbuttonDisabled && setIsbuttonDisabled(false);
          }
          setIsaddingCart(false);
        })
        .catch(() => {
          setIsaddingCart(false);
          setIsbuttonDisabled && setIsbuttonDisabled(false);
        });
    } else {
      const newCart = allCartItems.filter(
        (cartitem) => cartitem !== cartDetails?._id
      );
      setAllCartItems(newCart);
      dispatch(cartRemove(cartDetails));
      setIsaddingCart(false);
      setIsbuttonDisabled && setIsbuttonDisabled(false);
    }
  };

  const incrementCounter = () => {
    setCounter(counter + 1);
    setIsbuttonDisabled && setIsbuttonDisabled(true);
    handleQuantityChange(counter + 1);
  };

  const decrementCounter = () => {
    setIsbuttonDisabled && setIsbuttonDisabled(true);
    if (counter > 1) {
      setCounter(counter - 1);
      if (!!authToken) handleQuantityChange(counter - 1);
      else {
        dispatch(cartMinus(cartDetails));
        setIsbuttonDisabled && setIsbuttonDisabled(false);
      }
    } else handleDeleteCart();
  };
  let cartItem = !!authToken ? cartDetails.foodId : cartDetails;
  const Icon = cartItem.dishCategory === "Non Veg" ? nonvegIcon : vegIcon;
  let currency = cartItem?.chefId?.curreny;
  return (
    <>
      <div className=" bg-white-FF p-3 d-flex justify-content-start menu-cart content-media ">
        <div className="">
          {!!cartItem.dishImage ? (
            <div className="foodimage">
              <img
                src={!!cartItem.dishImage ? cartItem.dishImage : cheflist}
                alt=""
              />
              <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700"></div>
            </div>
          ) : (
            <img src={cheflist} className=" pe-3" alt="" />
          )}
        </div>
        <div
          className={`${
            !!cartItem.dishImage ? "menucard_description_cart ml-5" : "w-100"
          }`}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className=" foodname fs-18 line-height-20 fw-700 text-primary-color ">
                {cartItem.name}
              </div>
              <div className="nonVageIcon mb-1 line-height-20  ">
                <img src={Icon} alt="icon" />
              </div>
            </div>

            <div className=" order_status text-primary-color fs-18 line-height-20 fw-700 d-flex">
              {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
              {cartItem.price}
            </div>
          </div>
          {cartItem.about && (
            <p className="about-cart-min-height fs-14 text-grey-7c line-height-16  fw-400">
              {cartItem.about.length < 65 ? (
                cartItem.about
              ) : learnMore ? (
                <React.Fragment>
                  {cartItem.about}{" "}
                  <span
                    className="text-yellow-C1 cursor-pointer"
                    onClick={handleLearnmore}
                  >
                    ...Less
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {cartItem.about && cartItem.about.substring(0, 64)}{" "}
                  <span
                    className="text-yellow-C1 cursor-pointer"
                    onClick={handleLearnmore}
                  >
                    Read more...
                  </span>
                </React.Fragment>
              )}
            </p>
          )}

          <div className="d-flex justify-content-between flex-wrap type-count-container mt-0 ">
            <div className="d-flex type-menu-container ">
              {cartItem.preference ? (
                <div className="d-flex my-0 type-menu-container flex-wrap">
                  {cartItem.preference.length <= 2 ? (
                    cartItem.preference.map((type, index) => (
                      <>
                        <span
                          key={index}
                          className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                        >
                          {type}
                        </span>
                      </>
                    ))
                  ) : (
                    <React.Fragment>
                      {cartItem.preference.map(
                        (type, index) =>
                          index < 2 && (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          )
                      )}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 300 }}
                        overlay={
                          <Tooltip>
                            {cartItem.preference.slice(2).join(", ")}
                          </Tooltip>
                        }
                        containerPadding={20}
                      >
                        <span
                          className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                          style={{
                            color: "#b58204",
                          }}
                        >
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex mt-1 justify-content-between flex-wrap">
            <p className="mb-0 fw-700 text-primary-color text-start">
              {cartItem.AdvanceOrderType}
            </p>
            <div className="d-flex justify-content-end  ">
              <div className="count-contain-minus">
                <NormalButton
                  label="-"
                  className="count text-black-25 fs-20 bg-white-FF count-btn p-0"
                  onClick={() => decrementCounter()}
                  disabled={isAddingCart}
                />
              </div>
              <div className="px-2">
                <NormalButton
                  label={counter}
                  primary
                  className="count text-white-FF fs-14 bg-primary-color count-num-btn p-0"
                  disabled={isAddingCart}
                />
              </div>
              <div className="count-contain-plus">
                <NormalButton
                  label="+"
                  className="count text-black-25 fs-20 bg-white-FF count-btn p-0"
                  onClick={() => incrementCounter()}
                  disabled={isAddingCart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleAddToCart,
      deleteCart,
      getAllCartById,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(MenuCard);
