import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./style.scss";
import "slick-carousel/slick/slick-theme.css";
import unratedIcon from "../../../assets/images/unratedIcon.png";
import ratingicon from "../../../assets/images/ratingicon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export const Slick1 = ({ testimonilaData }) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    centerPadding: "30px",
    nextArrow: "",
    prevArrow: "",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <React.Fragment>
      {testimonilaData.length > 0 && (
        <div className="container pb-5 Slick">
          <div className="row mx-auto pb-5">
            <div className="col px-0">
              <p className="fs-36 fw-700 line-height-42 text-primary-color text-center pt-4">
                Testimonials
              </p>
              <div className="col-md-8 mx-auto">
                <Slider {...settings}>
                  {testimonilaData &&
                    testimonilaData.map((item, index) => {
                      const {
                        review = "",
                        rating = "",
                        businessName = "",
                        chefDetailsUrl = "",
                      } = item;

                      return (
                        <div key={index}>
                          <div className="col-md-12 mx-auto">
                            <div className="card ">
                              <div className="card-body testmonials-body">
                                <p className="card-text text-center px-4 mb-0 fs-16 fw-400 line-height-23 text-black">
                                  {review.length < 100 ? (
                                    review
                                  ) : (
                                    <React.Fragment>
                                      {review.substring(0, 100)}{" "}
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 200, hide: 300 }}
                                        overlay={<Tooltip>{review}</Tooltip>}
                                        containerPadding={20}
                                      >
                                        <span className="text-yellow-C1 cursor-pointer">
                                          ...Read more
                                        </span>
                                      </OverlayTrigger>
                                    </React.Fragment>
                                  )}
                                </p>
                                <div className="pt-3 fs-16 fw-400 justify-content-center">
                                  <div className="d-flex justify-content-center">
                                    {rating ? (
                                      <div className="d-flex mt-1 star-image">
                                        {[...Array(parseInt(rating))].map(
                                          (_, index) => (
                                            <span key={index}>
                                              <img
                                                src={ratingicon}
                                                alt="ratingicon"
                                              />
                                            </span>
                                          )
                                        )}
                                        {rating !== 5 &&
                                          [...Array(parseInt(5 - rating))].map(
                                            (_, index) => (
                                              <span key={index}>
                                                <img
                                                  src={unratedIcon}
                                                  alt="unratedIcon"
                                                />
                                              </span>
                                            )
                                          )}
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                  <div className="pt-4 fs-16   fw-400  d-flex justify-content-center ">
                                    <p className=" fs-16 fw-700 text-primary-color">
                                      <center>
                                        <a
                                          target="_blank"
                                          className="text-primary-color  cursor-pointer"
                                          href={
                                            chefDetailsUrl
                                              ? chefDetailsUrl
                                              : "-"
                                          }
                                        >
                                          {businessName ? businessName : "-"}
                                        </a>
                                      </center>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
