import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.scss";

export class CommonTableLoaderClass extends Component {
  render() {
    let { tdLength = 6 } = this.props;
    return [...Array(8)].map((_, index) => (
      <tr key={index}>
        {[...Array(tdLength)].map((_, tdIndex) => (
          <td key={tdIndex}>
            <div className="ph-loader ph-5h" />
          </td>
        ))}
      </tr>
    ));
  }
}

export const CommonTableLoader = connect(null, null)(CommonTableLoaderClass);
