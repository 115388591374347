import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'

import { TrackOrderComp } from 'component/TrackOrder/TrackOrder'
export class TrackOrderClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props
    return (
      <TrackOrderComp
        trackId={params.trackId}
        foodId={params.foodId}
        type={params.type}
      />
    )
  }
}
export const TrackOrder = connect(null, null)(TrackOrderClass)
