import React, { useState, useEffect } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NormalButton,
  CommonPageLoader,
  NormalSelect,
  NormalInput,
} from "component/common";
import OrderAvailable from "./OrderAvailable";
import OrderServedAvailable from "./OrderServedAvailable";
import { getAlluserOrder, getAllActiveOrder } from "action/CustomerDetailsAct";
import { history } from "service/helpers";
import { Toast } from "service/toast";
function CustomerOrder({
  getAlluserOrder,
  getAllActiveOrder,
  currentPageOrderCustomer,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [list, setList] = useState([]);
  const [pageMeta, setPageMeta] = useState(null);
  const [activelist, setactivelist] = useState([]);
  const [pageViewMore, setpageViewMore] = useState(1);
  const [selectfilter, setSelectfilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOrder, setSearchOrder] = useState("");

  const FilterList = [
    {
      label: "All Orders",
      value: "",
    },
    {
      label: "Declined",
      value: "DECLINE",
    },
    {
      label: "Delivered",
      value: "DELIVERED",
    },
  ];
  //life cycle
  useEffect(() => {
    if (currentPageOrderCustomer) {
      fetchOrder(currentPageOrderCustomer);
    } else {
      fetchOrder();
    }
  }, [selectfilter, pageViewMore]);

  useEffect(() => {
    if (searchOrder?.length === 0) {
      fetchOrder(1);
      setSearchOrder("");
      setpageViewMore(1);
    }
  }, [searchOrder]);

  useEffect(() => {
    OrderActiveApi();
  }, []);

  const fetchOrder = async (page = 1, search = "") => {
    const step = 10;
    const newPage = Math.ceil((pageViewMore + 2) / step);
    setCurrentPage(newPage);
    let query = {
      page: newPage === page ? page : newPage,
      limit: 10,
      userId: localStorage.getItem("userId"),
      orderStatus: selectfilter,
      searchKey: search,
    };
    setIsLoadingHistory(true);
    await getAlluserOrder(query)
      .then(({ data }) => {
        if (data.list) {
          setList(data.list);
          setPageMeta(data.pageMeta);
        }
        setIsLoadingHistory(false);
      })
      .catch(() => setIsLoadingHistory(false));
  };

  const OrderActiveApi = async () => {
    let query = {
      userId: localStorage.getItem("userId"),
    };
    setIsLoading(true);
    await getAllActiveOrder(query)
      .then(({ data }) => {
        if (data) {
          setactivelist(data);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const handleOrderSearch = (val = "") => {
    if (val?.length >= 6) {
      fetchOrder(1, val);
    } else {
      Toast({ type: "error", message: "Please enter a 6-Digit Order no!" });
    }
    if (val?.length === 0 || searchOrder === "") {
      fetchOrder();
    }
  };

  const handleViewMore = () => {
    setpageViewMore(pageViewMore + 2);
  };
  return (
    <>
      <div className="customer_detail_inner_container">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="header">Active orders</div>
          </div>
          <hr></hr>
        </div>
        {!isLoading ? (
          activelist.length > 0 ? (
            activelist.map((item, index) => {
              return (
                <div id="order-custom" className="bg-grey-f0 mb-3" key={index}>
                  <div className="px-2 pt-2">
                    <span className="fs-15 ms-2 text-green-C1 bg-green-C2 pr-2">
                      {item.paymentStatus === "captured" ||
                      item.paymentStatus === "Paid"
                        ? "Paid"
                        : item.paymentStatus === "COD"
                        ? "Cash on Delivery"
                        : item.paymentStatus === "failed"
                        ? "Failed"
                        : item.paymentStatus === "refunded"
                        ? "Refunded"
                        : ""}
                    </span>
                  </div>

                  <div className="d-flex align-items-center px-3 order-page-items">
                    <div className="col-12 col-md-7 fs-18 fw-700 text-gold mb-0">
                      {" "}
                      <span className="">Order ID: {item.orderId}</span>
                      <div
                        className={`col-12 col-md-8 ms-3 d-inline-flex justify-content-center fs-14 text-${
                          item.orderStatus === "REQUEST"
                            ? "secondary-color"
                            : item.orderStatus === "DECLINE"
                            ? "-red-DB"
                            : "gold-tt"
                        }   title-order-block  p-2   align-items-center  bg-${
                          item.orderStatus === "REQUEST"
                            ? "white-de"
                            : item.orderStatus === "DECLINE"
                            ? "white-green"
                            : "yellow-C3"
                        } `}
                      >
                        {item.orderStatus === "REQUEST"
                          ? "Order Placed!  we're waiting for their confirmation."
                          : item.orderStatus === "DECLINE"
                          ? "Declined"
                          : "Super! Your order has been accepted."}
                      </div>
                    </div>
                    <div className="col-12 col-md-5 d-flex justify-content-end">
                      <span className="fs-18 fw-700 text-gold mb-0 d-flex me-3 text-capitalize align-items-center ">
                        {item?.chefId?.businessName}
                      </span>
                      <div className="cursor-pointer text-end">
                        {item.orderStatus === "DECLINE" ? (
                          <NormalButton
                            disabled={true}
                            label="Declined"
                            className="clear text-black-25 line-height-18 bg-white fs-16 fw-400"
                          />
                        ) : (
                          <NormalButton
                            primary
                            label="Track Order"
                            className="px-5 order-track ff"
                            onClick={() => {
                              history.push(
                                `/index/trackorder/${item.id}/track`
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="availble-advance-order">
                    <div className="row mt-2">
                      {
                        // filter((item) => {
                        //   return item.foodId?.typeOfMenu === 'Available Now'
                        // })
                        item?.items.length > 0 && (
                          <>
                            <div className="d-flex">
                              <div className="col-6 d-flex justify-content-end align-items-center"></div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-lg-12 p-4 order-card">
                              {item.items.map((food, findex) => {
                                return (
                                  <React.Fragment ket={findex}>
                                    <OrderAvailable
                                      food={food}
                                      trackId={item._id}
                                      orderDetails={item}
                                    />
                                  </React.Fragment>
                                );
                              })}
                              <div className="row mx-1">
                                <div className="col-12 col-md-6 col-lg-3 px-md-4 p-3 chef_detail_inner_container justify-content-center align-item-center ms-auto">
                                  {/* <div className="d-flex justify-content-between">
                                    <div>Tax </div>
                                    <div>₹{item.tax}</div>
                                  </div>
                                  <hr /> */}
                                  <div className="d-flex justify-content-between">
                                    <div>Delivery charges </div>
                                    <div>₹{item.deliveryCharges}</div>
                                  </div>
                                  <hr />
                                  <div className="d-flex justify-content-between">
                                    <div className="fw-500">Total </div>
                                    <div className="fw-500">
                                      ₹{item.cartValue}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-gold text-center mt-2">No Order Found !!!</div>
          )
        ) : (
          <CommonPageLoader />
        )}
      </div>
      <div className="customer_detail_inner_container mt-5">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="header">Order History of page - {currentPage}</div>

            <div className="d-md-flex  justify-content-between align-items-center">
              <div className="mx-md-2 my-2">
                <NormalInput
                  type="number"
                  placeholder="Search order"
                  value={searchOrder}
                  onChange={(e) => setSearchOrder(e.target.value)}
                  name="searchOrder"
                  className=""
                  isMarginTop={false}
                />
              </div>
              <NormalButton
                primary
                label="Search"
                className=""
                onClick={() => handleOrderSearch(searchOrder)}
              />
              <div className="filter-outline ps-2">
                <NormalSelect
                  placeholder="Filter"
                  name="selectfilter"
                  options={FilterList}
                  value={selectfilter}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    setSelectfilter(value);
                  }}
                  className="form-control filter-select"
                />
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
        {!isLoadingHistory ? (
          list.length > 0 ? (
            <>
              {list.slice(0, pageViewMore).map((item, index) => {
                return (
                  <div
                    id="order-custom"
                    className="bg-grey-f0 mb-3"
                    key={index}
                  >
                    <div className="px-2 pt-2">
                      <span className="fs-15  ms-2 text-green-C1 bg-green-C2 pr-2">
                        {item.paymentStatus === "captured" ||
                        item.paymentStatus === "Paid"
                          ? "Paid"
                          : item.paymentStatus === "COD"
                          ? "Cash on Delivery"
                          : item.paymentStatus === "failed"
                          ? "Failed"
                          : item.paymentStatus === "refunded"
                          ? "Refunded"
                          : item.paymentStatus === "declined"
                          ? "Declined"
                          : item.paymentStatus === "refundInitiated"
                          ? "Refund Initiated"
                          : ""}
                      </span>
                    </div>
                    <div className="d-flex align-items-center px-3">
                      <div className="bitesheading col-6 line-height-36 fs-18 fw-700 text-gold mb-2 d-flex align-items-center flex-wrap">
                        {" "}
                        <span>Order ID: {item.orderId}</span>
                        <div
                          className={`d-flex fs-14 ${
                            item.orderStatus !== "DECLINE"
                              ? "text-green-C3"
                              : "text-red-DB"
                          }  title-order-block justify-content-end p-2   align-items-center bg-white-green ms-4`}
                        >
                          {item.orderStatus === "DECLINE"
                            ? "Declined"
                            : "Delivered"}
                        </div>
                        <div className="fs-16 line-height-24">
                          <p className="mb-0 text-red-DB">
                            {" "}
                            {item.orderStatus === "DECLINE" &&
                            (item.paymentStatus === "COD" ||
                              item.paymentStatus === "declined")
                              ? "Uh-oh! We're sorry " +
                                `${item?.chefId?.businessName}` +
                                ` cannot fullfil your order! Please try ordering from another chef!`
                              : item.paymentStatus === "failed"
                              ? "Uh-oh! We're sorry " +
                                `${item?.chefId?.businessName}` +
                                ` cannot fullfil your order!`
                              : item.paymentStatus === "refundInitiated"
                              ? "Apologies, your order with" +
                                `${item?.chefId?.businessName}` +
                                ` has been canceled. Your refund will be processed within 5-10 business days. We appreciate your understanding`
                              : item.paymentStatus === "refunded"
                              ? "Your declined order" +
                                `${item?.chefId?.businessName}` +
                                ` has been refunded successfully`
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 d-flex justify-content-end align-items-center">
                        <span className="fs-18 fw-700 text-gold mb-0 d-flex me-3 text-capitalize align-items-center ">
                          {item?.chefId?.businessName}
                        </span>
                        {item.orderStatus !== "DECLINE" &&
                        item.rating !== true ? (
                          <div className="text-end">
                            <NormalButton
                              primary
                              label="Rate & Review"
                              className="px-5 order-track cursor-pointer"
                              onClick={() => {
                                history.push(
                                  `/index/trackorder/${item._id}/review`
                                );
                              }}
                            />
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div className="availble-advance-order">
                      <div className="row mt-2">
                        <div className="col-xs-12 col-sm-12 col-lg-12 mb-2 p-3 order-card">
                          {item.items.length > 0 &&
                            item.items.map((food, findex) => {
                              return (
                                <React.Fragment key={findex}>
                                  <OrderServedAvailable
                                    food={food}
                                    orderDetails={item}
                                    trackId={item._id}
                                  />
                                </React.Fragment>
                              );
                            })}
                          <div className="row mx-1">
                            <div className="col-12 col-md-6 col-lg-3 px-md-4 p-3 chef_detail_inner_container justify-content-center align-item-center ms-auto">
                              {/* <div className="d-flex justify-content-between">
                                <div>Tax </div>
                                <div>₹{item.tax}</div>
                              </div>
                              <hr /> */}
                              <div className="d-flex justify-content-between">
                                <div>Delivery charges </div>
                                <div>₹{item.deliveryCharges}</div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div className="fw-500">Total </div>
                                <div className="fw-500">₹{item.cartValue}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="text-gold text-center mt-2">No Order Found !!!</div>
          )
        ) : (
          <CommonPageLoader />
        )}
        {list.length > 0 ? (
          <div className="d-flex justify-content-center align-items-center mt-4 order-served-viewmore">
            <NormalButton
              label="View More"
              className="clear text-primary-color border-1 line-height-18 fs-16 bg-white fw-700 py-2 px-4"
              onClick={handleViewMore}
              disabled={list.length === pageViewMore ? true : false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPageOrderCustomer: state.commonStore.currentPageOrderCustomer,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getAlluserOrder, getAllActiveOrder }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrder);
