import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import {
  CommonPageLoader,
  CountryCodeDropDown,
  NormalButton,
  NormalCheckbox,
  NormalInput,
  PageIcons,
} from "component/common";
import { IoClose } from 'react-icons/io5';
import { history } from "service/helpers";
import { getOneOrderApi } from "action/CustomerDetailsAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  createCustomerAddress,
  getCustomerActiveAddress,
  getPaymentDetail,
  codPayment,
  paymentDetailCapture,
  priceSummery,
  updateCustomerAddress,
  deleteCustomerActiveAddress,
} from "action/cartAct";
import logo from "assets/images/razorpay.png";
import tick from "assets/images/tick.png";
import untick from "assets/images/outline-4x.png";
import edit_underline from "assets/images/edit_underline.png";
import bin from "assets/images/bin.png";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { handleNameField } from "../../service/helperFunctions";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "../../service/toast";
import ErrorComponent from "../common/ErrorComponent";
import AddressDetails from "../customerProfile/AddressDetail/index";
import otpsuccess from "../../assets/svg/circle-check-fill.svg";
import {
  sendOtpEmailAddress,
  sendOtpVerifyEmailAddress,
  sendOtpphoneAddress,
  sendOtpVerifyphoneAddress,
  DeliverypartnerConnection,
} from "./../../action/AuthAct";
import { redirectToCheckout } from "utils/constants";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const OrderCreationComp = ({
  getCustomerActiveAddress,
  createCustomerAddress,
  updateCustomerAddress,
  orderId,
  getOneOrderApi,
  getPaymentDetailApi,
  paymentDetailCaptureApi,
  priceSummery,
  deleteCustomerActiveAddress,
  userCart = [],
  sendOtpAddressEmailCall,
  sendOtpVerifyAddressCall,
  sendOtpphoneAddress,
  sendOtpVerifyphoneAddress,
  codPaymentApi,
  deliverypartnerConnectionApi,
}) => {
  const initalState = {
    firstName: "",
    lastName: "",
    userTypeRole: "",
    phoneNumber: "",
    countryCode: "+91",
    AddressDetails: {
      country: "",
      state: "",
      address1: "",
      address2: "",
      city: "",
      area: "",
      pincode: "",
      location: { lat: "", long: "" },
    },
  };

  const [formData, setFormData] = useState(initalState);
  const [isCashOnDelivery, setIsCashOnDelivery] = useState(false);
  const [isDunzoCalled, setIsDunzoCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState(false);
  const [priceDetails, setPriceDetails] = useState(null);
  const [foodDetails, setFoodDetails] = useState([]);
  const [customerAddress, setCustomerAddress] = useState({});
  const [isLoadingPrice, setisLoadingPrice] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  const [showAdressPopup, setshowAdressPopup] = useState(false);
  const [showDeleteAddressPopup, setDeleteshowAddressPopup] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);

  const [AddressOtp, setAddressOtp] = useState({
    otp: "",
  });
  const [error, setErrors] = useState({});
  const [isProceedButtonClicked, setisProceedButtonClicked] = useState(false);
  const [sendOtpPopup, setSendOtpPopup] = useState(false);
  const [sendmobileOtpPopup, setSendmobileOtpPopup] = useState(false);
  const [sendSucess, SetSucesspopup] = useState(false);
  const [sendphoneSucess, SetsendphoneSucess] = useState(false);
  const [sendSucessEmail, SetSucessEmailPopup] = useState(false);
  const [isButtonVerify, setisButtonVerify] = useState(true);
  const [otpSend, setOtpSend] = useState(false);
  const [otpmobileSend, setOtpmobileSend] = useState(false);

  const handleClosePopup = () => setshowAdressPopup(false);
  const handleShowPopup = () => setshowAdressPopup(true);
  const handleCloseEmailPopup = () => {
    setSendOtpPopup(false);
    setAddressOtp({ otp: "" });
  };
  const handleCloseMobilePopup = () => {
    setSendmobileOtpPopup(false);
    setAddressOtp({ otp: "" });
  };
  const handleClosePopupDeleteAddress = () => setDeleteshowAddressPopup(false);
  const handleShowPopupDeleteAddress = (id) => {
    setAddressID(id);
    setDeleteshowAddressPopup(true);
  };

  const [isEdit, setIsEdit] = useState(true);
  const [show, setShow] = useState(false);

  const [FirstName, setFirstName] = useState(null);
  const [selectedAddressID, setSelectedAddressID] = useState(null);
  const [AddressID, setAddressID] = useState(null);
  const [LastName, setLastName] = useState(null);
  const [Email, setEmail] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState(null);
  const [countryCode, setCountryCode] = useState("+91");

  const paymentCartArr = foodDetails?.map((v) => ({
    quantity: v?.quantity || 0,
    price: v?.foodId?.price || 0,
    foodId: {
      $oid: v?.foodId?._id || 0,
    },
    orderStatus: v?.foodId?.status,
    orderAcceptedDate: {
      $date: v?.updatedAt,
    },
    rating: false,
    // _id: {
    //   $oid: "658458cbae5624b26ffe484c",
    // }
    // price_data: {
    //   currency: v?.foodId?.chefId?.currency?.toLowerCase() || "currency",
    //   unit_amount: v?.foodId?.price || 0,
    //   product_data: {
    //     name: v?.foodId?.name || "name",
    //     description: v?.foodId?.dishCategory || "description",
    //     images: [v?.foodId?.dishImage] || [""],
    //   },
    // },

    // price_data: {
    //   currency: v?.foodId?.chefId?.currency?.toLowerCase() || "currency",
    //   unit_amount: v?.foodId?.price || 0,
    //   product_data: {
    //     name: v?.foodId?.name || "name",
    //     description: v?.foodId?.dishCategory || "description",
    //     images: [v?.foodId?.dishImage] || [""],
    //   },
    // },
    // quantity: v?.quantity || 0,
  }));


  //life cycle
  useEffect(() => {
    if (userDetails?._id) {
      getOneOrderApiFunction();
      fetchPriceSummery();
      fetchCustomerActiveAddress();
    }
  }, []);

  const handleChange = ({ target: { name, value, currency } }) => {
    if (name === "countryCode") {
      setCountryCode(value);
    }
  };

  const handleChangeCOD = () => {
    setIsCashOnDelivery(!isCashOnDelivery);
  };

  const getOneOrderApiFunction = () => {
    let id = orderId;
    setIsLoading(true);
    getOneOrderApi(id)
      .then(({ data = {} }) => {
        if (data) {
          if (data.orderPlaced) {
            history.push("/");
          } else {
            setOrderData(data);
            setIsLoading(false);
          }
        }
      })
      .catch(() => setIsLoading(false));
  };

  const fetchPriceSummery = async () => {
    let body = {
      userId: userDetails?._id,
      orderId,
    };
    setisLoadingPrice(true);
    setIsLoading(true);
    await priceSummery(body)
      .then((data) => {
        if (!!data) {
          setPriceDetails(data.priceSummary);
          setFoodDetails(data?.data);
          setIsLoading(false);
          setisLoadingPrice(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const fetchCustomerActiveAddress = async () => {
    setisLoadingPrice(true);
    setIsLoading(true);
    await getCustomerActiveAddress(localStorage.getItem("userId"))
      .then((data) => {
        if (!!data) {
          setCustomerAddress(data.data);
          fetchdefaultAddress(data.data);
        }
      })
      .catch(() => setIsLoading(false));
  };
  const fetchdefaultAddress = async (activeAddress) => {
    var setactiveAddress = activeAddress.find((address) => address.default);
    if (setactiveAddress != undefined) {
      setSelectedAddressID(setactiveAddress._id);
    }
  };

  //sent email otp
  const sendOtpContact = (e) => {
    e.preventDefault();
    let body = {
      email: Email,
    };
    sendOtpAddressEmailCall(body).then(() => setSendOtpPopup(true));
  };
  // verify otp
  const handleOtpSubmit = () => {
    let body = {
      email: Email,
      otp: AddressOtp.otp,
    };
    sendOtpVerifyAddressCall(body).then(() => {
      SetSucesspopup(true);
      setSendOtpPopup(false);
      SetSucessEmailPopup(true);
      setOtpSend(true);
      setAddressOtp({ otp: "" });
    });
  };
  //sent mobile otp
  const sendOtphone = (e) => {
    e.preventDefault();
    // if (Email) {
    //   return Toast({
    //     type: "error",
    //     message: "Please Enter Email Id",
    //   });
    // }
    let body = {
      countryCode: countryCode,
      mobileNumber: PhoneNumber,
    };
    sendOtpphoneAddress(body).then(() => setSendmobileOtpPopup(true));
  };
  // verify mobile otp
  const handlemobileOtpSubmit = () => {
    let body = {
      countryCode: countryCode,
      mobileNumber: PhoneNumber,
      otp: AddressOtp.otp,
    };
    sendOtpVerifyphoneAddress(body).then(() => {
      SetsendphoneSucess(true);
      setSendmobileOtpPopup(false);
      setOtpmobileSend(true);
      setAddressOtp({ otp: "" });
    });
  };
  const handleInputOtpChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setAddressOtp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({ ...tempErrors });
    setisButtonVerify(false);
  };

  const newAddress = () => {
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPhoneNumber(null);
    setCountryCode("+91");
    setAddressID(null);
    setFormData({

      AddressDetails: {

        country: "India",
        state: "Tamil Nadu",
        address1: "",
        address2: "",
        city: "Chennai",
        area: "",
        pincode: "600005",
        location: {
          lat: "",
          long: "",
        },
      },
    });
    handleShowPopup();
  };
  const editAddress = (data) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setPhoneNumber(data.mobileNumber);
    setCountryCode(data.countryCode);
    setAddressID(data._id);
    setOtpSend(true);
    setOtpmobileSend(true);
    setFormData({
      AddressDetails: {
        country: data.AddressDetails.country,
        state: data.AddressDetails.state,
        address1: data.AddressDetails.address1,
        address2: data.AddressDetails.address2,
        city: data.AddressDetails.city,
        area: data.AddressDetails.area,
        pincode: data.AddressDetails.pincode,
        pincode: "60032001",
        location: {
          lat: data.AddressDetails.location.lat,
          long: data.AddressDetails.location.long,
        },
      },
    });

    handleShowPopup();
  };

  const handleDunzo = () => {
    if (!selectedAddressID) {
      return Toast({ type: "error", message: "Please choose an address." });
    }
    setisProceedButtonClicked(true);
    deliverypartnerConnectionApi({ orderId, addressId: selectedAddressID })
      .then(() => {
        Toast({
          type: "success",
          message: "Selected address is eligible for delivery!",
        });
        setIsDunzoCalled(true);
        setisProceedButtonClicked(false);
        fetchPriceSummery();
      })
      .catch(() => setisProceedButtonClicked(false));
  };


  const makeStripePayment = async (datum) => {
    localStorage.setItem("sessionId", JSON.stringify(datum));
    try {
      const session = datum;
      const result = redirectToCheckout({
        sessionId: datum?.id,
      });
      if (result?.error) {
        console.log(result.error);
      }
    } catch (error) {
      console.error("Error making payment:", error);
    }
  };

  const handlePayment = () => {
    if (!selectedAddressID) {
      return Toast({ type: "error", message: "Please choose an address." });
    }
    setisProceedButtonClicked(true);

    let payload = {
      userId: userDetails._id,
      orderId,
      addressId:   getCustomerActiveAddress,
    };
   

    paymentCartArr.forEach((item) => {
      if (!item.price_data?.product_data.images[0]) {
        delete item.price_data?.product_data.images;
      }
    });
    let stripePayload = {
      userId: userDetails._id,
      chefId: foodDetails[0].chefId,
      orderId,
      cartDetails: paymentCartArr,
      deliveryCharges: priceDetails?.availableNow?.deliveryCharges ? priceDetails?.availableNow?.deliveryCharges : 0,
      addressId: selectedAddressID,
      // addressId:
      // successUrl: `/success/index/orderplaced/${orderData.orderId}`,
      // cancelUrl: `/canceled/index/orderplaced/${orderData.orderId}`,

    };

    if (isCashOnDelivery) {
      codPaymentApi(payload)
        .then(() => history.push(`/index/orderplaced/${orderData.orderId}`))
        .catch(() => setisProceedButtonClicked(false));
    } else {
      getPaymentDetailApi(stripePayload)
        .then(({ data }) => {


          const paymentLink = data.short_url;

          if (paymentLink) {
            window.location.href = paymentLink;
          } else {
            setisProceedButtonClicked(false);
            Toast({ type: "error", message: "Failed to get payment link." });
          }

          // setisProceedButtonClicked(false);
          // displayRazorpay(data);
          // captureFunc();
          // makeStripePayment(data);
        })
        .catch(() => setisProceedButtonClicked(false));
    }
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const handleAddressDelete = async () => {
    setisLoadingPrice(true);
    setIsLoading(true);
    let id = localStorage.getItem("userId") + "/" + AddressID;

    await deleteCustomerActiveAddress(id)
      .then((data) => {
        fetchCustomerActiveAddress();
        fetchPriceSummery();
        setDeleteshowAddressPopup(false);
        setIsLoading(false);

        Toast({ type: "error", message: "Address deleted successfully" });
      })
      .catch(() => setIsLoading(false));
  };

  const handleAddressSubmit = async () => {
    if (AddressID) {
      setOtpSend(true);
      setOtpmobileSend(true);
    }
    if (validator.current.allValid() && otpSend && otpmobileSend) {
      // submit action (API call)

      //cleanup
      formData["userId"] = localStorage.getItem("userId");
      formData["firstName"] = handleNameField(FirstName);
      formData["lastName"] = handleNameField(LastName);
      formData["email"] = handleNameField(Email);
      formData["mobileNumber"] = handleNameField(PhoneNumber);
      formData["id"] = handleNameField(AddressID);
      formData["countryCode"] = handleNameField(countryCode);
      formData["userTypeRole"] = "customer";

      //ormData["location"]["lat"] = "customer";
      //ormData["location"]["long"] = "customer";

      delete formData["__v"];
      let formAction = AddressID
        ? updateCustomerAddress
        : createCustomerAddress;

      await formAction(formData)
        .then(() => {
          // setFirstName(null);
          // setLastName(null);
          // setEmail(null);
          // setPhoneNumber(null);
          // setAddressID(null);
          // setFormData({
          //   AddressDetails: {
          //     country: "",
          //     state: "",
          //     address1: "",
          //     address2: "",
          //     city: "",
          //     area: "",
          //     pincode: "",
          //     location: {
          //       lat: "",
          //       long: "",
          //     }
          //   }
          // })

          // close add address popup
          setshowAdressPopup(false);
          setIsLoading(false);

          setIsDunzoCalled(false);

          fetchCustomerActiveAddress();
          fetchPriceSummery();
          SetSucessEmailPopup(false);
          SetSucesspopup(false);
          SetsendphoneSucess(false);
          setOtpSend(false);
          setOtpmobileSend(false);
          Toast({
            type: "success",
            message: AddressID
              ? "Your address has been updated successfully."
              : "Your address has been added successfully.",
          });
        })
        .catch(() => setIsLoading(false));
    } else {
      validator.current.showMessages();
      if (!otpSend) {
        Toast({
          type: "error",
          message:
            "To verify your email address, please click on the 'Send OTP' button.",
        });
      }
      if (!otpmobileSend) {
        Toast({
          type: "error",
          message:
            "To verify your mobile number, please click on the 'Send OTP' button.",
        });
      }

      setUpdateValidator(!updateValidator);
      setIsLoading(false);
    }
  };
  const displayRazorpay = async (data) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let amount = data.amount;
    const options = {
      key: process.env.REACT_APP_RAZORPAYKEY,
      amount: amount ? amount : "",
      order_id: data.id,
      currency: data.currency,
      name: "The Chef Lane",
      image: logo,
      theme: { color: "#08316d" },
      handler: function (res) {
        captureFunc(res);
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const captureFunc = async () => {
    let responseData = {
      orderId: orderData.orderId,
      paymentDate: new Date(),
    };
    try {
      await paymentDetailCaptureApi(responseData).then((data) => {
        console.log(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  let currency = "";
  let cart = {};
  if (userCart.length > 0) {
    cart = userCart[0]?.foodId;
    currency = cart?.chefId?.currency;
  }

  return (
    <>

      <div className="orderOrderCreation_container">
        {!isLoading ? (
          <div className="container">
            <div className="text-black-0 fw-700 order-container-heading line-height-28 text-center">
              Your order has been created. Your order ID is{" "}
              <span className="text-gold"> &nbsp;{orderData.orderId}</span>
            </div>
            <div className="order-parent mt-3">
              <div className="address-container">
                <div className="text-primary-color fw-700 fs-18 line-height-20 d-flex justify-content-between mb-3">
                  <span>Select Address </span>
                  <span
                    className="text-secondary-color text-underline fs-14 cursor-pointer"
                    onClick={() => newAddress()}
                  >
                    Add New Address
                  </span>
                </div>
                <div className="address-box-parent">
                  {customerAddress?.length > 0 ? (
                    <>
                      {customerAddress.map((key, index) => (
                        <div
                          key={index}
                          className={
                            selectedAddressID === key._id
                              ? "address-boxes box-active"
                              : "address-boxes"
                          }
                        >
                          <div className="address-head">
                            <div className="address-head-left">
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  setSelectedAddressID(
                                    selectedAddressID === key._id
                                      ? null
                                      : key._id
                                  );
                                  setIsDunzoCalled(false);
                                }}
                              >
                                <img
                                  src={
                                    selectedAddressID === key._id
                                      ? tick
                                      : untick
                                  }
                                  alt="icon"
                                  width={"18px"}
                                />
                                <span
                                  className="text-secondary-color fs-18 fw-700"
                                  o
                                >
                                  {key.firstName} {key.lastName}{" "}
                                </span>
                              </div>
                            </div>
                            {!key.default ? (
                              <div className="address-head-right">
                                <img
                                  src={edit_underline}
                                  alt="icon"
                                  onClick={() => editAddress(key)}
                                />
                                <img
                                  src={bin}
                                  alt="icon"
                                  onClick={() =>
                                    handleShowPopupDeleteAddress(key._id)
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="address-body fs-14">
                            <p>{key.AddressDetails.address1} </p>
                            <p>{key.AddressDetails.address2} </p>
                            <p>{key.AddressDetails.area} </p>
                            <p>
                              {key.AddressDetails.city} -{" "}
                              {key.AddressDetails.pincode}{" "}
                            </p>
                            <p>
                              {key.AddressDetails.state} -{" "}
                              {key.AddressDetails.country}{" "}
                            </p>
                            <p>
                              Ph. No: {key.countryCode} {key.mobileNumber}
                            </p>
                            <p>Email: {key.email}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="col-12 mt-5 pt-5">
                      <p className="text-center fs-16 fw-600 text-black-1c">
                        No data found !!!
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="order-container  ">
                <div className="text-primary-color fw-700 fs-18 line-height-20  ">
                  Order summary{" "}
                </div>
                <div>
                  {!isLoadingPrice ? (
                    <>
                      {foodDetails.length > 0 ? (
                        <>
                          <div className="row">
                            {foodDetails?.filter(
                              (item) =>
                                item?.foodId?.typeOfMenu === "Available Now"
                            ).length > 0 && (
                                <div className="text-black fw-700 fs-18 mb-2 mt-4">
                                  Available Now
                                </div>
                              )}
                            {foodDetails
                              .filter(
                                (item) =>
                                  item?.foodId?.typeOfMenu === "Available Now"
                              )

                              .map((list, index) => (
                                <div
                                  className="menu_card col-xl-12 col-lg-12 mt-0 pt-0 col-sm-12 col-md-12 position-relative "
                                  key={index}
                                >
                                  {" "}
                                  <div className="d-flex justify-content-between">
                                    <h4 className="fs-16 fw-400 mb-0">
                                      {list?.quantity}{" "}
                                      <span className="fs-12">✕</span>{" "}
                                      {list?.foodId?.name}{" "}
                                    </h4>
                                    <p>
                                      {currency
                                        ? getSymbolFromCurrency(currency)
                                        : "₹"}{" "}
                                      {list?.foodId?.price
                                        ? eval(
                                          list?.foodId?.price * list?.quantity
                                        )
                                        : 0}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            {foodDetails?.filter(
                              (item) =>
                                item?.foodId?.typeOfMenu === "Available Now"
                            ).length > 0 && (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <h4 className="fs-16 fw-700 mb-0">
                                      Sub Total{" "}
                                    </h4>
                                    <p className="fw-700 mb-2">
                                      {currency
                                        ? getSymbolFromCurrency(currency)
                                        : "₹"}{" "}
                                      {priceDetails?.availableNow?.itemTotal || 0}
                                    </p>
                                  </div>
                                  <hr />
                                  <div className="d-flex justify-content-between">
                                    <h4 className="fs-16 fw-400 mb-0">
                                      Delivery charges{" "}
                                    </h4>
                                    <p>
                                      {!!currency
                                        ? getSymbolFromCurrency(currency)
                                        : "₹"}{" "}
                                      {priceDetails?.availableNow?.deliveryCharges
                                        ? priceDetails?.availableNow
                                          ?.deliveryCharges
                                        : 0}
                                    </p>
                                  </div>
                                </>
                              )}
                          </div>
                          {foodDetails?.filter(
                            (item) =>
                              item?.foodId?.typeOfMenu !== "Available Now"
                          ).length > 0 && (
                              <div className="fw-700 fs-18 text-black pt-0 line-height-20 mt-1 mb-2">
                                Advanced order
                              </div>
                            )}
                          <div className="row">
                            {foodDetails?.length > 0 ? (
                              foodDetails
                                ?.filter(
                                  (item) =>
                                    item?.foodId?.typeOfMenu !== "Available Now"
                                )
                                .map((list, index) => (
                                  <div
                                    className="menu_card col-xl-12 col-lg-12 mt-0 pt-0 col-sm-12 col-md-12 position-relative "
                                    key={index}
                                  >
                                    {" "}
                                    <div className="d-flex justify-content-between">
                                      <h4 className="fs-16 fw-400 mb-0">
                                        {list?.quantity}{" "}
                                        <span className="fs-12">✕</span>{" "}
                                        {list?.foodId?.name}{" "}
                                      </h4>
                                      <p>
                                        {currency
                                          ? getSymbolFromCurrency(currency)
                                          : "₹"}{" "}
                                        {list?.foodId?.price
                                          ? list?.foodId?.price
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                ))
                            ) : (
                              <div className="col-12 mt-3 pt-">
                                <p className="text-center fs-16 fw-600 text-black-1c">
                                  No data found !!!
                                </p>
                              </div>
                            )}
                            {foodDetails?.filter(
                              (item) =>
                                item?.foodId?.typeOfMenu !== "Available Now"
                            ).length > 0 && (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <h4 className="fs-16 fw-700 mb-0">
                                      Sub Total{" "}
                                    </h4>
                                    <p className="fw-700 mb-2">
                                      {!!currency
                                        ? getSymbolFromCurrency(currency)
                                        : "₹"}{" "}
                                      {priceDetails?.advanceOrder?.itemTotal}
                                    </p>
                                  </div>

                                  <hr />
                                  <div className="d-flex justify-content-between">
                                    <h4 className="fs-16 fw-400 mb-0">
                                      Delivery charges{" "}
                                    </h4>
                                    <p>
                                      {currency
                                        ? getSymbolFromCurrency(currency)
                                        : "₹"}{" "}
                                      {priceDetails?.advanceOrder
                                        ?.deliveryCharges || 0}
                                    </p>
                                  </div>
                                </>
                              )}
                          </div>
                        </>
                      ) : (
                        <div className="col-12 mt-5 pt-5">
                          <p className="text-center fs-16 fw-600 text-black-1c">
                            No data found !!!
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <CommonPageLoader />
                  )}
                </div>

                {/* <div className="d-flex justify-content-between ">
                  <div>Tax (estimated for india )</div>
                  <div>
                    {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                    {priceDetails?.tax}
                  </div>
                </div>
                <hr /> */}
                <div className="d-flex justify-content-between">
                  <div className="text-primary-color fw-700 fs-18">
                    Grand Total
                  </div>
                  <div className="text-primary-color">
                    <b>
                      {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                      {priceDetails?.grandTotal}
                    </b>
                  </div>
                </div>
                <hr />
                {foodDetails?.[0]?.foodId?.chefId?.cashOnDelivery && (
                  <NormalCheckbox
                    label="Cash on Delivery"
                    onChange={(e) => handleChangeCOD(e)}
                    checked={isCashOnDelivery}
                  />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {!isDunzoCalled && orderData.deliveryType === "PLATFORM" ? (
                <NormalButton
                  primary
                  label="Check Availablity"
                  className="create-order-btn "
                  onClick={() => handleDunzo()}
                  disabled={isProceedButtonClicked}
                />
              ) : (
                <NormalButton
                  primary
                  label={isCashOnDelivery ? "Proceed" : "Proceed to pay"}
                  className="create-order-btn "
                  onClick={() => setShow(true)}
                  disabled={isProceedButtonClicked}
                />
              )}
            </div>
          </div>
        ) : (
          <CommonPageLoader />
        )}
      </div>
      <PageIcons />
      {/* add address popup */}

      <Modal
        show={showDeleteAddressPopup}
        onHide={handleClosePopupDeleteAddress}
        backdrop="static"
        keyboard={false}
        className="address-popup"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" text-center fw-700 fs-20">
          Are you Sure you want to Delete?
        </Modal.Body>
        <Modal.Footer>
          <NormalButton
            whiteBtn
            label={"Yes, Delete"}
            className="create-order-btn"
            onClick={handleAddressDelete}
          />
          <NormalButton
            primary
            label="No, Keep Address Info"
            onClick={handleClosePopupDeleteAddress}
            className="create-order-btn border-btn"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAdressPopup}
        onHide={handleClosePopup}
        backdrop="static"
        keyboard={false}
        className="address-popup my-address-content"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {AddressID ? "Edit Address" : "Add New Address"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={FirstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {validator.current.message("First Name", FirstName, "required")}
            </Form.Group>

            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={LastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {validator.current.message("Last Name", LastName, "required")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email ID"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={AddressID ? true : false || sendSucessEmail}
              />
              {validator.current.message("Email", Email, "required")}
              {/* {otpSend && <ErrorComponent message={"OTP Must be Verified"} />} */}
              {!sendSucess ? (
                AddressID ? (
                  ""
                ) : (
                  <div
                    className="text-end text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtpContact}
                  >
                    <span>Send OTP</span>
                  </div>
                )
              ) : (
                <>
                  <div class="text-end text-primary-color fw-500 fs-16 cursor-pointer">
                    <img src={otpsuccess} alt="otpsuccess" />
                  </div>
                </>
              )}
              <Modal
                show={sendOtpPopup}
                onHide={handleCloseEmailPopup}
                backdrop="static"
                keyboard={false}
                className="sendotp-popup"
                size="sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black-25 fs-14 text-center">
                  OTP has been sent to email id {Email}
                </Modal.Body>
                <Modal.Footer>
                  <div className="input-group fs-16">
                    <NormalInput
                      label="Enter OTP"
                      value={AddressOtp.otp}
                      onChange={handleInputOtpChange}
                      name="otp"
                    />
                    {error.otp && <ErrorComponent message={error.otp[0]} />}
                  </div>
                  {error.emailotp && (
                    <ErrorComponent message={error.emailotp[0]} />
                  )}
                  <div
                    className="set-otp mt-1 text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtpContact}
                  >
                    <span>Resend OTP</span>
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center w-100 text-center">
                    <div className="">
                      <NormalButton
                        primary
                        label="Verify"
                        className="w-100 fw-700"
                        onClick={handleOtpSubmit}
                        disabled={isButtonVerify}
                      />
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form.Group>
            <Form.Group>
              <Form.Label>Mobile Number</Form.Label>
              <div className="input-group position-relative phone-number-input flex-inherit">
                <div className="country-code-dropdown">
                  <CountryCodeDropDown
                    value={countryCode}
                    onClick={(e, cur) => {
                      handleChange({
                        target: {
                          name: "countryCode",
                          value: e,
                          currency: cur,
                        },
                      });
                    }}
                    disabled={AddressID ? true : false || sendphoneSucess}
                  />
                </div>
                <input
                  type="number"
                  placeholder="Enter mobile number"
                  value={PhoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  disabled={AddressID ? true : false || sendphoneSucess}
                />
              </div>
              {validator.current.message(
                "PhoneNumber",
                PhoneNumber,
                "required|min:10|max:20"
              )}
              {/* {otpmobileSend && (
                  <ErrorComponent message={"OTP Must be Verified"} />
                )} */}
              {!sendphoneSucess ? (
                AddressID ? (
                  ""
                ) : (
                  <div
                    className="text-end text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtphone}
                  >
                    <span>Send OTP</span>
                  </div>
                )
              ) : (
                <>
                  <div class="text-end text-primary-color fw-500 fs-16 cursor-pointer">
                    <img src={otpsuccess} alt="otpsuccess" />
                  </div>
                </>
              )}

              <Modal
                show={sendmobileOtpPopup}
                onHide={handleCloseMobilePopup}
                backdrop="static"
                keyboard={false}
                className="sendotp-popup"
                size="sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black-25 fs-14 text-center">
                  OTP has been sent to Phone Number {PhoneNumber}
                </Modal.Body>
                <Modal.Footer>
                  <div className="input-group fs-16">
                    <NormalInput
                      label="Enter OTP"
                      value={AddressOtp.otp}
                      onChange={handleInputOtpChange}
                      name="otp"
                    />
                    {error.otp && <ErrorComponent message={error.otp[0]} />}
                  </div>
                  {error.emailotp && (
                    <ErrorComponent message={error.emailotp[0]} />
                  )}
                  <div
                    className="set-otp mt-1 text-primary-color fw-500 fs-16 cursor-pointer"
                    onClick={sendOtphone}
                  >
                    <span>Resend OTP</span>
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center w-100 text-center">
                    <div className="">
                      <NormalButton
                        primary
                        label="Verify"
                        className="w-100 fw-700"
                        onClick={handlemobileOtpSubmit}
                        disabled={isButtonVerify}
                      />
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form.Group>

            <div>
              <AddressDetails
                isEdit={isEdit}
                validator={validator}
                formData={formData}
                setFormData={setFormData}
                updateValidator={updateValidator}
                isDefault={true}
              />
            </div>
          </Form>
          {!otpSend && !otpmobileSend && (
            <p className="text-center fs-16 mt-3 mb-0 text-secondary-color">
              We will send you OTP to your phone number and email ID
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <NormalButton
            whiteBtn
            label="Cancel"
            onClick={handleClosePopup}
            className="create-order-btn border-btn me-2"
          />
          <NormalButton
            primary
            label={AddressID ? "Update" : "Save"}
            className="create-order-btn"
            onClick={handleAddressSubmit}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClosePopup}
        backdrop={false}
        keyboard={false}
        className="address-popup my-address-content"
        centered
      >
        <Modal.Body className="position-relative">
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 p-3 mt-0 mb-4"
            aria-label="Close"
            onClick={() => { setShow(false) }}
          >
            {/* <IoClose size={24} /> */}
          </button>
          <h4 className="mt-4 mb-4">
            Are you sure you want to continue with this payment?
          </h4>
          <div className="d-flex justify-content-evenly mt-4 mb-4">

            <div className="col-4">
              <NormalButton
                primary
                label="Continue"
                className="w-100 fw-700"
                onClick={() => {
                  handlePayment();
                }}
              />
            </div>
            <div className="col-4">
              <NormalButton
                primary
                label="Cancel"
                className="w-100 fw-700"
                onClick={() => {
                  setShow(false)
                }}
              />
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOneOrderApi,
      getPaymentDetailApi: getPaymentDetail,
      codPaymentApi: codPayment,
      paymentDetailCaptureApi: paymentDetailCapture,
      priceSummery,
      getCustomerActiveAddress,
      createCustomerAddress,
      updateCustomerAddress,
      deleteCustomerActiveAddress,
      sendOtpAddressEmailCall: sendOtpEmailAddress,
      sendOtpVerifyAddressCall: sendOtpVerifyEmailAddress,
      sendOtpphoneAddress,
      sendOtpVerifyphoneAddress,
      deliverypartnerConnectionApi: DeliverypartnerConnection,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(OrderCreationComp);
