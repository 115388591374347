import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import whatsapp from "assets/images/whatsappChat.png";
import "./style.scss";
import { NormalButton } from "component/common/NormalButton";
import UnderReviewTrial from "./ReviewTrial/index";
import orderplace from "assets/images/orderplace.png";
import { getDeleteOrder, getChefById } from "action/CustomerDetailsAct";
import { history } from "service/helpers";
import { getDeliveryCustomer, getDeliveryStatus } from "action/AuthAct";
import marker from "assets/svg/marker.svg";
import outfordelivery from "assets/images/outfordelivery.png";
import preparingorder from "assets/images/preparingorder.png";
import orderpacked from "assets/images/orderpacked.png";
import foodordered from "assets/images/foodordered.jpg";
import fooddelivered from "assets/images/fooddelivered.jpg";
import { CommonPageLoader } from "component/common";

const ReviewOrderRate = ({
  oneOrder,
  getChefById,
  getDeleteOrder,
  trackId,
  getDeliveryStatus,
  getDeliveryCustomer,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTrial, setisTrial] = useState(false);
  const [ChefwhatsappNumber, setChefwhatsappNumber] = useState("");
  const [orderDetails, setOrderDetails] = useState(oneOrder.items[0]);
  const [isbuttonClicked, setIsbuttonClicked] = useState(false);
  const [trackStatusDetails, settrackStatusDetails] = useState({});
  const [trackStatusOrderTrack, settrackStatusOrderDetails] = useState({});
  const location = useLocation();
  const { isOwnDelivery } = location?.state || {};
  const getDeliveryDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));

  const { deliveryPartnerPhone, deliveryPartnerName } =
    getDeliveryDetails || {};

  const handleDeleteOrder = async () => {
    let id = trackId;
    setIsLoading(true);
    setIsbuttonClicked(true);
    getDeleteOrder(id)
      .then((data) => {
        if (data) {
          history.push("/index/customerProfile");
          setIsbuttonClicked(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  //setOrderDetails(oneOrder?.items[0]);
  //life cycle
  useEffect(() => {
    getChefByIdFunction();
    if (type === "review") {
      setisTrial(true);
    }
    handleStatusOrder();
  }, []);

  const handleStatusOrder = async () => {
    setIsLoading(true);
    let body = {
      orderId: trackId,
    };
    getDeliveryCustomer(body)
      .then(({ data }) => {
        settrackStatusDetails(data);
        if (
          data.orderPlaced &&
          data.orderPacked &&
          data.outForDelivery &&
          oneOrder.deliveryType !== "OWN"
        ) {
          handleStatusOrderTrack();
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const handleStatusOrderTrack = async () => {
    setIsLoading(true);
    let id = trackId;
    getDeliveryStatus(id)
      .then(({ data }) => {
        settrackStatusOrderDetails(data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const getChefByIdFunction = async () => {
    let id = oneOrder.chefId._id;
    getChefById(id)
      .then(({ data }) => {
        if (!!data) {
          setChefwhatsappNumber(data.whatsappNumber);
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      <div className="">
        <div className="main_container container-track pt-5 mb-4">
          <div className="container padding-bottom-3x mb-1">
            <div className="card mb-3">
              <div className="card-body">
                <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                  {orderDetails.orderStatus === "Deliverd" ? (
                    <div
                      className={`step order-placed ${
                        trackStatusDetails.state === "created" ||
                        trackStatusDetails.state === "queued"
                      }`}
                    >
                      <div className="step-icon-wrap">
                        <div className="step-icon" />
                      </div>
                      <h4 className="step-title fs-16 text-black fw-700 ">
                        Order placed
                      </h4>
                    </div>
                  ) : (
                    <div
                      className={`step order-place ${
                        trackStatusDetails.orderPlaced ? "completed" : ""
                      }`}
                    >
                      <div className="step-icon-wrap">
                        <div className="step-icon-image">
                          <img
                            // src={
                            //   orderDetails?.foodId?.dishImage
                            //     ? orderDetails?.foodId?.dishImage
                            //     : orderplace
                            // }
                            src={foodordered}
                            className="w-100"
                            alt="orderplace"
                          />
                        </div>
                      </div>

                      <h4 className="step-title fs-16 text-black fw-700 mt-5 pt-3">
                        Order placed
                      </h4>
                    </div>
                  )}
                  <div
                    className={`step  icons preparing-order ${
                      trackStatusDetails.preparingOrder ? "completed" : ""
                    }`}
                  >
                    {trackStatusDetails.preparingOrder ? (
                      <img src={preparingorder} className="icon" alt="" />
                    ) : null}
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>
                    <h4 className="step-title fs-16 text-black fw-700">
                      Preparing order
                    </h4>
                  </div>

                  <div
                    className={`step icons packed-order ${
                      trackStatusDetails.orderPacked ||
                      trackStatusOrderTrack.state === "pickup_complete" ||
                      trackStatusOrderTrack.state === "started_for_delivery" ||
                      trackStatusOrderTrack.state === "reached_for_delivery" ||
                      trackStatusOrderTrack.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    {trackStatusDetails.orderPacked ? (
                      <img src={orderpacked} className="icon" alt="" />
                    ) : null}
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>

                    <h4 className="step-title fs-16 text-black fw-700">
                      Order packed
                    </h4>
                  </div>

                  <div
                    className={`step icons delivery-order ${
                      trackStatusDetails.outForDelivery ||
                      trackStatusOrderTrack.state === "started_for_delivery" ||
                      trackStatusOrderTrack.state === "reached_for_delivery" ||
                      trackStatusOrderTrack.state === "delivered"
                        ? "completed"
                        : ""
                    }`}
                  >
                    {trackStatusDetails.outForDelivery ? (
                      <img src={outfordelivery} className="icon" alt="" />
                    ) : null}
                    <div className="step-icon-wrap">
                      <div className="step-icon" />
                    </div>

                    <h4 className="step-title fs-16 text-black fw-700">
                      Out for delivery
                    </h4>
                  </div>

                  {trackStatusDetails.orderDelivered ? (
                    <div className="step delivered-order order-rating">
                      <div className="step-icon-wrap">
                        <div className="step-icon-image">
                          <img src={fooddelivered} className="w-100" alt="" />
                        </div>
                      </div>
                      <h4 className="step-title fs-16 text-black mt-5 pt-4 fw-700">
                        Order Delivered
                      </h4>
                    </div>
                  ) : (
                    <div
                      className={`step icons delivered-order ${
                        trackStatusDetails.orderDelivered ? "completed" : ""
                      }`}
                    >
                      {trackStatusDetails.orderDelivered ? (
                        <img src={outfordelivery} className="icon" alt="" />
                      ) : //  <img src={fooddelivered} className="w-100" alt="" />
                      null}
                      <div className="step-icon-wrap">
                        <div className="step-icon" />
                      </div>
                      <h4 className="step-title fs-16 text-black fw-700">
                        Order Delivered
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {orderDetails.orderStatus === "REQUEST" ? (
              <div className="track-button d-flex flex-row flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
                <div>
                  <NormalButton
                    label="Cancel Order"
                    className="clear text-black-25 line-height-18 fs-16 mx-3 fw-400 p-0"
                    onClick={() => handleDeleteOrder()}
                    disabled={isbuttonClicked}
                  />
                </div>
                <div></div>
              </div>
            ) : (
              // <div className="track-button d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
              //   <NormalButton
              //     label="Rate & Review Order"
              //     className="order-review text-white line-height-18 fs-16 mx-3 fw-400 p-0"
              //     onClick={() => setisTrial(true)}
              //   />
              // </div>
              ""
            )}
          </div>
        </div>
      </div>
      {(isOwnDelivery !== "OWN" &&
        trackStatusOrderTrack?.state === "pickup_complete") ||
      trackStatusOrderTrack?.state === "started_for_delivery" ||
      trackStatusOrderTrack?.state === "reached_for_delivery" ? (
        <div>
          <h4 className="step-title fs-16 text-primary-color fw-700">
            Delivery Partner Name:{" "}
            <span className="text-black text-capitalize">
              {trackStatusOrderTrack?.runner?.name}
            </span>
          </h4>
          <h4 className="step-title fs-16 text-primary-color fw-700 mb-3">
            Delivery Partner Phone Number:{" "}
            <span className="text-black text-capitalize">
              {trackStatusOrderTrack?.runner?.phone_number}
            </span>
          </h4>
          {trackStatusOrderTrack.trackingUrl && (
            <a
              href={trackStatusOrderTrack?.trackingUrl}
              className="text-primary-color bg-white order-link px-2 py-2"
              target="_blank"
            >
              <img src={marker} className="" alt="" />
              Tracking Order
            </a>
          )}
        </div>
      ) : (
        ""
      )}

      {isOwnDelivery === "OWN" &&
      (trackStatusDetails?.outForDelivery ||
        trackStatusDetails?.orderDelivered) ? (
        <div>
          <h4 className="step-title fs-16 text-primary-color fw-700">
            Delivery Partner Name:{" "}
            <span className="text-black text-capitalize">
              {(trackStatusDetails?.partnerName ? trackStatusDetails?.partnerName : '-') || (deliveryPartnerName ? deliveryPartnerName : "-")}
            </span>
          </h4>
          <h4 className="step-title fs-16 text-primary-color fw-700 mb-3">
            Delivery Partner Phone Number:{" "}
            <span className="text-black text-capitalize">
            {(trackStatusDetails?.partnerMobileNumber ? trackStatusDetails?.partnerMobileNumber : '-') || (deliveryPartnerPhone ? deliveryPartnerPhone : "-")}            
            </span>
          </h4>
        </div>
      ) : (
        ""
      )}
      {isLoading && (
        <CommonPageLoader />
      )}
      {!isLoading && (
        <div className="whatsappImage">
          <a
            href={`https://api.whatsapp.com/send?phone=${ChefwhatsappNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="" srcset="" />
          </a>
        </div>
      )}
      {isTrial && (
        <UnderReviewTrial
          oneOrder={oneOrder}
          isTrial={isTrial}
          setisTrial={setisTrial}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getDeleteOrder, getChefById, getDeliveryCustomer, getDeliveryStatus },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ReviewOrderRate);
