const routers = [
  {
    path: "/",
    exact: true,
    redirect: "/",
  },
  {
    path: "/auth",
    exact: true,
    redirect: "/auth/login",
  },

  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "Createaccount",
        path: "/createaccount",
        componentPath: "pages/Auth/Createaccount",
        name: "Createaccount",
        auth: false,
        exact: false,
      },

      {
        component: "Customeraccount",
        path: "/Customeraccount",
        componentPath: "pages/Auth/Customeraccount",
        name: "Customeraccount",
        auth: false,
        exact: true,
      },

      {
        component: "ForgotPassword",
        path: "/forgotPassword",
        componentPath: "pages/Auth/ForgotPassword/",
        name: "ForgotPassword",
        auth: false,
        exact: false,
      },
      {
        component: "ResetPassword",
        path: "/resetpassword",
        componentPath: "pages/Auth/ResetPassword",
        name: "ResetPassword",
        auth: false,
        exact: false,
      },

      {
        component: "Signup",
        path: "/signup",
        componentPath: "pages/Auth/Signup",
        name: "Signup",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "HomeLayout",
    path: "/",
    auth: false,
    exact: true,
    childrens: [
      {
        component: "Landing",
        path: "",
        componentPath: "pages/Website/Landing",
        name: "Landing",
        auth: true,
        exact: true,
      },
    ],
  },
  {
    component: "WebsiteLayout",
    path: "/index",
    auth: true,
    exact: false,
    childrens: [
      {
        component: "Home",
        path: "/home",
        componentPath: "pages/Website/Home",
        name: "Home",
        auth: true,
        exact: true,
      },
      {
        component: "UnderReview",
        path: "/underReview",
        componentPath: "pages/Website/UnderReview",
        name: "UnderReview",
        auth: true,
        exact: false,
      },
      {
        component: "Orderplaced",
        path: "/orderplaced/:orderId",
        componentPath: "pages/Website/Orderplaced",
        name: "Orderplaced",
        auth: true,
        exact: true,
      },
      {
        component: "Orderplaced",
        path: "/orderplaced/success/:orderId",
        componentPath: "pages/Website/orderplaced",
        name: "Orderplaced",
        auth: true,
        exact: true,
      },
      {
        component: "SuccessOrder",
        path: "/subscription/success/:orderId",
        componentPath: "pages/Website/SuccessOrder",
        name: "SuccessOrder",
        auth: true,
        exact: true,
      },
      {
        component: "CancelOrder",
        path: "/orderplaced/cancel/:orderId",
        componentPath: "pages/Website/CancelOrder",
        name: "CancelOrder",
        auth: true,
        exact: true,
      },
      {
        component: "SuccessSubscription",
        path: "/success-subscription",
        componentPath: "pages/Website/SuccessSubscription",
        name: "SuccessSubscription",
        auth: true,
        exact: true,
      },
      {
        component: "CancelSubscription",
        path: "/cancel-subscription",
        componentPath: "pages/Website/CancelSubscription",
        name: "CancelSubscription",
        auth: true,
        exact: true,
      },
      {
        component: "UserDetail",
        path: "/userDetail",
        componentPath: "pages/Website/UserDetail",
        name: "UserDetail",
        auth: true,
        exact: true,
      },
      {
        component: "ChefProfile",
        path: "/profile",
        componentPath: "pages/Website/ChefProfile",
        name: "ChefProfile",
        auth: true,
        exact: true,
      },
      {
        component: "ActiveProfilePage",
        path: "/activeProfile",
        componentPath: "pages/Website/activeProfile",
        name: "activeProfile",
        auth: true,
        exact: true,
      },
      {
        component: "CustomerProfilePage",
        path: "/customerProfile",
        componentPath: "pages/Website/activeProfile",
        name: "customerProfile",
        auth: true,
        exact: true,
      },
      {
        component: "PrivacyPolicy",
        path: "/pp",
        componentPath: "pages/Website/PrivacyPolicy",
        name: "PrivacyPolicy",
        auth: false,
        exact: true,
      },
      {
        component: "Faq",
        path: "/faq",
        componentPath: "pages/Website/Faq",
        name: "Faq",
        auth: false,
        exact: true,
      },
      {
        component: "AboutUs",
        path: "/aboutUs",
        componentPath: "pages/Website/aboutUs",
        name: "AboutUs",
        auth: false,
        exact: true,
      },
      {
        component: "Mission",
        path: "/mission",
        componentPath: "pages/Website/mission",
        name: "mission",
        auth: false,
        exact: true,
      },
      {
        component: "TermsAndCondition",
        path: "/t&c",
        componentPath: "pages/Website/TermsAndCondition",
        name: "TermsAndCondition",
        auth: false,
        exact: true,
      },
      {
        component: "Licence",
        path: "/l&r",
        componentPath: "pages/Website/Licence",
        name: "Licence",
        auth: true,
        exact: true,
      },
      {
        component: "ContactUs",
        path: "/contactUs",
        componentPath: "pages/Website/ContactUs",
        name: "ContactUs",
        auth: false,
        exact: true,
      },
      {
        component: "Notifications",
        path: "/notifications",
        componentPath: "pages/Website/Notifications",
        name: "Notifications",
        auth: true,
        exact: true,
      },
      {
        component: "TrackOrder",
        path: "/trackorder/:trackId/:type",
        componentPath: "pages/Website/TrackOrder",
        name: "TrackOrder",
        auth: true,
        exact: true,
      },
      {
        component: "ChefTrackOrder",
        path: "/cheftrackorder/:trackId/:type",
        componentPath: "pages/Website/ChefTrackorder",
        name: "ChefTrackOrder",
        auth: true,
        exact: true,
      },
      {
        component: "CartSummary",
        path: "/cart",
        componentPath: "pages/Website/CartSummary",
        name: "CartSummary",
        auth: true,
        exact: true,
      },
      {
        component: "DeliveryDetails",
        path: "/cart/delivery-details/:orderId",
        componentPath: "pages/Website/DeliveryDetails",
        name: "DeliveryDetails",
        auth: true,
        exact: true,
      },
      {
        component: "OrderCreation",
        path: "/order-creation/:orderId",
        componentPath: "pages/Website/OrderCreation",
        name: "OrderCreation",
        auth: true,
        exact: true,
      },
      {
        component: "ChefPdfPage",
        path: "/chefpdf/:chefId/:type",
        componentPath: "pages/Website/ChefPdfPage",
        auth: true,
        exact: true,
      },
      {
        component: "OwnPartnerOrder",
        path: "/ownPartner-order-verify",
        componentPath: "pages/Website/OwnPartnerOrder",
        name: "ownPartner-order-verify",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "ChefViewLayout",
    path: "/:businessName",
    auth: false,
    exact: true,
    childrens: [
      {
        component: "ChefDetail",
        path: "",
        componentPath: "pages/Website/ChefDatail",
        name: "ChefDetail",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
