import React, { Component } from "react";

export class Pagination extends Component {
  handlePagination = (direction) => {
    try {
      let { page = 1 } = this.props.pageMeta || {};
      let pageNumber = eval(`${page} ${direction} ${1}`);
      this.props.handleChange({ page: pageNumber });
    } catch (err) {}
  };

  handleLastPage = (totalPages) => {
    this.props.handleChange({ page: totalPages });
  };

  render() {
    let { totalPages = 10, page = 1 } = this.props.pageMeta || {};
    if (totalPages <= 1) {
      return "";
    }

    let { totalMembers = 0, isTotalEntriesNeeded = false } = this.props;

    const itemsPerPage = 5; // Number of pages to show at a time
    const currentSet = Math.ceil(page / itemsPerPage);
    const startPage = (currentSet - 1) * itemsPerPage + 1;
    const endPage = Math.min(startPage + itemsPerPage - 1, totalPages);

    const pageArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <div className="pagination-area flex-column">
        {isTotalEntriesNeeded ? (
          <div>
            <p className="fs-16 mb-2 fw-600 text-primary-color d-flex justify-content-center align-items-center">
              You have a total of {totalMembers} members
            </p>
          </div>
        ) : null}
        <div className="d-flex text-center w-100 justify-content-center pr-4">
          <p className="m-0 d-flex align-items-center">
            <button
              className={`btn-effect table-left-arrow ${
                page === 1 ? "disabled" : "cursor-pointer"
              }`}
              disabled={page === 1}
              onClick={(e) => page !== 1 && this.handlePagination("-")}
            >
              <span className="icon-page-left pr-3" />
              Previous
            </button>

            {pageArray.map((pageNumber, index) => {
              const isWithinRange = pageNumber >= startPage && pageNumber <= endPage;

              if (isWithinRange) {
                return (
                  <span
                    key={pageNumber}
                    className={`page-number mx-3 ${
                      page === pageNumber ? "current-page" : "cursor-pointer"
                    }`}
                    onClick={() =>
                      page !== pageNumber &&
                      this.props.handleChange({ page: pageNumber })
                    }
                  >
                    {pageNumber}
                  </span>
                );
              }

              if (index === endPage + 1 && endPage < totalPages - 1) {
                return (
                  <span
                    key={pageNumber}
                    className="page-dot cursor-pointer"
                    onClick={() =>
                      this.props.handleChange({
                        page: Math.min(endPage + 1, totalPages - 1),
                      })
                    }
                  >
                    ...
                  </span>
                );
              }

              return null;
            })}

            <button
              className={`btn-effect table-right-arrow ${
                page === totalPages ? "disabled" : "cursor-pointer"
              }`}
              disabled={page === totalPages}
              onClick={(e) => page !== totalPages && this.handlePagination("+")}
            >
              Next
              <span className="pl-3 icon-page-right" />
            </button>
          </p>
        </div>
      </div>
    );
  }
}
