import {
    atom,
    selector
  } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const cartItems = atom({
    key: 'cartItems',
    default: [],
    effects_UNSTABLE: [persistAtom]
});