import React, { useState, useEffect } from "react";
import "./style.scss";
import { NormalButton, CommonPageLoader } from "component/common";
import MenuCard from "./MenuCard";
import { history } from "service/helpers";
import {
  getAllCartById,
  priceSummery,
  handleAddToCartAll,
  createOrder,
  clearCart,
} from "action/cartAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { convertToSlug } from "service/helperFunctions";
import getSymbolFromCurrency from "currency-symbol-map";
import { localCartType } from "service/actionType";
import { useDispatch } from "react-redux";
import { PageIcons } from "component/common";
import { useRecoilState } from "recoil";
import { cartItems } from "coils";
import { Modal } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CartComp = ({
  getAllCartById,
  totalCartLocal,
  userCart = [],
  priceSummery,
  handleAddToCartAll,
  createOrder,
  clearCart,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCart, setIsLoadingCart] = useState(true);
  const [isButtonDisabled, setIsbuttonDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [priceDetails, setPriceDetails] = useState(null);
  const userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  const authToken = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const [allCartItems, setAllCartItems] = useRecoilState(cartItems);
  const location = useLocation();


  //life cycle
  useEffect(() => {
    if (userDetails?._id) {
      getAllCartItems();
      fetchPriceSummery(userDetails?._id);
    }
  }, [userDetails?._id]);

  useEffect(() => {
    if (userDetails?._id) {
      fetchCartDetails();
    } else {
      setData(totalCartLocal);
      setIsLoadingCart(false);
    }
    const initialLocalCart = JSON.parse(localStorage.getItem("localCart"));
    if (
      !!initialLocalCart &&
      initialLocalCart.length &&
      !!authToken &&
      userDetails?._id
    ) {
      handleAllItemsToCart(initialLocalCart);
    }
  }, [totalCartLocal, userCart]);

  const fetchCartDetails = async () => {
    setData([...userCart]);
  };

  const fetchPriceSummery = async () => {
    setIsLoading(false);

    let body = {
      userId: userDetails?._id,
    };
    setIsLoading(true);
    await priceSummery(body)
      .then((data) => {
        if (!!data) {
          setPriceDetails(data.priceSummary);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleAllItemsToCart = async (arr) => {
    let body = {
      userId: userDetails?._id,
      foodId: arr.map((item) => {
        return { id: item._id, quantity: item?.quantity ? item.quantity : 1 };
      }),
    };
    localStorage.removeItem("localCart");
    setIsLoading(true);
    await handleAddToCartAll(body)
      .then((data) => {
        if (!!data) {
          getAllCartItems();
          fetchPriceSummery(userDetails?._id);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleCreateOrder = () => {
    let chefId = userCart[0].foodId?.chefId;
    let body = {
      chefId: chefId?._id,
      userId: userDetails?._id,
      items: userCart.map((item) => {
        return {
          foodId: item.foodId._id,
          quantity: item.quantity,
          price: item.foodId.price,
          status: "REQUEST",
        };
      }),
      cartValue: priceDetails?.grandTotal,
      notes: "best food",
      tax: priceDetails?.tax,
      isPriceMatch: true,
      paymentId: "id",
      paymentStatus: "done",
      paymentType: "cod",
      orderStartDate: new Date(),
      orderEndDate: new Date(),
      colorStatus: 154,
      estimatedDeliveryDate: new Date(),
      cancelReason: "nothing",
      paymentDate: new Date(),
      paymentGateway: 69,
      cancelledByName: "john",
      cancelledByUserId: "id",
    };
    createOrder(body).then(({ data }) => {
      if (!!data) {
        localStorage.removeItem("localCart");
        history.push(`/index/order-creation/${data._id}`);
        fetchPriceSummery();
      }
    });
  };

  const getAllCartItems = async () => {
    setIsLoadingCart(true);
    let body = {
      userId: userDetails?._id,
    };
    await getAllCartById(body)
      .then(() => {
        fetchCartDetails();
        setIsLoadingCart(false);
      })
      .catch(() => setIsLoadingCart(false));
  };

  let currency = "";
  if (userCart.length > 0) {
    let cart = userCart[0]?.foodId;
    currency = cart?.chefId?.currency;
  }

  const handleClearCart = async () => {
    let id = userDetails?._id;
    setIsLoadingCart(true);
    setIsbuttonDisabled(true);
    if (!!authToken) {
      await clearCart(id)
        .then(() => {
          setAllCartItems([]);
          dispatch({ type: localCartType.totalCartLocal, payload: [] });
          setIsLoadingCart(false);
          setIsbuttonDisabled(false);
          window.location.reload();
        })
        .catch(() => {
          setIsLoadingCart(false);
          setIsbuttonDisabled(false);
        });
    } else {
      setAllCartItems([]);
      dispatch({ type: localCartType.totalCartLocal, payload: [] });
      setIsLoadingCart(false);
      setIsbuttonDisabled(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="cart_container ">
        <div className="container-fluid pt-1 pb-2 px-5">
          <div className="row ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xm-12 left-right-container">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="text-black-00 fw-700 fs-24  cart-summary">
                  Cart summary
                </div>
                <div className="add-more-div d-flex mt-md-0 mt-1">
                  <NormalButton
                    optionButton
                    label="Clear Cart"
                    className="text-primary-color fw-700 fs-24 add-clear me-3"
                    onClick={() => handleClearCart()}
                    disabled={data?.length === 0}
                  />
                  <NormalButton
                    optionButton
                    label="Add more"
                    className="text-black-00 fw-700 fs-24 add-more"
                    onClick={() => {
                      if (!!authToken && data.length > 0) {
                        let { foodId } = data[0];
                        let { businessNameSlug } = foodId.chefId;
                        history.push(`/${convertToSlug(businessNameSlug)}`);
                      } else if (data.length > 0) {
                        let { businessNameSlug } = data[0];
                        history.push(`/${convertToSlug(businessNameSlug)}`);
                      } else {
                        history.push(`/`);
                      }
                    }}
                  />
                </div>
              </div>
              {!isLoadingCart ? (
                <>
                  {data?.length > 0 ? (
                    <>
                      <div className="row">
                        {data
                          ?.filter((item) =>
                            !!authToken
                              ? item?.foodId?.typeOfMenu === "Available Now"
                              : item?.typeOfMenu === "Available Now"
                          )
                          .map((list, index) => (
                            <div
                              className="menu_card col-xl-12 col-lg-12 mt-3 pt-1 col-sm-12 col-md-12 position-relative "
                              key={index}
                            >
                              <MenuCard
                                cartDetails={list}
                                fetchPriceSummery={fetchPriceSummery}
                                setIsbuttonDisabled={setIsbuttonDisabled}
                              />
                            </div>
                          ))}
                      </div>
                      {data?.filter((item) =>
                        !!authToken
                          ? item?.foodId?.typeOfMenu !== "Available Now"
                          : item?.typeOfMenu !== "Available Now"
                      ).length > 0 && (
                          <div className="fw-700 fs-18 text-primary-color pt-2 line-height-20 mt-3">
                            Advanced order for tommorrow
                          </div>
                        )}
                      <div className="row">
                        {data?.length > 0 ? (
                          data
                            ?.filter((item) =>
                              !!authToken
                                ? item?.foodId?.typeOfMenu !== "Available Now"
                                : item?.typeOfMenu !== "Available Now"
                            )
                            .map((list, index) => (
                              <div
                                className="menu_card col-xl-12 col-lg-12 mt-3 pt-1 col-sm-12 col-md-12 position-relative "
                                key={index}
                              >
                                <MenuCard
                                  cartDetails={list}
                                  fetchPriceSummery={fetchPriceSummery}
                                  setIsbuttonDisabled={setIsbuttonDisabled}
                                />
                              </div>
                            ))
                        ) : (
                          <div className="col-12 mt-3 pt-3">
                            <p className="text-center fs-16 fw-600 text-black-1c">
                              No data found !!!
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="col-12 mt-5 pt-5">
                      <p className="text-center fs-16 fw-600 text-black-1c">
                        No data found !!!
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <CommonPageLoader />
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12  col-xm-12 left-right-container">
              {!!authToken ? (
                <div>
                  {!isLoading ? (
                    <>
                      <div className="text-black-00 fw-700 fs-24">
                        Price summary
                      </div>
                      <div>
                        <div className="text-black-00 fw-700 fs-18 mb-2 mt-4">
                          Available Now
                        </div>
                        <div className="d-flex justify-content-between">
                          <h4 className="fs-16 fw-400 mb-0">Item Total </h4>
                          <p>
                            {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                            {priceDetails?.availableNow?.itemTotal
                              ? priceDetails?.availableNow?.itemTotal
                              : 0}
                          </p>
                        </div>
                        {/* <div className="d-flex justify-content-between">
                          <h4 className="fs-16 fw-400 mb-0">
                            Delivery charges{" "}
                          </h4>
                          <p>
                            {!!currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                            {priceDetails?.availableNow?.deliveryCharges
                              ? priceDetails?.availableNow?.deliveryCharges
                              : 0}
                          </p>
                        </div> */}
                      </div>
                      <hr />
                      {Object.keys(priceDetails?.advanceOrder).length > 0 && (
                        <div>
                          <div className="text-black-00 fw-700 fs-18 mb-2">
                            Advanced order
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4 className="fs-16 fw-400 mb-0">Item Total </h4>
                            <p>
                              {!!currency
                                ? getSymbolFromCurrency(currency)
                                : "₹"}{" "}
                              {priceDetails?.advanceOrder?.itemTotal}
                            </p>
                          </div>
                          {/* <div className="d-flex justify-content-between">
                            <h4 className="fs-16 fw-400 mb-0">
                              Delivery charges{" "}
                            </h4>
                            <p>
                              {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                              {priceDetails?.advanceOrder?.deliveryCharges}
                            </p>
                          </div> */}
                          <hr />
                        </div>
                      )}

                      {/* <div className="d-flex justify-content-between">
                        <div>
                          Tax{" "}
                          <span className="text-grey-67">
                            (estimated for india)
                          </span>
                        </div>
                        <div>
                          {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                          {priceDetails.tax}
                        </div>
                      </div>
                      <hr /> */}
                      <div className="d-flex justify-content-between">
                        <div className="text-primary-color fw-700 fs-18">
                          Grand Total
                        </div>
                        <div className="text-primary-color fw-700 fs-18">
                          {currency ? getSymbolFromCurrency(currency) : "₹"}{" "}
                          {priceDetails.grandTotal}
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-center mt-5 mb-3">
                        {data.length > 0 && (
                          <NormalButton
                            label="Create order"
                            className="primary-btn create-order-btn"
                            onClick={() => handleCreateOrder()}
                            disabled={isButtonDisabled}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <CommonPageLoader />
                  )}
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center h-100">
                  <div className="text-black-00 fw-700 fs-18 mb-2 mt-4 text-center">
                    Login to Proceed
                  </div>
                  <hr className="w-100" />
                  <div className="d-flex justify-content-center mt-3">
                    <NormalButton
                      label="Login"
                      className="primary-btn create-order-btn"
                      onClick={() =>
                        history.push("/auth/login?userTypeRole=customer")
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <PageIcons />
    </>
  );
};

const mapStateToProps = (state) => ({
  totalCartLocal: state.localCart,
  userCart: state.commonStore.totalCart,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllCartById,
      priceSummery,
      handleAddToCartAll,
      createOrder,
      clearCart,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CartComp);
