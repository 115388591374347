import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { LoginComp } from "component/auth/login/index";
export class LoginClass extends Component {
  render() {
    return <LoginComp />;
  }
}
export const Login = connect(null, null)(LoginClass);
