import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

import cheflist from "assets/images/customerConnection.png";
import vegIcon from "assets/images/veg.png";
import nonvegIcon from "assets/images/nonveg.png";

const OrderAvailable = ({
  food,
  orderDetailsActive,
  orderUpdateStatus,
  OrderActiveApi,
}) => {
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [ViewMore, setViewMore] = useState(false);
  const [acceptDate, setAcceptDate] = useState(null);
  const [updateValidator, setUpdateValidator] = useState(true);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const handleViewmore = () => {
    setViewMore(!ViewMore);
  };
  //Add Order handleUpdateStatus
  const handleUpdateStatus = async (status) => {
    let query = {
      orderId: orderDetailsActive._id,
      orderStatus: status,
      foodId: food?.foodId?._id,
      orderAcceptedDate: acceptDate,
    };
    let setLoader =
      status === "ACCEPT"
        ? setAcceptLoader
        : status === "DECLINE"
          ? setCancelLoader
          : setFormLoader;
    if (validator.current.allValid() || status === "DECLINE") {
      setLoader(true);
      await orderUpdateStatus(query)
        .then(() => {
          OrderActiveApi();
          setLoader(false);
        })
        .catch(() => setLoader(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };
  return (
    <>
      <div className=" mb-3 chef_detail_inner_container p-3">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-8 order-image-column">
            <div className="d-flex outline flex-column">
              <div className="">
                <div className="d-flex ">
                  <div className="">
                    <div className="foodimage">
                      <img
                        src={
                          !!food?.foodId?.dishImage
                            ? food?.foodId?.dishImage
                            : cheflist
                        }
                        alt="dishImage"
                      />
                    </div>
                    {!ViewMore ? (
                      <div
                        className="d-md-none d-lg-none d-block"
                        onClick={handleViewmore}
                      >
                        <span className="fs-12 fw-bold text-primary-color cursor-pointer">
                          {" "}
                          View More
                        </span>
                      </div>
                    ) : (
                      <div
                        className="d-md-none d-lg-none d-block"
                        onClick={handleViewmore}
                      >
                        <span className="fs-12 fw-bold text-primary-color cursor-pointer">
                          {" "}
                          View Less
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="menucard_description  border-left-responsive ml-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex mb-3">
                        <div className="foodname fs-18 line-height-20 fw-700 text-grey-7c d-flex">
                          {food?.foodId?.name}
                          <div className="nonVageIcons ms-3">
                            <span className="mx-0">
                              <img src={food.foodId.dishCategory === "Non Veg" ? nonvegIcon : vegIcon} alt="icons" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    Quantity - {food.quantity}
                    {" * "} ₹{food.price}
                    <div className="d-block d-md-none p-2 mt-2 text-left">
                      ₹{food.price * food.quantity}
                    </div>
                    <div className="d-md-none d-lg-none d-block mt-3">
                      {food.orderStatus === "REQUEST" ? (
                        <>{""}</>
                      ) : (
                        <p className="fs-14 text-gray-black ">
                          {" "}
                          {food.orderStatus === "DECLINE"
                            ? "Declined "
                            : food.orderStatus === "PACKED"
                              ? "Packed on "
                              : "Accepted on "}
                          {food.orderStatus === "ACCEPT" ? (
                            <>
                              {moment(
                                orderDetailsActive.orderAcceptedDate
                              ).format("Do MMM YYYY")}
                              , <br />
                              at{" "}
                              {moment(
                                orderDetailsActive.orderAcceptedDate
                              ).format("LT")}
                            </>
                          ) : (
                            <>
                              {moment(orderDetailsActive.orderEndDate).format(
                                "Do MMM YYYY"
                              )}
                              , <br />
                              at{" "}
                              {moment(orderDetailsActive.orderEndDate).format(
                                "LT"
                              )}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-block  d-lg-block   d-none  col-xs-12 col-sm-12 col-lg-4 justify-content-center align-item-center">
            <div className="p-4 mt-2 text-end">
              ₹{food.price * food.quantity}
            </div>
          </div>
          {ViewMore ? (
            <div className="row mt-4 d-md-none d-lg-none d-block">
              <div className="col-12 me-4">
                <h4 className="fs-14 fw-700"> Placed By</h4>
                <div className="fs-14 fw-4=500 text-grey-7c">
                  {orderDetailsActive.userId?.firstName}
                </div>

                <div className="d-flex">
                  <p className="fs-14 fw-700 text-grey-7c me-4">
                    {orderDetailsActive.addressId?.address1}
                  </p>
                  <p className="fs-14 fw-700 text-grey-7c">
                    {orderDetailsActive.addressId?.city} ,
                    {orderDetailsActive.addressId?.state} ,
                    {orderDetailsActive.addressId?.pincode}
                  </p>
                </div>
              </div>
              <div className="col-12">
                <h4 className="fs-14 fw-700 text-nowrap"> Placed at </h4>
                <div className="d-flex">
                  <p className="fs-14 fw-700 text-grey-7c">
                    {" "}
                    {moment(orderDetailsActive.orderEndDate).format("hA")}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(null, mapDispatchToProps)(OrderAvailable);