import React from 'react'
import './styles.scss'
import rightIcon from 'assets/images/rightIcon.png'
function ProfileNavBar({ currentForm, setcurrentForm }) {
  return (
    <div className="bg-white">
      <div className="container profile_nav_container">
        <div
          className="nav_content "
          // onClick={() => setcurrentForm('basicDetails')}
        >
          <div className="nav_number active_number">1</div>
          <div
            className={
              currentForm === 'basicDetails'
                ? 'nav_text active_text'
                : 'nav_text'
            }
          >
            Profile
          </div>

          <span className="">
            <img src={rightIcon} alt="icons" />
          </span>
        </div>

        <div
          className="nav_content "
          // onClick={() => setcurrentForm('addressDetails')}
        >
          <div
            className={
              currentForm === 'basicDetails'
                ? 'nav_number inactive_number'
                : 'nav_number active_number'
            }
          >
            2
          </div>
          <div
            className={
              currentForm === 'addressDetails'
                ? 'nav_text active_text'
                : 'nav_text'
            }
          >
            Location
          </div>
          <span className="">
            <img src={rightIcon} alt="icons" />
          </span>
        </div>

        <div
          className="nav_content "
          // onClick={() => setcurrentForm('menuDetails')}
        >
          <div
            className={
              currentForm === 'menuDetails'
                ? 'nav_number active_number'
                : 'nav_number inactive_number'
            }
          >
            3
          </div>
          <div
            className={
              currentForm === 'menuDetails'
                ? 'nav_text active_text'
                : 'nav_text'
            }
          >
            Menu
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileNavBar
