import React, { useEffect, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { Marker } from "component/common";

import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";
import { Toast } from "service/toast";
import markerIcon from "assets/svg/marker.svg";

import GoogleMapReact from "google-map-react";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import currentLocation from "assets/svg/currentLocation.svg";

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    // minZoom: 11,
    // maxZoom: 18,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },
    draggableCursor: "pointer",

    // zoomControl: false,
    // scrollwheel: false,
    // clickableIcons: false,
    // draggable: false,
  };
};

function AddressDetails({
  formData,
  setFormData,
  isEdit,
  setFormDataAddress,
  validator,
  updateValidator,
  isDefault = false,
}) {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [defaultZoom, setDefaultZoom] = useState(18);
  const [getLocationLoader, setGetLocationLoader] = useState(false);
  //life cycle
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!formData.AddressDetails?.location?.lat) getLocation();
    }, 1000);
    return () => clearTimeout(timer);
  }, [updateValidator]);

  var optionsPosition = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    setGetLocationLoader(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        optionsPosition
      );
      setDefaultZoom(12);
    } else {
      Toast({
        type: "error",
        message: "Geolocation is not supported by this browser.",
      });
    }
  };
  const showPosition = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: lat,
      long: lon,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    navigatorGeoLocation(lat, lon).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      handleLocationGoogle(address);
      setGetLocationLoader(false);
    });
  };
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  };
  const handleLatLng = (data) => {
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: data.lat,
      long: data.lng,
    };

    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
  };
  const handleMApClick = ({ lat, lng }) => {
    // let userLocation = 
    navigatorGeoLocation(lat, lng).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      handleLocationGoogle(address);
    });
  };
  const handleLocationGoogle = (e) => {
    let { city, area, pincode, region, state, street } = e;
    let { AddressDetails } = formData;
    AddressDetails.country = region ? region : AddressDetails.country;
    AddressDetails.city = city ? city : AddressDetails.city;
    AddressDetails.area = area ? area : AddressDetails.area;
    AddressDetails.pincode = pincode ? pincode : AddressDetails.pincode;
    AddressDetails.state = state ? state : AddressDetails.state;
    AddressDetails.address1 = street ? street : AddressDetails.address1;
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    // scrollTop()
  };

  let {
    AddressDetails: { location },
  } = formData;

  return (
    <div id="address-custom-customer">
      <div className="address_detail_inner_container">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="header">
              Map{" "}
              <span className="fs-16 fw-500">
                (Please select location on the map first)
              </span>
            </div>
          </div>
          <hr></hr>
        </div>
        <div className="body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-12 mb-5">
              <div id="map-outline" className="position-relative">
                <div id="map">
                  <GoogleMapReact
                    center={
                      location
                        ? {
                          lat: Number(location.lat),
                          lng: Number(location.long),
                        }
                        : {
                          lat: 13.0827,
                          lng: 80.2707,
                        }
                    }
                    zoomControl={isEdit}
                    scrollwheel={isEdit}
                    clickableIcons={false}
                    draggable={isEdit}
                    defaultZoom={18}
                    zoom={defaultZoom}
                    onClick={(e) => {
                      let { lat, lng } = e;
                      if (isEdit) {
                        let { AddressDetails } = formData;
                        AddressDetails.location = {
                          lat: lat,
                          long: lng,
                        };
                        setFormData((prevState) => ({
                          ...prevState,
                          AddressDetails,
                        }));
                        handleMApClick(e);
                      }
                    }}
                    options={getMapOptions}
                  >
                    {location && (
                      <Marker
                        lat={location.lat}
                        lng={location.long}
                        name="My Marker"
                        color=""
                        isInfo={true}
                        infoWindowOpen={infoWindowOpen}
                        infoWindowContent={
                          <div className="info-outline-small d-flex justify-content-center">
                            <div className="">
                              <span className="text-red fs-12">
                                You are here!
                              </span>
                            </div>
                            <span className="rectang"></span>
                          </div>
                        }
                        children={
                          <img
                            src={markerIcon}
                            onClick={() => {
                              setInfoWindowOpen(!infoWindowOpen);
                            }}
                            alt="icon"
                          />
                        }
                      />
                    )}
                  </GoogleMapReact>
                  {isEdit && (
                    <LocationSearchInput
                      disabled={!isEdit}
                      isMap={"map"}
                      address={address}
                      handleAddress={(e) => handleLocationGoogle(e)}
                      placeholder=""
                      handleSelect={(address) => setAddress(address)}
                      handleLatLng={handleLatLng}
                      handleFind={() => { }}
                      handleCurrentLocation={() => getLocation()}
                      getLocationLoader={getLocationLoader}
                    />
                  )}
                  <div
                    id="custom-current-loaction"
                    className="verify d-flex align-items-center newEdit cursor-pointer "
                    onClick={() => getLocation()}
                    title="Current location"
                  >
                    <img
                      src={currentLocation}
                      alt="icons"
                      className={getLocationLoader ? "opacity-5" : "opacity-1"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-12">
              <div className="header">Address Detail</div>
              <hr />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  placeholder={`${isEdit ? "Enter Country" : ""}`}
                  disabled={formData.default || isDefault ? true : false}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.country = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.country}
                />
                {validator.current.message(
                  "Country",
                  formData.AddressDetails.country,
                  "required|min:3|alpha_space"
                )}
              </Form.Group>
            </div>

            <div className="col-xs-12 col-sm-6">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>State</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  placeholder={`${isEdit ? "Enter State" : ""}`}
                  disabled={formData.default || isDefault ? true : false}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.state = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.state}
                />
                {validator.current.message(
                  "State",
                  formData.AddressDetails.state,
                  "required|alpha_space"
                )}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>Address line 1</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  placeholder={`${isEdit ? "Enter Address" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.address1 = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.address1}
                />
                {validator.current.message(
                  "Address",
                  formData.AddressDetails.address1,
                  "required"
                )}
              </Form.Group>
            </div>
            <div className="col-sm-8">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>Address line 2</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  placeholder={`${isEdit ? "Enter Optional Address" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.address2 = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.address2}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>Area</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  placeholder={`${isEdit ? "Enter area" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.area = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.area}
                />
                {validator.current.message(
                  "Area",
                  formData.AddressDetails.area,
                  "required"
                )}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  placeholder={`${isEdit ? "Enter City" : ""}`}
                  disabled={formData.default || isDefault ? true : false}
                  type="text"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.city = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.city}
                />
                {validator.current.message(
                  "City",
                  formData.AddressDetails.city,
                  "required"
                )}
              </Form.Group>
            </div>

            <div className="col-xs-12 col-sm-6">
              <Form.Group style={{ width: "100%" }}>
                <Form.Label>Pin Code</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  placeholder={`${isEdit ? "Enter Pin Code" : ""}`}
                  disabled={formData.default || isDefault ? true : false}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.pincode = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.pincode}
                />
                {validator.current.message(
                  "Pincode",
                  formData.AddressDetails.pincode,
                  "required|numeric"
                )}
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddressDetails;
