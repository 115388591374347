import React from "react";
import "./style.scss";

export const TermsAndCondition = () => {
  return (
    <div className="termsAndCondition_container_background">
      <div className="container termsAndCondition_container ">
        <h1 className="text-primary-color line-height-42 fs-31 fw-700 text-center ">
          Terms & Conditions
        </h1>
        <div className="first_line_termsAndCondition  text-black-25 line-height-20 fs-16 fw-400 ">
          <p>
            These terms and conditions (“Terms”) and the privacy policy (as
            available on the url https://www.thecheflane.com/terms) (“Privacy
            Policy”) (collectively “User Agreement”) forms an electronic record
            in terms of Information Technology Act, 2000 (“IT Act”) and rules
            made there under, as applicable, and the amended provisions
            pertaining to electronic records in various statutes, as amended
            from time to time.
          </p>
          <p>
            This User Agreement is published in accordance with the provisions
            of Rule 3(1) of the Information Technology (Intermediaries
            Guidelines) Rules, 2011 that require publishing the rules and
            regulations, privacy policy and terms and conditions for access or
            usage of the website www.thecheflane.com (including the uniform
            resource locator created for each Merchant) (“Website”), and the
            uniform resource locator created for each Merchant (the ‘Website’,
            the ‘App’ is collectively hereinafter referred to as the
            “Platform”), operated by Chef Lane Online Services Private Limited, a company incorporated under
            the laws of India and its registered office at SP7A Guindy
            Industrial Estate, Guindy, Chennai - 600032, Tamilnadu, India.
          </p>

          <p>
            <b className="subheading"> General </b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              For the purposes of these Terms, ‘User’ and wherever the context
              may require, ‘You’ (or any variation thereof), shall mean any
              natural or legal person (including any legal heirs, administrators
              or successors) who has agreed to become a user of the Platform by
              accessing or using the Platform. If You are accepting these Terms
              and using the Services (as defined below) or the Platform on
              behalf of a company, organisation, government, or other legal entity,
              You represent and warrant that You are authorised to do so.
            </li>
            <li>
              Unless the context otherwise requires, “Chef Lane Online Services Private Limited’’, “We”,
              “us”, “our” or “Firm” shall mean Chef Lane Online Services Private Limited or any of its
              licensees, whether now or in the future.
            </li>
            <li>
              The Firm enables transactions on its Platform between
              participating sellers and buyers, dealing in food and beverages
              ("Platform Services''). The buyers ("Buyers'') can choose and
              place orders ("Orders'') from a variety of products listed and
              offered for sale by various merchants including but not limited to
              the sellers ("Merchants''), on the Platform.
            </li>
            <li>
              These Terms contain rules, regulations, policies, terms and
              conditions applicable to any Person who may access or use the
              Platform, as modified and updated from time to time.
            </li>
            <li>
              Use of the Platform and the Services provided are subject to the
              rules and regulations, policies, notices, terms and conditions set
              forth or included by reference in these Terms.
            </li>
            <li>
              Please read these Terms carefully before using or registering on
              the Platform or listing any item, accessing any material,
              information or Services, posting any information at or through the
              Platform.
            </li>
            <li>
              As a User, the User Agreement shall be effective and binding upon
              your ‘acceptance’. ‘Acceptance’ shall mean Your affirmative action
              is on entering information as requested on the sign up page or
              simply by accessing or visiting the Platform. If You do not agree
              or are not willing to be bound by the User Agreement and our
              Policies (defined below), please do not enter information as
              requested on the sign up page and click the “Accept” button or do
              not seek to obtain access to, view, visit, download or otherwise
              use the Platform (or any of its components/constituents) or any
              information or Services.
            </li>
            <li>
              By impliedly or expressly accepting these Terms, You also accept
              and agree to be bound by applicable policies of the Firm including
              the Privacy Policy as published on the Platform (“Policies”)
              communicated to the Users by publication on the Platform.
            </li>
            <li>
              The content on the Platform is provided for general information
              only. It is not intended to amount to advice on which you should
              rely. You must obtain professional or specialist advice before
              taking, or refraining from taking, any action on the basis of the
              content on the Platform.
            </li>
          </ul>

          <p>
            <b className="subheading">Amendment</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              The Firm reserves the right to modify the Platform and/or alter
              these Terms and/or Policies at any time and retains the right to
              deny access at any time including the termination of membership
              and deletion of the account, to anyone who the Firm believes has
              violated the provisions of the User Agreement.
            </li>
            <li>
              You are advised that any amendment to the User Agreement
              incorporated herein by reference will only be notified on the
              Platform on publicly accessible links and You agree by accessing,
              browsing or using the Platform that such publication shall
              immediately constitute sufficient notice for all purposes against
              You. The revised version/terms shall be effective from the time
              that the Firm publishes the same on the Platform.
            </li>
            <li>
              The Firm shall not be liable to give any prior notice to the Users
              for any amendments to the User Agreement, which in the opinion of
              the Firm, has the effect of diminishing the User’s existing rights
              or imposing additional obligations. You understand and hereby
              accept that the decision, whether any proposed amendments to the
              User Agreement have the effect of diminishing the User’s existing
              rights or imposing additional obligations on the User, shall be
              decided by the Firm in its sole discretion.
            </li>
          </ul>

          <p>
            <b className="subheading"> Definitions</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              “Affiliate” shall mean any Person who directly or indirectly,
              controls, is controlled by, or is under the common control of the
              Firm. The term “Control” is used in the sense of the possession by
              a Person or a group of Persons acting in concert, directly or
              indirectly, of the right to direct or cause the direction of the
              management and policies of another Person, whether through the
              board of directors or ownership of voting rights by such other
              Person, by the Articles of Association, contract or otherwise. A
              Person or a group of Persons acting in concert shall be deemed to
              be in control of a body corporate if such Person or group of
              Persons is in a position to appoint or appoints the majority of
              the directors of such body corporate.
            </li>
            <li>
              “Authority” shall mean any union, national, state, local, or other
              governmental, statutory, administrative, judicial, regulatory or
              self-regulating authority, agency or instrumentality having
              jurisdiction over the relevant matter.
            </li>
            <li>
              “Grievance Redressal Officer” means the grievance redressal
              officer appointed by the Firm in accordance with applicable Law,
              from time to time.
            </li>
            <li>
              “Information” means and shall include any confidential and/or
              personally identifiable information or other information provided
              to the Firm or other Users of the Platform or at the time of
              registration with the Platform, or through any email/messaging
              feature and shall include without limitation Your name, sex, age,
              email address, mailing address, phone number (if provided) or such
              other personal information.
            </li>
            <li>
              “Internal Service Provider” shall mean logistics or any back end
              service providers of the Platform appointed by the Firm that will
              provide various services that the Firm may require in order to run
              operations of the Platform for example to facilitate or outsource
              one or more aspects of the business, product and service
              operations provided on the Platform, including search technology,
              discussion boards, payments, affiliate and rewards programs,
              co-branded credit cards, maintenance services, database management
              etc.
            </li>
            <li>
              “Law” shall mean all statutes, enactments, acts of legislature,
              laws, ordinances, rules, bye-laws, regulations, notifications,
              guidelines, policies, directions, directives and orders of any
              government and or any Authority.
            </li>
            <li>
              “Losses” shall include, without limitation, losses, liabilities,
              actions, suits, claims, proceedings, costs, damages, penalties,
              judgments, amounts paid in settlement, expenses, etc.
            </li>
            <li>
              “Pay Facility” means the automated electronic payment or
              collection and remittance facility provided by the Firm to the
              Buyers to facilitate payments for paid services on the Platform
              directly through banks or financial institution infrastructure or
              indirectly through payment gateway facility providers or through
              any such facility authorised by the Reserve Bank of India to
              provide enabling support facility for collection and remittance of
              payment.
            </li>
            <li>
              “Person” shall mean and include any individual, legal entity,
              company, body corporate, partnership company, association, Hindu
              undivided family, trust, society, limited liability partnership or
              proprietorship, whether incorporated or not.
            </li>
            <li>
              “Policies” shall mean and include the Privacy Policy and any other
              policies of the Firm as amended and provided on the Platform or
              communicated to the Users in any other way from time to time.
            </li>
            <li>
              “Services” shall mean the services rendered by the Platform as may
              be specifically notified by the Firm on the Platform or by other
              means of communication from time to time, in accordance with the
              applicable Law, and currently includes acting as an intermediary
              or a facilitator for providing a platform web and/or mobile
              application for enabling the users to sell their products or
              services online.
            </li>
            <li>“User(s)” shall mean a user of the Platform.</li>
          </ul>
          <p>
            <b className="subheading">Eligibility</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              In order to use the Platform, You need to be 18 (eighteen) years
              of age or older. The Platform may only be used or accessed by such
              Persons who can enter into and perform legally binding contracts
              under Indian Contract Act, 1872.
            </li>
            <li>
              The Firm shall not be liable in case of any false information is
              provided by the User including User’s age and the User and/or his
              natural or appointed guardian alone shall be liable for the
              consequences as per the applicable Law of the country to which the
              User belongs.
            </li>
            <li>
              The Firm disclaims all liability arising out of such unauthorised
              use of the Platform and any third party liability arising out of
              Your use of the Platform if You are a minor.
            </li>
          </ul>
          <p>
            <b className="subheading">Registration and Creation of Profile:</b>
          </p>

          <ul className=" line-height-24 nested_list">
            <li>
              A User is required to register and create a profile by creating a
              username and password or registering using mobile verification and
              providing necessary details about the User in order to be eligible
              to access the relevant Services.
            </li>
            <li>
              You are solely responsible for maintaining secrecy and
              confidentiality of Your login details including Your user name,
              password and user code (if any).
            </li>
            <li>
              The User hereby agrees and acknowledges that the Platform will
              grant access to any person who has obtained Your username,
              password and code in the same manner as it would have granted
              access to You and You shall be responsible for all activities
              conducted under Your username, password and any code. The User is
              responsible for maintaining the confidentiality of the User’s
              account access information and password, if the User is registered
              on the Platform. The User shall be responsible for all usage of
              the User’s account and password, whether or not authorised by the
              Merchant.
            </li>
            <li>
              The User shall immediately notify the Firm of any actual or
              suspected unauthorised use of the User’s account or password.
              Although the Firm, its directors, shareholders, Affiliates,
              employees, associates, contractors or agents (“Personnel”) shall
              not be responsible in any manner for any Losses occurring from any
              breach of secrecy of Your username, password, user code or any
              unauthorised use of Your account, and You shall be liable for any
              Losses suffered by the Firm or such other parties as the case may
              be, due to any unauthorised use of Your account.
            </li>
            <li>
              You agree that the sole purpose of registering on or using the
              Platform is to enable yourself technically to run your legal
              business online, and You shall not use the Platform in any manner
              whatsoever for any other purpose other than as mentioned above and
              for purposes which are not permitted under the applicable Law.
            </li>
          </ul>
          <p>
            <b className="subheading">User Information</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              You agree to provide true, accurate, up to date and complete
              information while signing up on the Platform or for any other
              purpose when prompted or requested to do so on the Platform.
            </li>
            <li>
              Certain information You provide on the Platform in Your profile
              may reveal, or allow others to identify different aspects of Your
              private life, and more generally about You. You are expressly and
              voluntarily accepting the terms of the User Agreement and
              supplying all such information by You on the Platform, including
              all information deemed "personal" or “sensitive” by applicable
              Laws, is entirely voluntary on Your part
            </li>
            <li>
              For the use of our Services, You will be required to use certain
              devices, software, and data connections, which we otherwise do not
              supply. For as long as You use our Services, You consent to
              downloading and installing updates to our Services, including
              automatically, downloading and installing such updates
            </li>
            <li>
              You are responsible for all carrier data plan and other fees and
              taxes associated with Your use of our Services. We may charge You
              for our Services, including applicable taxes. We do not provide
              refunds for our Services, except as required by Law.
            </li>
            <li>
              The Firm shall not be responsible in any manner whatsoever for the
              authenticity of the personal information or sensitive personal
              data or information supplied by the User to the Firm or to any
              other person acting on behalf of the Firm.
            </li>
            <li>
              You are prohibited from misrepresenting Your identity and agree
              not to represent Yourself as another User or login/register using
              the identity of any other Person. You are responsible to maintain
              and promptly update the information provided while signing up or
              verifying or for any other purpose on the Platform to ensure that
              the information provided by You is true, accurate, current,
              complete and not misleading at all times.
            </li>
            <li>
              If You provide any information that is untrue, inaccurate,
              misleading, not current or incomplete or the Firm has reasonable
              grounds to believe that such information is untrue, inaccurate,
              misleading, not current or incomplete, or not in accordance with
              the User Agreement, the Firm reserves the right to indefinitely
              suspend or terminate or block Your use or access to the Platform
              in any manner whatsoever. Should any other User or Person act upon
              such untrue, inaccurate, not current or incomplete information
              provided or verified by You, the Firm, and its Personnel shall not
              be liable for any damages, Losses, direct, indirect, immediate or
              remote, interests or claims resulting from such information to You
              or to any third party. You hereby agree and undertake to indemnify
              and to hold harmless the Firm, and its Personnel in accordance
              with the Indemnity clause contained in these Terms.
            </li>
          </ul>

          <p>
            <b className="subheading"> Electronic Communication </b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              You agree to keep Yourself updated with all data, information and
              communication pertaining to You made available on the Platform by
              the Firm. You further agree that Your use of the Platform or
              provision of any data or information including any correspondence
              (by email or otherwise) to or by the Firm is through electronic
              records and You consent to receiving communication from the Firm
              via electronic documents including emails and/or SMS, which will
              be deemed adequate for service of notice/electronic record.
            </li>
            <li>
              You understand You may have to bear/pay any charges associated
              with any such access (including text messaging charges for
              messages from Your mobile device). Our communications to You may
              include communication that would inform Users about various
              features of our services. The Firm may also send You promotional
              information unless You have opted out of receiving such
              information.
            </li>
            <li>
              We may need to provide You with certain
              communications/notifications, such as service announcements and
              administrative messages. These communications are considered part
              of the Services and Your account, and You may not be able to
              opt-out from receiving them. If You added Your phone number to
              Your account and You later change or deactivate that phone number,
              You must update Your account information to help prevent us from
              communicating with anyone who acquires Your old number.
            </li>
          </ul>
          <p>
            <b className="subheading"> General Terms</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              All commercial/contractual terms are offered by and agreed to
              between Buyers and Merchants alone with respect to products and
              services being offered by the Merchants. The
              commercial/contractual terms include without limitation price,
              applicable taxes, shipping costs, payment terms, date, period and
              mode of delivery, warranties related to products and services and
              after sales services related to products and services. The Firm
              does not have any control or does not determine or advise or in
              any way or involve itself in the offering or acceptance of such
              commercial/contractual terms between the Buyers and Merchants. The
              Firm may, however, offer support services to Merchants in respect
              to Order fulfilment, logistics, mode of payment, payment
              collection and other ancillary services, pursuant to the
              understanding between the Firm and the Merchants. The price of the
              products and services offered by the Merchant are solely
              determined by the Merchant itself and the Firm has no role to play
              in such determination of price in any way whatsoever.
            </li>
            <li>
              The Firm does not make any representation or warranty as to the
              item-specifics (such as legal title, creditworthiness, identity,
              etc.) of any of the Merchants. You are advised to independently
              verify the bona fides of any particular Merchant that You choose
              to deal with on the Platform and use Your best judgment in this
              regard. All Merchant offers/promotions and third-party
              offers/promotions are subject to respective party terms and
              conditions and the Firm takes no responsibility for such offers or
              promotions.
            </li>
            <li>
              The Firm neither makes any representation or warranty as to
              specifics (such as quality, value, saleability, etc.) of the
              products or services proposed to be sold or offered to be sold or
              purchased on the Platform nor does implicitly or explicitly
              support or endorse the sale or purchase of any products or
              services on the Platform. The Firm accepts no liability for any
              errors or omissions, whether on behalf of itself or third parties
              (including the Merchants).
            </li>
            <li>
              The Firm is not responsible for any non-performance or breach of
              any contract entered into between Buyers and Merchants. The
              Merchant agrees to indemnify the Firm for any and all Losses
              suffered by the Firm due to Merchant’s use of the Platform and
              interactions with Buyers pursuant thereto. The Firm cannot and
              does not guarantee that the concerned Buyers and Merchants will
              perform any transaction concluded on the Platform. The Firm is not
              responsible for unsatisfactory or non-performance of services or
              damages or delays as a result of products which are out of stock,
              unavailable or back ordered.
            </li>
            <li>
              The Firm is operating an online marketplace and assumes the role
              of facilitator, and does not at any point of time during any
              transaction between Buyer and Merchant on the Platform come into
              or take possession of any of the products or services offered by
              Merchant. At no time shall the Firm hold any right, title or
              interest over the products nor shall the Firm have any obligations
              or liabilities in respect of such contract entered into between
              Buyer and Merchant.
            </li>
            <li>
              The Firm is only providing a platform for communication and it is
              agreed that the contract for sale of any of the products or
              services shall be a strictly bipartite contract between the
              Merchant and the Buyer. In case of complaints from the Buyer
              pertaining to efficacy, quality, or any other such issues, the
              Firm’s sole responsibility shall be to notify the same to the
              Merchant and shall also redirect the Buyer to the consumer call
              centre of the Merchant. The Merchant shall be solely liable for
              redressing Buyer complaints. In the event You raise any complaint
              on any Merchant accessed using our Platform, we shall assist You
              to the best of our abilities by providing relevant information to
              You, such as details of the Merchant and the specific Order to
              which the complaint relates, to enable satisfactory resolution of
              the complaint.
            </li>
          </ul>
          <p>
            <b className="subheading"> Merchant Terms</b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              The Merchants may list and provide the details of the
              products/services offered for sale to the Buyers as a registered
              user of the Platform, however the Merchants shall not be permitted
              to sell any product that are otherwise prohibited for sale under
              applicable Law.
            </li>
            <li>
              The Merchants agree and undertake that are legally permitted to
              sell the products listed for sale on the Platform. Listings may
              only include text descriptions, graphics and pictures that
              describe your products for sale. All listed products must be
              listed in an appropriate category on the Platform. Soliciting
              business offline or outside of the Platform with the Buyers is
              expressly prohibited and would be considered as a breach of the
              User Agreement. All listed products must be kept in stock for
              successful fulfilment of orders.
            </li>
            <li>
              The Merchants hereby agree and undertake that descriptions,
              images, and other content pertaining to the products/services is
              complete and accurate, and corresponds directly with the
              appearance, nature, quality, purpose and other features of such
              products/services.
            </li>
            <li>
              The listing description of the products must not be misleading and
              must describe the actual condition of the product. If the product
              description does not match the actual product, You agree to refund
              any amounts that You may have received from the Buyer.
            </li>
            <li>
              You shall not abuse or misuse the Platform or engage in any
              activity which violates the terms of the User Agreement. In any
              such case, the Firm may suspend your account or permanently debar
              You from accessing the Platform.
            </li>
          </ul>
          <p>
            <b className="subheading"> User Obligations </b>
          </p>

          <ul className="line-height-24 nested_list">
            <p>
              You agree, undertake and confirm that Your use of Platform shall
              be strictly governed by the following binding principles:
            </p>

            <ul className="line-height-24 nested_list">
              <li>
                You shall not host, display, upload, download, modify, publish,
                transmit, update or share any information which:
                <ul className="line-height-24 nested_list">
                  <li>
                    {" "}
                    belongs to another person and which You do not have any
                    right to;
                  </li>
                  <li>
                    is grossly harmful, harassing, blasphemous, defamatory,
                    obscene, pornographic, paedophilic, libellous, slanderous,
                    criminally inciting or invasive of another's privacy,
                    hateful, or racially, ethnically objectionable, disparaging,
                    relating or encouraging money laundering or gambling, or
                    otherwise unlawful in any manner whatsoever; or unlawfully
                    threatening or unlawfully harassing including but not
                    limited to "indecent representation of women" within the
                    meaning of the Indecent Representation of Women
                    (Prohibition) Act, 1986;
                  </li>
                  <li>is misleading or misrepresentative in any way;</li>
                  <li>
                    is patently offensive to the online community, such as
                    sexually explicit content, or content that promotes
                    obscenity, paedophilia, racism, bigotry, hatred or physical
                    harm of any kind against any group or individual;
                  </li>
                  <li>harasses or advocates harassment of another person;</li>
                  <li>
                    involves the transmission of "junk mail", "chain letters",
                    or unsolicited mass mailing or "spamming";
                  </li>
                  <li>
                    promotes illegal activities or conduct that is abusive,
                    threatening, obscene, defamatory or libellous;
                  </li>
                  <li>
                    infringes upon or violates any third party's rights
                    including, but not limited to, intellectual property rights,
                    rights of privacy (including without limitation unauthorised
                    disclosure of a person's name, email address, physical
                    address or phone number) or rights of publicity or any other
                    proprietary rights;
                  </li>
                  <li>
                    promotes an illegal or unauthorised copy of another person's
                    copyrighted work (see "copyright complaint" below for
                    instructions on how to lodge a complaint about uploaded
                    copyrighted material), such as providing pirated computer
                    programs or links to them, providing information to
                    circumvent manufacture-installed copy-protect devices, or
                    providing pirated music or links to pirated music files;
                  </li>
                  <li>
                    contains restricted or password-only access pages, or hidden
                    pages or images (those not linked to or from another
                    accessible page);
                  </li>
                  <li>
                    provides material that exploits people in a sexual, violent
                    or otherwise inappropriate manner or solicits personal
                    information from anyone;
                  </li>
                  <li>
                    provides instructional information about illegal activities
                    such as making or buying illegal weapons, violating
                    someone's privacy, or providing or creating computer
                    viruses;
                  </li>
                  <li>
                    contains video, photographs, or images of another person
                    (with a minor or an adult);
                  </li>
                  <li>
                    tries to gain unauthorised access or exceeds the scope of
                    authorised access to the Platform or to profiles, blogs,
                    communities, account information, bulletins, friend request,
                    or other areas of the Platform or solicits passwords or
                    personal identifying information for commercial or unlawful
                    purposes from other Users;
                  </li>
                  <li>
                    engages in commercial activities and/or sales without our
                    prior written consent such as contests, sweepstakes, barter,
                    advertising and pyramid schemes, or the buying or selling of
                    products related to the Platform. Throughout these Terms,
                    the Firm’s prior written consent means a communication
                    coming from the Firm’s authorised representative,
                    specifically in response to Your request, and expressly
                    addressing and allowing the activity or conduct for which
                    You seek authorisation;
                  </li>
                  <li>
                    solicits gambling or engages in any gambling activity which
                    is or could be construed as being illegal;
                  </li>
                  <li>
                    interferes with another user's use and enjoyment of the
                    Platform or any third party's user and enjoyment of similar
                    services;
                  </li>
                  <li>
                    refers to any website or URL that, in our sole discretion,
                    contains material that is inappropriate for the Platform or
                    any other website, contains content that would be prohibited
                    or violates the letter or spirit of these Terms;
                  </li>
                  <li>harm minors in any way;</li>
                  <li>
                    infringes any patent, trademark, copyright or other
                    intellectual property rights or third party's trade secrets
                    or rights of publicity or privacy or shall not be fraudulent
                    or involve the sale of counterfeit or stolen products;
                  </li>
                  <li>violates any Law for the time being in force;</li>
                  <li>
                    deceives or misleads the addressee/users about the origin of
                    such messages or communicates any information which is
                    grossly offensive or menacing in nature;
                  </li>
                  <li>impersonate another person;</li>
                  <li>
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer resource; or contains any
                    trojan horses, worms, time bombs, cancelbots, easter eggs or
                    other computer programming routines that may damage,
                    detrimentally interfere with, diminish value of,
                    surreptitiously intercept or expropriate any system, data or
                    personal information;
                  </li>
                  <li>
                    threatens the unity, integrity, defence, security or
                    sovereignty of India, friendly relations with foreign
                    states, or public order or causes incitement to the
                    commission of any criminal offence or prevents investigation
                    of any offence or is insulting any other nation;
                  </li>
                  <li>is false, inaccurate or misleading;</li>
                  <li>
                    directly or indirectly, offers, attempts to offer, trades or
                    attempts to trade in any item, the dealing of which is
                    prohibited or restricted in any manner under the provisions
                    of any applicable Law, rule, regulation or guideline for the
                    time being in force; or
                  </li>
                  <li>
                    creates liability for us or causes us to lose (in whole or
                    in part) the services of our internet service provider or
                    other suppliers.
                  </li>
                </ul>
              </li>
              <li>
                In case of any action, omission, transaction or attempted
                transaction which is violative of these Terms or applicable Laws
                comes to Your knowledge, You shall forthwith take all steps to
                inform the Firm of such violation at{" "}
                <a
                  className="text-primary-color mail cursor-pointer "
                  href="mailto: support@thecheflane.com"
                >
                  {" "}
                  <b> support@thecheflane.com </b>
                </a>
              </li>
              <li>
                You shall not use any "deep-link", "page-scrape", "robot",
                "spider" or other automatic device, program, algorithm or
                methodology, or any similar or equivalent manual process, to
                access, acquire, copy or monitor any portion of the Platform or
                any content, or in any way reproduce or circumvent the
                navigational structure or presentation of the Platform or any
                content, to obtain or attempt to obtain any materials, documents
                or information through any means not purposely made available
                through the Platform. We reserve our right to prohibit any such
                activity.
              </li>
              <li>
                {" "}
                You shall not attempt to gain unauthorised access to any portion
                or feature of the Platform, or any other systems or networks
                connected to the Platform or to any server, computer, network,
                or to any of the services offered on or through the Platform, by
                hacking, "password mining" or any other illegitimate means.
              </li>
              <li>
                You shall not probe, scan or test the vulnerability of the
                Platform or any network connected to the Platform nor breach the
                security or authentication measures on the Platform or any
                network connected to the Platform. You may not reverse look-up,
                trace or seek to trace any information on any other user of or
                visitor to Platform, or any other Buyer, including any account
                on the Platform not owned by You, to its source, or exploit the
                Platform or any service or information made available or offered
                by or through the Platform, in any way where the purpose is to
                reveal any information, including but not limited to personal
                identification or information, other than Your own information,
                as provided for by the Platform.
              </li>
              <li>
                You may not use the Platform or any content on the Platform for
                any purpose that is unlawful or prohibited by the User
                Agreement, or to solicit the performance of any illegal activity
                or other activity that infringes the rights of the Firm and/or
                others.
              </li>
              <li>
                You shall at all times ensure full compliance with the
                applicable provisions, as amended from time to time, of (a) the
                Information Technology Act, 2000 and the rules thereunder; (b)
                all applicable Laws; and (c) international laws, foreign
                exchange laws, statutes, ordinances and regulations (including,
                but not limited to sales tax/VAT, income tax, general sales tax,
                central excise, custom duty, local levies) regarding Your use of
                our service and Your listing, purchase, solicitation of offers
                to purchase, and sale of products or services. You shall not
                engage in any transaction in an item or service, which is
                prohibited by the provisions of any applicable law including
                exchange control laws or regulations for the time being in
                force.
              </li>
              <li>
                In order to allow us to use the information supplied by You,
                without violating Your rights or any laws, You agree to grant us
                a non-exclusive, worldwide, perpetual, irrevocable,
                royalty-free, sub-licensable (through multiple tiers) right to
                exercise the copyright, publicity, database rights or any other
                rights You have in Your information, in any media now known or
                not currently known, with respect to Your information. We will
                only use Your Information in accordance with these Terms and
                Policies applicable to use of the Platform.
              </li>
              <li>
                From time to time, You shall be responsible for providing
                information relating to the products or services proposed to be
                sold by You. In this connection, You undertake that all such
                information shall be complete, accurate and not misleading in
                all respects. You shall not exaggerate or overemphasise the
                attributes of such products or services so as to mislead other
                Users in any manner.
              </li>
              <li>
                You shall not engage in advertising to, or solicitation of,
                other Users of the Platform to buy or sell any products or
                services, including, but not limited to, products or services
                related to that being displayed on the Platform or related to
                us. You may not transmit any chain letters or unsolicited
                commercial or junk email to other Users via the Platform. It
                shall be a violation of the User Agreement to use any
                information obtained from the Platform in order to harass,
                abuse, or harm another person, or in order to contact, advertise
                to, solicit, or sell to another person other than us without our
                prior explicit consent. You understand that we have the right,
                at all times, to disclose any information (including the
                identity of the persons providing information or materials on
                the Platform) as necessary to satisfy any law, regulation or
                valid governmental request. This may include, without
                limitation, disclosure of the information in connection with
                investigation of alleged illegal activity or solicitation of
                illegal activity or in response to a lawful court order or
                subpoena. In addition, We can (and You hereby expressly
                authorise us to) disclose any information about You to any law
                enforcement or other government officials, as we, in our sole
                discretion, believe necessary or appropriate in connection with
                the investigation and/or resolution of possible crimes,
                especially those that may involve personal injury.
              </li>
              <li>
                We reserve the right, but have no obligation, to monitor the
                materials posted on the Platform. The Firm shall have the right
                to remove or edit any content that in its sole discretion
                violates, or is alleged to violate, any applicable law or either
                the spirit or letter of these Terms. Notwithstanding this right,
                You remain solely responsible for the content of the materials
                You post on the platform and in Your private messages. Please be
                advised that such content posted does not reflect the Firm’s
                views. In no event shall the Firm assume or have any
                responsibility or liability for any content posted or for any
                claims, damages or Losses resulting from use of content and/or
                appearance of the content on the Platform. You hereby represent
                and warrant that You have all necessary rights in and to all
                content which You provide and all information it contains and
                that such content shall not infringe any proprietary or
                intellectual property or other rights of third parties or
                contain any libellous, tortious, or otherwise unlawful
                information.
              </li>
              <li>
                Your correspondence or business dealings with, or participation
                in promotions of, advertisers found on or through the Platform,
                including payment and delivery of related products or services,
                and any other terms, conditions, warranties or representations
                associated with such dealings, are solely between You and such
                advertiser. We shall not be responsible or liable for any loss
                or damage of any sort incurred as the result of any such
                dealings or as the result of the presence of such advertisers on
                the Platform.
              </li>
              <li>
                It is possible that other Users (including unauthorised users or
                'hackers') may post or transmit offensive or obscene materials
                on the Platform and that You may be involuntarily exposed to
                such offensive and obscene materials. It is also possible for
                others to obtain personal information about You due to Your use
                of the Platform, and that the recipient may use such information
                to harass or injure You. We do not approve of such unauthorised
                uses, but by using the Platform You acknowledge and agree that
                we are not responsible for the use of any personal information
                that You publicly disclose or share with others on the Platform.
                Please carefully select the type of information that You
                publicly disclose or share with others on the Platform.
              </li>
            </ul>
            <li>
              The Firm respects the intellectual property rights of others and
              expects Users of the Services to do the same. We reserve the right
              to remove content alleged to be infringing without prior notice,
              at our sole discretion, and without any liability to You. We will
              respond to notices of alleged infringement that comply with
              applicable Law and are properly provided to us. If You believe
              that Your content has been copied in a way that constitutes
              copyright infringement, please report this at
              <a
                className="text-primary-color mail cursor-pointer "
                href="mailto: support@thecheflane.com"
              >
                {" "}
                <b> support@thecheflane.com </b>
              </a>
            </li>
            <li>
              The Firm may charge a fee or charges, as intimated from time to
              time on the Platform, for registration on the Platform as Users
              especially for maintaining an official account, for availing
              services provided by the Firm through the Platform. The Firm
              reserves the right to introduce new services including any premium
              or paid services or modify or discontinue any existing services
              provided on the Platform. Changes to the Terms or any of the
              Policies of the Firm shall be published on the Platform and such
              changes shall automatically become effective immediately after
              they are published on the Platform. You are requested to visit the
              Terms and various Policies links on the Platform often to keep
              abreast of any amendments.
            </li>
            <li>
              You confirm that these Terms hereunder (and the User Agreement)
              will not conflict with, result in a breach of or constitute a
              default (or any event that, with notice or lapse of time, or both,
              would constitute a default) or result in the acceleration of any
              obligation under any of the terms, conditions or provisions of any
              other agreement or instrument to which You are a party or by which
              You are bound or to which any of Your property or assets are
              subject, conflict with or violate any of the provisions of its
              charter documents, or violate any statute or any order, rule or
              regulation of any Authority that would materially and adversely
              affect the performance of Your duties hereunder. You have obtained
              any consent, approval, authorisation of Authority required for the
              execution, delivery and performance of its respective obligations
              hereunder. If the Firm suffers any Loss or damages or a claim is
              made by any Person against the Firm or the Platform as a result of
              a breach or default or contravention on Your part of the User
              Agreement , You agree to, forthwith upon delivery of notice by the
              Firm, make good such Losses or damages or claim amounts suffered
              by the Firm.
            </li>
            <li>
              You confirm that there is no action, suit or proceeding pending
              against You or to Your knowledge, threatened in any court or by or
              before any other Authority which would prohibit Your entering into
              or performing obligations under the User Agreement.
            </li>
            <li>
              You confirm that You shall not transfer Your account and shall not
              assign any rights and obligations under the User Agreement to any
              third party without the specific written permission of the Firm.
            </li>
          </ul>

          <p>
            <b className="subheading"> Action </b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              In case of any violation by You of the User Agreement , the Firm
              has the right to immediately terminate the access or usage rights
              of the User to the Platform without any notice and any such
              violative information that is displayed or submitted on the
              Platform may be removed immediately and completely and/or report
              to investigating authorities under applicable Law.
            </li>
            <li>
              If the Firm terminates Your access to the Platform, the Firm may,
              in its sole and absolute discretion, remove and destroy any data
              and files stored by You on its servers and You agree and
              acknowledge that the Firm or the Platform shall not in any manner
              be responsible and/or liable for removing or deleting such
              information.
            </li>
          </ul>

          <p>
            <b className="subheading"> No Endorsement</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              We neither endorse any Merchant or the products/services offered
              by them. In addition, although these Terms require You to provide
              accurate Information, we do not attempt to confirm, and do not
              confirm its purported identity. We will not be responsible for any
              damage or harm resulting from Your interactions with Merchants.
            </li>
            <li>
              By using the Services, You agree that any legal remedy or
              liability that You seek to obtain for actions or omissions of
              Merchants or other third parties will be limited to a claim
              against the particular Merchant or other third parties who caused
              You harm and You agree not to attempt to impose liability on, or
              seek any legal remedy from us with respect to such actions or
              omissions.
            </li>
          </ul>

          <p>
            <b className="subheading"> Disclaimers</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              The Platform may be under constant upgrades, and some functions
              and features may not be fully operational.
            </li>
            <li>
              Due to the vagaries that can occur in the electronic distribution
              of information and due to the limitations inherent in providing
              information obtained from multiple sources, there may be delays,
              omissions, or inaccuracies in the content provided on the platform
              or delay or errors in functionality of the Platform. As a result,
              we do not represent that the information posted is correct in
              every case.
            </li>
            <li>
              We expressly disclaim all liabilities that may arise as a
              consequence of any unauthorised use of credit/debit cards.
            </li>
            <li>
              The Firm disclaims all liability that may arise due to any
              violation of any applicable Laws including the Law applicable to
              products and services offered by the Merchant.
            </li>
            <li>
              While the materials provided on the Platform were prepared to
              provide accurate information regarding the subject discussed, the
              information contained in these materials is being made available
              with the understanding that we make no guarantees, representations
              or warranties whatsoever, whether expressed or implied, with
              respect to professional qualifications, expertise, quality of work
              or other information herein. Further, we do not, in any way,
              endorse any service offered or described herein. In no event shall
              we be liable to You or any third party for any decision made or
              action taken in reliance on such information.
            </li>
            <li>
              The information provided hereunder is provided "as is". We and /
              or our employees make no warranty or representation regarding the
              timeliness, content, sequence, accuracy, effectiveness or
              completeness of any information or data furnished hereunder or
              that the information or data provided hereunder may be relied
              upon. Multiple responses may usually be made available from
              different sources and it is left to the judgement of Users based
              on their specific circumstances to use, adapt, modify or alter
              suggestions or use them in conjunction with any other sources they
              may have, thereby absolving us as well as our consultants,
              business associates, Affiliates, business partners and employees
              from any kind of professional liability.
            </li>
            <li>
              We shall not be liable to You or anyone else for any Losses or
              injury arising out of or relating to the information provided on
              the Platform. In no event will we or our Personnel be liable to
              You or any third party for any decision made or action taken by
              Your reliance on the content contained on the Platform.
            </li>
            <li>
              In no event will we be liable for any damages (including, without
              limitation, direct, indirect, incidental, special, consequential
              or exemplary damages, damages arising from personal
              injury/wrongful death, and damages resulting from lost profits,
              lost data or business interruption), resulting from any services
              provided by any third party or merchant accessed through the
              platform, whether based on warranty, contract, tort, or any other
              legal theory and whether or not we are advised of the possibility
              of such damages.
            </li>
            <li>
              We reserve the right to do any of the following, at any time,
              without notice: (a) to modify, suspend or terminate operation of
              or access to the Platform, or any portion of the Platform, for any
              reason whatsoever; (b) to modify or change the Platform, or any
              portion of the Platform, and any applicable policies or terms; or
              (c) to interrupt the operation of the Platform, or any portion of
              the Platform, as necessary to perform routine or non-routine
              maintenance, error correction, or other changes.
            </li>
          </ul>

          <p>
            <b className="subheading"> Intellectual Property</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              We are either the owner of intellectual property rights or have
              the non-exclusive, worldwide, perpetual, irrevocable, royalty
              free, sub-licensable (through multiple tiers) right to exercise
              the intellectual property, in the Platform, and in the material
              published on it including but not limited to user interface,
              layout format, order placing process flow, ideas, design,
              structure, expression, and any content thereof (except any content
              that is posted by the Merchants).
            </li>
            <li>
              You recognise that the Firm is the registered owner of the word
              mark ‘The Chef Lane’ and the logo including but not limited to its
              variants (“IPR”) and You shall not directly or indirectly, attack
              or assist another in attacking the validity of, or Firm’s or its
              Affiliates proprietary rights in, the licensed marks or any
              registrations thereof, or file any applications for the
              registration of the licensed marks or any names or logos derived
              from or confusingly similar to the licensed marks, any variation
              thereof, or any translation or transliteration thereof in another
              language, in respect of any products/services and in any territory
              throughout the world. If You become aware or acquire knowledge of
              any infringement of IPR You shall report the same at{" "}
              <a
                className="text-primary-color mail cursor-pointer "
                href="mailto: support@thecheflane.com"
              >
                {" "}
                <b> support@thecheflane.com </b>
              </a>
              with all relevant information.
            </li>
            <li>
              You may print off one copy, and may download extracts, of any
              page(s) from the Platform for Your personal reference and You may
              draw the attention of others within Your organisation to material
              available on the Platform.
            </li>
            <li>
              You must not modify the paper or digital copies of any materials
              You have printed off or downloaded in any way, and You must not
              use any illustrations, photographs, video or audio sequences or
              any graphics separately from any affirming text.
            </li>
            <li>
              You must not use any part of the materials on the Platform for
              commercial purposes without obtaining a licence to do so from us
              or our licensors.
            </li>
            <li>
              If You print off, copy or download any part of the Platform in
              breach of these Terms, Your right to use the Platform will cease
              immediately and You must, at our option, return or destroy any
              copies of the materials You have made.
            </li>
            <li>
              You may download information presented on the Platform at Your
              sole risk, and without any express or implied warranty from us in
              relation to such information, provided that:
              <ul className="line-height-24 nested_list">
                <li>
                  such information is used solely for personal purposes and not
                  for any commercial purposes whatsoever;
                </li>
                <li>
                  no alterations or modifications of any kind are made to such
                  information;
                </li>
                <li>
                  no further statements and warranties shall be made with regard
                  to such information or documents containing such information
                  for or on our behalf;
                </li>
                <li>
                  no statements and/or notifications indicating our ownership of
                  and other legal rights and interests in relation to such
                  information shall be removed, in part or in full;
                </li>
                <li>
                  such information is not reproduced, republished, transmitted,
                  displayed, posted or distributed in any form or by any means.
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <b className="subheading"> Payment</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              We use third party service providers or payment gateway(s) for
              processing the payments made in relation to the purchase of
              products offered by the Merchants to Buyers.
            </li>
            <li>
              The relevant third party service provider shall transfer the
              amount paid by the Buyer against an Order (after deducting the
              Transaction Fees) in the Merchant’s account, within 2 working days
              from the Buyer being in receipt of the good/service pursuant to
              such an Order delivered.
            </li>
            <li>
              In order to enable Buyers to use paid services on the Platform,
              the Firm facilitates the provision of Pay Facility by a
              third-party service provider on the Platform, at its sole
              discretion. The Pay Facility shall enable automated collection and
              remittance services using the facilities of various Indian banks,
              financial institutions, credit/debit/cash card brands, various
              third-party service providers and/or payment card industry issuers
              and through such other infrastructure and facilities as may be
              authorised by the Reserve Bank of India for collection, refund and
              remittance, as the case may be. The Pay Facility shall be availed
              in accordance with these Terms.
            </li>
            <li>
              The Pay Facility may support payments through credit/debit cards
              from the banks that are available while selecting the Pay Facility
              as the payment method or by cash. The Pay Facility may support
              payments through UPI and mobile wallet payment options. However,
              payment mechanisms may be added or removed or suspended through
              any one or more banks directly or through any payment gateway
              facilitators and such change shall come into effect upon the same
              being published on such third party service provider’s website.
            </li>
            <li>
              The Pay Facility shall be made available to the Buyers to make
              payment easier. In case wrong bank account details are used by the
              Buyer, the Firm shall not be responsible for loss of money, if
              any. In case of there being any technical failure, at the time of
              transaction and there is a problem in making payment, You could
              contact us at{" "}
              <a
                className="text-primary-color mail cursor-pointer "
                href="mailto: support@thecheflane.com"
              >
                {" "}
                <b> support@thecheflane.com </b>
              </a>
              however, the Firm shall not be liable for transaction failure or
              payment issues. You assume sole responsibility for all risks
              associated with transfer of money or payments to the Merchants,
              whether or not such transfer or payments are done through the use
              of a third-party payment gateway. All disputes regarding delayed
              payments or failed payments must be resolved by You, and the
              third-party payment gateway directly.
            </li>
            <li>
              You agree and accept that the Firm is neither acting as a trustee
              nor acting in a fiduciary capacity with respect to the payment
              transaction by reason of providing the Pay Facility or any other
              method of payment to its Buyers.
            </li>
            <li>
              While availing any of the payment method/s available on the
              Platform, we will not be responsible or assume any liability,
              whatsoever in respect of any loss or damage arising directly or
              indirectly to You due to:
              <ul className="line-height-24 nested_list">
                <li>Lack of authorisation for any transaction;</li>
                <li>
                  Exceeding the preset limit mutually agreed by You and between
                  "Banks";
                </li>
                <li>Any payment issues arising out of the transaction; or</li>
                <li>Declined of transaction for any other reason.</li>
              </ul>
            </li>
            <li>
              All payment transactions are subject to approval by Your issuing
              bank as applicable. If Your bank refuses to authorise payment, the
              Merchant shall have the right to cancel the Order without any
              further liability. Further, You agree that we/Merchants will not
              be held accountable for any delays caused to the delivery in cases
              where the payment authorisation is delayed by Your bank/financial
              institution.
            </li>
            <li>
              You acknowledge and agree that You shall not use the details of
              credit card/debit card/net-banking or any other payment instrument
              which is not lawfully owned by You.
            </li>
            <li>
              All payments made against the purchases/Services on the Platform
              by You shall be compulsorily in Indian Rupees acceptable in the
              Republic of India. The Platform shall not facilitate transaction
              with respect to any other form of currency with respect to the
              purchases made on the Platform.
            </li>
            <li>
              The Merchants have specifically authorised the Firm or its service
              providers to collect, process, facilitate and remit payments
              electronically or through ‘cash on delivery’ to and from other
              Buyers in respect of transactions through Pay Facility.
            </li>
            <li>
              Your relationship with the Firm is on a principal to principal
              basis and by accepting these Terms, You agree that the Firm is an
              independent contractor for all purposes, and does not have control
              of or liability for the products or services that are listed on
              the Platform that are paid for by using the Pay Facility. The Firm
              does not guarantee the identity of any Buyer nor does it ensure
              that a Buyer or a Merchant will complete a transaction.
            </li>
            <li>
              You understand, accept and agree that the Pay Facility provided by
              the Firm is neither a banking nor financial service but is merely
              a facilitator providing an electronic, automated online electronic
              payment, receiving payment through ‘cash on delivery’, collection
              and remittance facility for the transactions on the Platform using
              the existing authorised banking infrastructure and credit card
              payment gateway networks. Further, by providing the Pay Facility,
              the Firm is neither acting as trustees nor acting in a fiduciary
              capacity with respect to the transaction or the transaction price.
            </li>
            <li>
              Buyers acknowledge and agree that the Firm acts as the Merchant's
              payment agent for the limited purpose of accepting payments from
              Buyers on behalf of the Merchant. Upon Your payment of amounts to
              us, which are due to the Merchant, Your payment obligation to the
              Merchant for such amounts is completed, and we are responsible for
              remitting such amounts to the Merchant. You shall not, under any
              circumstances whatsoever, make any payment directly to the
              Merchant for Order bookings made using the Platform.
            </li>
          </ul>

          <p>
            <b className="subheading"> Payment Facility for Buyers:</b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              You, as a Buyer, understand that upon initiating a transaction You
              are entering into a legally binding and enforceable contract with
              the Merchant to purchase the products and /or services from the
              Merchant using the Pay Facility, and You shall pay the transaction
              price through Your issuing bank to the Merchant using Pay
              Facility.
            </li>
            <li>
              You, as a Buyer, shall be entitled to claim a refund of the
              transaction price from the Merchant (as Your sole and exclusive
              remedy) in case You do not receive the delivery of the goods or
              services being sold by the Merchant within the time period agreed
              in the transaction. In case You do not raise a refund claim using
              the Platform features within the stipulated time, then this would
              make You ineligible for a refund.
            </li>
            <li>
              You, as a Buyer, understand that refund may not be available in
              full or in part for certain category of products and/or services
              and/or transactions and hence You may not be entitled to a refund
              in respect of the transactions for those products and /or
              services.
            </li>
            <li>
              Except for ‘cash on delivery’ transactions, refund, if any, shall
              be made at the same issuing bank from where the transaction price
              was received, or through any other method available on the third
              party payment gateway’s website, as chosen by You.
            </li>
            <li>
              For ‘cash on delivery’ transactions, refunds, if any, will be made
              via electronic payment transfers in accordance with the policies
              adopted by the third party payment gateway.
            </li>
            <li>
              Refund shall be made in Indian Rupees only and shall be equivalent
              to the transaction price received in Indian Rupees.
            </li>
            <li>
              For electronics payments, refund shall be made through payment
              facility using any other online banking / electronic funds
              transfer system approved by Reserve Bank India (RBI).
            </li>
            <li>
              Refunds may be supported for select banks. Where a bank is not
              supported for processing refunds, You will be required to share
              alternate bank account details with for processing the refund.
            </li>
            <li>
              Refund shall be conditional and shall be with recourse available
              to the Firm in case of any misuse by Buyer.
            </li>
            <li>
              We may also request You for additional documents for verification.
            </li>
            <li>
              The Firm reserves the right to impose limits on the number of
              transactions or transaction price from an individual valid
              Credit/Debit/Cash Card / Valid Bank Account/and such other
              infrastructure or any other financial instrument directly or
              indirectly through payment aggregator or through any such facility
              authorised by Reserve Bank of India to provide enabling support
              facility for collection and remittance of payment or by an
              individual Buyer during any time period, and reserves the right to
              refuse to process transactions exceeding such limit.
            </li>
            <li>
              The Firm reserves the right to refuse to process transactions by
              Buyers with a prior history of questionable charges including
              without limitation breach of the User Agreement by Buyer with the
              Firm or breach/violation of any law or any charges imposed by the
              issuing bank.
            </li>
            <li>
              The Buyer and Merchant acknowledge that the Firm will not be
              liable for any damages, interests or claims , etc., resulting from
              not processing a transaction/transaction price or any delay in
              processing a transaction/transaction price which is beyond control
              of the Firm.
            </li>
          </ul>

          <p>
            <b className="subheading"> Compliance with Laws:</b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              Buyer and Merchant shall comply with all the applicable Laws
              (including without limitation Foreign Exchange Management Act,
              1999 and the rules made and notifications issued thereunder and
              the Exchange Control Manual as may be issued by Reserve Bank of
              India from time to time, Customs Act, Information and Technology
              Act, 2000 as amended by the Information Technology (Amendment) Act
              2008, Prevention of Money Laundering Act, 2002 and the rules made
              there under, Foreign Contribution Regulation Act, 1976 and the
              rules made there under, Income Tax Act, 1961 and the rules made
              there under, Export Import Policy of government of India)
              applicable to them respectively for using the payment facility.
            </li>
          </ul>
          <p>
            <b className="subheading">
              {" "}
              Buyer's arrangement with Issuing Bank:
            </b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              All valid credit / debit/cash card/and other payment instruments
              are processed using a payment gateway or appropriate payment
              system infrastructure and the same will also be governed by the
              terms and conditions agreed to between the Buyer and the
              respective issuing bank and payment instrument issuing company.
            </li>
            <li>
              All online bank transfers from valid bank accounts are processed
              using the gateway provided by the respective issuing bank which
              support Pay Facility to provide these services to the Users. All
              such online bank transfers on Pay Facility are also governed by
              the terms and conditions agreed to between Buyer and the
              respective issuing bank.
            </li>
            <li>
              Third Party LinksTo the extent that the Platform contains links to
              advertisement banners, information about outside services and
              resources offered by third parties, we do not give any express or
              implied warranties or guarantees regarding the content, services,
              and information provided on such linked websites. Any concerns
              regarding any such services or resources, or any link thereto,
              should be directed to the particular service or resource provider.
              We provide these links only for the convenience of visitors to the
              Platform. You can visit any and all of the linked websites
              entirely at your own discretion, however we shall assume no
              responsibility for the outcome of any such interactions.
            </li>
          </ul>

          <p>
            <b className="subheading">Limitation of Liability</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              In no event shall the Firm or its Personnel or its suppliers,
              service providers and internal service providers be liable for any
              direct, indirect, incidental, special, consequential or exemplary
              damages, including but not limited to, damages for loss of
              profits, goodwill, use, data or other intangible Losses arising
              (in any manner whatsoever) out of or in connection with the
              Platform, the Pay Facility, services provided by internal service
              providers on behalf of the Platform or the Firm or any other
              services.
            </li>
            <li>
              The Firm’s liability under all circumstances is limited to the
              amount of charges/fees, if any, paid by You to the Firm. The Firm,
              its Personnel and internal service providers and technology
              partners make no representations or warranties about the accuracy,
              reliability, completeness, and/or timeliness of any information or
              communication provided on or through the use of the Platform or
              that of the operation of the Platform or Pay Facility will be
              error free and/or uninterrupted. The Firm assumes no liability
              whatsoever for any monetary or other damage suffered by You on
              account of: (a) the delay, failure, interruption, or corruption of
              any data or other information transmitted in connection with use
              of the Platform or Pay Facility; or (b) any delay, failure,
              interruption or errors in the operation of the Platform or Pay
              Facility.
            </li>
          </ul>
          <p>
            <b className="subheading">
              {" "}
              Indemnity for non-compliance or breach
            </b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              You shall indemnify and hold harmless the Firm and the Firm's
              parent, subsidiaries, its Personnel, Affiliates, internal service
              providers and their respective officers, shareholders, directors,
              agents, and employees, from and against any and all Losses,
              liabilities, actions, suits, claims, proceedings, costs, damages,
              judgments, amounts paid in settlement and expenses (including
              without limitation attorneys' fees and disbursements), made by any
              third party or penalty imposed due to or arising out of Your
              breach of the User Agreement or resulting from untrue, inaccurate,
              misleading not current or incomplete information provided or
              verified by You.
            </li>
          </ul>
          <p>
            <b className="subheading"> Consent and Privacy Policy</b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              By using the Platform and/or by providing Your Information, You
              consent to the collection and use of such Information disclosed by
              You on the Platform and on the Pay Facility (if applicable), by
              the Firm. The personal information / data including but not
              limited to the information provided by You to the Platform /Pay
              Facility (if applicable) during the course of You being a
              registered User shall be retained in accordance with the Privacy
              Policy published on the Platform from time to time which is
              incorporated herein by reference and applicable Laws including but
              not limited to Information Technology Act, 2000 and rules there
              under. If You do not agree to Your information being transferred
              or used in this way, please do not use the Platform.
            </li>
            <li>
              The Firm views the protection of User’s privacy as a very
              important community principle. The Firm clearly understands that
              You and the personal information provided by You is one of the
              most important assets to the Firm. The Firm stores and processes
              the information provided by You that are protected by physical as
              well as reasonable technological security measures and procedures
              in compliance with the applicable Law.
            </li>
          </ul>

          <p>
            <b className="subheading"> Breach and Termination </b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              Without limiting other remedies that the Firm may pursue, the Firm
              may at its sole discretion take such action as it deems fit
              including but not limited to cancellation of the membership and
              deletion of the account , limit Your activity on the Platform,
              immediately remove Your information, or warn other Users of Your
              actions, forthwith temporarily/indefinitely suspend or terminate
              or block Your user account/membership, and/or refuse to provide
              You with access to the Platform or initiate any legal action it
              may deem fit, particularly in the event: You breach any of the
              provisions of the User Agreement, any of the documents, agreements
              between the Firm and You in addition to these Terms, terms and
              conditions made thereunder which are incorporated therein by
              reference; any misuse of Your account or the Pay Facility (if
              utilised), in case the Firm is unable to verify or authenticate
              any information provided by You, if the Firm believes that Your
              actions may cause legal liability to the Firm, other Users or
              Yourself.
            </li>
            <li>
              No actions, omissions or decisions taken by the Firm shall waive
              any rights or claims that the Firm may have against the User. Any
              User that may have been suspended or blocked may not register or
              attempt to register with the Platform or use the Platform in any
              manner whatsoever until such time that such User is reinstated by
              the Firm. Notwithstanding the above, if You breach the Terms or
              the Policies and other documents incorporated therein by reference
              or any other agreements entered into by the Firm and You in
              addition to the Terms, the Firm reserves the right to take strict
              legal action including but not limited to referral to the
              appropriate police or other authorities for initiating criminal or
              civil or other proceedings against You.
            </li>
            <li>
              You agree and acknowledge that we, in our sole discretion, without
              prior notification or intimation, may terminate your access to all
              or part of the Platform or cease to provide accessibility to the
              Platform, for reasons including but not limited to:
              <ul className="line-height-24 nested_list">
                <li>
                  In response to the requests of law enforcement agencies or
                  other government institutions;
                </li>
                <li>In response to your own request;</li>
                <li>
                  The suspension or major modification of the website or any of
                  its services;
                </li>
                <li>Unforeseeable technical issues;</li>
                <li>Occurrence of an event outside our control.</li>
                <li>
                  All Your obligations and liabilities incurred prior to the
                  termination date shall survive the termination of the User
                  Agreement for all purposes.
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <b className="subheading"> Grievance Redressal Mechanism</b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              In case of any grievance, objection or complaint on Your part with
              respect to the Platform, other Users or the Firm, including any
              complaints or enquiry about suspension, termination or blocking of
              Your membership or right to use the Platform, You should promptly
              raise such grievance or complaint with the designated Grievance
              Officer at
              <a
                className="text-primary-color mail cursor-pointer "
                href="mailto: support@thecheflane.com"
              >
                {" "}
                <b> support@thecheflane.com </b>
              </a>{" "}
              and provide the Grievance Officer with all necessary information
              and/or documents to enable the Firm/Grievance Officer to try and
              resolve the issue.
            </li>
            <li>
              The Grievance Officer shall attempt to acknowledge your
              grievances/complaints within 48 (forty-eight) hours of receiving
              it. The Grievance Officer may requisite such information as he may
              require in order to look into the grievances/complaints for
              resolving the same. The Grievance Officer shall attempt to resolve
              the grievance/complaint within 1 (one) month of having received
              it. You acknowledge that in the event you fail to provide the
              information sought by the Grievance Officer to look into your
              complaint, the Grievance Officer shall not be able to proceed with
              your grievance/complaint, and as such, the grievance/complaint may
              remain unresolved. By agreeing to the Terms herein, you hereby
              consent and authorise the Grievance Officer to contact you on the
              basis of the information provided by you, for the purposes of the
              grievance/complaint.
            </li>
          </ul>
          <p>
            <b className="subheading"> Confidentiality</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              All communications between the You and the Firm and all
              confidential information given to or received by You from the
              Firm, and all information concerning the business transactions of
              the Firm with any entity or person with whom it may or may not
              have a confidentiality obligation with regard to the matter in
              question, shall be kept confidential by You (whether or not such
              information or data has been marked as confidential) unless
              specifically permitted to the contrary in writing to the Firm.
            </li>
            <li>
              Further the Firm may at any time disclose any confidential
              information on a confidential basis to any prospective and current
              investors, strategic or financial, partners or service providers
              other than other Users of the Platform.
            </li>
            <li>
              This confidentiality obligation shall survive the termination of
              the agreement with and the User account of the concerned User.
            </li>
          </ul>

          <p>
            <b className="subheading"> Severability </b>
          </p>
          <ul className="line-height-24 nested_list">
            <li>
              If any provision of the User Agreement or the application thereof
              to any person or circumstance is determined to be invalid,
              unlawful, void or unenforceable to any extent for any reason
              including by reason of any applicable Law, the remainder of the
              User Agreement and the application of such provision to persons or
              circumstances other than those as to which it is held invalid or
              unenforceable shall not be affected thereby, and each provision of
              the User Agreement shall be valid and enforceable to the fullest
              extent permitted by Law. Any invalid or unenforceable provision of
              the User Agreement shall be replaced with a provision, which is
              valid and enforceable and most nearly reflects the original intent
              of the invalid and unenforceable provision.
            </li>
          </ul>

          <p>
            <b className="subheading"> Governing Law and Jurisdiction</b>
          </p>

          <ul className="line-height-24 nested_list">
            <li>
              The User Agreement and documents incorporated by reference shall
              be governed and construed in accordance with the laws of India. If
              any dispute arises between You and the Firm during Your use of the
              Platform or the Pay Facility or any service incidental to the
              Platform or thereafter, in connection with the validity,
              interpretation, implementation or alleged breach of any provision
              of the Terms and/or any other agreement between the Firm and You
              in addition to the User Agreement and any other documents
              incorporated therein by reference, the dispute shall be referred
              to the senior management of the Firm for conciliation. If the
              dispute has not been resolved, then such a dispute shall be
              subject to the exclusive jurisdiction of the courts in Chennai,
              Tamilnadu and You hereby submit to the jurisdiction of such
              courts.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const TermsAndConditionComp = TermsAndCondition;
