import React, { useEffect } from "react";
import "./style.scss";
import { history } from "service/helpers";

function UnderReview({ formData }) {
  //life cycle
  useEffect(() => {
    if (formData.status !== "NEWREQUEST") {
      history.push(`/index/activeProfile`);
    }
  }, []);
  return (
    <div className="bg-outline">
      <div className="insidd">
        <h4 className="mt-5 mb-3 text-primary-color fw-700">Under Review</h4>
        <p className="pb-4 text-center">
          Your profile is currently under review. We will notify you via Email
          once it is approved.
        </p>
      </div>
    </div>
  );
}
export default UnderReview;
