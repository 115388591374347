import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import Modal from "react-bootstrap/Modal";

import getSymbolFromCurrency from "currency-symbol-map";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  NormalButton,
  NormalCustomCheckbox,
  NormalRadio,
  ImageCropComponent,
} from "component/common";

import { CommonPageLoader } from "component/common/CommonPageLoader";
import { imageUpload } from "action/FileUpload";
import {
  getMenuDetails,
  createDish,
  chefCompleted,
  chefUpdate,
  deleteDish,
  foodlabelList,
} from "action/ChefDetailsAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import editIcon from "assets/images/editIcon.png";
import deleteIcon from "assets/images/deleteIcon.png";
import searchIcon from "assets/images/search.png";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import SimpleReactValidator from "simple-react-validator";

import { Toast } from "service/toast";
import Paginations from "../../common/Paginations";
import { ternaryCondition } from "service/helperFunctions";

function MenuDetails({
  getMenuDetails,
  foodlabelList,
  handleFormChange,
  imageUpload,
  createDish,
  chefUpdate,
  fetchChefDetails,
  chefCompleted,
  deleteDish,
  data,
  currentPageMenu,
}) {
  const [show, setShow] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [preference, setPreference] = React.useState([]);
  const [isuploading, setIsuploading] = useState(false);
  const [pageMeta, setPageMeta] = useState(null);
  const [selectedImage, setSelectedImage] = React.useState();
  const [croppingImage, setCroppingImage] = useState();
  const reference = React.useRef();
  const exceptThisSymbols = ["+", "-"];
  const [formData, setFormData] = React.useState({
    name: "",
    about: "",
    typeOfMenu: "Available Now",
    price: "",
    AdvanceOrderType: "",
    dishImage: "",
    adminApproved: null,
    isEdit: false,
    dishCategory: "Veg",
    DishOrderCount: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [updateValidator, setUpdateValidator] = useState(true);
  const wrapperRef = useRef(null);
  const [showImageCrop, setShowImageCrop] = useState(false);
  const handleClose = () => {
    handleReset();
    validator.current.hideMessages();
    validatorAdvance.current.hideMessages();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorAdvance = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  //life cycle
  useEffect(() => {
    if (currentPageMenu) {
      menuListApiCallFunc(currentPageMenu);
    } else {
      menuListApiCallFunc();
    }
    fetchCusineList();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setToggle(false);
    }
  };
  const menuListApiCallFunc = (page = 1) => {
    let payload = {
      chefId: localStorage.getItem("userId"),
    };
    let query = {
      page: page,
      limit: 10,
      status: "PENDING",
    };
    setIsLoading(true);
    getMenuDetails(payload, query)
      .then(({ data }) => {
        if (data.list) {
          setList(data.list);
          setPageMeta(data.pageMeta);
        }

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const fetchCusineList = async () => {
    setIsLoading(true);
    await foodlabelList()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.length) {
          let newData = data.map((item) => {
            return {
              value: item,
              checked: false,
            };
          });
          setPreference(newData);
        }
      })
      .catch(() => setIsLoading(false));
  };
  const handleBeforeImageCropping = (event) => {
    let file = event.target.files[0];
    if (file) {
      setCroppingImage(file);
      event.target.value = "";
      setShowImageCrop(true);
    }
  };
  const handleFileUpload = async (event) => {
    setSelectedImage(event);

    var data = new FormData();
    data.append("file", event);
    let file = event;
    let size = file.size;
    if (size > 1048576 * 5) {
      Toast({
        type: "error",
        message: "Please upload image file that is 5MB or less",
      });
    } else {
      setIsuploading(true);
      await imageUpload(data)
        .then(({ data }) => {
          setFormData((prevState) => ({
            ...prevState,
            dishImage: data?.url,
          }));
          setIsuploading(false);
        })
        .catch(() => setIsuploading(false));
    }
  };

  const handleAddDish = async () => {
    formData["chefId"] = localStorage.getItem("userId");
    formData["isNewRequest"] = true;
    formData["status"] = "NEWREQUEST";
    formData["preference"] = preference
      .filter((item) => item.checked)
      .map((item) => {
        return item.value;
      });

    let flag =
      formData.typeOfMenu === "Available Now"
        ? validator.current.allValid()
        : validator.current.allValid() && validatorAdvance.current.allValid();
    if (flag) {
      setIsLoading(true);
      if (editId === "") {
        delete formData["_id"];
        createDish(formData)
          .then((data) => {
            if (data) {
              handleClose();
              setSelectedImage("");
              if (currentPageMenu) {
                menuListApiCallFunc(currentPageMenu);
              } else {
                menuListApiCallFunc();
              }
            }
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      } else {
        formData["_id"] = editId;
        createDish(formData)
          .then((data) => {
            if (data) {
              setEditId("");
              setSelectedImage("");
              if (currentPageMenu) {
                menuListApiCallFunc(currentPageMenu);
              } else {
                menuListApiCallFunc();
              }
              handleClose();
            }
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      }
    } else {
      if (formData.typeOfMenu !== "Available Now") {
        validatorAdvance.current.showMessages();
      }
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };

  const handleComplete = async () => {
    setIsLoading(true);
    let id = localStorage.getItem("userId");
    chefCompleted(id)
      .then((data) => {
        if (data) {
          fetchChefDetails();
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };
  const handleDeleteDish = async (list) => {
    let { _id } = list;

    setIsLoading(true);
    deleteDish(_id)
      .then((data) => {
        if (data) {
          menuListApiCallFunc();
        }
        // setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };
  const handleEdit = (data) => {
    let {
      about,
      name,
      typeOfMenu,
      orderType,
      dishImage,
      price,
      AdvanceOrderType,
      dishCategory,
    } = data;

    setFormData((prevState) => ({
      ...prevState,
      name,
      about,
      dishImage,
      typeOfMenu,
      orderType,
      price,
      dishCategory,
      AdvanceOrderType,
    }));

    let clone = [...preference];
    clone.map((item) => {
      item.checked = data.preference.includes(item.value);
      return item;
    });
    setPreference(clone);
    setEditId(data._id);
    handleShow();
  };
  const handleReset = () => {
    let defaultValue = {
      name: "",
      about: "",
      typeOfMenu: "Available Now",
      price: "",
      AdvanceOrderType: "",
      dishImage: "",
      adminApproved: null,
      isEdit: false,
      dishCategory: "Veg",
      DishOrderCount: "",
    };
    validator.current.hideMessages();
    setFormData((prevState) => ({
      ...prevState,
      ...defaultValue,
    }));
    setPreference(
      preference.map((item) => {
        item.checked = false;
        return item;
      })
    );
  };
  const handlePagination = (page) => {
    setItemOffset(page.selected);
    page = page.selected + 1;
    menuListApiCallFunc(page);
  };
  const [itemOffset, setItemOffset] = useState(0);
  return (
    <div id="menu-custom-item">
      <div className="container">
        <div className="menu_inner_container">
          <div className="menu_header">
            <div className="header">Menu</div>
            <div className="btn headerButton" onClick={handleShow}>
              Add Dish
            </div>
          </div>
          <hr></hr>

          <div>
            {!isLoading ? (
              <>
                {list.length > 0 ? (
                  <div className="row pt-2">
                    {list.map((item) => {
                      return (
                        <MenuCard
                          list={item}
                          handleEdit={handleEdit}
                          data={data}
                          handleDeleteDish={handleDeleteDish}
                        />
                      );
                    })}

                    {pageMeta ? (
                      <Paginations
                        postsPerPage={pageMeta.chunk}
                        totalPosts={pageMeta.total}
                        paginate={handlePagination}
                        itemOffset={itemOffset}
                      />
                    ) : null}
                  </div>
                ) : (
                  <h4 className="text-center my-5 py-5 text-gold ">
                    No Dish Available !!!
                  </h4>
                )}
              </>
            ) : (
              <CommonPageLoader />
            )}
          </div>
        </div>

        <Modal show={show}
          // onHide={handleClose} 
          size="lg">
          <Modal.Header className="bg-primary-color rounded-top">
            <Modal.Title className=" text-white">Add Dish</Modal.Title>
            <span class="cursor-pointer" onClick={handleClose}>
              <img src={closeBtn} alt="icon" />
            </span>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#F0F2F5" }}>
            <div className="modal_body add-dish-chef">
              <div className="modal_body_left">
                <div className="bg-white p-3 ">
                  <div className="image_box image-upload upload-loader-image">
                    {isuploading ? (
                      <CommonPageLoader />
                    ) : (
                      <>
                        {!!formData.dishImage ? (
                          <img src={formData.dishImage} alt="Thumb" />
                        ) : (
                          <span className="d-flex">
                            <img src={userUpload} alt="userUpload" />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div className="upload_btn">
                    <input
                      title=""
                      id="chefImage"
                      ref={reference}
                      onChange={(e) => handleBeforeImageCropping(e)}
                      type="file"
                      accept="image/*"
                      className="d-none"
                    />
                    <NormalButton
                      label=" Upload Image"
                      className="w-100 fw-700 outline-gray-btn"
                      onClick={() => reference.current.click()}
                      disabled={isuploading}
                    />
                    <p className="fs-12 alert-for-upload-food mb-0 mt-2">
                      Please upload image file that is 5MB or less
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal_body_right rounded">
                <div className="row">
                  <div className="col-sm-6">
                    <Form.Group>
                      <Form.Label>
                        Name<sup>*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Enter Dish Name"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          setFormData((prevState) => ({
                            ...prevState,
                            name: value,
                          }));
                        }}
                        value={formData.name}
                      />
                      {validator.current.message(
                        "Name",
                        formData.name,
                        "required|min:3"
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group>
                      <Form.Label>
                        Price<sup>*</sup>
                      </Form.Label>
                      <div class="input-group  search-input-group">
                        <div class="input-group-append Custom-left">
                          <span
                            class="input-group-text bg-white border-right-0"
                            id="basic-addon2"
                          >
                            {ternaryCondition(
                              data.currency,
                              getSymbolFromCurrency(data.currency),
                              "₹"
                            )}
                          </span>
                        </div>
                        <Form.Control
                          type="number"
                          placeholder="Enter Price..."
                          onChange={(e) => {
                            let {
                              target: { value },
                            } = e;
                            setFormData((prevState) => ({
                              ...prevState,
                              price:
                                value.indexOf(".") >= 0
                                  ? value.substr(0, value.indexOf(".")) +
                                  value.substr(value.indexOf("."), 3)
                                  : value,
                            }));
                          }}
                          value={formData.price}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                      </div>
                      {validator.current.message(
                        "Price",
                        formData.price,
                        "required|numeric|min:0,num"
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="mb-0">
                      <Form.Label>About dish</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Enter here..."
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          setFormData((prevState) => ({
                            ...prevState,
                            about: value,
                          }));
                        }}
                        value={formData.about}
                      />
                      {validator.current.message(
                        "About",
                        formData.about,
                        "max:150"
                      )}
                    </Form.Group>
                    <br></br>
                    <div>
                      <div>
                        <span className="mx-2">
                          <NormalRadio
                            className=" border-0 rounded-0"
                            label={"Veg"}
                            name="Veg"
                            value={formData.dishCategory === "Veg"}
                            onChange={(e) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                dishCategory: e.target.name,
                              }));
                            }}
                            checked={formData.dishCategory === "Veg"}
                          />
                          <span className="mx-0">
                            <img src={vegIcon} alt="icons" />
                          </span>
                        </span>
                        <span className="mx-4">
                          <NormalRadio
                            className=" border-0 rounded-0"
                            label={"Non Veg"}
                            name="Non Veg"
                            value={formData.dishCategory === "Non Veg"}
                            onChange={(e) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                dishCategory: e.target.name,
                              }));
                            }}
                            checked={formData.dishCategory === "Non Veg"}
                          />
                          <span className="mx-0">
                            <img src={nonvegIcon} alt="icons" />
                          </span>
                        </span>
                      </div>
                      <br />

                      <div>
                        <Form.Label>Food Labels</Form.Label>

                        <div
                          ref={wrapperRef}
                          className="position-relative"
                          onClick={() => setToggle(true)}
                        >
                          <div className="fool-lable-content multi-temp-select-outline">
                            <div className="d-flex foods-list">
                              {preference.map((items, index) => {
                                return (
                                  <>
                                    {items.checked && (
                                      <div className="contains_item">
                                        {items.value}&ensp;
                                        <b
                                          className="rmv_cuisine_item"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            items.checked = false;
                                            let clone = [...preference];
                                            clone[index] = items;
                                            setPreference(clone);
                                          }}
                                        >
                                          &#10006;
                                        </b>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          {toggle && (
                            <div className="position-options">
                              <div className="search-outer">
                                <div class="input-group  search-input-group">
                                  <input
                                    type="text"
                                    id="searchValue"
                                    name="searchValue"
                                    value={search}
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      setSearch(value);
                                    }}
                                    className="form-control border-right-0 searchInput"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                  />
                                  <div class="input-group-append ">
                                    <span
                                      class="input-group-text bg-white border-left-0"
                                      id="basic-addon2"
                                    >
                                      <img
                                        className="mailIcon"
                                        src={searchIcon}
                                        alt="search"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="list-body max-200">
                                  <div className="row">
                                    {preference
                                      .filter((item) =>
                                        search === ""
                                          ? item
                                          : String(item.value)
                                            .toLowerCase()
                                            .match(search.toLowerCase())
                                      )
                                      .map((item, index) => {
                                        return (
                                          <div
                                            className="col-12 mb-2"
                                            key={index}
                                          >
                                            <NormalCustomCheckbox
                                              name={item.value}
                                              checked={item.checked}
                                              value={item.checked}
                                              onChange={(e) => {
                                                if (e.target) {
                                                  item.checked = e.target.value;
                                                  let clone = [...preference];
                                                  let nweIndex =
                                                    clone.findIndex(
                                                      (ar) =>
                                                        ar.value ===
                                                        e.target.name
                                                    );
                                                  clone[nweIndex] = item;
                                                  setPreference(clone);
                                                }
                                              }}
                                              label={item.value}
                                            />
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <br></br>
                      <div>
                        <Form.Label>Type of Menu </Form.Label>
                        <br></br>

                        <span className="mx-2">
                          <NormalRadio
                            className=" border-0 rounded-0"
                            label={"Available Now"}
                            name="Available Now"
                            value={formData.typeOfMenu === "Available Now"}
                            onChange={(e) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                typeOfMenu: e.target.name,
                                orderType: "",
                                DishOrderCount: "",
                                AdvanceOrderType: "",
                              }));
                              validatorAdvance.current.hideMessages();
                            }}
                            checked={formData.typeOfMenu === "Available Now"}
                            disabled={false}
                          />
                        </span>
                        <span className="mx-4">
                          <NormalRadio
                            className=" border-0 rounded-0"
                            label={"Advanced Order"}
                            name="Advance Order"
                            value={formData.typeOfMenu === "Advance Order"}
                            onChange={(e) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                typeOfMenu: e.target.name,
                              }));
                            }}
                            checked={formData.typeOfMenu === "Advance Order"}
                            disabled={false}
                          />
                        </span>
                      </div>
                      <br></br>
                      <div>
                        <Form.Label
                          className={`mb-2 ${ternaryCondition(
                            formData.typeOfMenu === "Available Now",
                            "text-grey-9d",
                            ""
                          )}`}
                        >
                          Advanced Order type{" "}
                          {formData.typeOfMenu !== "Available Now" && (
                            <sup>*</sup>
                          )}{" "}
                        </Form.Label>
                        <br></br>
                        <div className="row">
                          <div className=" col-sm-12">
                            <Form.Group>
                              <Form.Control
                                disabled={
                                  formData.typeOfMenu === "Available Now"
                                }
                                maxLength="40"
                                type="text"
                                autoComplete="off"
                                placeholder="Order type in 30 characters "
                                onChange={(e) => {
                                  let {
                                    target: { value },
                                  } = e;

                                  setFormData((prevState) => ({
                                    ...prevState,
                                    AdvanceOrderType: value,
                                  }));
                                }}
                                value={formData.AdvanceOrderType}
                              />
                              {validatorAdvance.current.message(
                                "Advance Order Type",
                                formData.AdvanceOrderType,
                                "required|min:3|max:30"
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex mt-3">
              <NormalButton
                label="Cancel"
                className="white-btn mr-3"
                disabled={isLoading}
                onClick={() => handleClose()}
              />
              <span className="m-2" />

              <NormalButton
                primary
                label={ternaryCondition(editId === "", "Save", "Update")}
                className=""
                disabled={isLoading}
                onClick={() => handleAddDish()}
              />
            </div>
          </Modal.Footer>
        </Modal>
        <div className="body">
          <div className="d-flex mt-3">
            <NormalButton
              label="Back"
              className="white-btn mr-3"
              onClick={() => handleFormChange("addressDetails")}
            />
            <span className="m-2" />

            <NormalButton
              primary
              label="Finish"
              className=""
              disabled={isLoading || list.length === 0}
              onClick={() => handleComplete()}
            />
          </div>
        </div>
      </div>
      <ImageCropComponent
        setShowImageCrop={setShowImageCrop}
        showImageCrop={showImageCrop}
        handleFileUpload={handleFileUpload}
        setCroppingImage={setCroppingImage}
        croppingImage={croppingImage}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentPageMenu: state.commonStore.currentPageMenu,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMenuDetails,chefUpdate,
      createDish,
      chefCompleted,
      deleteDish,
      imageUpload,
      foodlabelList,
    },
    dispatch
  );
};

export const MenuDetailsComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuDetails);

const MenuCard = ({ list, data, handleEdit, handleDeleteDish }) => {
  const [showDeleteMenuPopup, setDeleteMenuPopup] = useState(false);
  const handleClosePopupDeleteMenu = () => setDeleteMenuPopup(false);
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-4">
      <div className="d-flex outline">
        <div className="d-flex overflow-hidden mobile-alignment">
          {list.dishImage ? (
            <>
              <div className="foodimage">
                <img src={list.dishImage} alt="" />
                <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                  {list.status}
                </div>
              </div>
              <div className="menucard_description ml-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <div className="foodname fs-18 line-height-20 fw-700 text-primary-color ">
                      {list.name.length < 15 ? (
                        list.name
                      ) : (
                        <React.Fragment>
                          {list.name.substring(0, 14)}
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 200, hide: 300 }}
                            overlay={<Tooltip>{list.name}</Tooltip>}
                            containerPadding={20}
                          >
                            <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                              ...Read more
                            </span>
                          </OverlayTrigger>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <p className="bites">{list.typeOfMenu}</p>
                {list.preference ? (
                  <div className="order-Type-container">
                    {list.preference.length <= 2 ? (
                      list.preference.map((type, index) => (
                        <span key={index} className="order-Type">
                          {type}
                        </span>
                      ))
                    ) : (
                      <React.Fragment>
                        {list.preference.map(
                          (type, index) =>
                            index < 2 && (
                              <span key={index} className="order-Type">
                                {type}
                              </span>
                            )
                        )}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={
                            <Tooltip>
                              {list.preference.slice(2).join(", ")}
                            </Tooltip>
                          }
                          containerPadding={20}
                        >
                          <span
                            className="text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500"
                            style={{
                              color: "#b58204",
                            }}
                          >
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                ) : null}
                <p className="about-height fs-14 text-grey-7c line-height-18  fw-400">
                  {list.about.length < 30 ? (
                    list.about
                  ) : (
                    <React.Fragment>
                      {list.about.substring(0, 29)}{" "}
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 300 }}
                        overlay={<Tooltip>{list.about}</Tooltip>}
                        containerPadding={20}
                      >
                        <span className="text-yellow-C1 cursor-pointer">
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </p>
              </div>
            </>
          ) : (
            <div className="menucard_description ms-0 w-100">
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <div className="foodname fs-18 line-height-20 fw-700 text-primary-color ">
                    {list.name.length < 15 ? (
                      list.name
                    ) : (
                      <React.Fragment>
                        {list.name.substring(0, 14)}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.name}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <p className="bites">{list.typeOfMenu}</p>

              {list.preference ? (
                <div className="order-Type-container">
                  {list.preference.length <= 2 ? (
                    list.preference.map((type, index) => (
                      <span key={index} className="order-Type">
                        {type}
                      </span>
                    ))
                  ) : (
                    <React.Fragment>
                      {list.preference.map(
                        (type, index) =>
                          index < 2 && (
                            <span key={index} className="order-Type">
                              {type}
                            </span>
                          )
                      )}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 300 }}
                        overlay={
                          <Tooltip>
                            {list.preference.slice(2).join(", ")}
                          </Tooltip>
                        }
                        containerPadding={20}
                      >
                        <span
                          className="text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500"
                          style={{
                            color: "#b58204",
                          }}
                        >
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>
              ) : null}
              <p className="about-height fs-14 text-grey-7c line-height-18  fw-400">
                {list.about.length < 30 ? (
                  list.about
                ) : (
                  <React.Fragment>
                    {list.about.substring(0, 29)}{" "}
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 200, hide: 300 }}
                      overlay={<Tooltip>{list.about}</Tooltip>}
                      containerPadding={20}
                    >
                      <span className="text-yellow-C1 cursor-pointer">
                        ...Read more
                      </span>
                    </OverlayTrigger>
                  </React.Fragment>
                )}
              </p>
            </div>
          )}
        </div>
        <div className="d-flex flex-column align-items-end justify-content-between mobile-alignment">
          <div className="d-flex align-items-center mobile-section-food">
            <div className="d-flex mx-3">
              {list.dishCategory && (
                <img
                  src={ternaryCondition(
                    list.dishCategory === "Non Veg",
                    nonvegIcon,
                    vegIcon
                  )}
                  alt=""
                />
              )}
            </div>
            <div className="foodname fs-18 line-height-20 fw-700 text-primary-color">
              <span>
                {ternaryCondition(
                  data.currency,
                  getSymbolFromCurrency(data.currency),
                  "₹"
                )}

                {list.price}
              </span>
            </div>
          </div>
          <div className="d-flex">
            <span
              className="px-2 cursor-pointer"
              onClick={() => handleEdit(list)}
            >
              <img src={editIcon} alt="icon" />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => setDeleteMenuPopup(true)}
            >
              <img src={deleteIcon} alt="icon" />
            </span>
          </div>

          <Modal
            show={showDeleteMenuPopup}
            onHide={handleClosePopupDeleteMenu}
            backdrop="static"
            keyboard={false}
            className="address-popup"
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body className=" text-center fw-700 fs-20">
              Are you Sure you want to Delete?
            </Modal.Body>
            <Modal.Footer>
              <NormalButton
                whiteBtn
                label={"Yes, Delete"}
                className="create-order-btn"
                onClick={() => handleDeleteDish(list)}
              />
              <NormalButton
                primary
                label="No, Keep Menu item"
                onClick={handleClosePopupDeleteMenu}
                className="create-order-btn border-btn"
              />
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
