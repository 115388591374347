import React, { useEffect } from "react";
import "./style.scss";
import { ProfileIcon, OrderIcon, TalkIcon, MarkerIcon } from "../icons/Icons";
import deleteProfile from "assets/svg/delete-profile.svg";
function SideBar({ active, setActive, data, setShowDeactivate }) {
  let userDetails = data;

  return (
    <div id="sideBar-chef">
      <div className="outline">
        <ul>
          <li
            className={`${active === "basicDetail" ? "active" : ""}`}
            onClick={() => setActive("basicDetail")}
          >
            <i>
              <ProfileIcon
                color={active === "basicDetail" ? "white" : "#08316d"}
              />
            </i>
            <span>My Profile</span>
          </li>
          <li
            className={`${active === "customerOrder" ? "active" : ""}`}
            onClick={() => setActive("customerOrder")}
          >
            <i>
              <OrderIcon
                color={active === "customerOrder" ? "white" : "#08316d"}
              />
            </i>
            <span>Orders</span>
          </li>
          <li
            className={`${active === "address" ? "active" : ""}`}
            onClick={() => setActive("address")}
          >
            <i>
              <MarkerIcon color={active === "address" ? "white" : "#08316d"} />
            </i>
            <span>My Addresses</span>
          </li>

          <li>
            <i>
              <TalkIcon color={active === "talk" ? "white" : "#08316d"} />
            </i>
            <span>
              {!!userDetails && (
                <a
                  href={`mailto:support@thecheflane.com?subject=You have a message from Customer - ${userDetails?.firstName} ${userDetails?.lastName}`}
                >
                  Email Admin
                </a>
              )}
            </span>
          </li>
          <hr className="m-0 text-primary-color"></hr>
          <li class="border-top-line" onClick={() => setShowDeactivate(true)}>
            <img class="me-2 pe-1" src={deleteProfile} alt="icon" />
            <span>Delete Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
