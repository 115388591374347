import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import SimpleReactValidator from "simple-react-validator";
import {
  NormalButton,
  Marker,
  NormalRadio,
  NormalCustomCheckbox,
} from "component/common";
import Modal from "react-bootstrap/Modal";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import GoogleMapReact from "google-map-react";
import { Toast } from "service/toast";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import {
  chefUpdate,
  checkDeliveryPartnerAvailablity,
} from "action/ChefDetailsAct";
import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";

import markerIcon from "assets/svg/marker.svg";
import currentLocation from "assets/svg/currentLocation.svg";
import closeGray from "assets/images/closeGray.png";

import { DELIVERY_PARTNER_TYPE } from "utils/constants";
const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    enableHighAccuracy: true,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    // minZoom: 11,
    // maxZoom: 18,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    zoomControl: true,
    clickableIcons: false,
    draggable: true,
    draggableCursor: "pointer",
  };
};

function AdressDetail({
  chefUpdate,
  formData,
  setFormData,

  handleFormChange,
  checkDeliveryPartnerAvailablity,
}) {
  const [updateValidator, setUpdateValidator] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [getLocationLoader, setGetLocationLoader] = useState(false);
  const [showPlatformAlert, setShowPlatformAlert] = useState(false);
  const [isValidDelivery, setValidDelivery] = useState(true);
  const [deliveryPartnersList, setDeliveryPartnersList] = useState([
    {
      name: "",
      mobileNumber: "",
    },
  ]);

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  //life cycle
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!formData.AddressDetails?.location?.lat) getLocation();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (
      formData &&
      formData?.ownDeliveryPartnerDetails &&
      formData?.deliveryPartners === "OWN"
    ) {
      setDeliveryPartnersList(formData?.ownDeliveryPartnerDetails);
    }
  }, []);

  var optionsPosition = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    setGetLocationLoader(true);
    setAddress("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        optionsPosition
      );
    } else {
      Toast({
        type: "error",
        message: "Geolocation is not supported by this browser.",
      });
    }
  };
  const showPosition = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: lat,
      long: lon,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    navigatorGeoLocation(lat, lon).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      handleLocationGoogle(address);
      setGetLocationLoader(false);
    });
  };
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  };

  const addDeliveryPartner = () => {
    setDeliveryPartnersList([
      ...deliveryPartnersList,
      {
        name: "",
        mobileNumber: "",
      },
    ]);
  };

  const removeDeliveryPartner = (index) => {
    const updatedDeliveryPartners = [...deliveryPartnersList];
    updatedDeliveryPartners.splice(index, 1);
    setDeliveryPartnersList(updatedDeliveryPartners);
  };

  const submitAddressDetails = async () => {
    const hasDuplicatePhoneNumber = deliveryPartnersList?.some(
      (partner, index) => {
        const currentPhoneNumber = partner.mobileNumber;
        return deliveryPartnersList
          .slice(0, index)
          .some((p) => p.mobileNumber === currentPhoneNumber);
      }
    );
    const hasIncompleteFields = deliveryPartnersList?.some(
      (partner) => !partner.name || !partner.mobileNumber
    );
    if (validator.current.allValid()) {
      // let { ownDeliveryPartnerDetails } = formData;
      // if (ownDeliveryPartnerDetails && ownDeliveryPartnerDetails.length) {
      //   let mobileNumberArray = ownDeliveryPartnerDetails.map(
      //     (o) => o.mobileNumber
      //   );
      //   let mobileNumberSame = mobileNumberArray.some((value) => {
      //     return (
      //       mobileNumberArray.indexOf(value) !==
      //       mobileNumberArray.lastIndexOf(value)
      //     );
      //   });

      //   if (mobileNumberSame) {
      //     Toast({
      //       type: "error",
      //       message: "Mobile number should be unique for delivery partners.",
      //     });
      //     return;
      //   }
      // }

      setIsLoading(true);
      formData.AddressDetails.primary = true;
      if (
        hasDuplicatePhoneNumber
        // ||
        // formData?.deliveryPartners === "OWN"
        // && hasIncompleteFields
      ) {
        Toast({
          type: "error",
          message:
            hasDuplicatePhoneNumber && "Please remove duplicated phone number!",

          // : "Please complete all required fields for delivery partners!",
        });
      } else {
        const passDeliveryPartner = deliveryPartnersList?.map((item) => {
          const { _id, ...rest } = item;
          return rest;
        });
        formData["ownDeliveryPartnerDetails"] = passDeliveryPartner;
        // if (formData.deliveryPartners !== "OWN") {
        //   delete formData["ownDeliveryPartnerDetails"];
        // }
        if (formData.deliveryPartners === "PLATFORM") {
          let {
            AddressDetails: {
              location: { lat, long },
            },
          } = formData;

          const requestObject = { lat, long };

          await checkDeliveryPartnerAvailablity(requestObject)
            .then(async () => {
              setIsLoading(false);
              Toast({
                type: "success",
                message: "Delivery partners are available in this location",
              });
              // handleFormChange("menuDetails");
              await chefUpdate(formData)
                .then(() => {
                  setIsLoading(false);
                  handleFormChange("menuDetails");
                })
                .catch(() => setIsLoading(false));
            })
            .catch(() => {
              setIsLoading(false);
              setValidDelivery(false);
            });
        } else {
          await chefUpdate(formData)
            .then(() => {
              setIsLoading(false);
              handleFormChange("menuDetails");
            })
            .catch(() => setIsLoading(false));
        }
      }
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please complete all required fields.",
      });
      // scrollTop();
    }
  };
  const handleLatLng = (data) => {
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: data.lat,
      long: data.lng,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
  };
  
  const handleMApClick = ({ lat, lng }) => {
    navigatorGeoLocation(lat, lng).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      handleLocationGoogle(address);
    });
  };

  // const handleMApClick = ({ lat, lng }) => {
  //   let userLocation =
  //     navigatorGeoLocation(lat, lng)?.then((response) => {
  //       let data = response.results;
  //       let address = extractAddressFields(data[0].address_components);
  //       handleLocationGoogle(address);
  //     });
  // };

  const handleLocationGoogle = (e) => {
    let { country, city, pincode, region, area, state, street } = e;
    let { AddressDetails } = formData;
    AddressDetails.country = region;
    AddressDetails.city = city;
    AddressDetails.area = area;
    AddressDetails.pincode = pincode;
    AddressDetails.state = state;
    AddressDetails.address1 = street;

    // setFormData({ ...formData, AddressDetails })
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    // scrollTop();
  };
  // const scrollTop = () => {
  //   let element = document.getElementById("sub-content");
  //   element.scrollIntoView({
  //     block: "start",
  //     behavior: "smooth",
  //     inline: "nearest",
  //   });
  // };

  

  let {
    AddressDetails: { location },
  } = formData;

  return (
    <div className="address_detail_container">
      <div className="container">
        <div className="address_detail_inner_container">
          <div className="body mt-0 mb-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <div className="header" id="sub-content">
                      Map{" "}
                      <span className="fs-16 fw-500">
                        (Please select location on the map)
                      </span>
                    </div>
                    <hr />
                  </div>
                  {validator.current.message(
                    "Location",
                    formData.AddressDetails.location,
                    "required"
                  )}
                  <div className="col-xs-12 col-sm-12">
                    <div id="map-outline" className="position-relative">
                      <div id="map">
                        <GoogleMapReact
                          center={
                            location.lat
                              ? {
                                  lat: Number(location.lat),
                                  lng: Number(location.long),
                                }
                              : {
                                  lat: 13.0827,
                                  lng: 80.2707,
                                }
                          }
                          defaultZoom={18}
                          zoom={18}
                          onClick={(e) => {
                            let { lat, lng } = e;
                            let { AddressDetails } = formData;
                            AddressDetails.location = {
                              lat: lat,
                              long: lng,
                            };
                            setFormData((prevState) => ({
                              ...prevState,
                              AddressDetails,
                            }));
                            handleMApClick(e);
                          }}
                          options={getMapOptions}
                        >
                          {location && (
                            <Marker
                              lat={location.lat}
                              lng={location.long}
                              name="My Marker"
                              color=""
                              isInfo={true}
                              infoWindowOpen={infoWindowOpen}
                              infoWindowContent={
                                <div className="info-outline-small d-flex justify-content-center">
                                  <div className="">
                                    <span className="text-red fs-12">
                                      You are here!
                                    </span>
                                  </div>
                                  <span className="rectang"></span>
                                </div>
                              }
                              children={
                                <img
                                  src={markerIcon}
                                  // onClick={() => {
                                  //   setInfoWindowOpen(!infoWindowOpen)
                                  // }}
                                  alt="icon"
                                />
                              }
                            />
                          )}
                        </GoogleMapReact>
                        <LocationSearchInput
                          isMap={"map"}
                          address={address}
                          handleAddress={(e) => handleLocationGoogle(e)}
                          placeholder=""
                          handleSelect={(address) => setAddress(address)}
                          handleLatLng={handleLatLng}
                          handleFind={() => {}}
                          handleCurrentLocation={() => getLocation()}
                          getLocationLoader={getLocationLoader}
                        />
                        <div
                          id="custom-current-loaction"
                          className="verify d-flex align-items-center newEdit cursor-pointer"
                          onClick={() => getLocation()}
                          title="Current location"
                        >
                          <img
                            src={currentLocation}
                            alt="icons"
                            className={
                              getLocationLoader ? "opacity-5" : "opacity-1"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <div className="header">Address Details</div>
                    <hr />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter Country"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.country = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.country}
                      />

                      {validator.current.message(
                        "Country",
                        formData.AddressDetails.country,
                        "required|min:3|alpha_space"
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter State"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.state = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.state}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group>
                      <Form.Label>
                        Address line 1<sup>*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.address1 = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.address1}
                      />
                      {validator.current.message(
                        "Address",
                        formData.AddressDetails.address1,
                        "required"
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group>
                      <Form.Label>Address line 2<sup>*</sup></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Optional Address"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.address2 = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.address2}
                      />
                      {validator.current.message(
                        "Address",
                        formData.AddressDetails.address2,
                        "required"
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group>
                      <Form.Label>Area</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area Name"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.area = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.area}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter City"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.city = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.city}
                      />
                      {validator.current.message(
                        "City",
                        formData.AddressDetails.city,
                        "required"
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>Pin Code</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter Pin Code"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.pincode = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData?.AddressDetails?.pincode}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-8">
                      <Form.Group>
                        <Form.Label>
                          Delivery Partner<sup>*</sup>
                        </Form.Label>
                        <div>
                          <span className="mx-2">
                            <NormalRadio
                              className=" border-0 rounded-0"
                              label="Own delivery partners"
                              name="OWN"
                              value={formData.deliveryPartners === "OWN"}
                              onChange={(e) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  deliveryPartners: e.target.name,
                                  cashOnDelivery: false,
                                }));
                              }}
                              checked={formData.deliveryPartners === "OWN"}
                              disabled={false}
                            />
                          </span>
                          <span className="mx-2 my-2">
                            <NormalRadio
                              className=" border-0 rounded-0"
                              label="Platform delivery partners"
                              name="PLATFORM"
                              value={formData.deliveryPartners === "PLATFORM"}
                              onChange={(e) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  deliveryPartners: e.target.name,
                                }));
                                setShowPlatformAlert(true);
                              }}
                              checked={formData.deliveryPartners === "PLATFORM"}
                            />
                          </span>
                        </div>

                        {validator.current.message(
                          "delivery partners",
                          formData.deliveryPartners,
                          "required"
                        )}

                        {formData.deliveryPartners === "OWN" && (
                          <div className="row">
                            {deliveryPartnersList?.map((partner, index) => (
                              <div
                                className="col-lg-8 col-xl-8 col-12"
                                key={index}
                              >
                                <div>
                                  <Form.Group className="mb-0">
                                    <div className="d-flex flex-column px-2">
                                      <div className="pt-3">
                                        <Form.Group>
                                          <Form.Label>
                                            Name
                                            {/* <sup>*</sup> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Partner Name"
                                            maxLength={10}
                                            onChange={(e) => {
                                              let {
                                                target: { value },
                                              } = e;
                                              setDeliveryPartnersList(
                                                (prevPartners) => {
                                                  const updatedPartners = [
                                                    ...prevPartners,
                                                  ];
                                                  updatedPartners[index].name =
                                                    value;

                                                  return updatedPartners;
                                                }
                                              );
                                            }}
                                            value={partner.name}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div>
                                        <Form.Group>
                                          <Form.Label>
                                            Phone Number
                                            {/* <sup>*</sup> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="tel"
                                            maxLength={10}
                                            placeholder="Enter Partner PhoneNumber"
                                            onKeyDown={(e) => {
                                              if (
                                                !(
                                                  e.key === "Backspace" ||
                                                  /^\d$/.test(e.key)
                                                )
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onChange={(e) => {
                                              let {
                                                target: { value },
                                              } = e;
                                              setDeliveryPartnersList(
                                                (prevPartners) => {
                                                  const updatedPartners = [
                                                    ...prevPartners,
                                                  ];
                                                  updatedPartners[
                                                    index
                                                  ].mobileNumber = value;
                                                  return updatedPartners;
                                                }
                                              );
                                            }}
                                            value={partner.mobileNumber}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div>
                                  {index > 0 && (
                                    <NormalButton
                                      primary
                                      label="Remove"
                                      className=""
                                      onClick={() =>
                                        removeDeliveryPartner(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className="col-sm-12 col-md-11">
                              <NormalButton
                                primary
                                label="Add Delivery Partner"
                                className="mt-4"
                                // disabled={
                                //   deliveryPartnersList[
                                //     deliveryPartnersList?.length - 1
                                //   ]?.mobileNumber?.length < 10
                                // }
                                onClick={addDeliveryPartner}
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center d-flex my-3 text-nowrap ">
                          {formData.deliveryPartners === "PLATFORM" ||
                          formData.deliveryPartners === "OWN" ? (
                            <NormalCustomCheckbox
                              name="cashOnDelivery"
                              checked={formData.cashOnDelivery}
                              value={formData.cashOnDelivery}
                              onChange={() =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  cashOnDelivery: !formData.cashOnDelivery,
                                }))
                              }
                              className="my-1"
                              label="Allow Cash on Delivery"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <NormalButton
            label="Back"
            className="white-btn mr-3"
            onClick={() => handleFormChange("basicDetails")}
          />
          <span className="m-2" />
          <Modal
            show={showPlatformAlert}
            onHide={() => setShowPlatformAlert(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Header className="bg-white border-0">
              <Modal.Title></Modal.Title>
              <span
                className="cursor-pointer"
                onClick={() => setShowPlatformAlert(false)}
              >
                <img src={closeGray} alt="icon" />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="alert-deActive mt-5 pb-5">
                <h4 className="mb-2">
                  Choosing platform delivery partners, may <br />
                  result in limited radius for delivery.
                </h4>
              </div>
              <Modal.Footer></Modal.Footer>
            </Modal.Body>
          </Modal>
          <NormalButton
            primary
            label={
              formData.deliveryPartners === "PLATFORM"
                ? "Check Delivery Partner Serviceability"
                : "Save"
            }
            className=""
            disabled={isLoading}
            onClick={() => submitAddressDetails()}
          />
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { chefUpdate, checkDeliveryPartnerAvailablity },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AdressDetail);
