import React, { useState, useEffect } from "react";
import MyAddressDetail from "./MyaddressDetail/AddressDetail";
import { CommonPageLoader, PageIcons } from "component/common";
import BasicDetail from "./BasicDetail/BasicDetail";
import CustomerOrder from "./CustomerOrder";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "./sideBar";
// import UnderTrial from './userTrial'
import { deleteUser } from "action/ChefDetailsAct";
import { getChefByIds, getCustomerById } from "action/CustomerDetailsAct";
import "./BasicDetail/styles.scss";
import { NormalButton } from "component/common";
import Modal from "react-bootstrap/Modal";
import { logout } from "service/utilities";
import { Toast } from "service/toast";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function CustomerComponent({ getCustomerById, deleteUser }) {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [active, setActive] = useState("basicDetail");
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [deactivateLoader, setdeactivateLoader] = useState(false);


  useEffect(() => {
    // window.location.reload()
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    if (userDetails?.userTypeRole === "customer") {
      fetchCusDetails();
    }
    else {
      fetchChefDetails();
    }
  }, [])

  const fetchCusDetails = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    if (!userDetails) {
      logout();
    }
    let body = {
      userTypeRole: userDetails?.userTypeRole,
      id: localStorage.getItem("userId"),
    };

    setIsLoading(true);
    await getCustomerById(body)
      .then(({ data }) => {
        if (!!data) {
          data.isEdit = false;
          setFormData(data);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const fetchChefDetails = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"))
    try {
      const response = await axios.get(`https://preprodapis.thecheflane.com/api/v1/chef/getone/Profile/${userDetails._id}`);
      if (!!response) {
        response.data.data.isEdit = false;
        setFormData(response.data.data);
        setIsLoading(false);
      }

    } catch (error) {
      console.error('API Error:', error);
    }
  };

  // const fetchChefDetails = async () => {
  //   let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  //   if (!userDetails) {
  //     logout();
  //   }
  //   let body = { id: localStorage.getItem("userDetails._id") }

  //   console.log(userDetails, "body")

  //   setIsLoading(true);
  //   await getChefByIds(userDetails._id)
  //     .then(({ data }) => {
  //       // if (!!data) {
  //       data.isEdit = false;
  //       setFormData(data);
  //       setIsLoading(false);
  //       console.log(data, "userdata")
  //       // }
  //     })
  //     .catch(() => setIsLoading(false));
  // };

  return (
    <>
      <section>
        {/* {isTrial && <UnderTrial />} */}
        <div className="container-fluid container-lg py-4 minimum-height">
          {!isLoading ? (
            <div className="row">
              <div className="col-md-3">
                <SideBar
                  active={active}
                  setActive={setActive}
                  data={formData}
                  setShowDeactivate={setShowDeactivate}
                />
              </div>
              <div className="col-md-9 ">
                {active === "basicDetail" ? (
                  <BasicDetail
                    active={active}
                    dataFromParent={formData}
                    fetchChefDetailsParent={fetchChefDetails}
                  />
                ) : active === "customerOrder" ? (
                  <>
                    <CustomerOrder />
                  </>
                ) : active === "address" ? (
                  <>
                    <MyAddressDetail />
                  </>
                ) : (
                  " "
                )}
                <Modal
                  show={showDeactivate}
                  onHide={() => setShowDeactivate(false)}
                  backdrop={true}
                  keyboard={false}
                  className="deactivate-modal"
                  centered
                >
                  <Modal.Body>
                    <div className="alert-deActive mt-5">
                      <h4 className="mb-0">
                        Are you Sure you want to
                        <br /> Delete?
                      </h4>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex deactivate-block">
                        <NormalButton
                          label="Yes, Delete"
                          className="w-100 white-btn mr-3"
                          disabled={deactivateLoader}
                          onClick={() => {
                            setdeactivateLoader(true);

                            let id = formData._id;
                            deleteUser(id)
                              .then((data) => {
                                if (data.deletedData) {
                                  setShowDeactivate(false);
                                  Toast({ type: "error", message: "Account is deactivated", toastId: 'success1' });
                                  logout();
                                }
                                setdeactivateLoader(false);
                              })
                              .catch(() => {
                                setdeactivateLoader(false);
                                setShowDeactivate(true);
                              });
                          }}
                        />
                        <span className="m-2" />
                        <NormalButton
                          primary
                          label="No, Keep my Account"
                          className="w-100 fw-700"
                          disabled={deactivateLoader}
                          onClick={() => setShowDeactivate(false)}
                        />
                      </div>
                    </Modal.Footer>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          ) : (
            <CommonPageLoader />
          )}
        </div>

        {!isLoading && <PageIcons />}
      </section>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCustomerById, deleteUser }, dispatch);
};
export default connect(null, mapDispatchToProps)(CustomerComponent);
