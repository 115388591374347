import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fssai from "assets/svg/fssai.svg";
import Instagram from "assets/images/Instagram.png";
import ratingicon from "assets/images/ratingicon.png";
import facebookIcon from "assets/images/facebook.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.scss";
import shareYellow from "assets/images/shareYellow.png";
import { NormalButton, Marker } from "component/common";
import copyIcon from "assets/images/copyIcon.png";
import { FRONT_END_PORT } from "utils/constants";
import { getSingle } from "action/ChefDetailsAct";
import GoogleMapReact from "google-map-react";
import markerIcon from "assets/svg/marker.svg";

import { convertToSlug } from "service/helperFunctions";
import { getChefPdf } from "action/ChefDetailsAct";
import "./style.scss";

//getMapOptions
const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,

    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    // minZoom: 11,
    // maxZoom: 18,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    clickableIcons: false,
    zoomControl: true,
    scrollwheel: false,
    draggable: false,
  };
};

const AboutChef = ({ list, getSingle, getChefPdf }) => {
  const [showShare, setShowShare] = useState(false);
  const [showShareResponsive, setshowShareResponsive] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const token = localStorage.getItem("authToken");
  const [isCopy, setIsCopy] = useState(false);

  const [isDownload, setIsDownload] = useState(false);

  const wrapperRef = useRef(null);
  const wrapperRefResponsive = useRef(null);
  const handleLearnmore = () => {
    setLearnMore(!learnMore);
  };
  //life cycle
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener("click", handleClickOutsideReponsive, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("click", handleClickOutsideReponsive, false);
    };
  }, []);

  //handleClickOutside
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowShare(false);
    }
  };
  //handleClickOutsideReponsive
  const handleClickOutsideReponsive = (event) => {
    if (
      wrapperRefResponsive.current &&
      !wrapperRefResponsive.current.contains(event.target)
    ) {
      setshowShareResponsive(false);
    }
  };
  //handleCopyText
  const handleCopyText = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };
  let {
    AddressDetails: { location },
    facebook,
    instagram,
  } = list;

  //handleDownloadPdf
  const handleDownloadPdf = async (id) => {
    setIsDownload(true);
    await getChefPdf(id)
      .then((data) => {
        if (data.link) {
          var file_path = data.link;
          var a = document.createElement("A");
          a.href = file_path;
          a.target = "_blank";
          a.download = file_path.substr(file_path);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        setIsDownload(false);
      })
      .catch(() => setIsDownload(false));
  };
  return (
    <div className="aboutChefDeatil custom-box-shadow p-3">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className=" d-flex chef-account-profile mobile-profie-chef">
            <div className=" d-flex align-items-center">
              <div className="chefprofileimage">
                <img src={list.chefImage} alt="" srcset="" />
              </div>
              <div className="sharecontainer_res mt-2 ms-2">
                {/* {!!token && ( */}
                <div className=" d-flex text-center mb-2">
                  <div ref={wrapperRefResponsive} className="position-relative">
                    <span>
                      <NormalButton
                        label="Share"
                        className="w-100 fw-500 outline-gray-btn"
                        onClick={() => {
                          setshowShareResponsive(!showShareResponsive);
                        }}
                        prefix={shareYellow}
                      />
                    </span>
                    {showShareResponsive && (
                      <div className="share-position">
                        <NormalButton
                          disabled={isDownload}
                          label="as .pdf"
                          className="w-100 fw-500 outline-gray-btn"
                          onClick={() => {
                            handleDownloadPdf(list._id);
                          }}
                        />
                        <div className="">
                          <NormalButton
                            label="Copy link"
                            className="w-100 fw-500 outline-gray-btn"
                            onClick={() => {
                              handleCopyText(
                                `${FRONT_END_PORT}${convertToSlug(
                                  list.businessNameSlug
                                )}`
                              );
                              setshowShareResponsive(false);
                            }}
                          />
                          {isCopy && (
                            <div className="position-copy">
                              <p className="mb-0 text-gold">
                                doodlebluelive.com:2013/
                                {list.businessName}
                              </p>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  handleCopyText(
                                    `${FRONT_END_PORT}${convertToSlug(
                                      list.businessNameSlug
                                    )}`
                                  );
                                }}
                              >
                                <img src={copyIcon} alt="icon" />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!!token && list.countryCode === "+91" ? (
                  <div className="d-flex align-items-center justify-content-center flex-wrap ">
                    <img src={fssai} alt="fssai" className="" />
                    <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center ">
                      {" "}
                      Lic No: {list.LicenseDetails.licenseNumber}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {list.LicenseDetails.adminApproved === "adminApproved" ||
                  list.countryCode === "+91" ? (
                  ""
                ) : (
                  <>
                    {" "}
                    {!!token && list?.LicenseDetails?.licenseNumber ? (
                      <>
                        <span className="d-flex align-items-center w-100 fw-600 pt-2 text-black fs-10 justify-content-center">
                          Registered with Food Safety Authority
                        </span>
                        <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center">
                          {" "}
                          Lic No: {list.LicenseDetails.licenseNumber}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="container position-relative">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="name_image d-flex mb-2 ">
                    <span className="chefname fs-18 line-height-20 me-2 sm-line-height-20 fw-700 text-black-25 text-nowrap">
                      {`${list.businessName} `}
                    </span>
                    <div className="chef_viewcontainer d-flex justify-content-between ms-3">
                      {/* {list.countryCode === "+91" && (
                        <span className="d-flex align-items-center ">
                          <span className="text-primary-color fs-16 line-height-18 fw-700 text-nowrap">
                            <a
                              href="https://foscos.fssai.gov.in"
                              className="text-primary-color"
                              target="_blank"
                            >
                              FSSAI Certified
                            </a>
                          </span>
                        </span>
                      )} */}
                      {list.ratings && (
                        <div className="d-flex align-items-center">
                          <img src={ratingicon} alt="" height={16} />
                          <span className="text-primary-color fs-15 fw-700">
                            &nbsp;
                            {list.ratings}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <p className="address mt-1  line-height-20 fw-600 fs-16 text-secondary-color  mb-3 d-block">
                    {list.cuisine.join(", ").length < 60 ? (
                      list.cuisine.join(", ")
                    ) : (
                      <React.Fragment>
                        {list.cuisine.join(", ").substring(0, 59)}{" "}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.cuisine.join(", ")}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className="text-yellow-C1 cursor-pointer fs-14 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className="sharecontainer">
                  <div className=" d-flex text-center justify-content-center mb-2">
                    <div ref={wrapperRef} className="position-relative">
                      <span>
                        <NormalButton
                          label="Share"
                          className="w-100 fw-500 outline-gray-btn"
                          onClick={() => {
                            setShowShare(!showShare);
                            setIsCopy(false);
                          }}
                          prefix={shareYellow}
                        />
                      </span>
                      {showShare && (
                        <div className="share-position">
                          <NormalButton
                            disabled={isDownload}
                            label="as .pdf"
                            className="w-100 fw-500 outline-gray-btn"
                            onClick={() => {
                              handleDownloadPdf(list._id);
                            }}
                          />
                          <div className="">
                            <NormalButton
                              label="Copy link"
                              className="w-100 fw-500 outline-gray-btn"
                              onClick={() => {
                                handleCopyText(
                                  `${FRONT_END_PORT}${convertToSlug(
                                    list.businessNameSlug
                                  )}`
                                );
                                setShowShare(false);
                              }}
                            />
                            {isCopy && (
                              <div className="position-copy">
                                <p className="mb-0 text-gold">
                                  doodlebluelive.com:2013/
                                  {list.businessName}
                                </p>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleCopyText(
                                      `${FRONT_END_PORT}${convertToSlug(
                                        list.businessNameSlug
                                      )}`
                                    );
                                  }}
                                >
                                  <img src={copyIcon} alt="icon" />
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!!token && list.countryCode === "+91" ? (
                    <div className="d-flex align-items-center flex-wrap justify-content-center">
                      <img src={fssai} alt="fssai" className="" />
                      <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center">
                        {" "}
                        Lic No: {list.LicenseDetails.licenseNumber}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {list.LicenseDetails.adminApproved === "adminApproved" ||
                    list.countryCode === "+91" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      {!!token && list?.LicenseDetails?.licenseNumber ? (
                        <>
                          <span className="d-flex align-items-center w-100 fw-600 pt-2 text-black fs-10 justify-content-center">
                            Registered with Food Safety Authority
                          </span>
                          <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center">
                            {" "}
                            Lic No: {list.LicenseDetails.licenseNumber}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
              <p className="about about-min-height fs-14 text-grey-7c fw-400 line-height-18 mb-0">
                {list.AboutDetails.About.length < 130 ? (
                  list.AboutDetails.About
                ) : learnMore ? (
                  <React.Fragment>
                    {list.AboutDetails.About}{" "}
                    <span
                      className="text-yellow-C1 cursor-pointer"
                      onClick={handleLearnmore}
                    >
                      ...Less
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {list.AboutDetails.About.substring(0, 129)}{" "}
                    <span
                      className="text-yellow-C1 cursor-pointer"
                      onClick={handleLearnmore}
                    >
                      Read more...
                    </span>
                  </React.Fragment>
                )}
              </p>
              <div className="content-login-update">
                {!!token ? (
                  <>
                    <div className="d-block content-user-login">
                      <div>
                        <p className="fs-14 text-primary-color fw-700 line-height-16 mb-2 mt-2">
                          <a
                            className="phone text-primary-color"
                            href={`tel:${list.mobileNumber}`}
                          >
                            {list.countryCode}
                            {list.mobileNumber}
                          </a>
                        </p>
                        <p className=" fs-14 text-grey-7c line-height-16 mb-2">
                          <a
                            className=" email text-grey-7c text-underline"
                            href={`mailto:${list.email}`}
                          >
                            {list.email}
                          </a>
                        </p>
                        <div className="socialmedia">
                          {!!token && instagram.instagramLink && (
                            <span className="mr-2">
                              <a
                                href={
                                  instagram.instagramLink.includes(
                                    "http" || "https"
                                  )
                                    ? instagram.instagramLink
                                    : "https://" + instagram.instagramLink
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={Instagram} alt="" srcset="" />
                              </a>
                            </span>
                          )}

                          {!!token && facebook.facebookLink && (
                            <span className="mr-2">
                              <a
                                href={
                                  facebook.facebookLink.includes(
                                    "http" || "https"
                                  )
                                    ? facebook.facebookLink
                                    : "https://" + facebook.facebookLink
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={facebookIcon} alt="" srcset="" />
                              </a>
                            </span>
                          )}
                        </div>
                        <div className="fs-14 fw-400 text-grey-7c addressrespon line-height-16 text-start mt-1">
                          {list.AddressDetails.address1 !== "" &&
                            list.AddressDetails.address1 + ","}{" "}
                          {list.AddressDetails.address2 !== "" &&
                            list.AddressDetails.address2 + ","}{" "}
                          {list.AddressDetails && list.AddressDetails.city},
                          {list.AddressDetails && list.AddressDetails.state},{" "}
                          {list.AddressDetails && list.AddressDetails.pincode}{" "}
                        </div>
                        {(!list?.availabilityStatus || !list?.isChefAvailable) ? (
                          <div className="text-red-D7 fs-18 fw-700 mt-3 text-center">
                            The Chef's is not currently accepting orders
                          </div>
                        ) : list?.islicensedExpried &&
                          list?.islicensedExpried ? (
                          <div className="text-red-D7 fs-18 fw-700 mt-3 text-center">
                            Uh-ho! This Chef’s license has expired and won’t be
                            able to receive orders until it’s renewed. Please
                            try ordering from another chef!
                          </div>
                        ) : (
                          ""
                        )}
                        {list?.isThreshold && (
                          <div className="text-red-D7 fs-18 fw-700 mt-3 text-center">
                            Closing Soon
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex mt-5">
                    <p className="fw-700 fs-16 text-grey-b3 mt-5 mb-0 toView-position">
                      Please login to view chef details
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-4 text-right m-auto">
          <div className="">
            {!!token && (
              <div id="map-outline-chef">
                <div id="map" className="">
                  <GoogleMapReact
                    center={
                      location
                        ? {
                          lat: Number(location.lat),
                          lng: Number(location.long),
                        }
                        : {
                          lat: 13.0827,
                          lng: 80.2707,
                        }
                    }
                    defaultZoom={18}
                    onClick={(e) => {
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
                      );
                    }}
                    options={getMapOptions}
                  >
                    {location && (
                      <Marker
                        lat={location.lat}
                        lng={location.long}
                        name="My Marker"
                        color=""
                        isInfo={false}
                        infoWindowOpen={false}
                        infoWindowContent={
                          <div className="info-outline-small d-flex justify-content-center">
                            <div className="">
                              <span className="text-red fs-12">
                                You are here!
                              </span>
                            </div>
                            <span className="rectang"></span>
                          </div>
                        }
                        children={
                          <img
                            src={markerIcon}
                            onClick={() => {
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
                              );
                            }}
                            alt="icon"
                          />
                        }
                      />
                    )}
                  </GoogleMapReact>
                </div>
              </div>
            )}
          </div>
          <div
            className="text-grey-7c fs-14 line-height-16 fw-325 mt-2"
            style={{ display: "none" }}
          >
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSingle,
      getChefPdf,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AboutChef);