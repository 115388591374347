import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Rating from '@mui/material/Rating';
import {getAllTestimonial } from "action/OrderReviewAct";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";

const TestimonialCarousel = ({
  getAllTestimonial
}) => {
  const [testimonialDetails, setTestimonialDetails] = useState([]);

  useEffect(() => {
    testimonialData()
      }, []);


  const testimonialData = async() => {
    getAllTestimonial().then(data => {
      const filteredData = data?.data?.filter(
        testimonial => testimonial.isPublish
        // || testimonial.chefIsPublish
      );
      setTestimonialDetails(filteredData);
      }).catch()
  }


  return (
    <div
      id="carouselExampleControls"
      className="carousel slide text-center carousel-dark mt-4 mb-4"
      data-bs-ride="carousel"
    >
      <h1>Testimonials</h1>
      <div className="carousel-inner">
        {testimonialDetails.length > 0 &&
          testimonialDetails.map((testimonial, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <div>
                {/* <img
                  className="rounded-circle shadow-1-strong mb-4"
                  src={testimonial.avatar} // Assuming 'avatar' field in data
                  alt="avatar"
                  style={{ width: '150px' }}
                /> */}
                <h4 style={{ color: '#052c65' }}>{testimonial.businessName}</h4>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-8">
                    <p>{testimonial.role}</p>
                    <p className="text-muted">
                      <i className="fas fa-quote-left pe-2"></i>
                      {testimonial.review}
                    </p>
                    <Rating
                      name="half-rating-read"
                      value={testimonial.rating} 
                      precision={0.5} 
                      readOnly 
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon text-body" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon text-body" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllTestimonial,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(TestimonialCarousel);



