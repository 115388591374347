import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Component import
import ActiveProfileComponent from 'component/activeProfile'
import { history } from 'service/helpers'

export class ActiveProfile extends Component {
  componentDidMount = () => {
    let authToken = localStorage.getItem('authToken')
    if (!authToken) {
      localStorage.clear()
      history.push('/')
    }
  }
  render() {
    return <ActiveProfileComponent />
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const component = ActiveProfile

export const ActiveProfilePage = connect(null, mapDispatchToProps)(component)
