import React from 'react'

export const ProfileIcon = ({ size = 18, color = '#25282B' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 12C13.3092 12 14.4428 11.5073 15.369 10.5351C16.2952 9.56312 16.7648 8.37382 16.7648 6.99984C16.7648 5.62634 16.2952 4.43688 15.3689 3.46457C14.4425 2.49273 13.309 2 12.0001 2C10.6908 2 9.55749 2.49273 8.63126 3.46473C7.70503 4.43673 7.23535 5.62618 7.23535 6.99984C7.23535 8.37382 7.70503 9.56327 8.63141 10.5353C9.55779 11.5071 10.6912 12 12.0001 12Z"
        fill={color}
      />
      <path
        d="M20.9564 17.5305C20.9272 17.1536 20.8683 16.7425 20.7816 16.3083C20.694 15.8709 20.5812 15.4573 20.4463 15.0794C20.3069 14.6888 20.1172 14.303 19.8828 13.9333C19.6396 13.5496 19.3538 13.2155 19.0332 12.9405C18.6979 12.6529 18.2873 12.4216 17.8126 12.253C17.3396 12.0852 16.8153 12.0001 16.2545 12.0001C16.0343 12.0001 15.8213 12.0812 15.4099 12.3213C15.1568 12.4693 14.8606 12.6405 14.5301 12.8298C14.2475 12.9913 13.8646 13.1426 13.3917 13.2795C12.9303 13.4134 12.4619 13.4813 11.9995 13.4813C11.5371 13.4813 11.0688 13.4134 10.607 13.2795C10.1346 13.1427 9.75169 12.9914 9.46939 12.83C9.142 12.6424 8.84571 12.4712 8.58877 12.3211C8.17792 12.081 7.96476 12 7.74452 12C7.18355 12 6.65945 12.0852 6.18655 12.2531C5.71216 12.4215 5.30148 12.6527 4.96585 12.9407C4.64537 13.2158 4.35946 13.5497 4.1165 13.9333C3.88227 14.303 3.69265 14.6886 3.55307 15.0795C3.41826 15.4575 3.3055 15.8709 3.21793 16.3083C3.13119 16.7419 3.07226 17.1532 3.04313 17.531C3.01449 17.9011 3 18.2853 3 18.6733C3 19.683 3.35801 20.5004 4.06399 21.1033C4.76125 21.6982 5.68385 22 6.80579 22H17.1942C18.3161 22 19.2384 21.6983 19.9358 21.1033C20.642 20.5009 21 19.6833 21 18.6731C20.9998 18.2834 20.9852 17.8989 20.9564 17.5305Z"
        fill={color}
      />
    </svg>
  )
}
export const MarkerIcon = ({ size = 24, color = '#25282B' }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58878 0 0 3.43457 0 7.65625C0 9.00758 0.373061 10.3361 1.07882 11.4982C1.24335 11.7692 1.42727 12.0332 1.62555 12.283L7.65241 20H8.34755L14.3744 12.283C14.5727 12.0332 14.7566 11.7692 14.9212 11.4983C15.6269 10.3361 16 9.00758 16 7.65625C16 3.43457 12.4112 0 8 0ZM8 10.1953C6.5371 10.1953 5.34694 9.05629 5.34694 7.65625C5.34694 6.25621 6.5371 5.11719 8 5.11719C9.4629 5.11719 10.6531 6.25621 10.6531 7.65625C10.6531 9.05629 9.4629 10.1953 8 10.1953Z"
        fill={color}
      />
    </svg>
  )
}
export const MenuIcon = ({ size = 24, color = '#25282B' }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3846 2.5H13.5385V0.625C13.5385 0.28 13.2628 0 12.9231 0H1.84615C0.828308 0 0 0.84125 0 1.875V17.5C0 18.8787 1.104 20 2.46154 20H15.3846C15.7243 20 16 19.72 16 19.375V3.125C16 2.78 15.7243 2.5 15.3846 2.5ZM11.6923 16.25H4.30769C3.968 16.25 3.69231 15.97 3.69231 15.625C3.69231 15.28 3.968 15 4.30769 15H11.6923C12.032 15 12.3077 15.28 12.3077 15.625C12.3077 15.97 12.032 16.25 11.6923 16.25ZM3.69231 7.5C3.69231 6.0975 4.50338 5 5.53846 5C6.57354 5 7.38462 6.0975 7.38462 7.5C7.38462 8.60625 6.87631 9.515 6.15385 9.85125V13.125C6.15385 13.47 5.87815 13.75 5.53846 13.75C5.19877 13.75 4.92308 13.47 4.92308 13.125V9.85125C4.20062 9.515 3.69231 8.60625 3.69231 7.5ZM12.3077 10.625C12.3077 10.97 12.032 11.25 11.6923 11.25H9.84615V13.125C9.84615 13.47 9.57046 13.75 9.23077 13.75C8.89108 13.75 8.61539 13.47 8.61539 13.125V5.625C8.61539 5.28 8.89108 5 9.23077 5C10.928 5 12.3077 6.4025 12.3077 8.125V10.625ZM12.3077 2.5H1.84615C1.50646 2.5 1.23077 2.22 1.23077 1.875C1.23077 1.53 1.50646 1.25 1.84615 1.25H12.3077V2.5Z"
        fill={color}
      />
    </svg>
  )
}
export const OrderIcon = ({ size = 24, color = '#25282B' }) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.444458H1C0.734784 0.444458 0.48043 0.538108 0.292893 0.704807C0.105357 0.871507 0 1.0976 0 1.33335V13.7778C0 14.0135 0.105357 14.2396 0.292893 14.4063C0.48043 14.573 0.734784 14.6667 1 14.6667H11C11.2652 14.6667 11.5196 14.573 11.7071 14.4063C11.8946 14.2396 12 14.0135 12 13.7778V1.33335C12 1.0976 11.8946 0.871507 11.7071 0.704807C11.5196 0.538108 11.2652 0.444458 11 0.444458ZM3.5 11.1111H2.5V10.2222H3.5V11.1111ZM3.5 9.33335H2.5V8.44446H3.5V9.33335ZM3.5 7.55557H2.5V6.66668H3.5V7.55557ZM3.5 5.77779H2.5V4.8889H3.5V5.77779ZM3.5 4.00001H2.5V3.11112H3.5V4.00001ZM9.5 11.1111H4.5V10.2222H9.5V11.1111ZM9.5 9.33335H4.5V8.44446H9.5V9.33335ZM9.5 7.55557H4.5V6.66668H9.5V7.55557ZM9.5 5.77779H4.5V4.8889H9.5V5.77779ZM9.5 4.00001H4.5V3.11112H9.5V4.00001Z"
        fill={color}
      />
    </svg>
  )
}
export const RatingIcon = ({ size = 24, color = '#25282B' }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18839 10.6227L2.3479 14.3031V10.8032V10.5532H2.0979H0.25V0.916687H14.75V10.5532H6.36136H6.26091L6.18839 10.6227Z"
        stroke={color}
        stroke-width="0.5"
      />
      <path
        d="M1.24658 9.56328V1.92194H13.7011V9.56328H1.24658Z"
        stroke={color}
        stroke-width="0.5"
      />
      <path
        d="M13.1644 4.90841L12.3123 5.74028L12.4913 6.89298L11.4027 6.37327L10.9712 5.44094L11.4027 3.6817L11.9649 4.71533L13.1644 4.90841Z"
        fill={color}
      />
      <path
        d="M11.4029 3.6817V6.37327L10.3138 6.89298L10.4933 5.74028L9.64111 4.90841L10.8407 4.71533L11.4029 3.6817Z"
        fill={color}
      />
      <path
        d="M9.20937 4.90841L8.35718 5.74028L8.53624 6.89298L7.4476 6.37327L7.01611 5.44094L7.4476 3.6817L8.00983 4.71533L9.20937 4.90841Z"
        fill={color}
      />
      <path
        d="M7.4478 3.6817V6.37327L6.35872 6.89298L6.53822 5.74028L5.68604 4.90841L6.88557 4.71533L7.4478 3.6817Z"
        fill={color}
      />
      <path
        d="M5.25429 4.90841L4.4021 5.74028L4.58117 6.89298L3.49252 6.37327L3.06104 5.44094L3.49252 3.6817L4.05475 4.71533L5.25429 4.90841Z"
        fill={color}
      />
      <path
        d="M3.49272 3.68274V6.37431L2.40365 6.89402L2.58315 5.74131L1.73096 4.90945L2.93049 4.71637L3.49272 3.68274Z"
        fill={color}
      />
    </svg>
  )
}
export const TalkIcon = ({ size = 24, color = '#25282B' }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 20.125C4.63763 19.0444 0.75 14.4567 0.75 9.625V4.375L9.5 0.875L18.25 4.375V9.625C18.25 14.4585 14.3624 19.0444 9.5 20.125ZM2.5 5.25V9.625C2.55 11.6481 3.26157 13.5991 4.52578 15.1794C5.79 16.7596 7.53721 17.8821 9.5 18.375C11.4628 17.8821 13.21 16.7596 14.4742 15.1794C15.7384 13.5991 16.45 11.6481 16.5 9.625V5.25L9.5 2.625L2.5 5.25Z"
        fill={color}
      />
    </svg>
  )
}
