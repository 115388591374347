// import React, { useEffect, useRef, useState } from "react";
// import "./styles.scss";
// import Form from "react-bootstrap/Form";
// import { checkDeliveryPartnerAvailablity } from "action/ChefDetailsAct";
// import SimpleReactValidator from "simple-react-validator";
// import {
//   NormalButton,
//   Marker,
//   NormalRadio,
//   NormalCustomCheckbox,
// } from "component/common";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { chefEditUpdate } from "action/ChefDetailsAct";

// import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
// import { extractAddressFields } from "service/helperFunctions";
// import { Toast } from "service/toast";
// import markerIcon from "assets/svg/marker.svg";

// import GoogleMapReact from "google-map-react";
// import LocationSearchInput from "component/common/locationSearchAutocomplete";
// import currentLocation from "assets/svg/currentLocation.svg";

// const getMapOptions = (maps) => {
//   return {
//     streetViewControl: false,
//     scaleControl: true,
//     fullscreenControl: false,
//     styles: [
//       {
//         featureType: "poi",
//         elementType: "labels",
//         stylers: [
//           {
//             visibility: "off",
//           },
//         ],
//       },
//     ],
//     gestureHandling: "greedy",
//     disableDoubleClickZoom: true,
//     // minZoom: 11,
//     // maxZoom: 18,

//     mapTypeControl: true,
//     mapTypeId: maps.MapTypeId.ROADMAP,
//     mapTypeControlOptions: {
//       style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
//       position: maps.ControlPosition.BOTTOM_CENTER,
//       mapTypeIds: [
//         maps.MapTypeId.ROADMAP,
//         maps.MapTypeId.SATELLITE,
//         maps.MapTypeId.HYBRID,
//       ],
//     },
//     draggableCursor: "pointer",
//   };
// };

// function AddressDetails({
//   chefEditUpdate,
//   formData,
//   setFormData,
//   setFormDataAddress,
//   checkDeliveryPartnerAvailablity,
// }) {
//   const [updateValidator, setUpdateValidator] = useState(true);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isEdit, setIsEdit] = useState(false);
//   const [infoWindowOpen, setInfoWindowOpen] = useState(false);
//   const [getLocationLoader, setGetLocationLoader] = useState(false);
//   const [address, setAddress] = useState("");
//   const [defaultZoom, setDefaultZoom] = useState(18);

//   const validator = useRef(
//     new SimpleReactValidator({
//       element: (message) => (
//         <span className="error-message fs-12">{message}</span>
//       ),
//       autoForceUpdate: this,
//     })
//   );
//   const changeDeliveryPartners = async (value) => {
//     if (value === "OWN") {
//       setFormData((prevState) => ({
//         ...prevState,
//         deliveryPartners: value,
//         cashOnDelivery: false,
//       }));
//     } else {
//       let {
//         AddressDetails: {
//           location: { lat, long },
//         },
//       } = formData;

//       const requestObject = { lat, long };

//       await checkDeliveryPartnerAvailablity(requestObject).then(async () => {
//         setFormData((prevState) => ({
//           ...prevState,
//           deliveryPartners: value,
//         }));
//       });
//     }
//   };
//   //life cycle
//   useEffect(() => {}, []);
//   const submitAddressDetails = async () => {
//     formData["masterChefId"] = localStorage.getItem("userId");
//     formData["isNewRequest"] = false;

//     delete formData["_id"];
//     delete formData["__v"];
//     if (validator.current.allValid()) {
//       setIsLoading(true);
//       await chefEditUpdate(formData)
//         .then(() => {
//           setIsLoading(false);
//           setIsEdit(false);
//           window.location.reload();
//         })
//         .catch(() => setIsLoading(false));
//     } else {
//       validator.current.showMessages();
//       setUpdateValidator(!updateValidator);
//       Toast({
//         type: "error",
//         message: "Please complete all required fields.",
//       });
//       scrollTop();
//     }
//   };
//   var optionsPosition = {
//     enableHighAccuracy: true,
//     timeout: 5000,
//     maximumAge: 0,
//   };
//   //loction
//   const getLocation = () => {
//     setGetLocationLoader(true);
//     setAddress("");
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         showPosition,
//         showError,
//         optionsPosition
//       );
//       setDefaultZoom(12);
//     } else {
//       Toast({
//         type: "error",
//         message: "Geolocation is not supported by this browser.",
//       });
//     }
//   };
//   const showPosition = (position) => {
//     let lat = position.coords.latitude;
//     let lon = position.coords.longitude;
//     let { AddressDetails } = formData;
//     AddressDetails.location = {
//       lat: lat,
//       long: lon,
//     };
//     setFormData((prevState) => ({
//       ...prevState,
//       AddressDetails,
//     }));
//     navigatorGeoLocation(lat, lon).then((response) => {
//       let data = response.results;
//       let address = extractAddressFields(data[0].address_components);
//       handleLocationGoogle(address);
//       setGetLocationLoader(false);
//     });
//   };
//   const showError = (error) => {
//     switch (error.code) {
//       case error.PERMISSION_DENIED:
//         console.log("User denied the request for Geolocation.");
//         break;
//       case error.POSITION_UNAVAILABLE:
//         console.log("Location information is unavailable.");
//         break;
//       case error.TIMEOUT:
//         console.log("The request to get user location timed out.");
//         break;
//       case error.UNKNOWN_ERROR:
//         console.log("An unknown error occurred");
//         break;
//       default:
//         console.log("error on Map");
//         break;
//     }
//   };
//   const handleLatLng = (data) => {
//     let { AddressDetails } = formData;
//     AddressDetails.location = {
//       lat: data.lat,
//       long: data.lng,
//     };

//     setFormData((prevState) => ({
//       ...prevState,
//       AddressDetails,
//     }));
//   };
//   const handleMApClick = ({ lat, lng }) => {
//     let userLocation = navigatorGeoLocation(lat, lng).then((response) => {
//       let data = response.results;
//       let address = extractAddressFields(data[0].address_components);
//       handleLocationGoogle(address);
//     });
//   };
//   const handleLocationGoogle = (e) => {
//     let { city, area, pincode, region, state, street } = e;
//     let { AddressDetails } = formData;
//     AddressDetails.country = region;
//     AddressDetails.city = city;
//     AddressDetails.area = area;
//     AddressDetails.pincode = pincode;
//     AddressDetails.state = state;
//     AddressDetails.address1 = street;
//     setFormData((prevState) => ({
//       ...prevState,
//       AddressDetails,
//     }));
//     scrollTop();
//   };
//   const scrollTop = () => {
//     let element = document.getElementById("Address");
//     element.scrollIntoView({
//       block: "start",
//       behavior: "smooth",
//       inline: "nearest",
//     });
//   };
//   let {
//     AddressDetails: { location },
//   } = formData;

//   return (
//     <div id="address-custom">
//       <div className="address_detail_inner_container">
//         <div>
//           <div className="d-flex align-items-center justify-content-between">
//             <div className="header">Map</div>
//             {!isEdit && (
//               <NormalButton
//                 primary
//                 label="Edit"
//                 className="fw-700"
//                 disabled={isLoading}
//                 onClick={() => setIsEdit(true)}
//               />
//             )}
//           </div>
//           <hr></hr>
//         </div>
//         <div className="body">
//           <div className="row">
//             <div className=" col-xs-12 col-sm-12 mb-5">
//               <div id="map-outline" className="position-relative">
//                 <div id="map">
//                   <GoogleMapReact
//                     center={
//                       location
//                         ? {
//                             lat: Number(location.lat),
//                             lng: Number(location.long),
//                           }
//                         : {
//                             lat: 13.0827,
//                             lng: 80.2707,
//                           }
//                     }
//                     zoomControl={isEdit}
//                     scrollwheel={isEdit}
//                     clickableIcons={false}
//                     draggable={isEdit}
//                     defaultZoom={18}
//                     zoom={defaultZoom}
//                     onClick={(e) => {
//                       if (isEdit) {
//                         let { lat, lng } = e;
//                         if (isEdit) {
//                           let { AddressDetails } = formData;
//                           AddressDetails.location = {
//                             lat: lat,
//                             long: lng,
//                           };
//                           setFormData((prevState) => ({
//                             ...prevState,
//                             AddressDetails,
//                           }));
//                           handleMApClick(e);
//                         }
//                       } else {
//                         window.open(
//                           `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
//                         );
//                       }
//                     }}
//                     options={getMapOptions}
//                   >
//                     {location && (
//                       <Marker
//                         lat={location.lat}
//                         lng={location.long}
//                         name="My Marker"
//                         color=""
//                         isInfo={true}
//                         infoWindowOpen={infoWindowOpen}
//                         infoWindowContent={
//                           <div className="info-outline-small d-flex justify-content-center">
//                             <div className="">
//                               <span className="text-red fs-12">
//                                 You are here!
//                               </span>
//                             </div>
//                             <span className="rectang"></span>
//                           </div>
//                         }
//                         children={<img src={markerIcon} alt="icon" />}
//                       />
//                     )}
//                   </GoogleMapReact>
//                   {isEdit && (
//                     <LocationSearchInput
//                       disabled={!isEdit}
//                       isMap={"map"}
//                       address={address}
//                       handleAddress={(e) => handleLocationGoogle(e)}
//                       placeholder=""
//                       handleSelect={(address) => setAddress(address)}
//                       handleLatLng={handleLatLng}
//                       handleFind={() => {}}
//                       handleCurrentLocation={() => getLocation()}
//                       getLocationLoader={getLocationLoader}
//                     />
//                   )}
//                   {isEdit && (
//                     <div
//                       id="custom-current-loaction"
//                       className="verify d-flex align-items-center newEdit cursor-pointer "
//                       onClick={() => getLocation()}
//                       title="Current location"
//                     >
//                       <img
//                         src={currentLocation}
//                         alt="icons"
//                         className={
//                           getLocationLoader ? "opacity-5" : "opacity-1"
//                         }
//                       />
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col-12" id="Address">
//               <div className="header">Address Details</div>
//               <hr />
//             </div>
//             <div className="col-xs-12 col-sm-6">
//               <Form.Group>
//                 <Form.Label>Country</Form.Label>
//                 <Form.Control
//                   disabled={true}
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   type="text"
//                   autoComplete="off"
//                   placeholder={`${isEdit ? "Enter Country" : ""}`}
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.country = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.country}
//                 />
//                 {validator.current.message(
//                   "Country",
//                   formData.AddressDetails.country,
//                   "required|min:3|alpha_space"
//                 )}
//               </Form.Group>
//             </div>

//             <div className="col-xs-12 col-sm-6">
//               <Form.Group>
//                 <Form.Label>State</Form.Label>
//                 <Form.Control
//                   disabled={true}
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   type="text"
//                   autoComplete="off"
//                   placeholder={`${isEdit ? "Enter State" : ""}`}
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.state = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.state}
//                 />
//               </Form.Group>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-sm-8">
//               <Form.Group>
//                 <Form.Label>Address line 1{isEdit && <sup>*</sup>}</Form.Label>
//                 <Form.Control
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   type="text"
//                   autoComplete="off"
//                   placeholder={`${isEdit ? "Enter Address" : ""}`}
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.address1 = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.address1}
//                 />
//                 {validator.current.message(
//                   "Address",
//                   formData.AddressDetails.address1,
//                   "required"
//                 )}
//               </Form.Group>
//             </div>
//             <div className="col-sm-8">
//               <Form.Group>
//                 <Form.Label>Address line 2</Form.Label>
//                 <Form.Control
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   type="text"
//                   autoComplete="off"
//                   placeholder={`${isEdit ? "Enter Optional Address" : ""}`}
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.address2 = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.address2}
//                 />
//               </Form.Group>
//             </div>
//             <div className="col-sm-8">
//               <Form.Group>
//                 <Form.Label>Area</Form.Label>
//                 <Form.Control
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   type="text"
//                   autoComplete="off"
//                   placeholder={`${isEdit ? "Enter Area Name" : ""}`}
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.area = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.area}
//                 />
//               </Form.Group>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-xs-12 col-sm-6">
//               <Form.Group>
//                 <Form.Label>City</Form.Label>
//                 <Form.Control
//                   disabled={true}
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   placeholder={`${isEdit ? "Enter City" : ""}`}
//                   type="text"
//                   autoComplete="off"
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.city = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.city}
//                 />
//                 {validator.current.message(
//                   "City",
//                   formData.AddressDetails.city,
//                   "required"
//                 )}
//               </Form.Group>
//             </div>

//             <div className="col-xs-12 col-sm-6">
//               <Form.Group>
//                 <Form.Label>Pin Code</Form.Label>
//                 <Form.Control
//                   disabled={true}
//                   className={!isEdit ? "remove-height" : ""}
//                   plaintext={isEdit ? false : true}
//                   readOnly={isEdit ? false : true}
//                   type="text"
//                   autoComplete="off"
//                   placeholder={`${isEdit ? "Enter Pin Code" : ""}`}
//                   onChange={(e) => {
//                     let {
//                       target: { value },
//                     } = e;
//                     let { AddressDetails } = { ...formData };
//                     AddressDetails.pincode = value;
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       AddressDetails,
//                     }));
//                   }}
//                   value={formData.AddressDetails.pincode}
//                 />
//               </Form.Group>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-sm-12 col-md-11">
//               <Form.Group className="mb-0">
//                 <Form.Label className="mb-0">
//                   Delivery Partners{isEdit && <sup>*</sup>}
//                 </Form.Label>
//                 {isEdit ? (
//                   <div className="d-flex flex-wrap">
//                     <span className="mx-2">
//                       <NormalRadio
//                         className=" border-0 rounded-0"
//                         label="Own delivery partners"
//                         name="OWN"
//                         value={formData.deliveryPartners === "OWN"}
//                         onChange={(e) => changeDeliveryPartners(e.target.name)}
//                         checked={formData.deliveryPartners === "OWN"}
//                         disabled={false}
//                       />
//                     </span>
//                     <span className="mx-4">
//                       <NormalRadio
//                         className=" border-0 rounded-0"
//                         label="Platform delivery partners"
//                         name="PLATFORM"
//                         value={formData.deliveryPartners === "PLATFORM"}
//                         onChange={(e) => changeDeliveryPartners(e.target.name)}
//                         checked={formData.deliveryPartners === "PLATFORM"}
//                       />
//                     </span>
//                   </div>
//                 ) : (
//                   <p className="fw-700 text-black-25 mb-0">
//                     {formData.deliveryPartners === "OWN"
//                       ? "Own delivery partners"
//                       : "Use platform delivery partners"}
//                   </p>
//                 )}

//                 {validator.current.message(
//                   "Delivery Partnersmm",
//                   formData.deliveryPartners,
//                   "required"
//                 )}
//               </Form.Group>
//             </div>
//           </div>
//           <br />
//           {formData.deliveryPartners === "OWN" && (
//             <div className="row">
//               <div className="col-sm-12 col-md-11">
//                 <Form.Group className="mb-0">
//                   <Form.Label className="mb-0">Delivery Charge</Form.Label>
//                   {isEdit ? (
//                     <div className="d-flex flex-wrap">
//                       <span className="mx-2">
//                         <Form.Control
//                           className={!isEdit ? "remove-height" : ""}
//                           plaintext={isEdit ? false : true}
//                           readOnly={isEdit ? false : true}
//                           type="text"
//                           placeholder={`${isEdit ? "Enter Amount" : "-"}`}
//                           onChange={(e) => {
//                             let {
//                               target: { value },
//                             } = e;
//                             if (
//                               (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) &&
//                               value.length < 5
//                             ) {
//                               setFormData((prevState) => ({
//                                 ...prevState,
//                                 deliveryCharge: value,
//                               }));
//                             }
//                           }}
//                           value={formData.deliveryCharge}
//                         />
//                       </span>
//                     </div>
//                   ) : (
//                     <p className="fw-700 text-black-25 mb-0">
//                       {formData.deliveryCharge}
//                     </p>
//                   )}
//                   <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center my-3 ml-3 text-nowrap">
//                     {/* {isEdit && formData.deliveryPartners === "PLATFORM" ? (
//                           <NormalCustomCheckbox
//                             name="cashOnDelivery"
//                             checked={formData.cashOnDelivery}
//                             value={formData.cashOnDelivery}
//                             onChange={() =>
//                               setFormData((prevState) => ({
//                                 ...prevState,
//                                 cashOnDelivery: !formData.cashOnDelivery,
//                               }))
//                             }
//                             label="Allow Cash on Delivery"
//                           />
//                         ) : (
//                           ""
//                         )} */}

//                     {/* <NormalCustomCheckbox
//                       name="cashOnDelivery"
//                       checked={formData.cashOnDelivery}
//                       value={formData.cashOnDelivery}
//                       onChange={() =>
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           cashOnDelivery: !formData.cashOnDelivery,
//                         }))
//                       }
//                       label="Allow Cash on Deliveryyyyy"
//                     /> */}

//                     {formData.cashOnDelivery && !isEdit && (
//                       <div className="row">
//                         <div className="col-sm-12 col-md-12 col-lg-4">
//                           <span className="label-txt fs-16 fw-600">
//                             * Cash on Delivery Allowed
//                           </span>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </Form.Group>
//               </div>
//             </div>
//           )}
//           {isEdit && (
//             <NormalCustomCheckbox
//               name="cashOnDelivery"
//               checked={formData.cashOnDelivery}
//               value={formData.cashOnDelivery}
//               onChange={() =>
//                 setFormData((prevState) => ({
//                   ...prevState,
//                   cashOnDelivery: !formData.cashOnDelivery,
//                 }))
//               }
//               label="Allow Cash on Deliveryyyyy"
//             />
//           )}
//         </div>
//       </div>
//       {isEdit && (
//         <div className="d-flex mt-3">
//           <NormalButton
//             label="Cancel"
//             className="white-btn mr-3"
//             onClick={() => setIsEdit(false)}
//           />
//           <span className="m-2" />

//           <NormalButton
//             primary
//             label="Save"
//             className=""
//             disabled={isLoading}
//             onClick={() => submitAddressDetails()}
//           />
//         </div>
//       )}
//     </div>
//   );
// }
// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     { chefEditUpdate, checkDeliveryPartnerAvailablity },
//     dispatch
//   );
// };
// export default connect(null, mapDispatchToProps)(AddressDetails);

import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { checkDeliveryPartnerAvailablity } from "action/ChefDetailsAct";
import SimpleReactValidator from "simple-react-validator";
import {
  NormalButton,
  Marker,
  NormalRadio,
  NormalCustomCheckbox,
} from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chefEditUpdate } from "action/ChefDetailsAct";

import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";
import { Toast } from "service/toast";
import markerIcon from "assets/svg/marker.svg";

import GoogleMapReact from "google-map-react";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import currentLocation from "assets/svg/currentLocation.svg";

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    // minZoom: 11,
    // maxZoom: 18,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },
    draggableCursor: "pointer",
  };
};

function AddressDetails({
  chefEditUpdate,
  formData,
  setFormData,
  setFormDataAddress,
  checkDeliveryPartnerAvailablity,
}) {
  const [updateValidator, setUpdateValidator] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [getLocationLoader, setGetLocationLoader] = useState(false);
  const [address, setAddress] = useState("");
  const [defaultZoom, setDefaultZoom] = useState(18);
  const [deliveryPartners, setDeliveryPartners] = useState([
    {
      name: "",
      mobileNumber: "",
    },
  ]);
  const [requestObject, setRequestObject] = useState({})

  const addDeliveryPartner = () => {
    setDeliveryPartners([
      ...deliveryPartners,
      {
        name: "",
        mobileNumber: "",
      },
    ]);
  };

  useEffect(() => {
    setRequestObject(formData?.AddressDetails?.location)
  }, [formData])

  const removeDeliveryPartner = (index) => {
    const updatedDeliveryPartners = [...deliveryPartners];
    updatedDeliveryPartners.splice(index, 1);
    setDeliveryPartners(updatedDeliveryPartners);
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const changeDeliveryPartners = async (value) => {
    // getCashOnDeliveryStatus(value);
    if (value === "OWN") {
      setFormData((prevState) => ({
        ...prevState,
        deliveryPartners: value,
        cashOnDelivery: false,
      }));
    } else {
      // let {
      //   AddressDetails: {
      //     location: { lat, long },
      //   },
      // } = formData;

      // const requestObject = { lat, long };

      await checkDeliveryPartnerAvailablity(requestObject).then(async () => {
        setFormData((prevState) => ({
          ...prevState,
          deliveryPartners: value,
        }));
      });
    }
  };
  //life cycle
  useEffect(() => {
    if (
      formData &&
      formData?.ownDeliveryPartnerDetails &&
      formData?.deliveryPartners === "OWN"
    ) {
      setDeliveryPartners(formData?.ownDeliveryPartnerDetails);
    }
  }, []);

  const submitAddressDetails = async () => {
    const hasDuplicatePhoneNumber = deliveryPartners?.some((partner, index) => {
      const currentPhoneNumber = partner.mobileNumber;
      return deliveryPartners.slice(0, index).some((p) => p.mobileNumber === currentPhoneNumber);
    });

    const hasIncompleteFields = deliveryPartners?.some(
      (partner) => !partner.name || !partner.mobileNumber
    );

    if (hasDuplicatePhoneNumber) {
      Toast({
        type: "error",
        message: "Please remove duplicated phone number!",
      });
    } else if (formData?.deliveryPartners === "PLATFORM") {
      try {
        await checkDeliveryPartnerAvailablity(requestObject);
        proceedToChefEditUpdate();
      } catch (error) {
        // Toast({
        //   type: "error",
        //   message: "Delivery partner not available. Please try again.",
        // });
      }
    } else {
      proceedToChefEditUpdate();
    }
  };

  const proceedToChefEditUpdate = async () => {
    const passDeliveryPartner = deliveryPartners?.map((item) => {
      const { _id, ...rest } = item;
      return rest;
    });

    formData["ownDeliveryPartnerDetails"] = passDeliveryPartner;

    if (formData.deliveryPartners !== "OWN") {
      delete formData["ownDeliveryPartnerDetails"];
    }

    formData["masterChefId"] = localStorage.getItem("userId");
    formData["isNewRequest"] = false;

    delete formData["_id"];
    delete formData["__v"];

    if (validator.current.allValid()) {
      setIsLoading(true);
      localStorage.setItem("call", true);

      try {
        await chefEditUpdate(formData);
        setIsLoading(false);
        setIsEdit(false);
        // window.location.reload(); // Uncomment if a full reload is necessary
      } catch (error) {
        setIsLoading(false);
        Toast({
          type: "error",
          message: "Update failed. Please try again.",
        });
      }
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please complete all required fields.",
      });
      scrollTop();
    }
  };


  // const submitAddressDetails = async () => {

  //   const hasDuplicatePhoneNumber = deliveryPartners?.some((partner, index) => {
  //     const currentPhoneNumber = partner.mobileNumber;
  //     return deliveryPartners
  //       .slice(0, index)
  //       .some((p) => p.mobileNumber === currentPhoneNumber);
  //   });
  //   const hasIncompleteFields = deliveryPartners?.some(
  //     (partner) => !partner.name || !partner.mobileNumber
  //   );

  //   if (
  //     hasDuplicatePhoneNumber
  //     // ||
  //     // (formData?.deliveryPartners === "OWN" && hasIncompleteFields)
  //   ) {

  //     Toast({
  //       type: "error",
  //       message: hasDuplicatePhoneNumber
  //         & "Please remove duplicated phone number!"
  //       // : "Please complete all required fields for delivery partners!",
  //     });
  //   }

  //   else if (formData?.deliveryPartners === "PLATFORM") {
  //     checkDeliveryPartnerAvailablity(requestObject);

  //   }

  //   else {
  //     const passDeliveryPartner = deliveryPartners?.map((item) => {
  //       const { _id, ...rest } = item;
  //       return rest;
  //     });
  //     formData["ownDeliveryPartnerDetails"] = passDeliveryPartner;
  //     if (formData.deliveryPartners !== "OWN") {
  //       delete formData["ownDeliveryPartnerDetails"];
  //     }
  //     formData["masterChefId"] = localStorage.getItem("userId");
  //     formData["isNewRequest"] = false;
  //     delete formData["_id"];
  //     delete formData["__v"];
  //     if (validator.current.allValid()) {
  //       setIsLoading(true);
  //       localStorage.setItem("call", true);
  //       await chefEditUpdate(formData)
  //         .then(() => {
  //           setIsLoading(false);
  //           setIsEdit(false);
  //           // window.location.reload();
  //         })
  //         .catch(() => setIsLoading(false));
  //     } else {
  //       validator.current.showMessages();
  //       setUpdateValidator(!updateValidator);
  //       Toast({
  //         type: "error",
  //         message: "Please complete all required fields.",
  //       });
  //       scrollTop();
  //     }
  //   }
  // };
  var optionsPosition = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  //loction
  const getLocation = () => {
    setGetLocationLoader(true);
    setAddress("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        optionsPosition
      );
      setDefaultZoom(12);
    } else {
      Toast({
        type: "error",
        message: "Geolocation is not supported by this browser.",
      });
    }
  };
  const showPosition = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: lat,
      long: lon,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    navigatorGeoLocation(lat, lon).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      handleLocationGoogle(address);
      setGetLocationLoader(false);
    });
  };
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  };
  const handleLatLng = (data) => {
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: data.lat,
      long: data.lng,
    };

    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
  };
  const handleMApClick = ({ lat, lng }) => {
    // let userLocation = 
    navigatorGeoLocation(lat, lng).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      handleLocationGoogle(address);
    });
  };
  const handleLocationGoogle = (e) => {
    let { city, area, pincode, region, state, street } = e;
    let { AddressDetails } = formData;
    AddressDetails.country = region;
    AddressDetails.city = city;
    AddressDetails.area = area;
    AddressDetails.pincode = pincode;
    AddressDetails.state = state;
    AddressDetails.address1 = street;
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    scrollTop();
  };
  const scrollTop = () => {
    let element = document.getElementById("Address");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };
  let {
    AddressDetails: { location },
  } = formData;

  return (
    <div id="address-custom">
      <div className="address_detail_inner_container">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="header">Map</div>
            {!isEdit && (
              <NormalButton
                primary
                label="Edit"
                className="fw-700"
                disabled={isLoading}
                onClick={() => {
                  setIsEdit(true);
                }}
              />
            )}
          </div>
          <hr></hr>
        </div>
        <div className="body">
          <div className="row">
            <div className=" col-xs-12 col-sm-12 mb-5">
              <div id="map-outline" className="position-relative">
                <div id="map">
                  <GoogleMapReact
                    center={
                      location
                        ? {
                          lat: Number(location.lat),
                          lng: Number(location.long),
                        }
                        : {
                          lat: 13.0827,
                          lng: 80.2707,
                        }
                    }
                    zoomControl={isEdit}
                    scrollwheel={isEdit}
                    clickableIcons={false}
                    draggable={isEdit}
                    defaultZoom={18}
                    zoom={defaultZoom}
                    onClick={(e) => {
                      if (isEdit) {
                        let { lat, lng } = e;
                        if (isEdit) {
                          let { AddressDetails } = formData;
                          AddressDetails.location = {
                            lat: lat,
                            long: lng,
                          };
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                          handleMApClick(e);
                        }
                      } else {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
                        );
                      }
                    }}
                    options={getMapOptions}
                  >
                    {location && (
                      <Marker
                        lat={location.lat}
                        lng={location.long}
                        name="My Marker"
                        color=""
                        isInfo={true}
                        infoWindowOpen={infoWindowOpen}
                        infoWindowContent={
                          <div className="info-outline-small d-flex justify-content-center">
                            <div className="">
                              <span className="text-red fs-12">
                                You are here!
                              </span>
                            </div>
                            <span className="rectang"></span>
                          </div>
                        }
                        children={<img src={markerIcon} alt="icon" />}
                      />
                    )}
                  </GoogleMapReact>
                  {isEdit && (
                    <LocationSearchInput
                      disabled={!isEdit}
                      isMap={"map"}
                      address={address}
                      handleAddress={(e) => handleLocationGoogle(e)}
                      placeholder=""
                      handleSelect={(address) => setAddress(address)}
                      handleLatLng={handleLatLng}
                      handleFind={() => { }}
                      handleCurrentLocation={() => getLocation()}
                      getLocationLoader={getLocationLoader}
                    />
                  )}
                  {isEdit && (
                    <div
                      id="custom-current-loaction"
                      className="verify d-flex align-items-center newEdit cursor-pointer "
                      onClick={() => getLocation()}
                      title="Current location"
                    >
                      <img
                        src={currentLocation}
                        alt="icons"
                        className={
                          getLocationLoader ? "opacity-5" : "opacity-1"
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12" id="Address">
              <div className="header">Address Details</div>
              <hr />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Country" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.country = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.country}
                />
                {validator.current.message(
                  "Country",
                  formData.AddressDetails.country,
                  "required|min:3|alpha_space"
                )}
              </Form.Group>
            </div>

            <div className="col-xs-12 col-sm-6">
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter State" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.state = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.state}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <Form.Group>
                <Form.Label>Address line 1{isEdit && <sup>*</sup>}</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Address" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.address1 = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.address1}
                />
                {validator.current.message(
                  "Address",
                  formData.AddressDetails.address1,
                  "required"
                )}
              </Form.Group>
            </div>
            <div className="col-sm-8">
              <Form.Group>
                <Form.Label>Address line 2{isEdit && <sup>*</sup>}</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Optional Address" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.address2 = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.address2}
                />
                {validator.current.message(
                  "Address",
                  formData.AddressDetails.address2,
                  "required"
                )}
              </Form.Group>
            </div>
            <div className="col-sm-8">
              <Form.Group>
                <Form.Label>Area</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Area Name" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.area = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.area}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  placeholder={`${isEdit ? "Enter City" : ""}`}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.city = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.city}
                />
                {validator.current.message(
                  "City",
                  formData.AddressDetails.city,
                  "required"
                )}
              </Form.Group>
            </div>

            <div className="col-xs-12 col-sm-6">
              <Form.Group>
                <Form.Label>Pin Code</Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Pin Code" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.pincode = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.pincode}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-11">
              <Form.Group className="mb-0">
                <Form.Label className="mb-0">
                  Delivery Partners
                  {isEdit && <sup>*</sup>}
                </Form.Label>
                {isEdit ? (
                  <div className="d-flex flex-wrap">
                    <span className="mx-2">
                      <NormalRadio
                        className=" border-0 rounded-0"
                        label="Own delivery partners"
                        name="OWN"
                        value={formData.deliveryPartners === "OWN"}
                        onChange={(e) => changeDeliveryPartners(e.target.name)}
                        checked={formData.deliveryPartners === "OWN"}
                        disabled={false}
                      />
                    </span>
                    <span className="mx-4">
                      <NormalRadio
                        className=" border-0 rounded-0"
                        label="Platform delivery partners"
                        name="PLATFORM"
                        value={formData.deliveryPartners === "PLATFORM"}
                        onChange={(e) => changeDeliveryPartners(e.target.name)}
                        checked={formData.deliveryPartners === "PLATFORM"}
                      />
                    </span>
                  </div>
                ) : (
                  <p className="fw-700 text-black-25 mb-0">
                    {formData.deliveryPartners === "OWN"
                      ? "Own delivery partners"
                      : "Platform delivery partners"}
                  </p>
                )}

                {validator.current.message(
                  "Delivery Partnersmm",
                  formData.deliveryPartners,
                  "required"
                )}
              </Form.Group>
            </div>
          </div>
          <br />
          {!isEdit && formData.deliveryPartners === "OWN" && (
            <>
              <Form.Label className="mb-0">Delivery Charge</Form.Label>
              <p className="fw-700 text-black-25 mb-0">
                {formData.deliveryCharge}
              </p>
            </>
          )}
          {(formData.deliveryPartners === "OWN" ||
            formData.deliveryPartners === "PLATFORM") && (
              <div className="row">
                <div className="col-sm-12 col-md-11">
                  <Form.Group className="mb-0">
                    {isEdit && formData.deliveryPartners === "OWN" ? (
                      <>
                        <div className="d-flex flex-wrap">
                          <span className="mx-2">
                            <Form.Label className="mb-0">
                              Delivery Charge
                            </Form.Label>
                            <Form.Control
                              className={!isEdit ? "remove-height" : ""}
                              plaintext={isEdit ? false : true}
                              readOnly={isEdit ? false : true}
                              type="text"
                              placeholder={`${isEdit ? "Enter Amount" : "-"}`}
                              onChange={(e) => {
                                let {
                                  target: { value },
                                } = e;
                                if (
                                  (!value ||
                                    value.match(/^\d{1,}(\.\d{0,4})?$/)) &&
                                  value.length < 5
                                ) {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    deliveryCharge: value,
                                  }));
                                }
                              }}
                              value={formData.deliveryCharge}
                            />
                          </span>
                        </div>


                        <div className="row">
                          {deliveryPartners?.map((partner, index) => (
                            <div className="col-lg-6 col-xl-6 col-12" key={index}>
                              <div className="d-flex flex-row">
                                <div className="col-8">
                                  <Form.Group className="mb-0">
                                    <div className="d-flex flex-column px-2">
                                      <div className="pt-3">
                                        <Form.Group>
                                          <Form.Label>
                                            Name
                                            {/* <sup>*</sup> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Partner Name"
                                            maxLength={10}
                                            onChange={(e) => {
                                              let {
                                                target: { value },
                                              } = e;
                                              setDeliveryPartners(
                                                (prevPartners) => {
                                                  const updatedPartners = [
                                                    ...prevPartners,
                                                  ];
                                                  updatedPartners[index].name =
                                                    value;

                                                  return updatedPartners;
                                                }
                                              );
                                            }}
                                            value={partner?.name}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div>
                                        <Form.Group>
                                          <Form.Label>
                                            Phone Number
                                            {/* <sup>*</sup> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="tel"
                                            maxLength={10}
                                            placeholder="Enter Partner PhoneNumber"
                                            onChange={(e) => {
                                              let {
                                                target: { value },
                                              } = e;
                                              setDeliveryPartners(
                                                (prevPartners) => {
                                                  const updatedPartners = [
                                                    ...prevPartners,
                                                  ];
                                                  updatedPartners[
                                                    index
                                                  ].mobileNumber = value;
                                                  return updatedPartners;
                                                }
                                              );
                                            }}
                                            value={partner?.mobileNumber}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div>
                                  {index > 0 && (
                                    <NormalButton
                                      primary
                                      label="Remove"
                                      className=""
                                      onClick={() => removeDeliveryPartner(index)}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="col-sm-12 col-md-11">
                            <NormalButton
                              primary
                              label="Add Delivery Partner"
                              className=""
                              // disabled={
                              //   deliveryPartners[deliveryPartners?.length - 1]
                              //     ?.mobileNumber?.length < 10
                              // }
                              onClick={addDeliveryPartner}
                            />
                          </div>
                        </div>

                        {/* <div className="d-flex flex-column px-2 d-none">
                        <div className="pt-3">
                          <Form.Group>
                            <Form.Label>
                              Name<sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Partner Name"
                              onChange={(e) => {
                                let {
                                  target: { value },
                                } = e;

                                setFormData((prevState) => ({
                                  ...prevState,
                                  name: value,
                                }));
                              }}
                              value={formData.name}
                            />

                            {validator.current.message(
                              "name",
                              formData.name,
                              "required|min:1|max:10"
                            )}
                          </Form.Group>
                        </div>

                        <div>
                          <Form.Group>
                            <Form.Label>
                              Phone Number<sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Partner PhoneNumber"
                              onChange={(e) => {
                                let {
                                  target: { value },
                                } = e;

                                setFormData((prevState) => ({
                                  ...prevState,
                                  mobileNumber: value,
                                }));
                              }}
                              value={formData.mobileNumber}
                            />
                            {validator.current.message(
                              "mobileNumber",
                              formData.mobileNumber,
                              "required|min:10|max:10"
                            )}
                          </Form.Group>
                        </div>
                      </div> */}
                      </>
                    ) : null}

                    <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center my-3 ml-3 text-nowrap">
                      {/* {isEdit && formData.deliveryPartners === "PLATFORM" ? (
                          <NormalCustomCheckbox
                            name="cashOnDelivery"
                            checked={formData.cashOnDelivery}
                            value={formData.cashOnDelivery}
                            onChange={() =>
                              setFormData((prevState) => ({
                                ...prevState,
                                cashOnDelivery: !formData.cashOnDelivery,
                              }))
                            }
                            label="Allow Cash on Delivery"
                          />
                        ) : (
                          ""
                        )} */}
                      {isEdit ? (
                        <NormalCustomCheckbox
                          name="cashOnDelivery"
                          checked={formData.cashOnDelivery}
                          value={formData.cashOnDelivery}
                          onChange={() =>
                            setFormData((prevState) => ({
                              ...prevState,
                              cashOnDelivery: !formData.cashOnDelivery,
                            }))
                          }
                          label="Allow Cash on Delivery"
                        />
                      ) : (
                        ""
                      )}
                      {formData.cashOnDelivery && !isEdit && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-4">
                            <span className="label-txt fs-16 fw-600">
                              * Cash on Delivery Allowed
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
            )}
        </div>
      </div>
      {isEdit && (
        <div className="d-flex mt-3">
          <NormalButton
            label="Cancel"
            className="white-btn mr-3"
            onClick={() => setIsEdit(false)}
          />
          <span className="m-2" />

          <NormalButton
            primary
            label="Save"
            className=""
            disabled={isLoading}
            onClick={() => submitAddressDetails()}
          />
        </div>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { chefEditUpdate, checkDeliveryPartnerAvailablity },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(AddressDetails);
