import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { CommonPageLoader } from "component/common/CommonPageLoader";
import AboutChef from "./AboutChef";
import MenuCard from "./MenuCard";
import whatsapp from "assets/images/whatsappChat.png";
import { NormalSearch } from "./NormalSearch";
import { convertToSlug } from "service/helperFunctions";
import { Slick1 } from "./../common/Slick1/";
import { Pagination } from "component/common/Pagination";
import { handleAddToCart, getAllCartById } from "action/cartAct";
import { viewPublishTestimonial } from "action/OrderReviewAct";
import {
  getMenuDetails,
  getSingle,
  getChefWithBrand,
} from "action/ChefDetailsAct";
import { PageIcons } from "component/common";
import { history } from "service/helpers";
import Paginations from "../common/Paginations";
import { useRecoilState } from "recoil";
import { cartItems } from "../../coils";
const ChefDetailClass = (props) => {
  const [allCartItems, setAllCartItems] = useRecoilState(cartItems);
  const [itemOffset, setItemOffset] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isAddingCart, setIsaddingCart] = useState(false);
  const [availableMenuenuList, setAvailableMenuenuList] = useState([]);
  //life cycle

  const [filterList, setFilterList] = useState([]);
  const [search, setSearch] = useState("");
  const [userID, setUserID] = useState("");
  const [ChefDetail, setChefDetail] = useState();
  const [pageMeta, setPageMeta] = useState(null);
  const [testimonilaData, setTestimonilaData] = useState([]);
  //life cycle
  useEffect(() => {
    setFilterList(
      availableMenuenuList.filter((searchUsers) => {
        return searchUsers.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, availableMenuenuList]);

  useEffect(() => {
    fetchChefDetails();
    getAllCartItems();
  }, []);
  //menuListApiCallFunc
  const menuListApiCallFunc = (id, page = 1) => {
    let payload = {
      userId: localStorage.getItem("userId"),
      chefId: id,
    };
    let query = {
      page: page,
      limit: 10,
    };
    setIsLoading(true);
    props
      .menuListApiCall(payload, query)
      .then((data) => {
        if (data.data.list) {
          setFilterList(data?.data?.list);
          setAvailableMenuenuList(data?.data?.list);
          setPageMeta(data?.data?.pageMeta);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };
  //fetchChefDetails
  const fetchChefDetails = async () => {
    let { location } = history;
    let name = location.pathname.split("/").pop();
    let body = {
      slug: convertToSlug(name),
    };
    setIsLoading(true);
    await props
      .getChefWithBrand(body)
      .then(({ data }) => {
        if (!!data) {
          setChefDetail(data);
          if (props.currentPageMenu) {
            menuListApiCallFunc(data._id, props.currentPageMenu);
          } else {
            menuListApiCallFunc(data._id);
          }
          publishTestimonial(data._id);
          setUserID(data._id);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };
  const publishTestimonial = async (id) => {
    let body = { chefId: id };
    await props
      .viewPublishTestimonial(body)
      .then(({ data }) => {
        setIsLoading(false);
        if (data) {
          setTestimonilaData(data.list);
        }
      })
      .catch(() => setTestimonilaData(false));
  };

  const token = localStorage.getItem("authToken");
  const handlePagination = (page) => {
    setItemOffset(page.selected);
    page = page.selected + 1;
    menuListApiCallFunc(userID, page);
  };
  const handleAddToCart = async (id) => {
    let body = { userId: ChefDetail._id, foodId: id, quantity: 1 };
    setIsaddingCart(true);
    await props
      .handleAddToCart(body)
      .then(({ data }) => {
        if (!!data) {
        }
        setIsaddingCart(false);
      })
      .catch(() => setIsaddingCart(false));
  };

  const getAllCartItems = async () => {
    if (localStorage.getItem("authToken")) {
      let body = {
        userId: localStorage.getItem("userId"),
      };
      await props
        .getAllCartById(body)
        .then((res) => {
          let arr = [];
          res.data.forEach((values, keys) => {
            if (!allCartItems.includes(values.foodId._id)) {
              arr.push(values.foodId._id);
            }
          });
          setAllCartItems([...allCartItems, ...arr]);
        })
        .catch();
    }
  };

  return (
    <div className="chefdeatil_container position-relative">
      <div className="container">
        <div className="pt-5">
          {isLoading ? (
            <CommonPageLoader />
          ) : (
            <React.Fragment>
              {!!ChefDetail ? (
                <div className="aboutChef bg-white-FF">
                  <AboutChef list={ChefDetail} />
                </div>
              ) : (
                <div className="col-12 mt-5 pt-5 mb-5 pb-5">
                  <p className="text-center fs-16 fw-600 text-black-1c">
                    No data found !!!
                  </p>
                </div>
              )}
              <div className="container chef_food_deatil_container">
                {!!ChefDetail && (
                  <div className="row my-4 ">
                    <div className="col-xs-12 col-sm-8 col-md-5 col-lg-4 mt-3">
                      <NormalSearch
                        placeholder="Search"
                        value={search}
                        name="searchValue"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {ChefDetail?.islicensedExpried ? (
                  <div className=" blur-certfication chef-details-blur media w-100">
                    <p className="blur mb-0 text-white">
                      Uh-ho! This Chef’s license has expired and won’t be able
                      to receive orders until it’s renewed. Please try ordering
                      from another chef!
                    </p>
                  </div>
                ) : (
                  <>
                    {filterList.length !== 0 ? (
                      filterList.filter(
                        (item) => item.typeOfMenu === "Available Now"
                      ).length > 0 && (
                        <>
                          <div className="row mt-4">
                            <div className="bitesheading col-10 line-height-36 fs-30 fw-700 text-primary-color">
                              Available Now
                            </div>
                          </div>
                          <div className="row">
                            {filterList
                              .filter(
                                (item) => item.typeOfMenu === "Available Now"
                              )
                              .map((list, index) => (
                                <div
                                  className="menu_card col-xl-6 col-lg-6 mt-3 pt-1 col-sm-12 col-xm-12 col-md-12 position-relative "
                                  key={index}
                                >
                                  <MenuCard
                                    isAddingCart={isAddingCart}
                                    list={list}
                                    handleAddToCart={handleAddToCart}
                                    ChefDetail={ChefDetail}
                                  />
                                </div>
                              ))}
                          </div>
                        </>
                      )
                    ) : (
                      <div className="col-12 mt-5 pt-5 mb-5 pb-5">
                        <p className="text-center fs-16 fw-600 text-black-1c">
                          No data found !!!
                        </p>
                      </div>
                    )}
                    {!!token &&
                      !isLoading &&
                      ChefDetail &&
                      ChefDetail.whatsappNumber && (
                        <div className="whatsappImage">
                          <a
                            href={`https://api.whatsapp.com/send?phone=${ChefDetail.whatsappNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={whatsapp} alt="" srcset="" />
                          </a>
                        </div>
                      )}

                    {filterList.length !== 0 &&
                      filterList.filter(
                        (item) => item.typeOfMenu !== "Available Now"
                      ).length > 0 && (
                        <>
                          <div className="d-flex position-relative">
                            <div className="bitesheading col-lg-12  col-md-12 col-10  mt-4 line-height-36 fs-30 fw-700 text-primary-color">
                              Advanced Order
                            </div>
                          </div>
                          <div className="row">
                            {filterList
                              .filter(
                                (item) => item.typeOfMenu !== "Available Now"
                              )
                              .map((list, index) => (
                                <div
                                  className="menu_card col-xl-6 col-lg-6 col-sm-12 col-xm-12 col-md-12  mt-3 pt-1 pb-2   position-relative  "
                                  key={index}
                                >
                                  <MenuCard
                                    isAddingCart={isAddingCart}
                                    list={list}
                                    handleAddToCart={handleAddToCart}
                                    ChefDetail={ChefDetail}
                                  />
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    <div className="">
                      {/*{pageMeta ? (
                    <Pagination
                      handleChange={handlePagination}
                      pageMeta={pageMeta}
                    />
                  ) : null}*/}

                      {pageMeta ? (
                        <Paginations
                          postsPerPage={pageMeta.chunk}
                          totalPosts={pageMeta.total}
                          paginate={handlePagination}
                          itemOffset={itemOffset}
                        />
                      ) : null}
                    </div>
                  </>
                )}
                {testimonilaData?.length !== 0 ? (
                  <div className="testimonial">
                    <Slick1 testimonilaData={testimonilaData} />
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentPageMenu: state.commonStore.currentPageMenu,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      menuListApiCall: getMenuDetails,
      getSingle,
      getChefWithBrand,
      handleAddToCart,
      viewPublishTestimonial,
      getAllCartById,
    },
    dispatch
  );
};

export const ChefDetailComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChefDetailClass);
