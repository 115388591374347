import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { history } from "service/helpers";
import { Navbar } from "component/common/Navbar";
import { NormalButton } from "component/common";
import { PageIcons } from "component/common";
import { useRecoilState } from "recoil";
import { cartItems } from "coils";
import { getOrderDetailsAct } from "action/CustomerDetailsAct";
import { paymentDetailCapture } from "action/cartAct";


const CancelOrderClass = ({ orderId, getOrderDetails }) => {
  const [allCartItems, setAllCartItems] = useRecoilState(cartItems);
  const [orderDetails, setOrderDetails] = useState(null);
  const [cancelOrderId, setCancelOrderId] = useState('');
  const authToken = localStorage.getItem("authToken");
  const getSessionId = JSON.parse(localStorage.getItem("sessionId"));
  const { id } = getSessionId || "";

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId).then(({ data }) => setOrderDetails(data));
    }
  }, [orderId, getOrderDetails]);

  const captureFunc = useCallback(async () => {
    let body = {
      orderId: orderId,
      paymentOrderId: id,
      paymentDate: new Date()
    };
    try {
      const data = await paymentDetailCapture(body);
      if (data) {
        const { orderId } = data?.data || {};
        setCancelOrderId(orderId);
        localStorage.removeItem('sessionId');
      }
    } catch (err) {
      console.log(err);
    }
  }, [authToken, window.location.pathname]);

  useEffect(() => {
    captureFunc();
  }, [captureFunc]);

  return (
    <div className="orderplaced">
      <div className="d-flex w-md-100 h-100 ">
        <div className="col-lg-12 col-12 mx-auto px-3 login-area">
          <div className=" mx-auto col-lg-7 col-12 h-100 ">
            <div className="d-flex align-items-center flex-column w-100">
              <Navbar />
              <>
                  <p className="fs-42 fw-700 line-height-48 text-black-33 mt-4 mb-2">
                    Sorry! Order failed!
                  </p>
                  <p className="col-12 col-sm-6 fs-16 fw-400 line-height-18 text-center text-grey-4f">
                    Your order {cancelOrderId}
                    has been failed.
                  </p>
                </>
              <div className="col-sm-6 col-12 py-5 mb-5 text-center">
                <NormalButton
                  optionButton
                  label="Back to home"
                  className="fw-700 mt-2 text-primary-color"
                  onClick={() => history.push("/index/customerProfile")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageIcons />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrderDetails: getOrderDetailsAct }, dispatch);
};

let component = CancelOrderClass;

export const CancelOrderComp = connect(null, mapDispatchToProps)(component);
