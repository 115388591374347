import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { CancelOrderComp } from 'component/CancelOrder'

export class CancelOrderClass extends Component {
  render() {
    let {
      match: { params },
    } = this.props
    return <CancelOrderComp orderId={params.orderId} />
  }
}
export const CancelOrder = connect(null, null)(CancelOrderClass)
