import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import { NormalButton } from "component/common/NormalButton";
const UnderReviewClass = (props) => {
  return (
    <div className="d-flex align-items-center flex-column w-100">
      <div className="w-100 Password">
        <div className="mb-4  pt-5 text-black-25 mx-auto line-height-46 fs-42 fw-700 heading">
          The Chef Lane
        </div>
        <div className="bg-clr mx-auto py-4">
          <div className="col-md-6 col-12 mx-auto">
            <div className="fw-700 fs-24 line-height-28 mt-md-4 text-black-18">
              Phi
            </div>
            <div className="mt-2 mt-md-1">
              <div className="fs-16 line-height-24 fs-400">
                We’ve sent password reset link to change password to{" "}
              </div>
              <div className="d-flex justify-content-center mt-4 my-3 ">
                <NormalButton
                  primary
                  label="Home"
                  className="w-100"
                  onClick={() => history.push("/")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const UnderReviewComp = connect(
  null,
  mapDispatchToProps
)(UnderReviewClass);
