import { uploadsApi } from "../service/apiVariables";
import { chefDataType } from "service/actionType";

export const fileUpload =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...uploadsApi.fileUpload, body })
        .then((response) => {
          resolve(response);
          dispatch({ type: chefDataType.chefData, payload: response });
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
export const imageUpload =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...uploadsApi.imageUpload, body })
        .then((response) => {
          resolve(response);
          dispatch({ type: chefDataType.chefData, payload: response });
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
