import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import {
  ProfileIcon,
  MarkerIcon,
  MenuIcon,
  OrderIcon,
  RatingIcon,
  TalkIcon,
} from "../icons/Icons";
import correct from "assets/images/correct.png";
import wrong from "assets/images/wrong.png";
import { convertToSlug, ternaryCondition } from "service/helperFunctions";
import { Modal, Table } from "react-bootstrap";
import { FRONT_END_PORT, options, redirectToCheckout } from "utils/constants";
import deleteProfile from "assets/svg/delete-profile.svg";
import { NormalButton } from "component/common";
import logo from "assets/svg/Transparent-logo.svg";
import appLlogo from "assets/images/razorpay.png";
import subActive from "assets/images/subActive.png";
import subInActive from "assets/images/subInActive.png";
import { quitChef, getSinglePending, getSingle, subscriptionInitiate} from "action/ChefDetailsAct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "service/helpers";

import {
  faArrowLeft,
  faArrowRight,
  faTruckMoving,
  faClock,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { CommonPageLoader } from "component/common";
import courier from "assets/images/courier.png";
import courierActive from "assets/images/courier-active.png";
import cadendarImage from "assets/images/cadendar.png";

import {
  capturePaymentDetails,
  capturePaymentFailureDetails,
  changeService,
  servicePayment,
  serviceSubscriptions,
  freeSubscription,
  offersList,
  offerSubScription,
  continueAsListingApi,
} from "action/OrderReviewAct";
import { Toast } from "service/toast";

import { DELIVERY_PARTNER_TYPE } from "utils/constants";
import axios from "axios";
import { paymentDetailCapture } from "action/cartAct";
import { logout } from "service/utilities";
import moment from "moment";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
}

function SideBar({
  active,
  setActive,
  data,
  setShowDeactivate,
  quitChefApi,
  serviceSubscriptionsApi,
  subscriptionInitiate,
  changeServiceApi,
  servicePaymentApi,
  capturePaymentDetailsApi,
  capturePaymentFailureDetailsApi,
  offersListApi,
  offerSubScriptionApi,
  setFormDatePending,
  freeSubscriptionApi,
  getSinglePending,
  continueAsListingApi,
  paymentDetailCaptureApi,
  getSingle,
}) {
  let userDetails = data;

  const [subscriptionValid, setSubscriptionValid] = useState(true);
  const [isQuitConfirm, setIsQuitConfirm] = useState(false);
  const [deactivateLoader, setdeactivateLoader] = useState(false);
  const [subScriptionPopup, setSubScriptionPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const [pricingOpen, setPricingOpen] = useState(false);
  const [typeSelected, setTypeSelected] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState("");
  const [packageDetails, setPackageDetails] = useState([]);
  const [pricingDetails, setPricingDetails] = useState([]);
  const [isFromExpired, setIsFromExpired] = useState(false);
  const [offerListData, setOfferListData] = useState([]);
  const [NewSubSCriptionPopup, setNewSubSCriptionPopup] = useState(false);
  const [OfferPricingDetails, setOfferPricingDetails] = useState({});
  const [pendingDetails, setPendingDetails] = useState({});
  const [licenseReviewPopup, setLicenseReviewPopup] = useState(false);
  const [bankDetailsReviewPopup, setBankDetailsReviewPopup] = useState(false);
  const [offerPage, setOfferPage] = useState(1);
  const [totalOfferPage, setTotalPageOffer] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleData = (key, val1 = "", val2 = "") => {
    return active === key ? val1 : val2;
  };

  useEffect(() => {
    let { isSubscriptionAvailable = false } = userDetails;
    let { isSubscriptionExpired, subscriptionEndDate } =
      userDetails?.subscriptionId || {};
    setSubscriptionValid(isSubscriptionAvailable);
    if (
      userDetails.isSubscriptionExpired &&
      userDetails.service === "LISTING PLUS" 
    ) {
      getOffersList();
      if (userDetails.isFirstTimeSubscription) {
        setPricingOpen(true);
      }
    }
    if (
      userDetails.service === "LISTING" &&
      userDetails.isListingToPlus &&
      userDetails.LicenseDetails.adminApproved === "APPROVED" && 
      userDetails?.bankDetails.adminApproved === "APPROVED" && 
      !userDetails.subscriptionId
    ) {
      getOffersList();
    }
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    const afterSeventhDay = moment(new Date(subscriptionEndDate))
      .add(7, "d")
      .format("YYYY-MM-DD");
    if (isSubscriptionExpired && todayDate > afterSeventhDay) {
      setSubScriptionPopup(true);
    }
    getPendingDetails();
  }, [userDetails]);

  useEffect(() => {
    if (offerPopup) {
      getOffersList();
    }
  }, [offerPage]);

  const getPendingDetails = () => {
    getSinglePending(userDetails?._id)
      .then(({ data }) => {
        setPendingDetails(data);
      })
      .catch(() => setdeactivateLoader(false));
  };

  const handleQuit = () => {
    if (isActive().then((res) => res === false)) {
      setdeactivateLoader(true);
      quitChefApi(userDetails?._id)
        .then(() => {
          setIsQuitConfirm(false);
          setSubscriptionValid(false);
          setdeactivateLoader(false);
        })
        .catch(() => setdeactivateLoader(false));
    }
  };

  const handleSelectButton = (buttonValue, type, disableStatus = false) => {
    return (
      <div className="mt-md-3 mt-1">
        <NormalButton
          primary
          label={buttonValue}
          disabled={disableStatus}
          className="px-md-5 px-4 fw-700"
          onClick={() => handleSubmit(type)}
        />
      </div>
    );
  };

  const handleSubmit = (serviceType) => {
    if (isActive().then((res) => res === false)) {
      if (serviceType === "LISTING") {
        let payload = { chefId: userDetails?._id, service: "LISTING" };
        changeServiceApi(payload).then(() => {
          setSubScriptionPopup(false);
          setTypeSelected(null);
          window.location.reload();
        });
      } else {
        setIsFromExpired(true);
        setPricingOpen(true);
        // setOfferPopup(true)
      }
    }
  };

  const renderInterval = (title) => {
    let interval = "month";
    if (
      title?.includes("year") ||
      title?.includes("Year") ||
      title?.includes("1Y") ||
      title?.includes("1y")
    ) {
      interval = "yearly";
    } else {
      interval = "monthly";
    }
    return interval;
  };

  const getSubscriptionPrice = async (planObj, key, listingplusPaymentData) => {

    const { subscriptionType, isUSD, price, title } = planObj || {};
    let body = {
      currency: isUSD ? "USD" : "INR",
      amount: Number(userDetails?.subscriptionId?.subscriptionAmount) || 0,
      interval: renderInterval(title || ""),
      name: userDetails?.subscriptionId?.subscriptionType,
      chefId: userDetails?._id,
      offerId: userDetails ? userDetails?.subscriptionId?.offerId : " ",
    };
    let offerBody = {
      currency: planObj?.currencyType,
      amount: Number(planObj?.amount) || 0,
      interval: renderInterval(planObj?.subscriptions),
      name: planObj?.subscriptions,
      chefId: planObj?.chefId,
      offerId: planObj ? planObj?.offerId : " ",

    }; 
    const datum = key === "offer" ? offerBody : body; 

    // try {
    //   const response = await (subscriptionInitiate(datum, options));
    //   console.log("Subscription Response:", response);
    // }
    // catch(err){
    //   console.log(err)
    // }

    
    try {
      const response = await axios.post(
        "https://preprodapis.thecheflane.com/api/v1/chef/subscriptionInitiate",
        datum,
        {
          headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${options.clientSecret}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };



      //   const paymentLink = listingplusPaymentData?.savePaymentLinkData?.paymentLinkResponse?.short_url;
      //   if (paymentLink) {
      //     window.location.href = paymentLink;
      //   } else {
      //     Toast({ type: "error", message: "Failed to get payment link." });
      //   }

      //   // makeStripePayment(response?.data);
    
  const handlePaymentSubmit = () => {

    if (isActive().then((res) => res === false)) {
      // if (userDetails?.service === "LISTING PLUS") {
      handlePayment();
      // }

      // else {
      //   let payload = { chefId: userDetails?._id, service: "LISTING PLUS" };
      //   changeServiceApi(payload).then(() => handlePayment());
      // }
    }
  };


  const handlePayment = async () => {
    let obj = pricingDetails?.find((x) => x.subscriptionType === typeSelected);
    let body = {
      chefId: userDetails?._id,
      currencyType: obj.isUSD ? "USD" : "INR",
      amount: obj?.totalPrice,
      subscriptions: obj?.subscriptionType,
      offerId: userDetails ? userDetails?.subscriptionId?.offerId : "",
    };
  
    await getSubscriptionPrice(body, "subscription", data);
  
    servicePaymentApi(body, { chefId: userDetails?._id }).then((data) => {
      localStorage.setItem("subscriptionData", JSON.stringify(data));
      if (data) {
        const paymentLink = data?.createPaymentLink?.short_url;
        if (paymentLink) {
          const paymentTab = window.open(paymentLink, "_blank");
  
          const handleMessage = (event) => {
            if (event.data === "paymentComplete") {
              paymentTab.close();
  
              history.push({
                pathname: "/index/activeProfile",
                state: { 
                  isRedirect:true, 
                }
              });
              
            }
          };
  
          window.addEventListener("message", handleMessage);
  
          return () => window.removeEventListener("message", handleMessage);
        }
      }
    });
  };
  
//not used anywhere so commented the makestripepayment

  // const makeStripePayment = async (datum) => {
  //   const { data } = datum || {};
  //   localStorage.setItem("sessionId", JSON.stringify(datum));
  //   try {
  //     const session = data;
  //     const result = redirectToCheckout({
  //       sessionId: session?.id,
  //     });
  //     if (result?.error) {
  //       console.log(result.error);
  //     }
  //   } catch (error) {
  //     console.error("Error making payment:", error);
  //   }
  // };

  const handleContinueListing = async () => {
    if (isActive().then((res) => res === false)) {
      try {
        const data = await continueAsListingApi({}, userDetails?._id);
        if (data) {
          setOfferPopup(false);
          setSubScriptionPopup(false);
          setTimeout(() => {
            Toast({
              type: "success",
              message: data?.message,
            });
          }, 700);
          window.location.reload();
        } else {
          Toast({
            type: "error",
            message: data?.message,
          });
          setOfferPopup(false);
          setSubScriptionPopup(false);
        }
      } catch (error) {
        setOfferPopup(false);
        setSubScriptionPopup(false);
        console.error("Error occurred:", error);
      }
    }
  };

  const handlePaymentOffer = async () => {
    if (isActive().then((res) => res === false)) {


      let obj = OfferPricingDetails;
      let body = {
        chefId: userDetails?._id,
        currencyType: obj.isUSD ? "USD" : "INR",
        amount: obj?.totalPrice,
        subscriptions: obj?.subscriptionType,
        offerId: selectedOffer,
      };
      if (obj?.totalPrice !== 0) {
        await getSubscriptionPrice(body, "offer", data);

        servicePaymentApi(body, { chefId: userDetails?._id }).then((data) => {
          localStorage.setItem("subscriptionData", JSON.stringify(data));
          if (data) {
            const paymentLink = data?.createPaymentLink?.short_url
            if (paymentLink) {
              // const successUrl = `${window.location.origin}/payment-success?orderId=${data.orderId}`;
              const paymentTab = window.open(paymentLink, "_blank");

              const handleMessage = (event) => {
                if (event.data === "paymentComplete") {
                  paymentTab.close();
      
                  history.push({
                    pathname: "/index/activeProfile",
                    state: { 
                      isRedirect:true, 
                    }
                  });
                  
                }
              };
              window.addEventListener("message", handleMessage);
  
              return () => window.removeEventListener("message", handleMessage);
              
              }
          }
          
        });
      } else {
        Toast({
          type: "error",
          message:
            "Amount 0 will be Not Applicable for Payment.Pls choose Other Offer",
          time: 2000,
        });
        setNewSubSCriptionPopup(false);
        getOffersList()
      }
    }
  };

  

  const freeSubscribtion = () => {
    if (isActive().then((res) => res === false)) {
      let obj = OfferPricingDetails;
      let body = {
        chefId: userDetails?._id,
        currencyType: obj.isUSD ? "USD" : "INR",
        amount: obj?.totalPrice,
        subscriptions: obj?.subscriptionType,
        offerId: selectedOffer,
      };

      freeSubscriptionApi(body, { chefId: userDetails?._id }).then((data) => {
        if (data?.data?.isLicenseNeeded) {
          setNewSubSCriptionPopup(false);
          setSelectedOffer("");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setPricingOpen(false);
        } else {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (subScriptionPopup && userDetails?._id) {
      serviceSubscriptionsApi({ chefId: userDetails?._id }).then(
        ({ subscriptions = [], pricing = [] }) => {
          setPackageDetails([...subscriptions]);
          setPricingDetails([...pricing]);
        }
      );
    }
  }, [subScriptionPopup, userDetails, serviceSubscriptionsApi]);

  const displayRazorpay = async (data, chefId) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let amount = Number(data.amount) * 100;
    const options = {
      key: process.env.REACT_APP_RAZORPAYKEY,
      amount: amount ? amount : "",
      order_id: data.id,
      currency: data.currency || "INR",
      name: "The Chef Lane",
      image: appLlogo,
      theme: { color: "#08316d" },
      handler: function (res) {
        captureFunc(res);
      },
      modal: {
        ondismiss: function () {
          captureFuncFailure(data, chefId);
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function () {
      captureFuncFailure(data, chefId);
    });
  };

  const captureFuncFailure = ({ subscriptionId }, chefId) => {
    let query = { chefId, subscriptionId };
    capturePaymentFailureDetailsApi(query);
  };

  const captureFunc = (result) => {
    if (result) {
      let responseData = {
        razorpay_order_id: result.razorpay_order_id || "",
        razorpay_payment_id: result.razorpay_payment_id || "",
        razorpay_signature: result.razorpay_signature || "",
        paymentDate: new Date(),
        chefId: userDetails?._id,
      };
      capturePaymentDetailsApi(responseData).then(() =>
        console.log(responseData)
      );
    }
  };

  const checkActiveStatus = useCallback(async () => {
    try {
      const response = await getSingle(localStorage.getItem("userId"));

      const { data } = response;
      if (data?.deactivateId) {
        Toast({
          type: "error",
          message: "Account is deactivated",
          toastId: "success1",
        });
        logout();
        return data?.deactivateId || true;
      }
      return false;
    } catch (error) {
      console.error("Error occurred while fetching active status:", error);
      return null;
    }
  }, []);

  const isActive = async () => {

    const result = await checkActiveStatus();
    return result;
  };

  const getOffersList = () => {
    setLoading(true);
    offersListApi({ page: offerPage, limit: 9 })
      .then((data) => {
        checkActiveStatus();
        setTotalPageOffer(data.pageMeta.totalPages);
        if (data && data.list.length) {
          setOfferListData(data.list);
          setOfferPopup(true);
        } else {
          setOfferPopup(false);
          setSubScriptionPopup(true);
        }
      })
      .catch((e) => console.log("e :>> ", e))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOfferSubmit = async () => {
    if (isActive().then((res) => res === false)) {
      let result = await offerSubScriptionApi({
        chefId: userDetails?._id,
        offerId: selectedOffer,
      });
      if (result) {
        setOfferPricingDetails(result?.pricing);
        setNewSubSCriptionPopup(true);
        setOfferPopup(false);
      }
    }
  };

  const handleChangeService = async () => {

    if (pendingDetails?.LicenseDetails?.adminApproved === "REJECTED" || pendingDetails?.bankDetails?.adminApproved === "REJECTED") {
      setFormDatePending(pendingDetails);
    } else if (
      !pendingDetails?.LicenseDetails?.licenseNumber ||
      !pendingDetails?.LicenseDetails?.licenseExpiryDate ||
      !pendingDetails?.LicenseDetails?.licenseFileUrl || 
      !pendingDetails?.bankDetails?.accountName ||
  !pendingDetails?.bankDetails?.accountNumber ||
  !pendingDetails?.bankDetails?.ifsc ||
  !pendingDetails?.bankDetails?.re_enter_accountNumber
    ) {
      setFormDatePending(pendingDetails);
    }
    else if (
      pendingDetails?.LicenseDetails?.licenseNumber &&
      pendingDetails?.LicenseDetails?.licenseExpiryDate &&
      pendingDetails?.LicenseDetails?.licenseFileUrl &&
      pendingDetails?.LicenseDetails?.adminApproved !== "APPROVED"
    ) {
      setLicenseReviewPopup(true);
    }
    // else if () {
    //   getOffersList();
    // }


// Bank Details

// if () {
//   setFormDatePending(pendingDetails);
// }
//  else if (
//   !pendingDetails?.bankDetails?.accountName ||
//   !pendingDetails?.bankDetails?.accountNumber ||
//   !pendingDetails?.bankDetails?.ifsc ||
//   !pendingDetails?.bankDetails?.re_enter_accountNumber
// ) {
//   setFormDatePending(pendingDetails);
// }
else if (
  pendingDetails?.bankDetails?.accountNumber &&
  pendingDetails?.bankDetails?.ifsc &&
  pendingDetails?.bankDetails?.re_enter_accountNumber &&
  pendingDetails?.bankDetails?.adminApproved !== "APPROVED"
) {
  setBankDetailsReviewPopup(true);
}
else if (userDetails?.LicenseDetails.adminApproved === "APPROVED" && userDetails?.bankDetails.adminApproved === "APPROVED") {
  getOffersList();
}

    else {
      setFormDatePending(pendingDetails);
    }
    checkActiveStatus();
  };

  const offerPagePagination = (forword = false) => {
    if (forword) {
      if (offerPage < totalOfferPage) {
        setOfferPage(offerPage + 1);
      }
    } else {
      if (offerPage !== 1) {
        setOfferPage(offerPage - 1);
      }
    }
  };

  return (
    <div id="sideBar-chef">
      <div className="outline">
        <ul>
          <li
            className={handleData("basicDetail", "active", "")}
            onClick={() => setActive("basicDetail")}
          >
            <i>
              <ProfileIcon
                color={handleData("basicDetail", "white", "#08316d")}
              />
            </i>
            <span>Profile</span>
          </li>
          {userDetails?.service === "LISTING PLUS" && (
            <li
              className={handleData("availabilityTime", "active", "")}
              onClick={() => setActive("availabilityTime")}
            >
              {active === "availabilityTime" ? (
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ color: "rgb(255, 255, 255)" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ color: "rgb(8, 49, 109)" }}
                />
              )}
              <span style={{ marginLeft: "13px" }}>Store Timing</span>
            </li>
          )}
          <li
            className={handleData("address", "active", "")}
            onClick={() => setActive("address")}
          >
            <i>
              <MarkerIcon color={handleData("address", "white", "#08316d")} />
            </i>
            <span>Address</span>
          </li>
          <li
            className={handleData("menu", "active", "")}
            onClick={() => setActive("menu")}
          >
            <i>
              <MenuIcon color={handleData("menu", "white", "#08316d")} />
            </i>
            <span>Menu</span>
          </li>
          {userDetails?.service === "LISTING PLUS" && (
            <li
              className={handleData("chefOrder", "active", "")}
              onClick={() => setActive("chefOrder")}
            >
              <i>
                <OrderIcon
                  color={handleData("chefOrder", "white", "#08316d")}
                />
              </i>
              <span>Orders</span>
            </li>
          )}
          {userDetails?.service === "LISTING PLUS" && (
            <li
              className={handleData("rateReview", "active", "")}
              onClick={() => setActive("rateReview")}
            >
              <i>
                <RatingIcon
                  color={handleData("rateReview", "white", "#08316d")}
                />
              </i>
              <span>Ratings and Review</span>
            </li>
          )}
          {userDetails?.service === "LISTING PLUS" && (
            <li
              className={handleData("subscription", "active", "")}
              onClick={() => setActive("subscription")}
            >
              {active === "subscription" ? (
                <img src={subActive} alt="" />
              ) : (
                <img src={subInActive} alt="" />
              )}
              <span style={{ marginLeft: "13px" }}>Subscription</span>
            </li>
          )}
          {userDetails?.deliveryPartners === DELIVERY_PARTNER_TYPE.OWN &&
            userDetails?.service === "LISTING PLUS" && (
              <li
                className={handleData("ownDelivery", "active", "")}
                onClick={() => setActive("ownDelivery")}
              >
                {active === "ownDelivery" ? (
                  <FontAwesomeIcon
                    icon={faTruckMoving}
                    style={{ color: "rgb(255, 255, 255)" }}
                  />
                ) : (
                  // <img src={courierActive} alt="" />
                  <FontAwesomeIcon
                    icon={faTruckMoving}
                    style={{ color: "rgb(8, 49, 109)" }}
                  />
                  // <img src={courier} alt="" />
                )}
                <span style={{ marginLeft: "13px" }}>Own Delivery Partner</span>
              </li>
            )}
          {userDetails?.service === "LISTING" && (
            <li
              className={handleData("changeService", "active", "")}
              onClick={() => {
                handleChangeService(userDetails);
              }}
            >
              <i>
                <OrderIcon
                  color={handleData("changeService", "white", "#08316d")}
                />
              </i>
              <span>Change Service</span>
            </li>
          )}
          <li>
            <i>
              <TalkIcon color={handleData("talk", "white", "#08316d")} />
            </i>
            <span>
              {!!userDetails.businessName && (
                <a
                  href={`mailto:support@thecheflane.com?subject=You have a message from chef - ${userDetails.businessName
                    }&body=Chefs webpage: ${FRONT_END_PORT}${convertToSlug(
                      userDetails.businessNameSlug
                    )}%0D%0A %0d%0aPlease do not delete above content %0D%0A %0d%0aHi, %0D%0A`}
                >
                  Email Admin
                </a>
              )}
            </span>
          </li>
          <hr className="m-0 text-primary-color"></hr>
          <li onClick={() => setShowDeactivate(true)}>
            <img class="me-2 pe-1" src={deleteProfile} alt="icon" />
            <span>Delete Profile</span>
          </li>
        </ul>
      </div>
      {subScriptionPopup && (
        <Modal
          show={subScriptionPopup}
          backdrop={true}
          className="subscription-modal"
          centered
          size="lg"
        >
          <Modal.Body className="p-md-4 p-3">
            <div>
              <div className="d-flex justify-content-end">
                {userDetails?.service === "LISTING" && !pricingOpen ? (
                  <i
                    onClick={() => setSubScriptionPopup(false)}
                    className="icon-close cursor-pointer ml-auto"
                  />
                ) : null}
                {isFromExpired ? (
                  <i
                    onClick={() => {
                      setPricingOpen(false);
                      setIsFromExpired(false);
                    }}
                    className="icon-close cursor-pointer ml-auto"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              {pricingOpen ? (
                <div className="mt-md-4 mt-3">
                  <h3 className="d-flex justify-content-center mb-md-4 fs-26">
                    Subscription Details
                  </h3>

                  <div
                    className={
                      pricingDetails?.length > 2
                        ? "row"
                        : "d-flex flex-lg-row flex-xl-row flex-column justify-content-center"
                    }
                  >
                    {pricingDetails.map((list, index) => (
                      <div
                        className={
                          pricingDetails?.length > 2
                            ? "col-lg-4 col-md-4 col-12 mb-3"
                            : "col-lg-4 col-md-12 col-12 mb-3 px-2"
                        }
                        key={index}
                      >
                        <div
                          className={`pricing-card ${ternaryCondition(
                            typeSelected === list.subscriptionType,
                            "active",
                            ""
                          )}`}
                          onClick={() => setTypeSelected(list.subscriptionType)}
                        >
                          <h5 className="text-center">{list.title}</h5>
                          <p className="text-center">
                            <b>Price : </b>
                            {ternaryCondition(list.isUSD, "USD", "INR")}{" "}
                            {list.price}{" "}
                            {ternaryCondition(list.isUSD, "", "+ 18% GST")}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {typeSelected && (
                    <p className="text-center fw-600 fs-16 mb-0">
                      Total amount to be paid is{" "}
                      {
                        pricingDetails?.find(
                          (x) => x.subscriptionType === typeSelected
                        )?.totalPrice
                      }
                    </p>
                  )}
                  <div className="mx-auto d-flex justify-content-center my-4">
                    <NormalButton
                      primary
                      label="Pay Now"
                      disabled={!typeSelected}
                      className="px-md-5 px-4 fw-700"
                      onClick={() => handlePaymentSubmit()}
                    />
                    <NormalButton
                      primary
                      label="Change to Listing"
                      className="px-md-3 px-4 mx-2 fw-700"
                      onClick={() => handleContinueListing()}
                    />
                  </div>
                </div>
              ) : (
                <div className="subscription-table mt-md-4 mt-3">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Packages</th>
                        {/* <th>Listing</th> */}
                        <th>ListingPLUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packageDetails.map((list, index) => (
                        <tr key={index}>
                          <td>{list.title}</td>
                          {/* <td>
                            {!list.listingPrice && list.title === "Pricing" ? (
                              "Free"
                            ) : list.listingPrice &&
                              list.title === "Pricing" ? (
                              "Paid"
                            ) : (
                              <img
                                alt="listing icon"
                                src={ternaryCondition(
                                  list.listing,
                                  correct,
                                  wrong
                                )}
                              />
                            )}
                          </td> */}
                          <td>
                            {!list?.listingPlusPrice &&
                              list?.title === "Pricing" ? (
                              "Free"
                            ) : list?.listingPlusPrice &&
                              list?.title === "Pricing" ? (
                              <>
                                {pricingDetails?.map((list, i) => (
                                  <p>
                                    <span>
                                      &#8377; {list?.price} /{" "}
                                      {list?.subscriptionType
                                        ?.split("")
                                        ?.pop()
                                        ?.toUpperCase() + " "}{" "}
                                      {pricingDetails?.length - 1 !== i && "&"}{" "}
                                    </span>
                                  </p>
                                ))}
                              </>
                            ) : (
                              <img
                                alt="listing icon"
                                src={ternaryCondition(
                                  list.listingPlus,
                                  correct,
                                  wrong
                                )}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td />
                        {/* <td>{handleSelectButton("Select", "LISTING")}</td> */}
                        <td>{handleSelectButton("Select", "LISTING PLUS")}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-lg-none d-block">
                    <p className="text-center fs-16 fw-600 py-3 mb-0">
                      <FontAwesomeIcon icon={faArrowLeft} /> Scroll{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {offerPopup && (
        <Modal
          show={
            // false
            offerPopup
            // && userDetails?.service !== "LISTING PLUS"
          }
          backdrop={true}
          className="subscription-modal"
          centered
          size="lg"
        >
          <Modal.Body className="p-md-4 p-3">
            <div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              {offerListData ? (
                <div className="mt-md-4 mt-3">
                  {loading ? (
                    <CommonPageLoader />
                  ) : (
                    <>
                      <h3 className="d-flex justify-content-center mb-md-4 fs-26">
                        Offer Details
                      </h3>
                      <div className="d-flex">
                        <div className="d-flex align-items-center cursor-pointer">
                          {offerPage !== 1 && (
                            <FontAwesomeIcon
                              onClick={() => offerPagePagination()}
                              icon={faChevronLeft}
                              size="2x"
                              className="mx-2"
                            />
                          )}
                        </div>
                        <div className="w-100">
                          <div
                            className={
                              offerListData?.length > 2
                                ? "row"
                                : "d-flex flex-lg-row flex-xl-row flex-column justify-content-center"
                            }
                          >
                            {offerListData.map((list, index) => (
                              <div
                                className={`${offerListData?.length > 2
                                  ? "col-lg-4 col-md-4 col-12"
                                  : "col-lg-4 col-md-12 col-12 px-2"
                                  }  mb-3`}
                                key={index}
                              >
                                <div
                                  className={`pricing-card ${ternaryCondition(
                                    selectedOffer === list._id,
                                    "active",
                                    ""
                                  )}`}
                                  onClick={() => setSelectedOffer(list._id)}
                                >
                                  <h5 className="text-center">{list.title}</h5>
                                  <p className="text-center">
                                    <b>Percentage : </b>
                                    {list.offerPercentage}
                                    {"%"}
                                  </p>
                                  <p className="text-center">
                                    <b>Plan : </b>
                                    {list.offerPlan}{" "}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="d-flex align-items-center cursor-pointer">
                          {totalOfferPage !== offerPage && (
                            <FontAwesomeIcon
                              onClick={() => offerPagePagination(true)}
                              icon={faChevronRight}
                              size="2x"
                              className="mx-2"
                            />
                          )}
                        </div>
                      </div>

                      <div className="mx-auto d-flex flex-xl-row flex-lg-row flex-column align-items-center justify-content-between my-4">
                        <NormalButton
                          primary
                          label="Skip Offer"
                          className="submitOfferBtnClass fw-700 mb-xl-0 mb-lg-0 mb-3"
                          onClick={() => {
                            setOfferPopup(false);
                            setSubScriptionPopup(true);
                            checkActiveStatus();
                          }}
                        />
                        <NormalButton
                          primary
                          label="Change to Listing"
                          className="submitOfferBtnClass fw-700 mb-xl-0 mb-lg-0 mb-3"
                          onClick={() => handleContinueListing()}
                        />
                        <NormalButton
                          primary
                          label="Submit Offer"
                          disabled={!selectedOffer}
                          className="submitOfferBtnClass fw-700"
                          onClick={() => handleOfferSubmit()}
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                setSubScriptionPopup(true)
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {NewSubSCriptionPopup && (
        <Modal
          show={NewSubSCriptionPopup}
          backdrop={true}
          className="subscription-modal"
          centered
          size="lg"
        >
          <Modal.Body className="p-md-4 p-3">
            <div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              {OfferPricingDetails ? (
                <div className="mt-md-4 mt-3">
                  <h3 className="d-flex justify-content-center mb-md-4 fs-26">
                    Subscription Details
                  </h3>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mb-3">
                      <div>
                        <p className="text-center">
                          <b>Actual Price : </b>
                          {OfferPricingDetails.actualPrice}
                        </p>
                        <p className="text-center">
                          <b>Offer Percentage : </b>
                          {OfferPricingDetails.offerPercentage}
                          {"%"}
                        </p>
                        <p className="text-center">
                          <b>Offer Price: </b>
                          {OfferPricingDetails.price.toFixed(2)} + 18% GST
                        </p>
                        <p className="text-center">
                          <b>Total Net Payable: </b>
                          {/* {OfferPricingDetails.totalPrice.toFixed(2)} */}
                          {Math.round(OfferPricingDetails.totalPrice)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto text-center my-4">
                    <NormalButton
                      primary
                      label="Pay Now"
                      className="px-md-5 px-4 fw-700"
                      onClick={
                        !!OfferPricingDetails.actualPrice
                          ? () => handlePaymentOffer()
                          : () => freeSubscribtion()
                      }
                    />
                  </div>
                </div>
              ) : (
                setSubScriptionPopup(true)
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {subscriptionValid && (
        <Modal
          show={false}
          backdrop={true}
          className="subscription-modal"
          centered
          size="md"
        >
          <Modal.Body className="px-md-5 px-3 pt-md-5 pt-md-3 pb-3">
            <div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              <h4 className="mb-2 fs-15 line-height-21 fw-400 text-center mt-3 mt-md-4">
                Dear {userDetails?.firstName} thank you for trying The Chef
                Lane’s 3 Months Trial. Hope you enjoyed. We would like to inform
                you that your Trial has been ended.
              </h4>
              {/* <h4 className="mb-2 fs-15 line-height-21 fw-400 text-center">
                To continue enjoying your benefits please pay and proceed..
              </h4> */}
            </div>
            <Modal.Footer>
              <div className="d-flex mx-auto">
                <NormalButton
                  label="Quit"
                  className="w-100 white-btn"
                  onClick={() => setIsQuitConfirm(true)}
                />
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      )}

      {isQuitConfirm && (
        <Modal
          show={isQuitConfirm}
          backdrop={true}
          className="subscription-modal"
          centered
          size="md"
        >
          <Modal.Body className="px-md-5 px-3 pt-md-4 pt-md-3 pb-3">
            <div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              <h4 className="mb-2 fs-15 line-height-21 fw-400 text-center mt-3 mt-md-4">
                Are you sure you want to quit?
              </h4>
              <h4 className="mb-2 fs-15 line-height-21 fw-400 text-center">
                This will result in deactivation of your account
              </h4>
            </div>
            <Modal.Footer>
              <div className="d-flex mx-auto">
                <NormalButton
                  label="Yes, I want to Quit"
                  className="w-100 white-btn"
                  disabled={deactivateLoader}
                  onClick={() => handleQuit()}
                />
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      )}

      {licenseReviewPopup && (
        <Modal
          show={licenseReviewPopup}
          backdrop={true}
          className="subscription-modal"
          centered
          size="md"
        >
          <Modal.Body className="px-md-5 px-3 pt-md-4 pt-md-3 pb-3">
            <div>
              <div className="d-flex justify-content-end">
                <i
                  onClick={() => {
                    setLicenseReviewPopup(false);
                  }}
                  className="icon-close cursor-pointer ml-auto"
                />
              </div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              <h4 className="mb-2 fs-15 line-height-21 fw-400 text-center mt-3 mt-md-4">
                Your license is under review !
              </h4>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Bank */}
      {bankDetailsReviewPopup && (
        <Modal
          show={bankDetailsReviewPopup}
          backdrop={true}
          className="subscription-modal"
          centered
          size="md"
        >
          <Modal.Body className="px-md-5 px-3 pt-md-4 pt-md-3 pb-3">
            <div>
              <div className="d-flex justify-content-end">
                <i
                  onClick={() => {
                    setBankDetailsReviewPopup(false);
                  }}
                  className="icon-close cursor-pointer ml-auto"
                />
              </div>
              <div className="text-center">
                <img src={logo} alt="homechefLogo" width={150} height={100} />
              </div>
              <h4 className="mb-2 fs-15 line-height-21 fw-400 text-center mt-3 mt-md-4">
                Your Bank Details is under review !
              </h4>
            </div>
          </Modal.Body>
        </Modal>
      )}

    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      quitChefApi: quitChef,
      serviceSubscriptionsApi: serviceSubscriptions,
      changeServiceApi: changeService,
      servicePaymentApi: servicePayment,
      capturePaymentDetailsApi: capturePaymentDetails,
      capturePaymentFailureDetailsApi: capturePaymentFailureDetails,
      offersListApi: offersList,
      offerSubScriptionApi: offerSubScription,
      freeSubscriptionApi: freeSubscription,
      getSinglePending,
      // getSingle,
      continueAsListingApi,
      paymentDetailCaptureApi: paymentDetailCapture,
      getSingle,
      subscriptionInitiate,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(SideBar);
