import { addQuery } from "service/helperFunctions";
import { availabilityTime } from "../service/apiVariables";

export const getAvailabilityTime =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      addQuery(body, availabilityTime.getAvailabilityTime)
      return new Promise((resolve, reject) => {
        api({ ...availabilityTime.getAvailabilityTime })
          .then((response) => {
            resolve(response);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const editAvailabilityTime =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...availabilityTime.editAvailabilityTime, body, api: availabilityTime.editAvailabilityTime.api + body.chefId })
          .then(({ data, message }) => {
            Toast({ type: "success", message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };