import React from "react";
import "./style.scss";
import missionone from "../../assets/images/missin-one.png";
import missiontwo from "../../assets/images/missin-two.png";
import missionthree from "../../assets/images/missin-three.png";

export const Mission = () => {
  return (
    <div className="mission_container_background ">
      <div className="container mission_container ">
        <h1 className="text-primary-color line-height-42 fs-31 fw-700 text-center mb-lg-5 mb-4">
          Mission
        </h1>
        <div className="d-flex mb-4 justify-content-center align-items-center flex-wrap">
          <p className="col-12 text-center mb-0 fs-18">
            {" "}
            At The Chef Lane, we are <em>Here for Home Chefs</em>.
          </p>
          <p className="col-12 text-center mb-0 fs-18">
            We have a simple mission
          </p>

          <p className="col-12 text-center fs-18 text-gold fw-500">
            To enhance the lives, and businesses, of home chefs.
          </p>
        </div>
        <div className="col-lg-11 col-12 mx-auto">
          <div className="row mb-5 ">
            <div className="col-md-12 col-lg-4 mb-lg-4 mb-3">
              <div className="bg-white d-flex flex-column align-items-center px-3 mission-block ">
                <img alt="chef" className="mx-auto h-100" src={missionone} />
                <p className="pt-4 px-md-3 mx-auto mb-md-3 fs-16 mb-4 text-center">
                  We help create the ideal business environment, so chefs can
                  focus on their food.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mb-lg-4 mb-3">
              <div className="bg-white d-flex flex-column align-items-center px-3  mission-block">
                <img alt="chef" className="mx-auto h-100" src={missiontwo} />
                <p className="pt-4 px-md-3 mx-auto  mb-md-3 mb-4 fs-16 text-center">
                  Building a profile is just the first step on this journey.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bg-white d-flex flex-column align-items-center px-2  mission-block ">
                <img alt="chef" className="mx-auto h-100" src={missionthree} />
                <p className="pt-1 px-md-3 mx-auto mb-md-3 mb-4 fs-16 text-center mission-third">
                  We intend to develop an ecosystem around home catering, so we
                  can boost chefs' visibility through effective toolkits on
                  social media projection, nutritional data and best business
                  practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MissionComp = Mission;
