import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FaqComp } from "component/Faq";
export class FaqClass extends Component {
  componentDidMount() {
    let element = document.getElementById("faq");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <link rel="canonical" href="https://www.thecheflane.com/index/faq" />
          <title>
            FAQ | Home Chef near you | Get to Know what people ask us frequently
            | The Chef Lane
          </title>
          <meta
            property="og:title"
            content="FAQ | Home Chef near you | Get to Know what people ask us frequently | The Chef Lane"
          />
          <meta
            name="description"
            content="The Chef Lane: We know you might have some questions about us and how you can be part of this journey? Here are frequently asked question we usually encountered. Drop your questions and we will answer them too."
          />
          <meta
            name="keywords"
            content="homemade cake, homemade chocolate, homemade pizza, home made chocolates, home made cake, homemade food, homemade cakes near me, homemade snacks, homemade chocolate cake, home made food, homemade birthday cake, homemade pickles, homemade sweets, home cooked, home chef, chef cooked, homebakers near me, good baker, cooked dinner, cooked dishes, home cooked food delivered, home made food online, cooked food list, home cooked meals, home made food near me, home cooked meals delivered, authentic food, homebakers, home bread bakery, hand crafted chocolate, handcrafted cake, handmade cake, handmade homemade chocolate, handmade biscuits, handmade cookies"
          />
        </Helmet>
        <div id="faq">
          <FaqComp />
        </div>
      </>
    );
  }
}
export const Faq = connect(null, null)(FaqClass);
